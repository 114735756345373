import { motion, useAnimate } from "framer-motion";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useCallback, useRef } from "react";
import { observer } from "mobx-react";
import { useStores } from "@/hooks/useStore";
import { TradeMode } from "@/store/trade/TradeStore";
import { fontBold } from "@/style/style.global";

const LEFT_COLOR =
  "linear-gradient(88deg, #8FEF2F 0%, #10CE5C 50%, #B4FDFD 100%)";
const RIGHT_COLOR =
  "linear-gradient(92deg, #FEFEEB 0%, #FF1E2B 49.5%, #FFAFDA 100%)";

const FONT_COLOR = "var(--Deep-200, #b2b6bc)";
const FONT_ACTIVE_COLOR = "var(--White, #fff)";

const DURATION = {
  duration: 0.25,
};

const Container = styled.div`
  margin: 0 16px;
  width: calc(100% -32px);
  height: 40px;
  border-radius: 8px;
  background: var(--White, #fff);
  padding: 4px;
  display: flex;
  justify-content: center;
  gap: 2px;
  user-select: none;
`;

const FixedBlock = styled.div<{ $isActive?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  background: transparent;
  position: relative;

  color: ${(props) => (props.$isActive ? FONT_ACTIVE_COLOR : FONT_COLOR)};
  text-align: center;
  ${fontBold};
  font-size: 16px;

  cursor: pointer;
`;

const BlockText = styled(motion.span)`
  z-index: 10;
`;

const Slider = styled(motion.div)<{ $bgColor: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${(props) => props.$bgColor};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 40,
};

export const TradeModeToggle: React.FC = observer(() => {
  const { t } = useTranslation();
  const { tradeStore } = useStores();
  const mode = tradeStore.mode;
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [leftScope, leftAnimate] = useAnimate();
  const [rightScope, rightAnimate] = useAnimate();

  const handleSwitch = useCallback(
    (mode: TradeMode) => {
      if (mode === tradeStore.mode) {
        return;
      }
      tradeStore.switchMode(mode);
    },
    [tradeStore]
  );

  const handleClick = useCallback(
    (newMode: TradeMode, target: "left" | "right") => {
      if (target === "left") {
        rightAnimate(rightScope.current, { color: FONT_COLOR }, DURATION);
        leftAnimate(leftScope.current, { color: FONT_ACTIVE_COLOR }, DURATION);
      } else {
        rightAnimate(
          rightScope.current,
          { color: FONT_ACTIVE_COLOR },
          DURATION
        );
        leftAnimate(leftScope.current, { color: FONT_COLOR }, DURATION);
      }
      handleSwitch(newMode);
    },
    [leftAnimate, leftScope, handleSwitch, rightAnimate, rightScope]
  );

  return (
    <Container ref={wrapperRef}>
      {/* Left */}
      <FixedBlock
        $isActive={mode === TradeMode.Buy}
        onClick={() => handleClick(TradeMode.Buy, "left")}
      >
        <BlockText ref={leftScope}>{t("Buy")}</BlockText>
        {mode === TradeMode.Buy && (
          <Slider $bgColor={LEFT_COLOR} layoutId={"spot"} transition={spring} />
        )}
      </FixedBlock>

      {/* Right */}
      <FixedBlock
        $isActive={mode === TradeMode.Sell}
        onClick={() => handleClick(TradeMode.Sell, "right")}
      >
        <BlockText ref={rightScope}>{t("Sell")}</BlockText>
        {mode === TradeMode.Sell && (
          <Slider
            $bgColor={RIGHT_COLOR}
            layoutId={"spot"}
            transition={spring}
          />
        )}
      </FixedBlock>
    </Container>
  );
});
