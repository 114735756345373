import React, { useState } from "react";
import { useLocation, Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import {
  ROUTE_HOME,
  ROUTE_SETTINGS,
  ROUTE_TRADE,
} from "src/router/router-config";
import { NO_NAV_ROUTES } from "src/router/router-map";
import { useStores } from "src/hooks/useStore";
import HomeSvg from "src/components/navi-bar/HomeSvg";
import TradeSvg from "./TradeSvg";
import SettingsSvg from "src/components/navi-bar/SettingsSvg";

const NavigationBarContainer = styled.nav<{ $isOverWidth: boolean }>`
  position: ${(props) => (props.$isOverWidth ? "absolute" : "fixed")};
  bottom: 0;
  width: 100%;
  height: var(--navi-height);
  border-top: 1px solid var(--Deep-50, #edeef1);
  z-index: 99;
  overflow-y: visible;
`;

const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 32px;
  background: var(--White, #fff);
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const navigationBarMap = [
  {
    path: ROUTE_HOME,
    icon: HomeSvg,
    loginRequired: false,
    display: true,
  },
  {
    path: ROUTE_TRADE,
    icon: TradeSvg,
    loginRequired: true,
    display: true,
  },
  {
    path: ROUTE_SETTINGS,
    icon: SettingsSvg,
    loginRequired: true,
    display: true,
  },
];

const NavigationBar: React.FC = () => {
  const { uiStore, userStore } = useStores();
  const location = useLocation();
  const displayItems = navigationBarMap.filter((item) => item.display === true);
  const [hoverIndex, setHoverIndex] = useState<number>();
  const [searchParams] = useSearchParams();
  const hideNavigationBar = searchParams.get("hideNavigationBar");

  const isActive = (path: string): boolean => {
    return location.pathname.startsWith(path);
  };

  if (
    NO_NAV_ROUTES.some(
      (element) =>
        location.pathname.toLowerCase()?.includes(element.toLowerCase())
    ) ||
    hideNavigationBar
  ) {
    return <div></div>;
  } else {
    return (
      <NavigationBarContainer $isOverWidth={uiStore.isOverWidth}>
        <SubContainer>
          {displayItems.map((item, index) => (
            <Link
              key={item.path}
              to={
                userStore.pubKeyException && item.loginRequired
                  ? ROUTE_HOME
                  : item.path === ROUTE_TRADE
                    ? uiStore.tradeRoute
                    : item.path
              }
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(undefined)}
            >
              <item.icon
                isActive={isActive(item.path) || hoverIndex === index}
                disable={item.path === ROUTE_TRADE && userStore.pubKeyException}
              />
            </Link>
          ))}
        </SubContainer>
      </NavigationBarContainer>
    );
  }
};

export default observer(NavigationBar);
