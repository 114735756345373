import { useStores } from "src/hooks/useStore";
import { useEffect, useRef } from "react";
import { getTransactionReceipt } from "@wagmi/core";
import { wagmiConfig } from "src/configs/wagmi";
import { callSyncSingleBalance } from "src/apis/explorer-apis";

export const useWatchDefaultPendingTx = (
  callback?: (status: "success" | "reverted", explorerUrl: string) => void
) => {
  const { receiveStore, userStore } = useStores();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (receiveStore.pendingTxHashes.length === 0) {
      return;
    }

    timerRef.current = setInterval(async () => {
      if (receiveStore.pendingTxHashes.length === 0) {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        return;
      }

      try {
        const resp = await getTransactionReceipt(wagmiConfig, {
          hash: receiveStore.pendingTxHashes[0].hash as `0x${string}`,
          // @ts-ignore
          chainId: parseInt(receiveStore.pendingTxHashes[0].chainId),
        });

        if (resp.status === "success") {
          await callSyncSingleBalance({
            account: userStore.accountInfo?.name || "",
            chainIndex: receiveStore.pendingTxHashes[0].chainId.toString(),
            assetAddress: receiveStore.pendingTxHashes[0].address.toString(),
          });
        }

        callback?.(resp.status, receiveStore.pendingTxHashes[0].explorerUrl);
        receiveStore.removePendingTxHash(receiveStore.pendingTxHashes[0].hash);
      } catch (e) {
        console.log(e);
      }
    }, 8000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [
    callback,
    receiveStore,
    receiveStore.pendingTxHashes.length,
    userStore.accountInfo?.name,
  ]);
};
