import styled from "styled-components";
import warningIco from "src/assets/icons/guardian/warning.svg";
import successIco from "src/assets/icons/guardian/success.svg";
import networkImg from "src/assets/icons/guardian/bg-network.png";
import { fontBold } from "@/style/style.global";

const Contianer = styled.div`
  border-radius: 8px;
  background: var(--Deep-800, #1a2535);
  /* background: linear-gradient(88deg, #000d1f 0.94%, #2c3e59 98.56%);
  background-image:
    url(${networkImg}),
    lightgray 50% / cover no-repeat;
  background-blend-mode: soft-light; */
  background-blend-mode: soft-light;

  /* background-image: url(${networkImg}),
    linear-gradient(88deg, #000d1f 0.94%, #2c3e59 98.56%);
  background-color: lightgray;
  background-repeat: no-repeat; */
  // background-blend-mode: soft-light;
`;

const SubContainer = styled.div`
  position: relative;

  padding: 13px 25px;
  background-image: url(${networkImg});
  background-size: auto 90%; /* 设置背景图片的大小：宽度自动，高度为div高度的90% */
  background-position: bottom center; /* 设置背景图片底部对齐并在水平方向居中 */
  background-repeat: no-repeat; /* 设置背景图片不重复 */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 30.704px;
  height: 22.127px;
`;

const Title = styled.div<{ stateType: "success" | "warning" }>`
  margin-top: 6.87px;

  color: ${(props) =>
    props.stateType === "success"
      ? props.theme.colors.__white
      : props.theme.colors.__warning};
  text-align: center;
  ${fontBold};
  font-size: 14px;
`;

const Content = styled.div`
  margin-top: 5px;

  color: ${(props) => props.theme.colors.__white};
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const Button = styled.button<{
  stateType: "success" | "warning";
  $noContent: boolean;
}>`
  margin-top: ${(props) => (props.$noContent === true ? "16px" : "11px")};

  padding: 6px 6px;
  width: 110px;

  border: none;

  border-radius: 99px;
  background: ${(props) =>
    props.stateType === "success"
      ? props.theme.colors.__kontos_blue
      : props.theme.colors.__white};

  text-align: center;
  ${fontBold};
  font-size: 14px;
  color: ${(props) =>
    props.stateType === "success"
      ? props.theme.colors.__white
      : props.theme.colors.__error};

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const RightTopButton = styled.svg`
  position: absolute;
  top: 11px;
  right: 12px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export interface OneBtnPanelProps {
  type: "success" | "warning";
  title: string;
  content?: string;
  btnText: string;
  onClick: () => void;
  onRightTopClick?: () => void;
}

const OneBtnPanel: React.FC<OneBtnPanelProps> = ({
  type,
  title,
  content,
  btnText,
  onClick,
  onRightTopClick,
}) => {
  return (
    <Contianer>
      <SubContainer>
        <Icon src={type === "success" ? successIco : warningIco} />
        <Title stateType={type}>{title}</Title>
        {content && <Content>{content}</Content>}
        <Button stateType={type} $noContent={!content} onClick={onClick}>
          {btnText}
        </Button>
        {!!onRightTopClick && (
          <RightTopButton
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={onRightTopClick}
          >
            <circle cx="10" cy="10" r="10" fill="white" />
            <path
              d="M13.5355 11.3925L10 7.85693L6.46447 11.3925"
              stroke="#1A2535"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </RightTopButton>
        )}
      </SubContainer>
    </Contianer>
  );
};

export default OneBtnPanel;
