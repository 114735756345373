import { useTranslation } from "react-i18next";
import styled from "styled-components";
import blackArrowIco from "src/assets/icons/events/eb/right-black-arrow.svg";
import whiteArrowIco from "src/assets/icons/events/eb/right-white-arrow.svg";
import avatarImg from "src/assets/images/event/eb/avatar.svg";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { fontBold } from "@/style/style.global";

const Container = styled(motion.div)`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .text {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }

  .text-strong {
    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 12px;
  }

  .text-strong-2 {
    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 14px;
  }

  .top-box {
    width: 100%;
    height: 16px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* TODO: Eb */
    /* cursor: pointer;
    &:active {
      opacity: 0.9;
    }
    &:hover {
      opacity: 0.9;
    } */

    .arrow-icon {
      margin-left: 2px;
      width: 6px;
      height: 10px;
    }
  }

  .avatar-icon {
    margin-top: 4px;

    width: 40px;
    height: 40px;
  }

  .name-box {
    margin-top: 6px;
    height: 16px;
  }

  .ball-box {
    margin-top: 6px;
    height: 14px;
  }

  .backpack-box {
    margin-top: 16px;
    margin-bottom: 12px;
    border-radius: 99px;
    background: var(--Deep-800, #1a2535);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 13px 7px 17px;
    height: 28px;

    cursor: pointer;
    &:active {
      opacity: 0.9;
    }
    &:hover {
      opacity: 0.9;
    }

    .backpack-text {
      color: var(--White, #fff);
      ${fontBold};
      font-size: 12px;
      line-height: 14px;
    }

    .backpack-icon {
      margin-left: 5px;
      width: 10px;
      height: 10px;
    }
  }
`;

interface Props {
  accountName: string | undefined;
  accountCreditLevel: string | undefined;
  ballNum: string | undefined;
  onCreditClick: () => void;
  onBackpackClick: () => void;
}

const EbAccountPanelMain: React.FC<Props> = ({
  accountName,
  accountCreditLevel,
  ballNum,
  onCreditClick,
  onBackpackClick,
}) => {
  const { t } = useTranslation();

  return (
    <Container
    // TODO: eb anime
    // initial={{ x: 100, opacity: 0 }}
    // animate={{ x: 0, opacity: 1 }}
    // exit={{ x: -100, opacity: 0 }}
    // transition={{ ease: "easeInOut" }}
    >
      {/* TODO: Eb */}
      {/* <div className="top-box" onClick={onCreditClick}>
        <span className="text">{t("Account Credit:")}&nbsp;</span>
        <span className="text-strong">
          {accountCreditLevel || (
            <Skeleton count={1} style={{ width: "20px" }} />
          )}
        </span>
        <img className="arrow-icon" src={blackArrowIco} alt="" />
      </div> */}
      <div className="top-box"></div>
      <img className="avatar-icon" src={avatarImg} alt="" />
      <div className="name-box text-strong-2">
        {accountName ? (
          accountName.replaceAll(".os", "") + ".os"
        ) : (
          <Skeleton count={1} style={{ width: "70px" }} />
        )}
      </div>
      <div className="ball-box text">
        {t("Energy Orbs:")}{" "}
        {ballNum || <Skeleton count={1} style={{ width: "30px" }} />}
      </div>
      <div className="backpack-box" onClick={onBackpackClick}>
        <span className="backpack-text">{t("My Backpack")}</span>
        <img className="backpack-icon" src={whiteArrowIco} alt="" />
      </div>
    </Container>
  );
};

export default EbAccountPanelMain;
