import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { KontosQrReader } from "src/components/qr-reader/QrReader";
import styled from "styled-components";
import { useStores } from "src/hooks/useStore";
import { SheetView } from "src/store/SheetStore";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadingStore } from "src/store/loadingStore";
import PoweredBy from "src/components/common/powerdBy";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  .qr-title {
    margin-top: 23px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    white-space: nowrap;
    ${fontBold};
    font-size: 24px;
  }

  .qr-box {
    margin-top: 32px;
    width: 100%;
    background-color: transparent;
  }
`;

interface ContentProps {
  onClose: () => void;
}

const QrReaderWrapper: React.FC<ContentProps> = observer(({ onClose }) => {
  const { t } = useTranslation();
  const { dappConnectStore } = useStores();
  const [showQrReader, setShowQrReader] = useState<boolean>(true);
  const [uri, setUri] = useState<string>();

  const onError = useCallback(() => {
    setShowQrReader(false);
    onClose();
  }, [onClose]);

  const onConnect = useCallback(
    async (scannedUri: string) => {
      if (uri) {
        return;
      }
      setUri(scannedUri);
      try {
        loadingStore.showLoading();
        await dappConnectStore.onPair(scannedUri);
      } catch (e) {
        console.log("QrReaderSheet: Failed when pairing", e);
      } finally {
        loadingStore.hideLoading();
      }
    },
    [dappConnectStore, uri]
  );

  return (
    <Container>
      <div className="qr-title">{t("Scan the QR Code")}</div>
      {showQrReader && (
        <div className="qr-box">
          <KontosQrReader onConnect={onConnect} onError={onError} />
        </div>
      )}
      <Scrollable />
      <PoweredBy style={{ padding: "0" }} />
    </Container>
  );
});

interface Props {
  mountPoint: Element | undefined;
}

export const QrReaderSheet: React.FC<Props> = observer(({ mountPoint }) => {
  const { sheetStore } = useStores();
  const isOpen = sheetStore.sheetVisibility.get(SheetView.QrReader) as boolean;

  const onClose = useCallback(() => {
    sheetStore.closeSheetByView(SheetView.QrReader);
  }, [sheetStore]);

  return (
    <BottomSheet isOpen={isOpen} mountPoint={mountPoint} onClose={onClose}>
      <QrReaderWrapper onClose={onClose} />
    </BottomSheet>
  );
});
