import { t } from "i18next";
import styled from "styled-components";
import { KontosButton } from "src/components/button/KontosButton";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import { FtAsset } from "@/type/zkkontos";
import { useEffect, useState } from "react";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  padding: 0 24px 16px 24px;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  .top-16 {
    margin-top: 16px;
  }
`;

const CommonText = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const CommonText2 = styled.span`
  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

const StrongText = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontBold};
  font-size: 18px;
`;

const Title = styled.div`
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fontBold};
  font-size: 24px;
`;

const ItemList = styled.div`
  margin-top: 14px;

  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--Deep-400, #80868f);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .strong {
    color: var(--Deep-800, #1a2535);
  }

  &:not(:first-child) {
    margin-top: 16px;
    overflow: hidden;
    display: flex;
    align-items: space-between;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span:first-child {
    flex: 0 0 70%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span:not(:first-child) {
    text-align: right;
    flex: 0 0 30%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Spliter = styled.div`
  margin: 16px 0;
  height: 1px;
  background: #cccfd2;
`;

interface CostDetailModalProps {
  onDone?: () => void;
  taskData?: RespTaskPayment;
  targetAsset?: FtAsset;
}

const DETAIL_PRECISION = 8;

const CostDetailModal: React.FC<CostDetailModalProps> = ({
  onDone,
  taskData,
  targetAsset,
}) => {
  enum CostType {
    BASIC = "basic",
    TIP = "tip",
    ASSET = "asset",
  }
  interface RowPair {
    type: CostType;
    left: string;
    right: string;
  }

  // Calculated by back-end task detail
  const [basicCost, setBasicCost] = useState<RowPair[]>([]);
  const [tipCost, setTipCost] = useState<RowPair[]>([]);
  const [assetCost, setAssetCost] = useState<RowPair[]>([]);

  const getRow = (item: any): RowPair[] => {
    if (item.desc.startsWith("0x") && item?.asset?.amount) {
      return [
        {
          type: CostType.ASSET,
          left:
            "Required Asset: " +
            new KontosNumber(item.asset.amount, DEFAULT_DECIMAL).toFormat(
              DETAIL_PRECISION
            ) +
            " " +
            targetAsset?.name,
          right:
            "≈ $" +
            new KontosNumber(
              item.required_USD_amount,
              DEFAULT_DECIMAL
            ).toFormat(DETAIL_PRECISION),
        },
      ];
    }
    if (item.desc === "tip") {
      return [
        {
          type: CostType.TIP,
          left: item.desc,
          right:
            "≈ $" +
            new KontosNumber(
              item.required_USD_amount,
              DEFAULT_DECIMAL
            ).toFormat(DETAIL_PRECISION),
        },
      ];
    }
    if (!!item.desc) {
      return [
        {
          type: CostType.BASIC,
          left: item.desc,
          right:
            "≈ $" +
            new KontosNumber(
              item.required_USD_amount,
              DEFAULT_DECIMAL
            ).toFormat(DETAIL_PRECISION),
        },
      ];
    }
    return [];
  };

  useEffect(() => {
    let arr: RowPair[] = [];
    taskData
      ?.msgs!.map((task) => task.taskDetail)
      .forEach((taskDetail) =>
        taskDetail.cost_details.forEach((item) => {
          arr = arr.concat(getRow(item));
        })
      );

    setBasicCost(arr.filter((item) => item.type === CostType.BASIC));
    setTipCost(arr.filter((item) => item.type === CostType.TIP));
    setAssetCost(arr.filter((item) => item.type === CostType.ASSET));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title>{t("Cost Details")}</Title>
      <ItemList>
        {basicCost.map((row, index) => (
          <Item key={`item-${index}`}>
            <CommonText>{row.left}</CommonText>
            <CommonText>{row.right}</CommonText>
          </Item>
        ))}
        {basicCost?.length > 0 && <Spliter />}

        {tipCost.map((row, index) => (
          <Item key={`item-${index}`}>
            <CommonText>{row.left}</CommonText>
            <CommonText>{row.right}</CommonText>
          </Item>
        ))}
        {tipCost?.length > 0 && <Spliter />}

        {assetCost.map((row, index) => (
          <Item key={`item-${index}`}>
            <CommonText>{row.left}</CommonText>
            <CommonText>{row.right}</CommonText>
          </Item>
        ))}
        {assetCost?.length > 0 && <Spliter />}

        <Item>
          <CommonText2>{t("Total")}:</CommonText2>
          <StrongText>
            {"≈ $" +
              new KontosNumber(
                taskData?.totalPaymentsInUsd,
                DEFAULT_DECIMAL
              ).toFormat(DETAIL_PRECISION)}
          </StrongText>
        </Item>
      </ItemList>
      <KontosButton className="top-16" $isOutlined={false} onClick={onDone}>
        {t("Done")}
      </KontosButton>
    </Container>
  );
};

export default CostDetailModal;
