import styled, { css } from "styled-components";

const buttonHover = css<{
  $hoverColor?: string;
  $hoverBgColor?: string;
  $hoverOpacity?: number;
  $radius?: string | number;
}>`
  position: relative;
  &:hover::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: ${(props) => props.$hoverColor};
    background-color: ${(props) => props.$hoverBgColor};
    opacity: ${(props) => props.$hoverOpacity || 1};
    ${({ $radius }) =>
      $radius
        ? css`
            border-radius: ${typeof $radius === "number"
              ? `${$radius}px`
              : $radius};
          `
        : css`
            border-radius: 8px;
          `};
  }
`;

const buttonClick = css<{
  $activeColor?: string;
  $activeBgColor?: string;
  $activeOpacity?: number;
  $radius?: string | number;
}>`
  position: relative;
  &:active::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: ${(props) => props.$activeColor};
    background-color: ${(props) => props.$activeBgColor};
    opacity: ${(props) => props.$activeOpacity || 1};
    ${({ $radius }) =>
      $radius
        ? css`
            border-radius: ${typeof $radius === "number"
              ? `${$radius}px`
              : $radius};
          `
        : css`
            border-radius: 8px;
          `};
  }
`;

const buttonDisabled = css<{
  disabled?: boolean;
  $disabledColor?: string;
  $disabledBgColor?: string;
}>`
  ${(props) =>
    props.disabled &&
    `
      color: ${props.$disabledColor};
      background-color: ${props.$disabledBgColor};
    `}
`;

const buttonLoading = css<{
  $loading?: boolean;
  $loadingColor?: string;
  $loadingBgColor?: string;
}>`
  ${(props) =>
    props.$loading &&
    `
      color: ${props.$loadingColor};
      background-color: ${props.$loadingBgColor};
    `}
`;

const BaseButton = styled.button<{
  $width?: string | number;
  $height?: string | number;
  $radius?: string | number;
  $loading?: boolean;
  $color?: string;
  $bgColor?: string;
  $hoverColor?: string;
  $hoverBgColor?: string;
  $hoverOpacity?: number;
  $activeColor?: string;
  $activeBgColor?: string;
  $activeOpacity?: number;
  $disabledColor?: string;
  $disabledBgColor?: string;
  $loadingColor?: string;
  $loadingBgColor?: string;
}>`
  /* Clear default style */
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;

  /* Default to center */
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $height }) =>
    $height &&
    css`
      height: ${typeof $height === "number" ? `${$height}px` : $height};
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${typeof $width === "number" ? `${$width}px` : $width};
    `};

  ${({ $radius }) =>
    $radius
      ? css`
          border-radius: ${typeof $radius === "number"
            ? `${$radius}px`
            : $radius};
        `
      : css`
          border-radius: 8px;
        `};

  cursor: ${(props) =>
    props.$loading ? "wait" : props.disabled ? "not-allowed" : "pointer"};
  ${buttonHover}
  ${buttonClick}
  ${(props) =>
    props.$loading ? buttonLoading : props.disabled ? buttonDisabled : ""}
`;

export default BaseButton;
