import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import { ReactNode } from "react";
import { KontosButton } from "src/components/button/KontosButton";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { useNavigate } from "react-router-dom";
import { ROUTE_TRADE } from "src/router/router-config";
import { CloseButton } from "src/components/button/CloseButton";
import { BASIC_SHEET_INDEX } from "src/config";
import loadingIcon from "src/assets/icons/white-linear-loading.svg";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  position: relative;
  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 16px 39px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  > .title {
    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 18px;
    line-height: 22px;
  }

  > .content {
    margin-top: 12px;
    padding: 0 11px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }

  > .list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

const StyledKontosButton = styled(KontosButton)<{ bgColor?: string }>`
  width: 72px;
  height: 24px;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  padding: 4px;

  ${(props) =>
    props.bgColor &&
    `
    background-color: ${props.bgColor} !important
  `}
`;

const StyledDeepButton = styled(DeepButton)`
  width: 72px;
  height: 24px;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  padding: 4px;
`;

const LoadingIcon = styled.img`
  display: span;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
`;

const ItemContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: var(--Deep-25, #f5f5f6);
  padding: 15px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    margin-right: 20px;
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
    display: flex;
  }

  .right {
    width: 72px;
    height: 24px;
  }
`;

const Item: React.FC<{ index: number; text: string; btn: ReactNode }> = ({
  index,
  text,
  btn,
}) => {
  return (
    <ItemContainer>
      <div className="left">
        <div className="left-left">{index}.&nbsp;</div>
        <div className="left-right">{text}</div>
      </div>
      <div className="right">{btn}</div>
    </ItemContainer>
  );
};

interface Props {
  onClose: (open: boolean) => void;
  onAuth: (authType: number) => void;
  discordPending: boolean;
  twitterPending: boolean;
}

export const EbNoBoostPreconditionPopup: React.FC<Props> = ({
  onClose,
  onAuth,
  discordPending,
  twitterPending,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const title = t("Oops!");
  const content = t(
    "You must complete any of the following tasks to obtain the qualification for boosting other users."
  );
  const items = [
    {
      index: 1,
      text: t("Follow @Kontosio on X (Twitter)"),
      btn: twitterPending ? (
        <StyledKontosButton
          disabled
          bgColor={"var(--Kontos-Blue, #413DEC)"}
          onClick={() => {}}
        >
          <LoadingIcon src={loadingIcon} alt="Pending" />
        </StyledKontosButton>
      ) : (
        <StyledKontosButton
          $isOutlined
          onClick={() => onAuth(ebTypes.EB_SOCIAL_ACTION_TYPE_TWITTER)}
        >
          {t("Follow")}
        </StyledKontosButton>
      ),
    },
    {
      index: 2,
      text: t("Join Discord Server"),
      btn: discordPending ? (
        <StyledKontosButton
          disabled
          bgColor={"var(--Kontos-Blue, #413DEC)"}
          onClick={() => {}}
        >
          <LoadingIcon src={loadingIcon} alt="Pending" />
        </StyledKontosButton>
      ) : (
        <StyledKontosButton
          $isOutlined
          onClick={() => onAuth(ebTypes.EB_SOCIAL_ACTION_TYPE_DISCORD)}
        >
          {t("Join Now")}
        </StyledKontosButton>
      ),
    },
    {
      index: 3,
      text: t("Trade on Any Chain(exclude Kontos)"),
      btn: (
        <StyledDeepButton onClick={() => navigate(ROUTE_TRADE)}>
          {t("Go")}
        </StyledDeepButton>
      ),
    },
  ];

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <CloseButton className="close-btn" onClick={() => onClose(false)} />
        <div className="title">{title}</div>
        <div className="content">{content}</div>

        <div className="list">
          {items.map((item) => (
            <Item
              key={item.index}
              index={item.index}
              text={item.text}
              btn={item.btn}
            ></Item>
          ))}
        </div>
      </Container>
    </Overlay>
  );
};
