import { MAX_HEIGHT, MAX_WIDTH } from "src/config";
import styled from "styled-components";
import { Sheet } from "react-modal-sheet";

const bgPath = "/static/h5-bg.png";

export const FullPageDisplayWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const ParitalDisplayWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${bgPath});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
`;

export const ParitalDisplayBox = styled.div`
  width: ${MAX_WIDTH}px;
  height: min(calc(100vh - 40px), ${`${MAX_HEIGHT}px`});
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto !important;
  overflow-x: hidden !important;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }

  display: flex;
  flex-direction: column;

  border-radius: 16px;
  background: #fff;
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.1);

  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

export interface ModalBoxProps {
  onModalChange?: (isOpen: boolean) => void;
}

export const NoScrollerBarSheet = styled(Sheet)`
  .react-modal-sheet-scroller {
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-ms-scrollbar {
      width: 0;
    }
  }

  .react-modal-sheet-header {
    height: 32px !important;
  }

  /* .react-modal-sheet-content {
    user-select: text !important;
  } */
`;
