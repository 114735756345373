import {
  MANTLE_RPC_URL,
  WALLET_CONNECT_METADATA,
  WALLET_CONNECT_PROJECT_ID,
} from "src/config";
import { http, createConfig } from "wagmi";
import {
  base,
  mainnet,
  polygon,
  bsc,
  mantle,
  optimism,
  manta,
  xLayer,
  opBNB,
  fantom,
  sei,
  zetachain,
  arbitrum,
  avalanche,
  linea,
  blast,
  scroll,
} from "wagmi/chains";
import { injected, walletConnect } from "wagmi/connectors";

export const walletConnectConnector = walletConnect({
  projectId: WALLET_CONNECT_PROJECT_ID,
  metadata: WALLET_CONNECT_METADATA,
});

export const wagmiConfig = createConfig({
  chains: [
    mainnet,
    base,
    polygon,
    bsc,
    mantle,
    optimism,
    manta,
    xLayer,
    opBNB,
    fantom,
    sei,
    zetachain,
    arbitrum,
    avalanche,
    linea,
    blast,
    scroll,
  ],
  connectors: [injected(), walletConnectConnector],
  transports: {
    [mainnet.id]: http(),
    [base.id]: http(),
    [polygon.id]: http(),
    [bsc.id]: http(),
    [mantle.id]: http(MANTLE_RPC_URL),
    [optimism.id]: http(),
    [manta.id]: http(),
    [xLayer.id]: http(),
    [opBNB.id]: http(),
    [fantom.id]: http(),
    [sei.id]: http(),
    [zetachain.id]: http(),
    [arbitrum.id]: http(),
    [avalanche.id]: http(),
    [linea.id]: http(),
    [blast.id]: http(),
    [scroll.id]: http(),
  },
});
