import { ReactNode } from "react";
import { Sheet } from "react-modal-sheet";
import { NoScrollerBarSheet } from "../wrapper/ReactiveWrapper";
import { BASIC_SHEET_INDEX } from "src/config";

interface Props {
  isOpen: boolean;
  children?: ReactNode;
  onClose?: () => void;
  mountPoint: Element | undefined;
  customHeight?: number;
  disableDrag?: boolean;
  zExtraIndex?: number;
  disableScrollLocking?: boolean;
  snapPoints?: number[];
}

export const BottomSheet: React.FC<Props> = ({
  isOpen,
  children,
  onClose,
  mountPoint,
  customHeight,
  disableDrag = false,
  zExtraIndex = 0,
  disableScrollLocking = true,
  snapPoints,
}) => {
  return (
    <NoScrollerBarSheet
      isOpen={isOpen}
      onClose={() => onClose?.()}
      mountPoint={mountPoint}
      detent={!!customHeight ? "content-height" : "full-height"}
      disableDrag={disableDrag}
      style={{ zIndex: BASIC_SHEET_INDEX + zExtraIndex }}
      disableScrollLocking={disableScrollLocking}
      snapPoints={snapPoints}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          {!!customHeight ? (
            <div
              style={{
                height: customHeight,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              {children}
            </div>
          ) : (
            <>{children}</>
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </NoScrollerBarSheet>
  );
};
