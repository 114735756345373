import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BackIcon from "src/assets/icons/back-btn.svg";
import React, { useEffect } from "react";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div<{ $transparent: boolean }>`
  padding: 20px;
  background: #fff;

  ${(props) =>
    props.$transparent &&
    `
    background: transparent;
  `}
  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: 100%;
    color: #000d1f;
    line-height: 19px;
    ${fontBold};
    font-size: 24px;
    font-style: normal;

    .icon-box {
      position: absolute;
      left: 0;
      top: -2.5px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10000;

      &:hover {
        background: #edeef1;
        border-radius: 4px;
      }
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    .rightText {
      position: absolute;
      right: 0px;
      bottom: 0px;
      color: var(--Kontos-Blue, #413dec);
      text-align: right;

      /* H6 */
      ${fontBold}
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      cursor: pointer;
    }
  }
`;
type IHeaderProps = {
  title: string;
  transparent?: boolean;
  handleBack?(): void;
  padding?: string;
  ignored?: boolean;
  costDetailShow?: boolean;
  rightText?: string | undefined | null;
  rightClickFunc?(): void;
  headStyle?: any;
  rightComponent?: React.ReactNode;
};
const SheetUpHeader: React.FC<IHeaderProps> = ({
  title,
  transparent = false,
  ignored = false,
  handleBack,
  padding = "20px",
  costDetailShow = false,
  headStyle = {},
  rightText,
  rightClickFunc,
  rightComponent,
}) => {
  return (
    <Wrapper $transparent={transparent} style={{ padding }}>
      <div style={{ ...headStyle }} className={`head `}>
        {handleBack && (
          <div
            className="icon-box"
            onClick={(e) => {
              handleBack();
            }}
          >
            <img src={BackIcon} className="icon" alt="back-icon" />
          </div>
        )}
        <span>{title}</span>
        {rightText && (
          <div
            onClick={() => {
              rightClickFunc && rightClickFunc();
            }}
            className={"rightText"}
          >
            {rightText}
          </div>
        )}
        {rightComponent}
      </div>
    </Wrapper>
  );
};

export default SheetUpHeader;
