import CircleIconPair from "src/components/icons/CircleIconPair";
import styled from "styled-components";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import { fontH7, fontSmaller } from "@/style/style.global";
import { useState } from "react";
import tipIcon from "./tip-icon.svg";
import { useTranslation } from "react-i18next";
import expandIcon from "./expand-icon.svg";
import expandActiveIcon from "./expand-active-icon.svg";
import { FtAsset } from "@/type/zkkontos";
import { getChainByAsset } from "@/store/storeHelper";
import TokensIcon from "@/components/icons/TokensIcon";
import {
  DEFAULT_ASSET_ICON_OMIT,
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
} from "@/config";
import {
  bulkConvertFtAssetToUniformedPayment,
  isMobileDevice,
} from "@/utils/helper";
import { getIconListWithOmit } from "@/utils/zkkontosHelper";
import KontosNumber from "@/utils/KontosNumber";

const MAIN_PANEL_HEIGHT = "58px";

const Container = styled.div<{ $isSelected: boolean }>`
  flex: 1;
  min-height: ${MAIN_PANEL_HEIGHT};
  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background: var(--White, #fff);
`;

const MainPanel = styled.div<{ $isExpanded: boolean; $isSelected: boolean }>`
  padding: 8px 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${MAIN_PANEL_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.$isSelected
        ? "var(--Kontos-Blue, #413dec)"
        : "var(--Deep-50, #edeef1)"};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
    }
  }
  background: ${(props) =>
    props.$isSelected ? "#ECECFD" : "var(--White, #fff)"};
`;

const ExpandButton = styled.button`
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const ExpandButtonIcon = styled.img<{ $isExpanded: boolean }>`
  width: 16px;
  height: 16px;
  transform: ${(props) => (props.$isExpanded ? "rotate(180deg)" : "")};
`;

const ExpandButtonText = styled.span`
  overflow: hidden;
  color: var(--Kontos-Blue, #413dec);
  text-align: right;
  text-overflow: ellipsis;

  ${fontSmaller}
`;

const MainPanelContent = styled.div`
  flex: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const MainPanelLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const MainPanelLeftAssetChainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

const AssetLine = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontH7}
`;

const ChainLine = styled.span`
  color: var(--Deep-400, #80868f);
  ${fontSmaller}
`;

const MainPanelRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  text-overflow: ellipsis;
`;

const PlanNameLine = styled.span`
  color: var(--Deep-800, #1a2535);
  ${fontH7}
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PriceLine = styled.span`
  color: var(--Deep-400, #80868f);
  ${fontSmaller}
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ExpandPanel = styled.div<{ $isEmpty?: boolean }>`
  width: 100%;
  padding: 66px 8px 8px 8px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background: ${(props) => (props.$isEmpty ? "#ececfd;" : "transparent")};
`;

const ExpandPanelEmptyLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  -webkit-tap-highlight-color: transparent;
`;

const ItemTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  justify-content: center;
  align-items: flex-start;
`;

const ItemTextContainer2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-end;
`;

const ItemText = styled.div`
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  ${fontH7}
`;

const ItemText2 = styled.div`
  margin-top: 2px;
  color: var(--Deep-400, #80868f);
  ${fontSmaller}
`;

const TipIcon = styled.img`
  width: 21px;
  height: 12px;
  flex-shrink: 0;
`;

const TipText = styled.span`
  color: var(--Kontos-Blue, #413dec);
  ${fontSmaller}
`;

interface Props {
  title: string;
  subTitle: string;
  className?: string;
  style?: React.CSSProperties;
  isExpanded: boolean;
  isSelected: boolean;
  assets?: FtAsset[];
  onExpand: () => void;
  onSelect?: () => void;
}

export const PaymentButton: React.FC<Props> = ({
  title,
  subTitle,
  className,
  style,
  isExpanded,
  isSelected,
  assets,
  onExpand,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [showSelectText, setShowSelectText] = useState<boolean>(false);

  return (
    <Container className={className} style={style} $isSelected={isSelected}>
      <MainPanel $isExpanded={isExpanded} $isSelected={isSelected}>
        <MainPanelContent onClick={onSelect}>
          <MainPanelLeft>
            {assets !== undefined ? (
              assets.length === 1 ? (
                <>
                  <CircleIconPair
                    mainIcon={assets[0].imageUrl}
                    mainIconFallbackSrc={defaultTokenIcon}
                    subIcon={getChainByAsset(assets[0])?.greyImageURL}
                    subIconFallbackSrc={defaultChainIcon}
                    mainWidth={32}
                    mainHeight={32}
                    subWidth={16}
                    subHeight={16}
                    totalWidth={40}
                    totalHeight={32}
                  />
                  <MainPanelLeftAssetChainBox>
                    <AssetLine>{assets[0].symbol}</AssetLine>
                    <ChainLine>
                      {getChainByAsset(assets[0])?.chainSymbol}
                    </ChainLine>
                  </MainPanelLeftAssetChainBox>
                </>
              ) : (
                <>
                  <TokensIcon
                    iconList={getIconListWithOmit(
                      bulkConvertFtAssetToUniformedPayment(assets),
                      isMobileDevice() ? 2 : DEFAULT_ASSET_ICON_OMIT
                    )}
                    size={32}
                    offset={10}
                    max={5}
                  />
                </>
              )
            ) : (
              <>
                <CircleIconPair
                  mainIcon={defaultTokenIcon}
                  mainIconFallbackSrc={defaultTokenIcon}
                  subIcon={defaultChainIcon}
                  subIconFallbackSrc={defaultChainIcon}
                  mainWidth={32}
                  mainHeight={32}
                  subWidth={16}
                  subHeight={16}
                  totalWidth={40}
                  totalHeight={32}
                />
                <MainPanelLeftAssetChainBox>
                  <AssetLine>...</AssetLine>
                  <ChainLine>....</ChainLine>
                </MainPanelLeftAssetChainBox>
              </>
            )}
          </MainPanelLeft>

          <MainPanelRight>
            <PlanNameLine>{title}</PlanNameLine>
            <PriceLine>{subTitle}</PriceLine>
          </MainPanelRight>
        </MainPanelContent>

        <ExpandButton
          onMouseEnter={() => setShowSelectText(true)}
          onMouseLeave={() => setShowSelectText(false)}
          onClick={onExpand}
        >
          <ExpandButtonIcon
            $isExpanded={isExpanded}
            src={showSelectText ? expandActiveIcon : expandIcon}
            alt=""
          />
          {showSelectText && <ExpandButtonText>{t("Detail")}</ExpandButtonText>}
        </ExpandButton>
      </MainPanel>

      {isExpanded && (
        <>
          {assets !== undefined && assets?.length > 0 ? (
            <ExpandPanel>
              {assets.map((asset, index) => {
                return (
                  <ItemContainer key={index}>
                    <CircleIconPair
                      mainIcon={asset.imageUrl || defaultTokenIcon}
                      mainIconFallbackSrc={defaultTokenIcon}
                      subIcon={
                        getChainByAsset(asset)?.greyImageURL || defaultChainIcon
                      }
                      subIconFallbackSrc={defaultChainIcon}
                      mainWidth={32}
                      mainHeight={32}
                      subWidth={16}
                      subHeight={16}
                      totalWidth={40}
                      totalHeight={32}
                    />
                    <ItemTextContainer>
                      <ItemText>{asset.symbol}</ItemText>
                      <ItemText2>
                        {getChainByAsset(asset)?.chainSymbol}
                      </ItemText2>
                    </ItemTextContainer>
                    <ItemTextContainer2>
                      <ItemText>
                        {new KontosNumber(
                          asset.balance?.balance,
                          DEFAULT_DECIMAL
                        ).toFormat(DEFAULT_DISPLAY_PRECESION)}
                      </ItemText>
                      <ItemText2>
                        {new KontosNumber(
                          asset.balance?.balanceUsdAmount,
                          DEFAULT_DECIMAL
                        ).toFormat(DEFAULT_DISPLAY_PRECESION) + " USD"}
                      </ItemText2>
                    </ItemTextContainer2>
                  </ItemContainer>
                );
              })}
            </ExpandPanel>
          ) : (
            <ExpandPanel $isEmpty>
              <ExpandPanelEmptyLine>
                <TipIcon src={tipIcon} alt="" />
                <TipText>
                  {t("The recommend plan is system-generated.")}
                </TipText>
              </ExpandPanelEmptyLine>
            </ExpandPanel>
          )}
        </>
      )}
    </Container>
  );
};
