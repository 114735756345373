import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tipsIcon from "src/assets/icons/trade/tip.svg";
import { fontDescription } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  padding: 60px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const TipsIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const Title = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontDescription}
`;

const Desc = styled.span`
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontDescription}
`;

export const NoMantleAddressMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TipsIcon src={tipsIcon} alt="" />
      <Title>{t("Please use the button below to make your deposit.")}</Title>
      <Desc>
        {t(
          "Note: The MNT deposit task in the Bybit event is exclusively accessible via this button at the moment."
        )}
      </Desc>
    </Container>
  );
};
