import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AssetChange, Task, TaskDetail } from "src/type/zkkontos";
import InfiniteScroll from "react-infinite-scroll-component";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { t } from "i18next";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { EndMessage } from "src/components/no-data/EndMessage";
import { BottomSheet } from "../bottom-sheet/BottomSheet";
import {
  RecordOverviewDisplayProps,
  TaskOrActivityOverviewItem,
  transferAcToDisplayData,
  transferTaskToDisplayData,
} from "./TaskOrActivityOverviewItem";
import { TaskDetailView } from "./TaskDetailView";
import { callSignerNativeTxsAssetsChange } from "src/apis/kontos-chain-api";
import localKeeper from "src/store/localKeeper";
import toast from "../toast/Toast";
import { ActivityDetail } from "./ActivityDetail";
import { useStores } from "src/hooks/useStore";
import { fetchAccountTasks } from "@/service/task-service";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const LoadingGif = styled.img`
  text-align: center;
  height: 20px;
  width: 100px;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const LIMIT = 15;
const REFRESH_INTERVAL = 45000;

interface IProps {
  accountName: string;
  type: "task" | "activity";
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  scrollStyle?: React.CSSProperties;
}

export const TaskOrActivityList: React.FC<IProps> = ({
  accountName,
  type,
  wrapperClassName,
  wrapperStyle,
  scrollStyle,
}) => {
  const { userStore, tradeStore } = useStores();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentAssetChanges, setCurrentAssetChanges] = useState<AssetChange[]>(
    []
  );
  const [currentTasks, setCurrentTasks] = useState<Task[]>([]);
  const [beRecords, setBeRecords] = useState<RecordOverviewDisplayProps[]>([]);
  const [records, setRecords] = useState<RecordOverviewDisplayProps[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showTaskDetail, setShowTaskDetail] = useState<boolean>(false);
  const [showActivityDetail, setShowActivityDetail] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [taskPreview, setTaskPreview] = useState<Task>();
  const [assetChange, setAssetChange] = useState<AssetChange>();
  const requestingTimestampRef = useRef<number>(0);

  useEffect(() => {
    if (beRecords.length === 0) return;

    const localRecords =
      localKeeper
        .getLocalRecords(accountName, type)
        .map((item) => item.record) || [];
    const availableLocalRecords = localRecords.filter(
      (item) => item.createdAt > beRecords[beRecords.length - 1].createdAt
    );

    if (availableLocalRecords.length > 0) {
      userStore.fetchAndUpdateAccountBalances();

      const uniqueItemsMap = new Map<string, RecordOverviewDisplayProps>();

      beRecords.forEach((item) => {
        uniqueItemsMap.set(item.id, item);
      });

      availableLocalRecords.forEach((item) => {
        if (!uniqueItemsMap.has(item.id)) {
          uniqueItemsMap.set(item.id, item);
        }
      });

      const uniqueItemsArray = Array.from(uniqueItemsMap.values());
      uniqueItemsArray.sort((a, b) => b.createdAt - a.createdAt);

      localKeeper.removeLocalRecord([...uniqueItemsMap.keys()]);

      setRecords(uniqueItemsArray);
    } else {
      setRecords(beRecords);
    }
  }, [accountName, beRecords, type, userStore]);

  const fetchMoreData = useCallback(async () => {
    if (type === "task") {
      const timestamp = Date.now();
      requestingTimestampRef.current = timestamp;

      try {
        const { tasks, hasMore } = await fetchAccountTasks(
          accountName,
          beRecords.length,
          LIMIT
        );
        const arr = tasks || [];
        setBeRecords(
          beRecords.concat(arr.map((task) => transferTaskToDisplayData(task)))
        );
        setCurrentTasks(currentTasks.concat(tasks));
        setHasMore(hasMore);
      } catch (e) {
        console.warn(e);
      }
      return;
    }

    if (type === "activity") {
      const timestamp = Date.now();
      requestingTimestampRef.current = timestamp;

      try {
        const { assetChange, total } = await callSignerNativeTxsAssetsChange({
          signer: accountName,
          offset: beRecords.length,
          limit: LIMIT,
        });
        const arr = assetChange || [];
        setBeRecords(
          beRecords.concat(arr.map((item) => transferAcToDisplayData(item)))
        );
        setCurrentAssetChanges(currentAssetChanges.concat(assetChange));
        setHasMore(arr.length + beRecords.length < total);
      } catch (e) {
        console.warn(e);
      }
      return;
    }
  }, [accountName, beRecords, currentAssetChanges, currentTasks, type]);

  useEffect(() => {
    if (type === "task") {
      const refreshData = async () => {
        const timestamp = Date.now();
        requestingTimestampRef.current = timestamp;

        try {
          const { tasks, hasMore } = await fetchAccountTasks(
            accountName,
            0,
            currentTasks.length
          );
          if (timestamp === requestingTimestampRef.current) {
            setBeRecords(tasks.map((task) => transferTaskToDisplayData(task)));
            setCurrentTasks(tasks);
            setHasMore(hasMore);
          }
        } catch (e) {
          console.log("task refresh exception", e);
        }
      };

      const intervalId = setInterval(refreshData, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    if (type === "activity") {
      const refreshData = async () => {
        const timestamp = Date.now();
        requestingTimestampRef.current = timestamp;

        try {
          const { assetChange, total } = await callSignerNativeTxsAssetsChange({
            signer: accountName,
            offset: 0,
            limit: currentAssetChanges.length,
          });
          setBeRecords(
            assetChange.map((item) => transferAcToDisplayData(item))
          );
          setCurrentAssetChanges(assetChange);
          setHasMore(assetChange.length + beRecords.length < total);
        } catch (e) {
          console.log("activity refresh exception", e);
        }
      };

      const intervalId = setInterval(refreshData, REFRESH_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [accountName, beRecords, currentAssetChanges, currentTasks, type]);

  useEffect(() => {
    fetchMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountName]);

  const handleTaskOnClick = useCallback(
    (record: RecordOverviewDisplayProps) => {
      if (record.isFake) {
        toast({
          text: t(
            "Our monitor has not captured this transaction yet. Please try again later"
          ),
          type: "warning",
        });
        return;
      }

      if (!!record.activityType) {
        const matched = currentAssetChanges.find(
          (ac) => ac.assetChangeId.toString() === record.id
        );
        if (matched) {
          setAssetChange(matched);
          setShowActivityDetail(true);
        }
        return;
      }

      if (!!record.tasktype) {
        const matched = currentTasks.find((task) => task.opHash === record.id);
        if (matched) {
          setTaskPreview(matched);
          setShowTaskDetail(true);
        }
        return;
      }
    },
    [currentAssetChanges, currentTasks]
  );

  useEffect(() => {
    if (tradeStore.newestOpHash !== undefined) {
      const now = Date.now();
      if (now <= tradeStore.newestOpHash.validUntil) {
        const matchedRecord = records.find(
          (record) => record.id === tradeStore.newestOpHash?.opHash
        );
        if (matchedRecord !== undefined) {
          handleTaskOnClick(matchedRecord);
          tradeStore.setNewestOpHash(undefined);
        }
      } else {
        tradeStore.setNewestOpHash(undefined);
      }
    }
  }, [handleTaskOnClick, records, tradeStore, tradeStore.newestOpHash]);

  const updateTaskFromDetail = useCallback(
    (taskFull: TaskDetail) => {
      if (taskPreview && showTaskDetail) {
        setRecords((currentTaskRecords) =>
          currentTaskRecords.map((taskRecord) =>
            taskRecord.id === taskFull.opHash
              ? transferTaskToDisplayData(taskFull)
              : taskRecord
          )
        );
      }
    },
    [showTaskDetail, taskPreview]
  );

  useMouseDownOutside({
    ref: wrapperRef,
    callback: () => {
      setShowTaskDetail(false);
      setShowActivityDetail(false);
    },
    shouldClose: true,
  });

  return (
    <Container
      ref={wrapperRef}
      className={wrapperClassName}
      style={wrapperStyle}
    >
      {!hasMore && records?.length === 0 ? (
        <NoDataV2
          style={{
            marginTop: "50px",
          }}
          className="dapp-no-data"
          text={t("No Data")}
        />
      ) : (
        <Scrollable id="tasksScrollableDiv" ref={scrollRef}>
          <InfiniteScroll
            dataLength={records.length} // The current length of displayed data
            next={fetchMoreData} // The function to trigger to load more
            hasMore={hasMore} // Whether there are more items to load
            loader={
              <div style={{ marginTop: "24px", textAlign: "center" }}>
                <LoadingGif src="/static/loading.gif" alt="Loading..." />
              </div>
            }
            endMessage={<EndMessage />}
            scrollableTarget="tasksScrollableDiv"
            style={scrollStyle}
          >
            {records?.map((record) => {
              return (
                <TaskOrActivityOverviewItem
                  key={record.id}
                  displayItem={record}
                  onClick={() => {
                    handleTaskOnClick(record);
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </Scrollable>
      )}

      <BottomSheet
        isOpen={showTaskDetail}
        onClose={() => setShowTaskDetail(false)}
        mountPoint={domNode}
        zExtraIndex={1}
      >
        <TaskDetailView
          accountName={accountName}
          taskPreview={taskPreview!}
          onBack={() => {
            setShowTaskDetail(false);
          }}
          onUpdate={updateTaskFromDetail}
        />
      </BottomSheet>

      <BottomSheet
        isOpen={showActivityDetail}
        onClose={() => setShowActivityDetail(false)}
        mountPoint={domNode}
        zExtraIndex={1}
      >
        <ActivityDetail
          assetChange={assetChange!}
          onBack={() => {
            setShowActivityDetail(false);
          }}
        />
      </BottomSheet>
    </Container>
  );
};
