import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { FtAsset } from "@/type/zkkontos";
import { observer } from "mobx-react";
import { t } from "i18next";
import { loadingStore } from "src/store/loadingStore";
import { DEFAULT_DISPLAY_PRECESION } from "src/config";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "src/router/router-config";
import { isKontosChain } from "src/utils/helper";
import { fetchMaxFtAssetToSend } from "@/service/transfer-service";
import KontosNumber from "src/utils/KontosNumber";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import TransferAssetStep from "./steps/TransferAssetStep";
import TransferInputStep from "./steps/TransferInputStep";

const Container = styled.div`
  //height: calc(100%-60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 40px 0 0 0;

  box-sizing: border-box;
`;

export type TransferStep = "asset" | "input";

const displayPrecision = DEFAULT_DISPLAY_PRECESION;

const Transfer: React.FC = observer(() => {
  const { userStore, tradeStore } = useStores();
  const [step, setStep] = useState<TransferStep>(
    tradeStore.toSendFtAsset && tradeStore.fromAi ? "input" : "asset"
  );
  const [toSendFtAsset, setToSendFtAsset] = useState<FtAsset | undefined>(
    tradeStore.toSendFtAsset
  );
  const [maxAvailable, setMaxAvailable] = useState<KontosNumber | undefined>(
    tradeStore.fromAi ? tradeStore.toSendMax : undefined
  );
  const navigate = useNavigate();

  const handleChooseAsset = useCallback(
    async (ftAsset: FtAsset) => {
      if (isKontosChain(ftAsset.chainIndex)) {
        setMaxAvailable(undefined);
        setToSendFtAsset(ftAsset);
        setStep("input");
      } else {
        loadingStore.showLoading();
        try {
          const resp = await fetchMaxFtAssetToSend(
            userStore.accountName!,
            userStore.accountName!,
            ftAsset.chainIndex,
            ftAsset.address
          );
          setMaxAvailable(resp);
          setToSendFtAsset(ftAsset);
          setStep("input");
        } catch (e) {
          toast({
            type: "error",
            text: t("Failed to obtain max available balance"),
          });
        } finally {
          loadingStore.hideLoading();
        }
      }
    },
    [userStore.accountName]
  );

  return (
    <Container>
      {step === "asset" && (
        <TransferAssetStep onChooseAsset={handleChooseAsset} />
      )}

      {step === "input" && (
        <TransferInputStep
          maxAvailable={maxAvailable ? maxAvailable : undefined}
          toSendFtAsset={toSendFtAsset}
          displayPrecision={displayPrecision}
          onBack={() => {
            setStep("asset");
          }}
          onSuccess={() => {
            navigate(ROUTE_HOME);
          }}
        />
      )}
    </Container>
  );
});

export default Transfer;
