import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import HorizontalScrollableElements, {
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import CircleIconPair from "src/components/icons/CircleIconPair";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL, DEFAULT_DISPLAY_PRECESION } from "src/config";
import { getChainIcon } from "src/utils/zkkontosHelper";
import { FtAsset } from "src/type/zkkontos";
import { t } from "i18next";
import { KontosButton } from "src/components/button/KontosButton";
import toast from "src/components/toast/Toast";
import { useStores } from "src/hooks/useStore";
import { EndMessage } from "src/components/no-data/EndMessage";
import { observer } from "mobx-react";
import forbidIcon from "src/assets/icons/trade/forbid.svg";
import Header from "src/components/common/header";
import { PaymentTip } from "@/pages/settings/payment/PaymentTip";
import { getChainByAsset } from "@/store/storeHelper";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ paddingBottom?: number }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  padding-bottom: ${(props) => `${props.paddingBottom}px`};
`;

const MainContainer = styled.div`
  padding: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const RadioButton = styled.div<{ $isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.colors.__kontos_blue : theme.colors.__deep_200};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.__kontos_blue};
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  }
`;

const ForbidIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const ItemTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  justify-content: center;
  align-items: flex-start;
`;

const ItemTextContainer2 = styled.div`
  flex: 1;
  display: flex;
  margin-left: 4px;
  justify-content: flex-end;
  align-items: center;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const ItemText = styled.div`
  color: var(--Deep-800, #1a2535);
  ${fontBold};
  font-size: 14px;

  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
`;

const ItemText2 = styled.div`
  margin-top: 2px;
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans;
  font-size: 12px;
  font-weight: 400;
`;

const RadioBox = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div<{ $isSelected?: boolean; $disable?: boolean }>`
  margin-right: 2px;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;

  border-radius: 8px;
  background: ${(props) => props.theme.colors.__white};

  &:not(:first-child) {
    margin-top: 8px;
  }

  ${(props) =>
    !props.$disable &&
    `
    @media (hover: hover) {
    &:hover {
      background-color: var(--Deep-25, #f5f5f6);

      ${RadioButton} {
        border-color: ${props.theme.colors.__kontos_blue};
      }
    }
  }
  `};

  ${(props) =>
    props.$disable &&
    `${ItemText2},${ItemText} { color:  ${props.theme.colors.__deep_200} } `};

  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
  -webkit-tap-highlight-color: transparent;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Spliter = styled.div`
  margin-top: 8px;
  height: 1px;
  background: #cccfd2;
`;

const LineContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;

  .text-1 {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  .text-2 {
    color: var(--Deep-800, #1a2535);
    ${fontBold}
    font-size: 16px;
  }
`;

const PaymentTipWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomContainer = styled.div`
  background-color: #fff;
  width: calc(100% - 32px);
  position: fixed;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ITEMS_TO_INIT = 15;
const ITEMS_TO_FETCH = 10;

const filterAndSortAssetsBySearchTerm = (
  assets: FtAsset[],
  searchTerm: string,
  chainIndex: string
) => {
  let arr = [];
  if (chainIndex === "all") {
    arr = assets;
  } else {
    arr = assets.filter((asset) => asset.chainIndex === chainIndex);
  }

  return arr
    .filter(
      (asset) =>
        asset.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.address.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const aMatchExact = a.symbol.toLowerCase() === searchTerm.toLowerCase();
      const bMatchExact = b.symbol.toLowerCase() === searchTerm.toLowerCase();

      if (aMatchExact && !bMatchExact) {
        return -1;
      } else if (!aMatchExact && bMatchExact) {
        return 1;
      }

      const aIndex = Math.min(
        a.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
      );
      const bIndex = Math.min(
        b.symbol.toLowerCase().indexOf(searchTerm.toLowerCase())
      );

      if (aIndex !== bIndex) {
        return aIndex - bIndex;
      }

      const aLength = a.symbol.length;
      const bLength = b.symbol.length;
      return aLength - bLength;
    });
};

interface IProps {
  selectedBalanceIds: string[];
  fixedBalanceIds: string[];
  onDone: (ids: string[]) => void;
  onBack: () => void;
}

export const PaymentPlanFlowCustom: React.FC<IProps> = observer(
  ({ selectedBalanceIds, fixedBalanceIds, onDone, onBack }) => {
    const { chainStore, tradeStore } = useStores();
    const bottomRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [chainIndex, setChainIndex] = useState<string>(NonChainIndex.All);
    const [filteredWhitelistAssets, setFilteredWhitelistAssets] = useState<
      FtAsset[]
    >(tradeStore.userHoldingsInWhitelist);
    const [filteredBlacklistAssets, setFilteredBlacklistAssets] = useState<
      FtAsset[]
    >(tradeStore.userHoldingsInBlacklist);
    const [visibleItemCount, setVisibleItemCount] =
      useState<number>(ITEMS_TO_INIT);
    const [usedMap, setUsedMap] = useState<{ [key: string]: boolean }>(() => {
      let tempMap: { [key: string]: boolean } = {};

      if (selectedBalanceIds.length === 0)
        tradeStore.userHoldingsInWhitelist.forEach((asset) => {
          tempMap[asset.balance!.id] = true;
        });
      else
        tradeStore.userHoldingsInWhitelist.forEach((asset) => {
          tempMap[asset.balance!.id] = selectedBalanceIds.includes(
            asset.balance!.id
          );
        });
      return tempMap;
    });

    const totalAssets: FtAsset[] = useMemo(() => {
      return filteredWhitelistAssets.concat(filteredBlacklistAssets);
    }, [filteredBlacklistAssets, filteredWhitelistAssets]);

    const getMoreData = () => {
      setVisibleItemCount((prev) => prev + ITEMS_TO_FETCH);
    };

    const handleChooseAsset = useCallback(
      (asset: FtAsset) => {
        if (asset.balance && fixedBalanceIds.includes(asset.balance?.id)) {
          toast({
            text: t("For this transaction, this option cannot be changed"),
            type: "warning",
          });
          return;
        }

        const tempBanMap = { ...usedMap };
        tempBanMap[asset.balance!.id] = !tempBanMap[asset.balance!.id];
        setUsedMap(tempBanMap);
      },
      [fixedBalanceIds, usedMap]
    );

    const filterFtAssetsByChainIndex = useCallback(
      (chainIndex: string) => {
        if (chainIndex === "all") {
          return tradeStore.userHoldings;
        } else {
          return tradeStore.userHoldings.filter(
            (asset) => asset.chainIndex === chainIndex
          );
        }
      },
      [tradeStore.userHoldings]
    );

    const filterUsedMapByChainIndex = useCallback(
      (chainIndex: string) => {
        if (chainIndex === "all") {
          return usedMap;
        } else {
          const toFilterAssets = filterFtAssetsByChainIndex(chainIndex);
          return Object.keys(usedMap).reduce(
            (rMap, key) => {
              if (toFilterAssets.find((asset) => asset.balance?.id === key)) {
                rMap[key] = usedMap[key];
              }
              return rMap;
            },
            {} as { [key: string]: boolean }
          );
        }
      },
      [filterFtAssetsByChainIndex, usedMap]
    );

    const isAllSelected = useMemo(() => {
      return Object.keys(filterUsedMapByChainIndex(chainIndex)).every(
        (key) => fixedBalanceIds.includes(key) || usedMap[key] === true
      );
    }, [chainIndex, filterUsedMapByChainIndex, fixedBalanceIds, usedMap]);

    const handleBatchChooseAsset = useCallback(() => {
      if (Object.keys(filterUsedMapByChainIndex(chainIndex)).length === 0) {
        return;
      }
      const tempBanMap = {
        ...filterUsedMapByChainIndex(chainIndex),
      };
      Object.keys(tempBanMap).forEach((key) => {
        if (!fixedBalanceIds.includes(key)) {
          tempBanMap[key] = !isAllSelected;
        }
      });
      const combinedMap = { ...usedMap, ...tempBanMap };
      setUsedMap(combinedMap);
    }, [
      chainIndex,
      filterUsedMapByChainIndex,
      fixedBalanceIds,
      isAllSelected,
      usedMap,
    ]);

    const toolBtnText = useMemo(() => {
      if (Object.keys(filterUsedMapByChainIndex(chainIndex)).length === 0) {
        return "";
      }
      if (
        Object.keys(filterUsedMapByChainIndex(chainIndex)).every((item) =>
          fixedBalanceIds.includes(item)
        )
      ) {
        return "";
      }
      if (isAllSelected) {
        return t("Clear");
      } else {
        return t("Select All");
      }
    }, [chainIndex, filterUsedMapByChainIndex, fixedBalanceIds, isAllSelected]);

    useEffect(() => {
      setVisibleItemCount(ITEMS_TO_INIT);
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }

      setFilteredWhitelistAssets(
        filterAndSortAssetsBySearchTerm(
          tradeStore.userHoldingsInWhitelist,
          searchTerm,
          chainIndex
        )
      );
      setFilteredBlacklistAssets(
        filterAndSortAssetsBySearchTerm(
          tradeStore.userHoldingsInBlacklist,
          searchTerm,
          chainIndex
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, chainIndex]);

    const handleConfirm = async () => {
      const toSelectIds = Object.keys(usedMap).filter(
        (key) => usedMap[key] === true
      );
      onDone(toSelectIds);
    };

    const isAssetSelected = useCallback(
      (asset: FtAsset): boolean => {
        return (
          usedMap.hasOwnProperty(asset.balance!.id) &&
          usedMap[asset.balance!.id] === true
        );
      },
      [usedMap]
    );

    const realTimeAvailableBalance = useMemo((): KontosNumber => {
      const availableAssets = tradeStore.userHoldings.filter((asset) =>
        isAssetSelected(asset)
      );
      return availableAssets.reduce((accumulator, currentItem) => {
        return accumulator.add(
          currentItem.balance!.balanceUsdAmount,
          DEFAULT_DECIMAL
        );
      }, new KontosNumber(0));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAssetSelected, totalAssets]);

    return (
      <Container paddingBottom={bottomRef.current?.offsetHeight}>
        <Header
          title={t("Custom Plan")}
          padding="8px 24px"
          enableBack
          callBack={onBack}
          rightBtnText={toolBtnText}
          rightBtnCallBack={handleBatchChooseAsset}
        />

        <MainContainer>
          <SearchInput
            style={{ marginTop: "10px" }}
            ref={inputRef}
            placeholder={t("Search assets")}
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
          <HorizontalScrollableElements
            chains={chainStore.userHoldingsAndAllowBuyChains}
            onSelect={(index) => {
              setChainIndex(index);
            }}
            chainIndex={chainIndex}
            hasAll
          />
          <Scrollable id="scrollableDiv" ref={scrollRef}>
            <InfiniteScroll
              dataLength={visibleItemCount} // The current length of displayed data
              next={getMoreData} // The function to trigger to load more
              hasMore={visibleItemCount < totalAssets.length} // Whether there are more items to load
              loader={<h4>{t("Loading...")}</h4>}
              endMessage={<EndMessage />}
              scrollableTarget="scrollableDiv"
              style={{ overflowX: "hidden" }}
            >
              {filteredWhitelistAssets
                .slice(0, visibleItemCount)
                .map((item, index) => (
                  <ItemContainer
                    key={index}
                    $isSelected={isAssetSelected(item)}
                    onClick={() => handleChooseAsset(item)}
                  >
                    <CircleIconPair
                      mainIcon={item.imageUrl}
                      mainIconFallbackSrc={DefaultTokenSvg}
                      subIcon={getChainIcon(
                        chainStore.chains,
                        item.chainIndex,
                        item.address
                      )}
                      subIconFallbackSrc={DefaultChainSvg}
                      mainWidth={32}
                      mainHeight={32}
                      subWidth={16}
                      subHeight={16}
                      totalWidth={40}
                      totalHeight={32}
                    />
                    <ItemTextContainer>
                      <ItemText>{item.symbol}</ItemText>
                      <ItemText2>
                        {getChainByAsset(item)?.chainSymbol}
                      </ItemText2>
                    </ItemTextContainer>

                    <ItemTextContainer2>
                      <AmountBox>
                        <ItemText>
                          {new KontosNumber(
                            item.balance?.balance,
                            DEFAULT_DECIMAL
                          ).toFixed(DEFAULT_DISPLAY_PRECESION)}
                        </ItemText>
                        <ItemText2>
                          {new KontosNumber(
                            item.balance?.balanceUsdAmount,
                            DEFAULT_DECIMAL
                          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
                        </ItemText2>
                      </AmountBox>
                      <RadioBox>
                        <RadioButton $isSelected={isAssetSelected(item)} />
                      </RadioBox>
                    </ItemTextContainer2>
                  </ItemContainer>
                ))}
              {visibleItemCount > filteredWhitelistAssets.length &&
                filteredBlacklistAssets.length > 0 && (
                  <>
                    <PaymentTipWrapper>
                      <PaymentTip />
                    </PaymentTipWrapper>
                    {filteredBlacklistAssets
                      .slice(
                        0,
                        visibleItemCount - filteredWhitelistAssets.length
                      )
                      .map((item, index) => (
                        <ItemContainer key={index} $disable>
                          <CircleIconPair
                            mainIcon={item.imageUrl}
                            mainIconFallbackSrc={DefaultTokenSvg}
                            subIcon={getChainIcon(
                              chainStore.chains,
                              item.chainIndex,
                              item.address
                            )}
                            subIconFallbackSrc={DefaultChainSvg}
                            mainWidth={32}
                            mainHeight={32}
                            subWidth={16}
                            subHeight={16}
                            totalWidth={40}
                            totalHeight={32}
                          />
                          <ItemTextContainer>
                            <ItemText>{item.symbol}</ItemText>
                            <ItemText2>
                              {getChainByAsset(item)?.chainSymbol}
                            </ItemText2>
                          </ItemTextContainer>

                          <ItemTextContainer2>
                            <AmountBox>
                              <ItemText>
                                {new KontosNumber(
                                  item.balance?.balance,
                                  DEFAULT_DECIMAL
                                ).toFixed(DEFAULT_DISPLAY_PRECESION)}
                              </ItemText>
                              <ItemText2>
                                {new KontosNumber(
                                  item.balance?.balanceUsdAmount,
                                  DEFAULT_DECIMAL
                                ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
                              </ItemText2>
                            </AmountBox>
                            <RadioBox>
                              <ForbidIcon src={forbidIcon} />
                            </RadioBox>
                          </ItemTextContainer2>
                        </ItemContainer>
                      ))}
                  </>
                )}
            </InfiniteScroll>
          </Scrollable>
          <BottomContainer ref={bottomRef}>
            <Spliter />
            <LineContainer>
              <LineItem>
                <div className="text-1">{t("Allowed to pay:")}</div>
                <div className="text-2">
                  {realTimeAvailableBalance.toFormat() + " USD"}
                </div>
              </LineItem>
              <LineItem>
                <div className="text-1">{t("My balance:")}</div>
                <div className="text-2">
                  {tradeStore.balanceInUSD
                    ? tradeStore.balanceInUSD?.toFormat() + " USD"
                    : "0.00 USD"}
                </div>
              </LineItem>
            </LineContainer>
            <KontosButton
              onClick={() => {
                handleConfirm();
              }}
            >
              {t("Done")}
            </KontosButton>
          </BottomContainer>
        </MainContainer>
      </Container>
    );
  }
);
