import { TEMP_EXPLORER_URL } from "@/config";
import { API } from "./core/API";
import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import * as ApiTypes from "./types";

export interface ReqTask {
  opHash: string;
  chainIndex: string;
  account: string;
  offset: number;
  limit: number;
}

export interface RespTask {
  tasks: ApiTypes.ApiTask[] | null;
  taskDetail: ApiTypes.ApiTaskDetail | null;
  hasMore: boolean;
}

export const callTask = async (
  data: ReqTask,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespTask> => {
  return await request<ReqTask, RespTask>(API.mtask.task, data, endpoint);
};
