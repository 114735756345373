import _copy from "copy-to-clipboard";
import { TOKEN_EXCHANGE_RATE } from "./transfer";
import { BigNumber, ethers } from "ethers";
import { get } from "../store/local";
import { KEYS } from "../store/keys";
import { formatUnits } from "ethers/lib/utils";
// import { AUTH } from '../router/router-config';
import {
  KONTOS_CHAIN_INDEX,
  DEFAULT_TX_HASH,
  DEFAULT_DECIMAL,
  DEFAULT_PRECISION,
  LEGACY_USER_OP_HASH,
} from "../config";
import { utils } from "ethers";
import KontosNumber from "src/utils/KontosNumber";
import { FtAssetWatched } from "src/type/ftAsset";
import { Dapp, FtAsset, FtAssetBase } from "src/type/zkkontos";
import { parseApiErrorMessage } from "./zkkontosHelper";
import tgManager from "src/store/managers/tgManager";
import UAParser from "ua-parser-js";
import { UniqueAsset } from "@zkkontos/kontos-sdk/src/api/reqTypes";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { getChainByAsset } from "@/store/storeHelper";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";

export const copy = (str: string): void => {
  _copy(str, {
    format: "text/plain",
  });
};

export const getRequestParam = (href: string) => {
  href = decodeURI(href ? href : window.location.href);
  var url = href.substr(href.indexOf("?"));
  const theRequest: any = {};
  if (url.indexOf("?") !== -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
};

export const cutAddress = (address: string, cut1 = 6, cut2 = 6) => {
  if (!address) return "";
  if (address.length < cut1 + cut2) return address;
  return (
    address.substr(0, cut1) +
    "..." +
    address.substr(address.length - cut2, address.length)
  );
};

export const setUnitFloor = (num: any, decimal = 6) => {
  const multiple = Math.pow(10, decimal);
  let rs = Math.floor(num * multiple) / multiple || 0;
  if (!isFinite(rs)) {
    rs = 0;
  }
  return rs;
};

export const getFixedTokenAmount = (
  amount: string,
  decimal = 18,
  fixed = 6
): number => {
  if (amount === "" || amount === "0") return 0;
  return parseFloat(
    parseFloat(ethers.utils.formatUnits(amount, decimal)).toFixed(fixed)
  );
};

export const getFixedUSDAmount = (
  amount: string,
  decimal = 36,
  fixed = 3
): number => {
  if (amount === "" || amount === "0") return 0;
  return parseFloat(
    parseFloat(ethers.utils.formatUnits(amount, decimal)).toFixed(fixed)
  );
};

export const getTokenAmountWithDecimals = (
  amount: string,
  decimal = 18
): string => {
  return ethers.utils.parseUnits(amount, decimal).toString();
};

export const getIdentifiableAmount = (
  amount: string | undefined,
  decimal = 18
) => {
  const _decimal = decimal || 18;
  return parseFloat(getIdentifiableAmountString(amount, _decimal));
};

export const getIdentifiableAmountFloat = (amount: string, decimal = 18) => {
  if (amount === "") return "0";
  return setUnitFloor(
    parseFloat(amount) / parseFloat(Math.pow(10, decimal).toString())
  );
};

export const getIdentifiableAmountString = (
  amount: string | undefined,
  decimal = 18
) => {
  if (!amount || amount === "" || amount === "0") return "0";
  return formatUnits(amount, decimal);
};

export const getUnIdentifiableAmount = (amount: string, decimal = 18) => {
  if (amount === "" || amount === "0") return "0";
  const _decimal = decimal || 18;
  const paddingAmount = ethers.utils.parseUnits(amount, _decimal);
  // const decimalBN = BigNumber.from(Math.pow(10, decimal).toString());
  return paddingAmount.toString();
};

export const getMulOneEtherBigNumber = (
  amount: string | BigNumber,
  decimals = 18
) => {
  if (amount === "" || amount === "0") return BigNumber.from("0");
  const oneEtherBigNumber = ethers.utils.parseUnits("1", decimals);
  return BigNumber.from(amount).mul(oneEtherBigNumber);
};

export const getDivOneEtherBigNumber = (
  amount: string | BigNumber,
  decimals = 18
) => {
  if (amount === "" || amount === "0") return BigNumber.from("0");
  const oneEtherBigNumber = ethers.utils.parseUnits("1", decimals);
  return BigNumber.from(amount).div(oneEtherBigNumber);
};

export const getDisplayStringFromEther = (
  amount: string,
  decimal = 18,
  fixed: number = 6
) => {
  const _decimal = decimal || 18;

  const amountString = getIdentifiableAmount(amount, _decimal) || 0;
  const multiple = Math.pow(10, fixed);
  return (Math.floor(amountString * multiple) / multiple || 0) + "";
};

export const getPriceFromBalance = (
  balance?: string,
  symbol?: string,
  tokenPrice?: string
) => {
  if (!balance || (!symbol && !tokenPrice)) return BigNumber.from(0).toString();
  let rate = 1 as string | number;
  if (tokenPrice) {
    rate = tokenPrice;
  } else if (symbol) {
    rate = TOKEN_EXCHANGE_RATE[symbol] || 1;
  }
  const big = BigNumber.from(balance);
  const rateBig = BigNumber.from(rate);
  return big.mul(rateBig).toString() ? big.mul(rateBig).toString() : "0";
};

export const getBalanceFromPrice = (
  price?: string,
  symbol?: string,
  tokenPrice?: string
) => {
  if (!price || (!symbol && !tokenPrice)) return BigNumber.from(0).toString();
  let rate = 1 as string | number;
  if (tokenPrice) {
    rate = tokenPrice;
  } else if (symbol) {
    rate = TOKEN_EXCHANGE_RATE[symbol] || 1;
  }
  const big = BigNumber.from(price);
  const rateBig = BigNumber.from(rate);
  return big.div(rateBig).toString() ? big.div(rateBig).toString() : "0";
};

export const getAmountString = (amount: string) => {
  if (amount.replace(".", "").replaceAll("0", "") === "") return "0";
  const match = amount.match(/^\d+(?:\.\d{0,4})?/);
  if (match && match[0]) {
    return match[0];
  }
  return amount;
};
export const shortAddress = (str: string, front = 6, behind = 6): string => {
  if (!str.startsWith("0x")) return str;
  if (!str) return "";
  if (str.length <= front + behind) return str;
  return `${str.substring(0, front)}...${str.substring(str.length - behind)}`;
};

export const formatAddress = (
  str?: string,
  short = true,
  front = 6,
  behind = 6
): string => {
  if (!str) {
    return "-";
  }
  if (!ethers.utils.isAddress(str)) {
    return str.replaceAll(".os", "") + ".os";
  }
  if (str.length <= front + behind || !short) return str;
  return `${str.substring(0, front)}...${str.substring(str.length - behind)}`;
};

export const parseWithDefault = (value: string, defaultValue: any) => {
  try {
    const resp = JSON.parse(value);
    if (resp === null) {
      return defaultValue;
    }
    return resp;
  } catch (error) {
    return defaultValue;
  }
};

export const getHostName = (href: string | undefined) => {
  if (!href) return "";
  const url = new URL(href);
  return url.hostname;
};

export const splitInjectAddress = (addr: string) => {
  return addr.substr(2, addr.length);
};

export const hasAccountOnChain = (
  chain_index: string,
  blokChainAccounts: {}
) => {
  if (chain_index === "0") return true;
  return (
    Object.keys(blokChainAccounts)?.findIndex((item) => item === chain_index) >
    -1
  );
};

export const defaultChainId = "56";

export const getChainId = async () => {
  const chain_id = (await get(KEYS.default_chain_id)) || defaultChainId;
  return chain_id;
};

export const isKontosChain = (chainIndex?: string) => {
  return chainIndex === KONTOS_CHAIN_INDEX;
};

export const checkURL = (url: string | null | undefined) => {
  if (url === "" || !url) return false;
  var str = url;
  var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
  var objExp = new RegExp(Expression);
  return objExp.test(str);
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeoutId: NodeJS.Timeout | null = null;

  return (...args: Parameters<F>): void => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), waitFor);
  };
};

export const throttle = <F extends (...args: any[]) => any>(
  func: F,
  delay: number
): F => {
  let lastCall = 0;

  return function (...args: any[]) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  } as unknown as F;
};

// /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
export const isUUID = (input: string) => {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-f]{4}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(input);
};

export const isEmoji = (str: string): boolean => {
  const emojiRegexp = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
  return emojiRegexp.test(str);
};

export const isMobileDevice = () => {
  // const userAgent =
  //   typeof window.navigator === "undefined" ? "" : navigator.userAgent;
  // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //   userAgent);
  const parser = new UAParser();
  const device = parser.getDevice();
  return (
    device.type === "mobile" ||
    device.type === "tablet" ||
    device.type === "wearable"
  );
};

export function hexStringToUint8Array(hexString: string) {
  hexString = hexString.replace(/^0x/, "");
  const bytes = new Uint8Array(hexString.length / 2);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
  }
  return bytes;
}

export function asciiStringToUint8Array(str: string) {
  const bytes = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}

export function base64ToUint8Array(base64: string) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export const decodeBase64ToJson = <T>(base64: string): T => {
  const binaryString = atob(base64);
  const jsonString = decodeURIComponent(
    binaryString
      .split("")
      .map((char) => "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
  return JSON.parse(jsonString) as T;
};

export function uint8ArrayToBase64(input: Uint8Array): string {
  let binaryString = "";
  input?.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });

  return btoa(binaryString);
}

export function fmDate(time: number) {
  let date = new Date(time);
  let y = date.getFullYear();
  let MM = (date.getMonth() + 1).toString();
  MM = parseInt(MM) < 10 ? "0" + MM : MM;
  let d = date.getDate().toString();
  d = parseInt(d) < 10 ? "0" + d : d;
  let h = date.getHours().toString();
  h = parseInt(h) < 10 ? "0" + h : h;
  let m = date.getMinutes().toString();
  m = parseInt(m) < 10 ? "0" + m : m;
  let s = date.getSeconds().toString();
  s = parseInt(s) < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
}

export const beautifyISOTimestamp = (isoTimestamp: string | number): string => {
  const date = new Date(isoTimestamp);

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  return `${formattedDate} ${formattedTime}`;
};

export const isValidGoogleEmail = (str: string): boolean => {
  const lowerCaseStr: string = str.toLowerCase();
  const lowerCaseSuffix: string = "@gmail.com";

  return lowerCaseStr.endsWith(lowerCaseSuffix);
};

export const convertToLowercaseIfGmail = (str: string): string => {
  const lowerCaseStr: string = str.toLowerCase();
  const lowerCaseSuffix: string = "@gmail.com";

  if (lowerCaseStr.endsWith(lowerCaseSuffix)) {
    return lowerCaseStr;
  } else {
    return str;
  }
};

export const judgeAndSimplifyAddress = (address: string) => {
  if (address.length < 12) return address;
  if (ethers.utils.isAddress(address)) {
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 6
    )}`;
  }
  return address;
};

export const isSafari = () => {
  const userAgent = window.navigator.userAgent;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isSafari =
    /Safari/.test(userAgent) &&
    !/CriOS/.test(userAgent) &&
    !/FxiOS/.test(userAgent) &&
    !/OPiOS/.test(userAgent) &&
    !/QHBrowser/.test(userAgent);
  return isIOS && isSafari;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isTimestampExpired = (
  expiredTimestamp: number,
  isSeconds: boolean = false
): boolean => {
  const now = Date.now();

  const adjustedTimestamp = isSeconds
    ? expiredTimestamp * 1000
    : expiredTimestamp;

  return now > adjustedTimestamp;
};

/**
 * Converts hex to utf8 string if it is valid bytes
 */
export const convertHexToUtf8 = (value: string) => {
  if (utils.isHexString(value)) {
    return utils.toUtf8String(value);
  }

  return value;
};

/**
 * Gets message from various signing request methods by filtering out
 * a value that is not an address (thus is a message).
 * If it is a hex string, it gets converted to utf8 string
 */
export const getSignParamsMessage = (params: string[]) => {
  const message = params?.filter((p) => !utils.isAddress(p))[0];

  return convertHexToUtf8(message);
};

export const getSignPramsMessageHex = (params: string[]) => {
  return params?.filter((p) => !utils.isAddress(p))[0];
};

/**
 * Gets data from various signTypedData request methods by filtering out
 * a value that is not an address (thus is data).
 * If data is a string convert it to object
 */
export const getSignTypedDataParamsData = (params: string[]) => {
  const data = params.filter((p) => !utils.isAddress(p))[0];

  if (typeof data === "string") {
    return JSON.parse(data);
  }

  return data;
};

export const convertChainId = (
  input: string,
  format: "toHex" | "toDecimal" | "toEIP155"
): string => {
  let decimalInput: string;
  if (input.startsWith("eip155:")) {
    decimalInput = input.substring(7);
  } else if (input.startsWith("0x")) {
    decimalInput = parseInt(input, 16).toString();
  } else {
    decimalInput = input;
  }

  switch (format) {
    case "toHex":
      return "0x" + parseInt(decimalInput, 10).toString(16);
    case "toDecimal":
      return decimalInput;
    case "toEIP155":
      return `eip155:${decimalInput}`;
    default:
      return decimalInput;
  }
};

export const areAllStrings = (...args: any[]): boolean => {
  return args.every((arg) => typeof arg === "string");
};

export const getDefaultTxHash = (chainId: string): string => {
  if (chainId in DEFAULT_TX_HASH) {
    return DEFAULT_TX_HASH[chainId as keyof typeof DEFAULT_TX_HASH];
  }
  return DEFAULT_TX_HASH["1"];
};

export const withStopPropagation =
  (
    handlerWithEvent?: (
      event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>,
      ...args: any[]
    ) => void,
    handler?: (...args: any[]) => void,
    ...args: any[]
  ) =>
  (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (handlerWithEvent) {
      handlerWithEvent(event, ...args);
      return;
    }
    if (handler) {
      handler(...args);
      return;
    }
  };

export const calculateItemsPerRow = (
  itemWidth: number,
  itemGap: number,
  containerWidth: number
): number => {
  const totalWidthPerItem: number = itemWidth + itemGap;
  const itemsPerRow: number = Math.floor(
    (containerWidth + itemGap) / totalWidthPerItem
  );

  return itemsPerRow;
};

export const getFluctuate = (fluctuate: string, decimal = DEFAULT_DECIMAL) => {
  const fluctuateNumber = parseFloat(
    new KontosNumber(fluctuate || 0, decimal).toFixed(2)
  );
  return !!fluctuateNumber ? fluctuateNumber : 0;
};

export const isFavoriteFtAssetId = (
  id: number,
  favorites: FtAssetWatched[]
) => {
  return favorites.some((item) => item.watchId === id);
};

export const getDisplayAmount = (
  amount: string | undefined | 0,
  {
    isAbbreviated = false,
    precision = DEFAULT_PRECISION,
    isVerySmallDecimalValue = false,
    isAutomateProcessAllTypes = false,
    decimal = DEFAULT_DECIMAL,
  }: {
    isAbbreviated?: boolean;
    // how many decimal places to keep
    precision?: number;
    isVerySmallDecimalValue?: boolean;
    // convert the number based on how many decimal places.
    decimal?: number;
    // warn: If the return value is used for calculation, do not enable this option as it may cause calculation error.
    isAutomateProcessAllTypes?: boolean;
  } = {}
) => {
  if (amount === "0" || amount === 0) return "0";
  if (!amount || amount === "-") return "-";
  const kontosNumberObj = new KontosNumber(amount, decimal);
  const strNumber = parseFloat(kontosNumberObj.toFixed(precision)).toString();
  if (isNaN(parseFloat(strNumber))) return "-";
  const preFix = kontosNumberObj.lt(0) ? "-" : "";
  if (isAutomateProcessAllTypes) {
    if (kontosNumberObj.abs().lt(1))
      return preFix + kontosNumberObj.abs().toFormat(precision);
    if (kontosNumberObj.abs().gt(1000))
      return preFix + kontosNumberObj.abs().toFormatWithSymbol(2);
    return strNumber;
  }
  if (isAbbreviated)
    return preFix + kontosNumberObj.abs().toFormatWithSymbol(precision);
  if (isVerySmallDecimalValue)
    return preFix + kontosNumberObj.abs().toFormat(precision);
  return strNumber;
};

export const isFavoriteDapp = (dapp?: Dapp, favorites?: Dapp[]) => {
  return favorites?.some((item) => item.id === dapp?.id);
};

export const isFavoriteFtAsset = (asset?: FtAsset, favorites?: FtAsset[]) => {
  // TODO: fav
  return true;
  // return favorites?.some((item) => item.ftAssetId === asset?.ftAssetId);
};

export const handleApiError = (name: string, params: Object, error: any) => {
  const message =
    error instanceof Error
      ? parseApiErrorMessage(error).message
      : error?.response
        ? `${error.response.status}: ${error.response.statusText}`
        : error?.message
          ? error.message
          : JSON.stringify(error);
  window?.gtag("event", "exception", {
    name,
    params: JSON.stringify(params),
    message,
    description:
      "name: " +
      name +
      " params: " +
      JSON.stringify(params) +
      " message: " +
      message,
    fatal: false,
  });
};

export const calculateBySlippage = (
  value?: KontosNumber,
  slippage?: KontosNumber
): KontosNumber => {
  if (!value || !slippage) return new KontosNumber(0);
  return value.multiply(slippage.multiply(-1).add(1));
};

export const withTimeout = <T>(promise: Promise<T>, ms: number): Promise<T> => {
  const timeout = new Promise<T>((_, reject) =>
    setTimeout(() => reject(new Error("Timeout")), ms)
  );
  return Promise.race([promise, timeout]);
};

export const openUrl = (
  url?: string,
  backup: "_blank" | "_self" | null = "_self",
  preference: string = "_blank"
) => {
  if (!url) return;
  if (preference === "_blank" && !!window?.Telegram?.WebApp?.openLink) {
    window.Telegram.WebApp.openLink(url, { try_instant_view: true });
    return;
  }
  const newWindow = window.open(url, preference);
  if (backup && backup !== preference && !newWindow && !tgManager.isTg())
    window.open(url, backup);
};

export const isAccountLowercaseAlphanumeric = (input: string): boolean => {
  const regex = /^[a-z0-9]+$/;
  return regex.test(input);
};

export const isAccountValidLength = (input: string): boolean => {
  return /^.{4,64}$/.test(input);
};

export type Email = {
  address: string;
  isVerified: boolean;
};

/*
 * push new email to userEmailArr
 * */
export const pushEmail = (
  email: string,
  userEmailArr: Email[],
  isVerified: boolean
) => {
  // check if email is already in the array
  if (userEmailArr.some((item) => item.address === email)) {
    return userEmailArr;
  }
  // max 5 emails
  if (userEmailArr.length >= 5) {
    return userEmailArr;
  }
  const newArr = [...userEmailArr];
  newArr.push({ address: email, isVerified });
  return newArr;
};

export function getDeviceInfo() {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    browser: result.browser.name + " " + result.browser.version,
    os: result.os.name + " " + result.os.version,
    device: result.device.vendor + " " + result.device.model,
    userAgent: navigator.userAgent,
  };
}

export const checkCryptoSupport = async () => {
  const cryptoSupport = {
    subtleCrypto: !!window.crypto?.subtle,
    signMethod: false,
    ecdsaSupport: false,
  };

  if (cryptoSupport.subtleCrypto) {
    // Check if the sign method exists
    cryptoSupport.signMethod = typeof window.crypto.subtle.sign === "function";

    if (cryptoSupport.signMethod) {
      // Attempt to generate key pairs that support ECDSA to check ECDSA algorithm support
      try {
        const keyPair = await window.crypto.subtle.generateKey(
          {
            name: "ECDSA",
            namedCurve: "P-256",
          },
          true,
          ["sign"]
        );

        // Attempt to sign using the generated key pair to verify support for ECDSA and SHA-256
        const data = new TextEncoder().encode("test");
        await window.crypto.subtle.sign(
          {
            name: "ECDSA",
            hash: { name: "SHA-256" },
          },
          keyPair.privateKey,
          data
        );

        cryptoSupport.ecdsaSupport = true;
      } catch (error) {
        cryptoSupport.ecdsaSupport = false;
      }
    }
  }

  return cryptoSupport;
};

export const getUsdValue = (
  quantity: KontosNumber,
  price: KontosNumber | string
): KontosNumber => {
  return quantity.multiply(price, DEFAULT_DECIMAL);
};

export const isWasmLoaded = () => {
  return window.isWasmLoaded === true;
};

export const waitForWasmLoad = async (
  maxWaitTime: number,
  interval: number
): Promise<void> => {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    const checkWasmLoad = () => {
      if (isWasmLoaded()) {
        resolve();
      } else if (Date.now() - startTime >= maxWaitTime) {
        reject(new Error("Timed out waiting for Wasm to load"));
      } else {
        setTimeout(checkWasmLoad, interval);
      }
    };

    checkWasmLoad();
  });
};

export function formatLargeNumber(num: number): string {
  if (num >= 1_000_000) {
    // Number greater than or equal to one million, displayed as M
    return `${Math.floor(num / 1_000_000)}M`;
  } else if (num >= 1_000) {
    // A number greater than or equal to one thousand is displayed as K
    return `${Math.floor(num / 1_000)}K`;
  } else {
    // If the number is less than one thousand, it will be displayed directly
    return num.toString();
  }
}
export const getNoSuffixKontosName = (raw: string) => {
  return raw.trim().replaceAll(".os", "").trim();
};

export const convertUniqueAssetToFtAssetBase = (
  asset: UniqueAsset
): FtAssetBase => {
  return {
    chainIndex: asset.chainIndex,
    address: asset.assetAddress,
  };
};

export const convertFtAssetBaseToUniqueAsset = (
  asset: FtAssetBase
): UniqueAsset => {
  return {
    chainIndex: asset.chainIndex,
    assetAddress: asset.address,
  };
};

export const setCronJob = (
  cronjobId: NodeJS.Timer | null,
  callback: () => Promise<any>,
  interval: number
) => {
  if (cronjobId !== null) {
    clearInterval(cronjobId);
    cronjobId = null;
  }

  // Ensure non overlapping triggering
  cronjobId = setInterval(async () => {
    try {
      await callback();
    } catch (error) {
      console.error("Error in cron job:", error);
    }
  }, interval);

  return cronjobId;
};

export const convertFtAssetToUniformedPayment = (
  asset: FtAsset
): UniformedPayment => {
  return {
    ...asset,
    assetAddress: asset.address,
    assetAmount: new KontosNumber(
      asset.balance?.balanceUsdAmount,
      DEFAULT_DECIMAL
    )
      .divide(asset.usdPrice, DEFAULT_DECIMAL)
      .toString(),
    usdAmount: asset.balance?.balanceUsdAmount || "0",
    assetName: asset.name,
    assetSymbol: asset.symbol,
    assetImageUrl: asset.imageUrl,
    assetDecimals: asset.decimals,
    assetUsdPrice: asset.usdPrice,
    chainSymbol: getChainByAsset(asset)?.chainSymbol || "-",
    chainImageUrl: getChainByAsset(asset)?.imageURL || defaultChainIcon,
    chainGreyImageUrl: getChainByAsset(asset)?.greyImageURL || defaultChainIcon,
  };
};

export const bulkConvertFtAssetToUniformedPayment = (
  assets: FtAsset[]
): UniformedPayment[] => {
  return assets.map((asset) => convertFtAssetToUniformedPayment(asset));
};

export const isNative = (asset?: FtAsset) => {
  if (!asset) {
    return false;
  }

  if (
    asset.address.length > 12 &&
    asset.address.toLocaleLowerCase().replaceAll("e", "") === "0x"
  ) {
    return true;
  }
  return false;
};

export const convertUniformedPaymentToFtAssetBase = (
  payment: UniformedPayment
): FtAssetBase => {
  return {
    ...payment,
    address: payment.assetAddress,
  };
};

export const bulkConvertUniformedPaymentToFtAssetBase = (
  payments: UniformedPayment[]
): FtAssetBase[] => {
  return payments.map((payment) =>
    convertUniformedPaymentToFtAssetBase(payment)
  );
};

export const convertUniformedPaymentToFtAsset = (
  payment: UniformedPayment
): FtAsset => {
  return {
    ...payment,
    address: payment.assetAddress,
    name: payment.assetName,
    symbol: payment.assetSymbol,
    decimals: payment.assetDecimals,
    imageUrl: payment.assetImageUrl,
    usdPrice: payment.assetUsdPrice,
    balance: {
      id: "-",
      balance: payment.assetAmount,
      balanceUsdAmount: payment.usdAmount,
    },
    isWhitelist: true,
  };
};

export const bulkConvertUniformedPaymentToFtAsset = (
  payments: UniformedPayment[]
): FtAsset[] => {
  return payments.map((payment) => convertUniformedPaymentToFtAsset(payment));
};

export const isValidUserOpHash = (hash?: string): boolean => {
  if (typeof hash !== "string") return false;
  if (hash === "") return false;
  if (hash === LEGACY_USER_OP_HASH) return false;
  if (!hash.startsWith("0x")) return false;
  return true;
};

export const isValidTxHash = (hash?: string): boolean => {
  if (typeof hash !== "string") return false;
  if (hash === "") return false;
  if (hash === LEGACY_USER_OP_HASH) return false;
  if (!hash.startsWith("0x")) return false;
  return true;
};
