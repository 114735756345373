import { useTranslation } from "react-i18next";
import { CloseButton } from "src/components/button/CloseButton";
import styled from "styled-components";
import arrowIco from "src/assets/icons/events/eb/banner-arrow.svg";
import bannerImg from "src/assets/images/event/eb/energy-booster-banner.png";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_ENERGYBOOSTING,
  ROUTE_ENERGYBOOSTING_LEADERBOARD,
} from "src/router/router-config";
import closeIco from "src/assets/icons/close3.svg";
import bgImg from "src/assets/images/event/eb/eb-banner-bg.jpg";
import useAuthCheck from "@/hooks/useAuthCheck";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Deep-25, #f5f5f6);
  background-image: url(${bgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .close-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
  }

  .left {
    margin: 12px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > .line-1 {
      color: var(--Deep-800, #1a2535);
      ${fontBold};
      font-size: 14px;
    }

    > .line-2 {
      margin-top: 20px;
      color: var(--Kontos-Blue, #413dec);
      font-family: "HarmonyOS Sans";
      font-size: 12px;
      font-weight: 400;

      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          opacity: 0.9;
        }
      }
      &:active {
        opacity: 0.9;
      }

      display: flex;
      align-items: center;

      > img {
        margin-left: 6px;
        width: 14px;
        height: 8px;
      }
    }
  }

  .right {
    margin: 8px 43px 8px 0;

    > img {
      width: 93px;
      height: 63px;
    }
  }
`;

interface Props {
  className?: string;
  onClose?: (open: boolean) => void;
}

export const EbBanner: React.FC<Props> = ({ className, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authed } = useAuthCheck();

  return (
    <Container
      className={className}
      onClick={() => {
        authed
          ? navigate(ROUTE_ENERGYBOOSTING)
          : navigate(
              `${ROUTE_ENERGYBOOSTING}/${ROUTE_ENERGYBOOSTING_LEADERBOARD}`
            );
      }}
    >
      {onClose && (
        <CloseButton
          className="close-btn"
          $lazy
          closeIco={closeIco}
          onClick={(e) => {
            onClose(false);
            e.stopPropagation();
          }}
        />
      )}

      <div className="left">
        <div className="line-1">{t("Unlock Your Exclusive Rewards Now!")}</div>
        <div className="line-2">
          {t("Let's get started")}
          <img src={arrowIco} alt="" />
        </div>
      </div>
      <div className="right">
        <img src={bannerImg} alt="" />
      </div>
    </Container>
  );
};
