import { Chain, FtAssetBase } from "@/type/zkkontos";
import { rootStore } from "..";
import { FtAsset } from "@/apis/types";
import { isSameFtAsset } from "@/utils/zkkontosHelper";

export const getChain = (chainIndex: string | undefined): Chain | undefined => {
  return rootStore.chainStore.chains.find(
    (chain) => chain.chainIndex === chainIndex
  );
};

export const getChainByAsset = (
  ftAsset: FtAsset | undefined
): Chain | undefined => {
  return rootStore.chainStore.chains.find(
    (chain) => chain.chainIndex === ftAsset?.chainIndex
  );
};

export const isSameChainAsset = (assetA: FtAsset, assetB: FtAsset): boolean => {
  const chainA = getChainByAsset(assetA);
  const chainB = getChainByAsset(assetB);
  if (
    typeof chainA !== "undefined" &&
    typeof chainB !== "undefined" &&
    chainA.chainIndex === chainB.chainIndex
  ) {
    return true;
  }
  return false;
};

export const filterUserHoldings = (
  assets: FtAssetBase[],
  useTradeStore?: boolean
): FtAsset[] => {
  if (useTradeStore)
    return rootStore.tradeStore.accountBalances.filter((item) =>
      assets.some((subItem) => isSameFtAsset(subItem, item))
    );
  return rootStore.userStore.accountBalances.filter((item) =>
    assets.some((subItem) => isSameFtAsset(subItem, item))
  );
};

export const findUserHolding = (
  asset: FtAssetBase,
  useTradeStore?: boolean
): FtAsset | undefined => {
  if (useTradeStore)
    return rootStore.tradeStore.accountBalances.find((item) =>
      isSameFtAsset(asset, item)
    );

  return rootStore.userStore.accountBalances.find((item) =>
    isSameFtAsset(asset, item)
  );
};
