import { makeAutoObservable, reaction, runInAction } from "mobx";
import { RootStore } from "../RootStore";
import localKeeper from "../localKeeper";
import { FtAsset } from "@/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import sessionKeeper from "../sessionKeeper";
import { fetchAccountBalances } from "@/service/asset-service";
import { DEFAULT_DECIMAL, VISITOR_ACCOUNT } from "@/config";

export enum TradeMode {
  Buy = "buy",
  Sell = "sell",
}

export class TradeStore {
  rootStore: RootStore;
  payableChangeCount: number = 0;
  showManageTip: boolean = false;
  disableAiScore?: boolean;
  fromAi: boolean = false;
  toSendFtAsset?: FtAsset;
  toSendMax?: KontosNumber;
  mode: TradeMode = sessionKeeper.getTradeMode() || TradeMode.Buy;
  fakeAccountBalances: FtAsset[] = [];
  newestOpHash?: { opHash: string; validUntil: number };

  constructor(rootStore: RootStore) {
    this.disableAiScore = localKeeper.getDisableAiScore();
    this.rootStore = rootStore;
    this.checkAccountName();
    this.startTrackingAccountName();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  checkAccountName = async () => {
    if (this.rootStore.userStore.accountName === undefined) {
      try {
        const resp = await fetchAccountBalances(VISITOR_ACCOUNT);
        runInAction(() => {
          this.fakeAccountBalances = resp;
        });
      } catch (e) {
        console.warn(e);
      }
    } else {
      this.fakeAccountBalances = [];
    }
  };

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.userStore.accountName,
      () => {
        this.checkAccountName();
      }
    );
  };

  get authed(): boolean {
    return this.rootStore.userStore.accountName !== undefined;
  }

  get accountName(): string | undefined {
    return this.rootStore.userStore.accountName || VISITOR_ACCOUNT;
  }

  get accountBalances(): FtAsset[] {
    return this.authed
      ? this.rootStore.userStore.accountBalances
      : this.fakeAccountBalances;
  }

  get userHoldings(): FtAsset[] {
    return this.accountBalances ? this.accountBalances : [];
  }

  get userHoldingsInWhitelist(): FtAsset[] {
    return this.userHoldings.filter((item) => item.isWhitelist === true);
  }

  get userHoldingsInBlacklist(): FtAsset[] {
    return this.userHoldings.filter((item) => item.isWhitelist === false);
  }

  get balanceInUSD(): KontosNumber | undefined {
    return this.accountBalances?.reduce(
      (acc, cur) =>
        acc.add(cur?.balance?.balanceUsdAmount || 0, DEFAULT_DECIMAL),
      new KontosNumber(0)
    );
  }

  resetInput = () => {
    this.rootStore.buyStore.resetInput();
    this.rootStore.sellStore.resetInput();
  };

  reset = () => {
    this.rootStore.buyStore.reset();
    this.rootStore.sellStore.reset();
  };

  changePayableAssets = () => {
    this.payableChangeCount += 1;
  };

  setShowManageTip = (show: boolean) => {
    this.showManageTip = show;
  };

  toggleDisableAiScore = () => {
    localKeeper.setDisableAiScore(!this.disableAiScore);
    this.disableAiScore = localKeeper.getDisableAiScore();
  };

  setFromAi = (from: boolean) => {
    this.fromAi = from;
  };

  resetSendInput = () => {
    this.toSendFtAsset = undefined;
  };

  setToSendFtAsset = (asset: FtAsset) => {
    this.toSendFtAsset = asset;
  };

  setToSendMax = (max?: KontosNumber) => {
    this.toSendMax = max;
  };

  resetAiFields = () => {
    this.fromAi = false;
    this.toSendFtAsset = undefined;
    this.toSendMax = undefined;
  };

  switchMode = (mode: TradeMode) => {
    sessionKeeper.setTradeMode(mode);
    this.mode = mode;
  };

  setNewestOpHash = (opHash?: string) => {
    if (typeof opHash === "string") {
      this.newestOpHash = {
        opHash,
        validUntil: Date.now() + 60000,
      };
    } else {
      this.newestOpHash = undefined;
    }
  };
}
