import { DEFAULT_DECIMAL } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import { callTaskPaymentWithAbortAndMemory } from "./trade-service";

export const fetchMaxFtAssetToSend = async (
  account: string,
  payer: string,
  chainIndex: string,
  assetAddress: string
): Promise<KontosNumber> => {
  const transferData = {
    chainIndex,
    receiver: "",
    assetAddress,
    assetAmount: new KontosNumber(0).toStringWithDecimal(DEFAULT_DECIMAL),
  };
  const { respSimulateTransfer } = await callTaskPaymentWithAbortAndMemory(
    {
      account,
      payer,
      reqTaskTransfer: transferData,
    },
    false
  );
  return new KontosNumber(
    respSimulateTransfer?.maxAvailableBalance,
    DEFAULT_DECIMAL
  );
};
