import styled from "styled-components";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { SheetView } from "src/store/SheetStore";
import { observer } from "mobx-react";
import { verify } from "src/store/DappConnectStore";
import { useCallback } from "react";
import { WcConnectGeneralInfo } from "./components/WcConnectGeneralInfo";
import { WcConnectDesc } from "./components/WcConnectDesc";
import { WcConnectItems } from "./components/WcConnectItems";
import { loadingStore } from "src/store/loadingStore";
import toast from "src/components/toast/Toast";
import {
  convertChainId,
  getSignParamsMessage,
  getSignTypedDataParamsData,
} from "src/utils/helper";
import { KontosButton } from "src/components/button/KontosButton";
import { SignClientTypes } from "@walletconnect/types";
import { SessionTypes } from "@walletconnect/types/dist/types/sign-client/session";
import Header from "src/components/common/header";
import { Scrollable } from "src/components/scrollable/Scrollable";

const Container = styled.div`
  flex: 1;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

  .sign-general-info {
    margin-top: 10px;
    padding: 0 20px;
  }

  > .sign-message {
    margin-top: 16px;
    padding: 0 20px;
  }

  > .sign-items {
    margin-top: 19px;
    padding: 0 24px;
  }

  > .sign-btns {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

interface SignProps {
  onClose: () => void;
  requestEvent: SignClientTypes.EventArguments["session_request"];
  requestSession: SessionTypes.Struct;
  isMessgae: boolean;
}

const Sign: React.FC<SignProps> = observer(
  ({ onClose, requestEvent, requestSession, isMessgae }) => {
    const { t } = useTranslation();
    const { dappConnectStore, userStore } = useStores();
    const accountName = userStore?.accountName || "";

    // Get required request data
    const { peer } = requestSession;
    const { topic, params, verifyContext } = requestEvent;
    const { request, chainId } = params;
    const verifyStatus = verify(
      verifyContext.verified.isScam,
      verifyContext.verified.validation
    );
    const dappName = peer.metadata.name;
    const dappIcon = peer.metadata.icons?.[0];
    const dappUrl = peer.metadata.url;
    const decimalChainId = convertChainId(chainId, "toDecimal");
    // Get data
    const data = isMessgae
      ? getSignParamsMessage(request.params)
      : getSignTypedDataParamsData(request.params);
    const message = isMessgae ? data : JSON.stringify(data, null, 2);

    const onApprove = useCallback(async () => {
      const cli = userStore.kontosKey;
      if (!cli) {
        console.log("no cli");
        userStore.unlock(onApprove);
        return;
      }

      loadingStore.showLoading();
      try {
        const response = await dappConnectStore.approveEIP155Request(
          requestEvent,
          cli
        );
        try {
          await dappConnectStore?.client?.respondSessionRequest({
            topic,
            response,
          });
          dappConnectStore.markUsedRequest(requestEvent.id, true);
          toast({
            text: t(
              "Successfully signed! If the Dapp does not display success, it is possible that the other party has not yet supported EIP-1271"
            ),
            type: "success",
          });
          onClose();
        } catch (e) {
          toast({
            text: t("Communication error, please try again later"),
            type: "success",
          });
        }
      } catch (e) {
        console.log("Unknown error when signing", e);
      } finally {
        loadingStore.hideLoading();
      }
    }, [dappConnectStore, onClose, requestEvent, t, topic, userStore]);

    // Handle reject action
    const onReject = useCallback(async () => {
      if (requestEvent) {
        loadingStore.showLoading();
        const response = dappConnectStore.rejectEIP155Request(requestEvent);
        try {
          await dappConnectStore?.client?.respondSessionRequest({
            topic,
            response,
          });
        } catch (e) {
          console.log(
            `Failed to respond to ${requestSession?.peer.metadata.name}`,
            e
          );
        } finally {
          dappConnectStore.markUsedRequest(requestEvent.id, false);
          loadingStore.hideLoading();
          onClose();
        }
      }
    }, [
      dappConnectStore,
      onClose,
      requestEvent,
      requestSession?.peer.metadata.name,
      topic,
    ]);

    return (
      <Container>
        <Header title={t("Signature Requested")} padding={"0 8px 8px"} />

        {/* General Info */}
        <div className="sign-general-info">
          <WcConnectGeneralInfo
            className="general-info"
            type={verifyStatus}
            icon={dappIcon}
            name={dappName}
            url={dappUrl}
          />
        </div>

        <div className="sign-message">
          {isMessgae ? (
            <WcConnectDesc desc={message} />
          ) : (
            <WcConnectDesc data={data} />
          )}
        </div>

        <div className="sign-items">
          <WcConnectItems
            className="items"
            wallet={accountName || ""}
            chainId={decimalChainId}
          />
        </div>

        <Scrollable />

        <div className="sign-btns">
          <KontosButton onClick={onApprove}>{t("Sign")}</KontosButton>
          <KontosButton $isOutlined onClick={onReject}>
            {t("Cancel")}
          </KontosButton>
        </div>
      </Container>
    );
  }
);

interface Props {
  mountPoint?: Element;
}

const SignSheet: React.FC<Props> = observer(({ mountPoint }) => {
  const { userStore, sheetStore } = useStores();
  const isOpen = sheetStore.sheetVisibility.get(SheetView.Sign) as boolean;
  const sheet = sheetStore.getLatestSheetForView(SheetView.Sign);
  const requestEvent = sheet?.data?.requestEvent;
  const requestSession = sheet?.data?.requestSession;
  const isMessgae = sheet?.data?.isMessgae;
  const accountName = userStore.accountName;
  const condition = !(
    !requestEvent ||
    !requestSession ||
    isMessgae === undefined ||
    !accountName
  );

  const onClose = useCallback(() => {
    sheetStore.closeSheetByView(SheetView.Sign);
  }, [sheetStore]);

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      mountPoint={mountPoint}
      zExtraIndex={2}
      disableDrag={true}
    >
      {condition ? (
        <Sign
          onClose={onClose}
          requestEvent={requestEvent}
          requestSession={requestSession}
          isMessgae={isMessgae}
        />
      ) : (
        <></>
      )}
    </BottomSheet>
  );
});

export default SignSheet;
