import { fontDescription } from "@/style/style.global";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 16px 8px;
  background: transparent;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const Line = styled.div`
  height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftItem = styled.div`
  height: 16px;
  color: var(--Deep-400, #80868f);
  ${fontDescription}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightItem = styled.div`
  line-height: 16px;
`;

export interface IProps {
  list: {
    label: string;
    value: ReactNode;
  }[];
}

export const TradeBreakdownViewV2: React.FC<IProps> = ({ list }) => {
  return (
    <Container>
      {list.map((item) => (
        <Line key={item.label}>
          <LeftItem>{item.label}</LeftItem>
          <RightItem>{item.value}</RightItem>
        </Line>
      ))}
    </Container>
  );
};
