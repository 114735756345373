import styled from "styled-components";
import tipsIcon from "src/assets/icons/tips.svg";
import { useTranslation } from "react-i18next";
import arrowImg from "src/assets/icons/trade/payment-helper.svg";

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 18px;
`;

const HelperIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const HintBox = styled.div`
  line-height: 16px;
  position: relative;
  padding: 8px 10px;
  border-radius: 8px;
  background: var(--Deep-50, #edeef1);

  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--Deep-400, #80868f);
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const Arrow = styled.img`
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);

  width: 8px;
  height: 16px;
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export const CrossChainAssetSelectTip: React.FC<IProps> = ({
  className,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <Container className={className} style={style}>
      <HelperIcon src={tipsIcon} />

      <HintBox>
        {t(
          "Cross-chain swap only supports whitelisted assets. For unsupported assets, please swap them within the same chain first.👇"
        )}

        <Arrow src={arrowImg} />
      </HintBox>
    </Container>
  );
};
