import { t } from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import KontosIconSvg from "src/assets/icons/auth/kontos.svg";
import newWalletIco from "src/assets/icons/new-wallet-ico.svg";
import toast from "src/components/toast/Toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  ROUTE_CREATE_ACCOUNT,
  ROUTE_RECOVER_ACCOUNT,
} from "src/router/router-config";
import arrow11 from "src/assets/icons/arrow11.svg";
import { useStores } from "src/hooks/useStore";
import { NoNetworkPopup } from "src/components/popups/NoNetworkPopup";
import { PRIVACY_POLICY } from "src/config";
import { Trans } from "react-i18next";
import { fontBold, fontH5 } from "@/style/style.global";

const bgPath = "/static/bg-light.png";

const Container = styled.div<{ $isOverWidth?: boolean }>`
  height: ${(props) => (props.$isOverWidth ? "800px" : "100%")};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  padding: 0 20px 43px 20px;
  box-sizing: border-box;

  background-image: url(${bgPath});
  background-repeat: no-repeat;
  background-position: center calc(50% - 100px);
  background-size: 170%;
`;

const ShowArea = styled.div`
  flex: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }

  .icon {
    margin-top: 20px;
    width: 96px;
    height: 96px;
  }

  .title {
    margin-top: 16px;

    color: var(--Kontos-Blue, #413dec);
    text-align: center;
    ${fontBold}
    font-size: 28px;
  }

  .text-1 {
    color: var(--Kontos-Blue, #413dec);
    text-align: center;
    font-family: HarmonyOS Sans;
    font-size: 18px;
    font-weight: 400;
  }
`;

const ButtonArea = styled.div`
  width: 100%;

  & > button:not(:first-child) {
    margin-top: 12px;
  }

  .buttonGoogle {
    color: var(--White, #fff);
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 12px;

    /* H5 */
    ${fontBold}
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    background: var(--Kontos-Blue, #413dec);
    height: 56px;
    padding: 13px 30px 13px 46px;
    flex-shrink: 0;
    position: relative;

    .img0 {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      margin-right: 12px;
    }

    .img1 {
      width: 14px;
      height: 16px;
      flex-shrink: 0;
      position: absolute;
      right: 30px;
      top: 18px;
    }

    .img1PlaceHolder {
      width: 14px;
      height: 16px;
    }
  }

  .buttonRegister {
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 13px 30px 13px 46px;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 120px;
    border: 1px solid var(--Kontos-Blue, #413dec);
    color: var(--Kontos-Blue, #413dec);
    text-align: center;

    /* H5 */
    ${fontH5}

    .img1 {
      width: 14px;
      height: 16px;
      flex-shrink: 0;
      position: absolute;
      right: 30px;
      top: 18px;
    }
  }
`;

const TermsArea = styled.div`
  margin-top: 43px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text-2 {
    color: var(--Deep-400, #80868f);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }

  .text-3 {
    color: var(--Kontos-Blue, #413dec);
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;

    text-decoration-line: none;
  }
`;

const Welcome: React.FC = () => {
  const { uiStore, userStore } = useStores();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [termsAgreed, setTermsAgreed] = useState(true);
  const referralCode = searchParams.get("referralCode");
  const [showNoNetworkWarnPopup, setShowNoNetworkPopup] =
    useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleNewWalletClick = () => {
    if (!termsAgreed) {
      toast({
        text: t(
          "Please check the box to accept the Kontos Terms of Service before you continue."
        ),
        type: "warning",
      });
      return;
    }
    // navigate(ROUTE_CREATE_ACCOUNT, { state: { fromWelcome: true } });
    navigate(
      referralCode
        ? ROUTE_CREATE_ACCOUNT + `?referralCode=${referralCode}`
        : ROUTE_CREATE_ACCOUNT
    );
  };

  const handleRecoverWalletClick = () => {
    if (!termsAgreed) {
      toast({
        text: t(
          "Please check the box to accept the Kontos Terms of Service before you continue."
        ),
        type: "warning",
      });
      return;
    }
    navigate(ROUTE_RECOVER_ACCOUNT);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (userStore.noNetwork && !showNoNetworkWarnPopup) {
        setShowNoNetworkPopup(true);
        event.stopPropagation();
        return;
      }
    };

    const rootElement = wrapperRef.current;
    if (rootElement) {
      rootElement.addEventListener("click", handleClick);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener("click", handleClick);
      }
    };
  }, [
    navigate,
    showNoNetworkWarnPopup,
    userStore.noNetwork,
    userStore.pubKeyException,
  ]);

  useEffect(() => {
    if (userStore.noNetwork && !showNoNetworkWarnPopup) {
      setShowNoNetworkPopup(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Container $isOverWidth={uiStore.isOverWidth} ref={wrapperRef}>
      <ShowArea>
        <img className="icon" src={KontosIconSvg} alt="Kontos" />
        <div className="title">{t("Welcome to Kontos")}</div>
        <div className="text-1">{t("One Account")}</div>
        <div className="text-1">{t("Infinite Possibilities")}</div>
      </ShowArea>
      <ButtonArea>
        <div className={"buttonGoogle"} onClick={handleNewWalletClick}>
          <img className={"img0"} src={newWalletIco} alt="" />
          {t("New Wallet")}
          <div className={"img1PlaceHolder"} />
        </div>
        <div className={"buttonRegister"} onClick={handleRecoverWalletClick}>
          {t("Recover Wallet")}
          <img className={"img1"} src={arrow11} alt="" />
        </div>
      </ButtonArea>
      <TermsArea>
        <Trans i18nKey="trans-welcome-terms-of-service">
          <span className="text-2">
            By continuing, you agree to our{" "}
            <a href={PRIVACY_POLICY} className="text-3">
              "Terms of Service"
            </a>
          </span>
        </Trans>
      </TermsArea>

      {/* no network exception modal */}
      {showNoNetworkWarnPopup && (
        <NoNetworkPopup
          onRetry={userStore.retryConnectToNetwork}
          onClose={() => setShowNoNetworkPopup(false)}
        />
      )}
    </Container>
  );
};

export default observer(Welcome);
