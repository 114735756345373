import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import kontosAvatarGoogle from "src/assets/images/kontos-avatar-google.svg";
import kontosAvatar from "src/assets/images/kontos-avatar-normal.svg";
import success from "src/assets/images/success.svg";
import arrow from "src/assets/images/arrow.svg";
import arrowWhite from "src/assets/images/arrow-white.svg";
import HorizontalLine from "src/components/line/HorizontalLine";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import { ButtonCancel } from "src/components/button/ButtonCancel";
import PoweredBy from "src/components/common/powerdBy";
import { useSearchParams } from "react-router-dom";
import StrikethroughText from "src/components/line/TextLine";
import { statusRouterMap } from "src/pages/newAuth/CreateAccount";
import { fontBold, fontH5, fontSmaller } from "@/style/style.global";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .description {
      color: var(--Deep-800, #1a2535);
      text-align: center;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */

      span {
        color: var(--Kontos-Blue, #413dec);
        ${fontBold};
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
      }
    }

    .kontosAvatarGoogle,
    .kontosAvatar {
      width: 89px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;

      .securityEmail,
      .setUpGuardian {
        box-sizing: border-box;
        display: inline-flex;
        height: 56px;
        padding: 17px 20px 18px 30px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 99px;
        border: 1px solid var(--Deep-50, #edeef1);
        background: var(--Deep-25, #f5f5f6);
        width: 100%;
        color: var(--Deep-800, #1a2535);

        /* H5 */
        ${fontH5}

        .title {
          color: var(--Deep-800, #1a2535);

          /* H5 */
          ${fontH5}
          margin-right: 8px;
        }

        .text {
          color: var(--Deep-400, #80868f);

          /* Smaller */
          ${fontSmaller}
          margin-right: 25px;
        }

        img {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }

      .securityEmailActive,
      .setUpGuardianActive {
        width: 100%;
        position: relative;

        .arrow {
          width: 14px;
          height: auto;
          right: 30px;
          top: 20px;
          position: absolute;
        }
      }

      .securityEmailActive {
        margin-bottom: 12px;
      }
    }
  }

  .buttonWrap {
    width: calc(100% - 40px);
    position: fixed;
    bottom: 16px;
  }
`;

export const securityInfoText = {
  mustBeSet: t(
    "Please choose any of the following options to add security measures to your account."
  ),
  canBeSet: t(
    "You can either complete the account creation directly or continue to add security settings to your account "
  ),
  fullySet: t("You have added complete security protection to your account: "),
};

type IProps = {
  kontosName?: string;
  userEmailArr: string[];
  setRouteStatus: (status: string) => void;
  securityInfoKey: keyof typeof securityInfoText;
  securitySettingsInitType: string;
};

const SecuritySettingsInit: React.FC<IProps> = ({
  kontosName,
  userEmailArr,
  setRouteStatus,
  securityInfoKey,
  securitySettingsInitType,
}) => {
  const [inputValue, setInputValue] = useState("Jacky.seegmal@gmail.com");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClearClick = () => {
    setInputValue("");
  };

  return (
    <Wrapper>
      <div className={"main"}>
        {userEmailArr.length > 0 ? (
          <img
            className={"kontosAvatarGoogle"}
            src={kontosAvatarGoogle}
            alt="kontosAvatarGoogle"
          />
        ) : (
          <img
            className={"kontosAvatar"}
            src={kontosAvatar}
            alt="kontosAvatar"
          />
        )}
        <div className={"description"}>
          {t(securityInfoText[securityInfoKey])}
          <span>{kontosName}.os</span>
        </div>
        <div className={"lineWrap"}>
          <HorizontalLine />
        </div>
        <div className={"inputWrap"}>
          <div className={"securityEmailActive"}>
            <Button
              onClick={() => {
                setRouteStatus("google");
              }}
              text={t("Add Security Email")}
              height={"56px"}
            />
            <img className={"arrow"} src={arrowWhite} />
          </div>

          {securitySettingsInitType !== statusRouterMap.google && (
            <>
              <div
                style={{
                  width: "100%",
                  padding: "23px 25px",
                }}
              >
                <StrikethroughText text={"OR"} />
              </div>
              <div className={"setUpGuardianActive"}>
                <ButtonCancel
                  onClick={() => {
                    setRouteStatus("setUpGuardians");
                  }}
                  text={t("Set up Guardians")}
                  height={"56px"}
                />
                <img className={"arrow"} src={arrow} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={"buttonWrap"}>
        <PoweredBy />
      </div>
    </Wrapper>
  );
};

export default observer(SecuritySettingsInit);
