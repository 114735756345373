import { useTranslation } from "react-i18next";
import Header from "src/components/common/header";
import styled from "styled-components";
import tipsIco from "src/assets/icons/trade/tip.svg";
import { SearchInput } from "src/components/input-search/InputSearch";
import { useCallback, useEffect, useRef, useState } from "react";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { useStores } from "src/hooks/useStore";
import { SheetView } from "src/store/SheetStore";
import { observer } from "mobx-react";
import Divider from "src/components/divider/Divider";
import { KontosButton } from "src/components/button/KontosButton";
import spinIco from "src/assets/icons/dapp/spin.png";
import toast from "src/components/toast/Toast";
import NoDataV2 from "src/components/no-data/NoDataV2";
import DappConnectItems from "./components/DappConnectItems";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;

  > .icon-tips {
    margin-top: 20px;
    width: 28px;
    height: 16px;
  }

  > .text-tips {
    margin-top: 8px;
    padding: 0 35px;
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }

  > .search-input-box {
    width: 100%;
    padding: 0 16px;
    margin-top: 18px;
    display: flex;
    align-items: center;

    .search-input {
      flex: 1;
      height: 44px !important;
      margin: 0 !important;
    }

    > .dapp-connection-connect-btn {
      margin-left: 8px;
      width: 86px;
      height: 38px;
      line-height: 16px;
      padding: 11px 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--White, #fff);
      text-align: center;
      ${fontBold};
      font-size: 14px;

      > .dapp-connection-pair-loading {
        @keyframes dapp-connection-spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        width: 24px;
        height: 24px;
        animation: dapp-connection-spin 1s linear infinite;
        overflow: auto;
      }
    }
  }

  > .connect-items {
    width: 100%;
    padding: 0 16px 16px 16px;
  }

  > .no-data {
    margin-bottom: 120px;
  }

  .connect-tip {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
  }
`;

const DappConnections: React.FC = observer(() => {
  const { dappConnectStore, sheetStore } = useStores();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    dappConnectStore.refreshSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sheetStore.latestSheet?.view !== SheetView.DappConnection) {
      setInputValue("");
    }
  }, [sheetStore.latestSheet]);

  const handleClose = useCallback(() => {
    sheetStore.closeSheetByView(SheetView.DappConnection);
  }, [sheetStore]);

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === "Enter" &&
        inputValue &&
        !dappConnectStore.pairLoading
      ) {
        inputRef.current?.blur();
        await dappConnectStore.onPair(inputValue);
      }
    },
    [dappConnectStore, inputValue]
  );

  const handleConnect = useCallback(() => {
    if (!inputValue) {
      toast({
        type: "warning",
        text: t("Please enter the WalletConnect link to connect"),
      });
      return;
    }
    if (!dappConnectStore.pairLoading) {
      dappConnectStore.onPair(inputValue);
    }
  }, [dappConnectStore, inputValue, t]);

  return (
    <Container>
      <Header
        padding="0 20px 20px 20px"
        title={t("DApp Connections")}
        callBack={handleClose}
        rightTipText={t("Help?")}
        rightTipContent={
          <div className="connect-tip">
            {t(
              "To connect to the DApp, you need to paste the link you copied from the WalletConnect popup into the input field below."
            )}
          </div>
        }
      />

      <img className="icon-tips" src={tipsIco} alt="" />
      <div className="text-tips">
        {t(
          "You can paste the WalletConnect Link to proactively connect to the DApp."
        )}
      </div>
      <div className="search-input-box">
        <SearchInput
          ref={inputRef}
          className="search-input"
          placeholder={t("Please enter the WalletConnect link")}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <KontosButton
          className="dapp-connection-connect-btn"
          $loading={dappConnectStore.pairLoading}
          onClick={handleConnect}
        >
          {dappConnectStore.pairLoading ? (
            <img
              className="dapp-connection-pair-loading"
              src={spinIco}
              alt="loading..."
            />
          ) : (
            t("Connect")
          )}
        </KontosButton>
      </div>
      <Divider color="#EDEEF1" top={18} bottom={20} left={45} right={45} />

      {dappConnectStore.sessions.length > 0 ? (
        <Scrollable className="connect-items">
          <DappConnectItems
            sessions={dappConnectStore.sessions}
            onDisconnect={dappConnectStore.onDelete}
          />
        </Scrollable>
      ) : (
        <NoDataV2
          className="no-data"
          text={t("There are no connected DApps.")}
        />
      )}
    </Container>
  );
});

interface Props {
  mountPoint?: Element;
}

export const DappConnectionsSheet: React.FC<Props> = observer(
  ({ mountPoint }) => {
    const { sheetStore } = useStores();
    const isOpen = sheetStore.sheetVisibility.get(
      SheetView.DappConnection
    ) as boolean;

    const handleClose = useCallback(() => {
      sheetStore.closeSheetByView(SheetView.DappConnection);
    }, [sheetStore]);

    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={handleClose}
        mountPoint={mountPoint}
      >
        <DappConnections />
      </BottomSheet>
    );
  }
);
