import { fontBold } from "@/style/style.global";
import { Button } from "../../components/button/Button";
import { scrollbar } from "../../components/global-styles";
import styled from "styled-components";

export const Wrap = styled.div`
  box-sizing: border-box;
  font-family: "HarmonyOS Sans";
  background: url(/static/kontos-bg.png) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  //height: 100vh;
  background-color: #fff;
  overflow: auto;

  .center {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .logo {
    margin-bottom: 10px;
    width: 150px;
    height: auto;
  }

  .content {
    flex-shrink: 0;
    width: 80%;
    max-width: 420px;
    min-height: 510px;
    background: #ffffff;
    border: 2px solid #eff0f1;
    box-shadow: 8px 8px 8px rgba(51, 0, 143, 0.05);
    border-radius: 38px;
    padding: 30px 20px;
    position: relative;

    .logoIco {
      width: 96px;
      height: 96px;
      display: block;
      margin: 0 auto 32px;
    }

    .logoIcoSmall {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto 32px;
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
    .main-loading {
      width: 80px;
      height: 80px;
      margin: 100px auto;
      transform: rotate(45deg);
      animation: rotation 1s linear infinite;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      ${fontBold}
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #413dec;
      margin-bottom: 30px;
      position: relative;
      .infoIco {
        z-index: 9;

        .info {
          width: 395px;
          height: 393px;
          position: absolute;
          left: -26px;
          top: 0;
          // display: none;
        }

        /* &:hover {
     img {
      display: none;
     }

     .infoIcoImg {
      display: block;
     }

     .info {
      display: block;
     }
    } */
      }
    }

    .accountList {
      padding: 0 20px 0 4px;
      height: 269px;
      overflow-y: auto;
      ${scrollbar};
      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .headImg {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          margin-right: 8px;
        }

        .accountName {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }

        .successText {
          color: #10ce5c;
          margin-right: 7px;
        }
        .pendingText {
          color: #ffb258;
          margin-right: 7px;
        }

        .statusIco {
          width: 17px;
          height: 17px;
        }
      }
    }

    .importSuccess {
      width: 105px;
      height: 105px;
      margin-top: auto;
    }

    .chains {
      width: 100%;
      max-width: none;
      display: block;
      object-fit: cover;
      margin-bottom: 31px;
      height: 68.64px;
      overflow: hidden;
    }

    .back {
      width: 13px;
      height: 22px;
      cursor: pointer;
      max-width: none;
      display: block;
      left: 39px;
      top: 38px;
      position: absolute;
    }
    .skip-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      right: 39px;
      top: 38px;
      color: #413dec;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;
      .skip-icon {
        width: 13px;
        height: 13px;
        margin-left: 6px;
        transform: rotate(180deg);
      }
    }

    .congratulate {
      position: absolute;
      left: 0;
      top: 28px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 354px;
        height: 230px;
      }
    }

    .addressInput {
      position: relative;
      z-index: 3;

      input {
        padding: 0 78px 0 58px;
        margin-bottom: 49px;
        text-align: center;

        &::placeholder {
          text-align: left;
        }
        &:focus {
          color: #413dec;
          text-align: center;
        }
      }

      .ico {
        position: absolute;
        left: 16px;
        top: 8px;
        width: 35px;
        height: 35px;
        z-index: 3;
      }
      .icoLoading {
        width: 20px;
        height: 20px;
        top: 15px;
        transform: rotate(45deg);
        animation: rotation 1s linear infinite;
      }
      .icoSuccess {
        width: 20px;
        height: 20px;
        top: 15px;
      }

      .icoSearch {
        width: 24px;
        height: 27px;
        left: 18px;
        top: 12px;
        z-index: 3;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000d1f;
        position: absolute;
        right: 41px;
        top: 12px;
        z-index: 3;
      }
      .nameErr {
        //display: none;
      }
    }

    .addressInputErr {
      input {
        color: #ff1e2b;
        border: 2px solid #ff1e2b;
        outline: none;
      }
      input:focus {
        color: #ff1e2b;
      }
      span {
        color: #ff1e2b;
      }
      .nameErr {
        display: block;
        position: absolute;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #ff1e2b;
        left: 24px;
        top: 53px;
      }
    }

    .errText {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #ff1e2b;
      text-align: left;
      padding-left: 28px;
      margin-bottom: 16px;
    }

    .errTextR {
      margin-bottom: 0px;
      position: absolute;
      left: 0;
      top: 58px;
    }

    .searchList {
      position: absolute;
      z-index: 2;
      width: 100%;
      background: #ffffff;
      overflow-y: auto;
      ${scrollbar};
      outline: 1px solid #413dec;
      border: 1px solid #413dec;
      border-radius: 0px 0px 26px 26px;
      max-height: 240px;
      padding: 27px 25px 3px;
      left: 0;
      top: 30px;

      > .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;
        .name {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          color: #000d1f;
        }

        .suffix {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          text-align: center;
          color: #bbbfc3;
        }
      }
    }

    .addressInput1 {
      input {
        margin-bottom: 18px;
      }
    }

    .addressInput2 {
      input {
        padding: 0 24px;
        margin-bottom: 21px;
      }
    }

    .service {
      font-style: normal;
      ${fontBold}
      font-size: 14px;
      text-align: center;
      color: #000000;
      margin-top: 13px;
      margin-bottom: 23px;

      a {
        color: #413dec;
        margin-left: 4px;
      }
    }
    .guardian-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      height: 170px;
      overflow-y: auto;
      ${scrollbar};
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        color: #413dec;
        font-size: 18px;
        line-height: 23px;
        .icon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
      .selected {
        justify-content: flex-start;
        padding: 0 12px;
        color: #000d1f;
        .icon {
          width: 22px;
          height: 22px;
          cursor: pointer;
          margin-right: 12px;
        }
      }
    }
    .guardian-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      ${fontBold}
      font-size: 18px;
      line-height: 21px;
      color: #000d1f;
      margin-bottom: 30px;
      .guardian-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
      }
      .main-text {
        ${fontBold}
        font-size: 24px;
        line-height: 28px;
        color: #413dec;
      }
      .icon {
        width: 24px;
        height: 24px;
        margin: 0 17px;
        cursor: pointer;
      }
      .disabled {
        cursor: not-allowed;
      }
    }

    .add {
      height: 48px;
      border: 1px dashed #80868f;
      border-radius: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      color: #80868f;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      .icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
    .mg-top {
      margin-top: auto;
    }

    .inputPasswordWithEye {
      margin-bottom: 152px;
      position: relative;

      input {
        margin-bottom: 0;
      }
    }
    .btns {
      margin-top: auto;
      width: 100%;
    }
    .text-butntton {
      width: 100%;
      color: #413dec;
      text-align: center;
      ${fontBold}
      font-size: 14px;
      margin-top: 16px;
      cursor: pointer;
    }
  }

  .content1 {
    // padding: 26px 30px;
    display: flex;
    flex-direction: column;
  }

  .content2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logoIco {
      margin: 0 auto 80px;
    }
  }

  .success-head {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 354px;
    height: 230px;
    background: url(/static/congratulate.png) no-repeat 100% 100%;
    .logoIcoSmall {
      width: 112px;
      height: 112px;
    }
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #80868f;
  }
  .payment-container {
    margin-bottom: auto;
    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #1a2535;
      font-size: 16px;
      font-family: HarmonyOS Sans;
      ${fontBold}
      .btn {
        color: #fff;
        text-align: center;
        font-size: 14px;
        ${fontBold}
        padding: 8px 28px;
        border-radius: 99px;
        background: #1a2535;
        cursor: pointer;
      }
    }
    .desc {
      color: #80868f;
      font-size: 12px;
      font-weight: 500;
      line-height: 130%;
      margin-top: 12px;
    }
    .payment-list {
      position: relative;
      flex-direction: column;
      border-top: 1px solid #eff0f1;
      margin-top: 16px;
      ::after {
        position: absolute;
        left: 0;
        top: -9px;
        background-color: #fff;
        content: "Chains";
        color: #80868f;
        font-size: 12px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        line-height: 130%;
        width: 72px;
        text-align: left;
        z-index: 9999;
      }
      .list {
        // position: relative;
        display: flex;
        flex-direction: column;
        max-height: 180px;
        overflow-y: auto;
        margin-top: 16px;
        ${scrollbar};
      }
      .payment-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        color: #000d1f;
        font-size: 14px;
        ${fontBold}
        .chain-icon {
          width: 40px;
          height: 40px;
          margin-right: 8px;
          border-radius: 50%;
        }
        .amount {
          margin-left: auto;
        }
        .error {
          color: #ff1e2b;
        }
      }
    }
    .payment-total {
      position: relative;
      border-top: 1px solid #eff0f1;
      padding-top: 24px;
      ::after {
        position: absolute;
        left: 0;
        top: -9px;
        background-color: #fff;
        content: "Total cost";
        color: #80868f;
        font-size: 12px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        line-height: 130%;
        width: 72px;
        text-align: left;
      }
      .list {
        // position: relative;
        display: flex;
        flex-direction: column;
        max-height: 180px;
        margin-bottom: 16px;
        overflow-y: auto;
        ${scrollbar};
        .list-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .chain-icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        border-radius: 50%;
      }
      .info-container {
        margin-right: auto;
        display: flex;
        flex-direction: column;
        color: #000d1f;
        font-size: 14px;
        ${fontBold}
        :last-child {
          // margin-left: auto;
        }
        .info-desc {
          color: #80868f;
          font-size: 12px;
          font-family: HarmonyOS Sans;
          font-weight: 500;
          margin-top: 4px;
        }
        .end {
          text-align: right;
        }
        .total-amount {
          color: #413dec;
        }
      }
      .info-container-2 {
        margin-left: auto;
        margin-right: 0;
      }
    }
    .payment-balance {
      margin-top: 10px;
      width: 100%;
      color: #80868f;
      text-align: right;
      font-size: 12px;
      font-family: HarmonyOS Sans;
      font-weight: 500;
    }
    .payment-err {
      width: 100%;
      margin: 16px 0;
      color: #ff1e2b;
      text-align: center;
      font-size: 12px;
      font-family: HarmonyOS Sans SC;
      font-weight: 500;
      line-height: 130%;
    }
  }

  .mb16 {
    margin-bottom: 16px;
  }
  .rc-search {
    position: relative;
    .rc-select__control {
      font-family: "HarmonyOS Sans";
      background: #f7f7f8;
      border: 1px solid #dfe1e3;
      border-radius: 54px;
      line-height: 34px;
      box-sizing: border-box;
      padding: 0 24px;
      width: 100%;
      height: 48px;
      color: #80868f;
      margin-bottom: 11px;
      font-size: 18px;
      box-shadow: none;
      z-index: 2;
      &::placeholder {
        color: #80868f;
      }
    }
    .rc-select__indicators {
      display: none;
    }
    .rc-select__value-container {
      padding: 0 20px;
    }
    .rc-select__menu {
      padding-top: 26px;
      border-width: 0px 2px 2px 2px;
      border-style: solid;
      border-color: #413dec;
      border-radius: 0px 0px 26px 26px;
      top: calc(100% - 26px);
      overflow: hidden;
    }
    .rc-select__control--is-focused {
      outline: 2px solid #413dec;
      border: 2px solid #413dec;
      background: #fff;
    }
    .rc-select__option {
      position: relative;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #000d1f;
      :after {
        position: absolute;
        right: 22px;
        content: ".os";
        color: #bbbfc3;
        font-size: 18px;
        line-height: 23px;
      }
    }
    .rc-select__option--is-selected {
      color: #413dec;
      background: #fff;
    }
    .rc-select__option--is-focused {
    }
  }
  .input-wrapper {
    margin: auto 0;
  }
  .forget-password-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    .forget-password-header {
      color: #1a2535;
      text-align: center;
      ${fontBold}
      font-size: 20px;
      .main-text {
        color: #413dec;
      }
    }
    .forget-password-desc {
      color: #1a2535;
      text-align: center;
      font-size: 14px;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 12px;
    }
    .copy-wrapper {
      border-radius: 54px;
      border: 1px solid #dfe1e3;
      background: #f7f7f8;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 14px 16px;
      overflow: hidden;
      margin-bottom: 20px;
      .copy-content {
        font-size: 16px;
        color: #1a2535;
        text-overflow: ellipsis;
        width: 220px;
        overflow: hidden;
      }
      div {
        font-size: 14px;
        padding: 6px 14px;
        width: auto;
        height: 30px;
      }
    }
    .forget-password-qr-code {
      width: 120px;
      height: 120px;
      margin-bottom: 20px;
    }
  }
`;

export const DefalutButton = styled(Button)`
  border-color: #413dec;
  button:disabled {
    border: 0;
  }
`;
