import { callAccount, callNonce } from "@/apis/aa-apis";
import { Account } from "@/type/zkkontos";
import { ApiAccountDetail } from "@/apis/types";

export const fetchAccount = async (account: string): Promise<Account> => {
  const { accountDetail } = await callAccount({ account, offset: 0, limit: 0 });
  return accountDetail;
};

export const fetchGuardians = async (account: string): Promise<string[]> => {
  const {
    accountDetail: { guardians },
  } = await callAccount({ account, offset: 0, limit: 0 });
  return guardians || [];
};

export const getSequence = async (account: string): Promise<number> => {
  const { nonce } = await callNonce({ account });
  return nonce;
};

export const fetchAaAccounts = async (
  account: string
): Promise<{
  [key: string]: string;
}> => {
  const {
    accountDetail: { blockChainAccounts },
  } = await callAccount({ account, offset: 0, limit: 0 });
  const aaAccounts = Object.keys(blockChainAccounts).reduce(
    (acc, key) => {
      acc[key] = blockChainAccounts[key].aaAddress;
      return acc;
    },
    {} as { [key: string]: string }
  );
  return aaAccounts;
};

export const fetchAccountDetail = async (
  account: string
): Promise<ApiAccountDetail> => {
  const { accountDetail } = await callAccount({ account, offset: 0, limit: 0 });
  return accountDetail;
};
