import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import fireworksBgImg from "src/assets/images/event/eb/fireworks-bg.png";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { getOrbImg } from "src/utils/events/ebHelper";
import { BASIC_SHEET_INDEX } from "src/config";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  background-image: url(${fireworksBgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center 800%;

  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 32px 20px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 18px;
    line-height: 22px;
  }

  > .content {
    margin-top: 10px;
    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  > .orbs {
    margin-top: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .orb {
      border-radius: 99px;
      background: var(--Deep-25, #f5f5f6);
      padding: 4px 13px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        margin-right: 4px;
        width: 24px;
        height: 24px;
      }

      > span {
        color: var(--Deep-800, #1a2535);
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  > .ok-btn {
    margin-top: 16px;
  }
`;

interface Props {
  thresholds: ebTypes.EnergyThreshold[];
  onClose: (open: boolean) => void;
}

export const EbNewStagePopup: React.FC<Props> = ({ thresholds, onClose }) => {
  const { t } = useTranslation();
  const title = t("Congratulations!");
  const content = t("You've unlocked the opportunity to earn more rewards.");

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="orbs">
          {thresholds.map((item) => (
            <div key={item.value} className="orb">
              <img src={getOrbImg(item.energyOrbRewardType)} alt="" />
              <span>x{item.energyOrbsRewardCount}</span>
            </div>
          ))}
        </div>
        <DeepButton className="ok-btn" onClick={() => onClose(false)}>
          {t("OK")}
        </DeepButton>
      </Container>
    </Overlay>
  );
};
