import styled from "styled-components";
import { t } from "i18next";
import { SingleIconButton } from "src/components/button/SingleIconButton";
import Floater from "react-floater";
import { ManageAssetTip } from "src/components/tips/ManageAssetTip";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { useRef, useState } from "react";
import { CallBackProps, STATUS } from "react-joyride";
import { OnboardingType } from "src/store/localKeeper";
import { fontH4 } from "@/style/style.global";

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  height: 36px;
  width: 100%;
  padding: 0 16px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  user-select: none;
  ${fontH4}
`;

const ManageBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  onClick?: () => void;
}

export const TradeNavigator: React.FC<IProps> = observer(({ onClick }) => {
  const { tradeStore } = useStores();
  const manageBtnRef = useRef<HTMLDivElement | null>(null);

  return (
    <Container>
      <Title>{t("Trading")}</Title>

      {/* <ManageBtnWrapper ref={manageBtnRef}>
        <SingleIconButton
          className="payment-asset-manage"
          type="paymentAssetsManage"
          size={36}
          onClick={onClick}
        />
      </ManageBtnWrapper> */}

      {tradeStore.showManageTip && (
        <Floater
          open
          eventDelay={0}
          component={<ManageAssetTip />}
          styles={{
            options: {
              zIndex: 2000000,
            },
            arrow: {
              color: "var(--Deep-800, #1a2535)",
              length: 5,
              spread: 11,
            },
          }}
          offset={3}
          target={".payment-asset-manage"}
          placement={"top"}
        />
      )}
    </Container>
  );
});
