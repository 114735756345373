import { fontSmaller } from "@/style/style.global";
import { ReactNode } from "react";
import styled from "styled-components";
import warningIco from "src/assets/icons/receive/warning.svg";

const Container = styled.div`
  width: 100%;
  padding: 8px 20px;
  border-radius: 8px;
  background: rgba(250, 173, 20, 0.05);

  display: flex;
  align-items: center;
  gap: 16px;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const Content = styled.div`
  color: var(--Warning, #faad14);
  /* Smaller */
  ${fontSmaller}
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
}

export const WarningArea: React.FC<IProps> = ({
  className,
  style,
  children,
}) => {
  return (
    <Container className={className} style={style}>
      <Icon src={warningIco} alt="warning" />
      <Content>{children}</Content>
    </Container>
  );
};
