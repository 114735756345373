import styled from "styled-components";
import TipsIcon from "../../assets/images/main-tips-icon.svg";
import { t } from "i18next";
import { useState } from "react";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div<{ $hover: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  width: 18px;
  ${(props) =>
    props.$hover &&
    `
    :hover {
      .tips {
        display: flex;
      }
    }
  `}

  .tips {
    display: ${(props) => (props.$hover ? "none" : "flex")};
    position: absolute;
    top: 29px;
  }

  .info-tips-content {
    position: relative;
    height: auto;
    min-height: auto;
    font-size: 16px;
    line-height: 23px;
    background: #ffffff;
    border: 1px solid #413dec;
    border-radius: 20px;
    color: #000d1f;
    text-align: left;
    font-family: "HarmonyOS Sans";
    font-weight: 400;
    .title {
      color: #413dec;
      ${fontBold}
    }
    .last {
      margin-top: 20px;
    }
  }

  .info-tips-content:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #413dec;
    // left: 20px;
    top: -16px;
  }
  .info-tips-content:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    // left: 20px;
    top: -15px;
  }

  @media (max-width: 600px) {
    .tips {
      left: -260px;
    }

    .info-tips-content {
      width: 270px;
      padding: 15px;
    }

    .info-tips-content:before {
      left: 260px;
    }

    .info-tips-content:after {
      left: 260px;
    }
  }

  @media (min-width: 600px) {
    .tips {
      left: -18px;
    }

    .info-tips-content {
      width: 400px;
      padding: 24px;
    }

    .info-tips-content:before {
      left: 20px;
    }

    .info-tips-content:after {
      left: 20px;
    }
  }

  .btn {
    color: #413dec;
    font-size: 16px;
    font-style: normal;
    ${fontBold}
    text-align: center;
    width: 100%;
    cursor: pointer;
    margin-top: 24px;
  }
`;

type TipsData = {
  title: string;
  content: string[];
};
type Props = {
  initShow?: boolean;
  hover?: boolean;
  data: TipsData[];
};

export const InfoTips: React.FC<Props> = ({
  hover = false,
  initShow = true,
  data,
}) => {
  const [open, setOpen] = useState(initShow);

  return (
    <Wrapper $hover={hover}>
      <img
        src={TipsIcon}
        className="icon"
        onClick={() => setOpen(true)}
        alt=""
      />
      {(hover || open) && (
        <div className="tips">
          <div className="info-tips-content">
            {data.map((item) => (
              <div key={item.title}>
                <div className="title">{t(`${item.title}`)}</div>
                {item.content.map((subItem, index) => (
                  <div key={`${item.title}-${index}`}>- {t(`${subItem}`)}</div>
                ))}
              </div>
            ))}
            {!hover && (
              <div className="btn" onClick={() => setOpen(false)}>
                OK
              </div>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
};
