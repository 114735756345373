import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { EXPLORER_KONTOS_URL, TEMP_EXPLORER_URL } from "src/config";
import { API } from "./core/API";

export interface ReqDepositCallback {
  eoaAccount: string;
  account: string;
  aaAddress: string;
  txHash: string;
}

export interface RespDepositCallback {
  isExist: boolean;
}

export const callDepositCallback = async (
  data: ReqDepositCallback,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespDepositCallback> => {
  return await request<ReqDepositCallback, RespDepositCallback>(
    API.mwallet.bybitTaskDepositCallback,
    data,
    endpoint
  );
};
