import { set, get } from "./session";
import { KEYS } from "./keys";
import { TradeMode } from "./trade/TradeStore";

export interface AiScoreItem {
  chainIndex: string;
  ftAssetAddress: string;
  score: number;
  expiredAt: number;
}

class SessionKeeper {
  getTradeMode = (): TradeMode => {
    const mode = get(KEYS.session_trade_mode);
    return mode ? (mode as TradeMode) : TradeMode.Buy;
  };

  setTradeMode = (mode: TradeMode) => {
    set(KEYS.session_trade_mode, mode);
  };

  getShowedManageAssetTip = (): boolean => {
    const showed = get(KEYS.session_showed_manage_asset_tip);
    return typeof showed === "boolean" ? showed : false;
  };

  setShowedManageAssetTip = (showed: boolean) => {
    set(KEYS.session_showed_manage_asset_tip, showed);
  };

  getFtAssetAiScore = (): AiScoreItem[] => {
    const arr = get(KEYS.session_0x_scope_ai_score);
    const currentTime = Date.now();

    if (!arr) {
      return [];
    }

    const validItems = (arr as AiScoreItem[]).filter(
      (item) => item.expiredAt > currentTime
    );
    set(KEYS.session_0x_scope_ai_score, validItems);
    return validItems;
  };

  setFtAssetAiScore = (item: AiScoreItem): void => {
    const currentItems = this.getFtAssetAiScore(); // Obtain currently valid projects

    // Find if the same project exists
    const index = currentItems.findIndex(
      (existingItem) =>
        existingItem.ftAssetAddress === item.ftAssetAddress &&
        existingItem.chainIndex === item.chainIndex
    );

    // Set a new expiration time
    const newItem = {
      ...item,
      expiredAt: Date.now() + 5 * 60 * 1000, // The expiration time is the current time plus 5 minutes
    };

    if (index > -1) {
      // If the same item is found, replace the old one
      currentItems[index] = newItem;
    } else {
      // If not found, add a new project
      currentItems.push(newItem);

      // Ensure that the array length does not exceed 20
      if (currentItems.length > 20) {
        currentItems.shift(); // Remove the earliest element
      }
    }

    // Save updated array
    set(KEYS.session_0x_scope_ai_score, currentItems);
  };
}

const sessionKeeper = new SessionKeeper();
export default sessionKeeper;
