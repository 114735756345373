import styled, { css } from "styled-components";

export const fontRegular = css`
  font-family: "HarmonyOS Sans", sans-serif;
  font-weight: 400;
`;
export const fontBold = css`
  font-family: "HarmonyOS Sans Bold", sans-serif;
  font-weight: 700;
`;
export const fontH1 = css`
  ${fontBold}
  font-size: 36px;
`;
export const fontH2 = css`
  ${fontBold}
  font-size: 36px;
  line-height: 34px;
`;
export const fontH3 = css`
  ${fontBold}
  font-size: 24px;
`;
export const fontH4 = css`
  ${fontBold}
  font-size: 20px;
  line-height: 24px;
`;
export const fontH5 = css`
  ${fontBold}
  font-size: 18px;
  line-height: 22px;
`;
export const fontH6 = css`
  ${fontBold}
  font-size: 16px;
  line-height: 20px;
`;
export const fontH7 = css`
  ${fontBold}
  font-size: 14px;
`;
export const fontH8 = css`
  ${fontBold}
  font-size: 12px;
`;
export const fontSubtitle = css`
  ${fontRegular}
  font-size: 18px;
  line-height: 22px;
`;
export const fontMainText = css`
  ${fontRegular}
  font-size: 16px;
  line-height: 130%;
`;
export const fontDescription = css`
  ${fontRegular}
  font-size: 14px;
`;
export const fontSmaller = css`
  ${fontRegular}
  font-size: 12px;
`;

export const H1Text = styled.span`
  ${fontH1}
`;
export const H2Text = styled.span`
  ${fontH2}
`;
export const H3Text = styled.span`
  ${fontH3}
`;
export const H4Text = styled.span`
  ${fontH4}
`;
export const H5Text = styled.span`
  ${fontH5}
`;
export const H6Text = styled.span`
  ${fontH6}
`;
export const H7Text = styled.span`
  ${fontH7}
`;
export const H8Text = styled.span`
  ${fontH8}
`;
export const SubtitleText = styled.span`
  ${fontSubtitle}
`;
export const MainTextText = styled.span`
  ${fontMainText}
`;
export const DescriptionText = styled.span`
  ${fontDescription}
`;
export const SmallerText = styled.span`
  ${fontSmaller}
`;

export const BaseText = styled.span<{ color?: string }>`
  color: ${(props) => props.color || colors.deep400};
  ${fontRegular}
`;

export const colors = {
  kontos: "var(--Kontos-Blue)",
  white: "var(--White)",
  deep25: "var(--Deep-25)",
  deep50: "var(--Deep-50)",
  deep100: "var(--Deep-100)",
  deep200: "var(--Deep-200)",
  deep400: "var(--Deep-400)",
  deep800: "var(--Deep-800)",
  success: "var(--Success)",
  error: "var(--Error)",
  warning: "var(--Warning)",
};
