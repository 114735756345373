import styled from "styled-components";
import { FtAsset } from "@/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import {
  DEFAULT_DECIMAL,
  DEFAULT_GAS_LIMIT_FOR_KONTOS_OTHERS_TRANSFER,
  DEFAULT_PRECISION,
  KONTOS_CHAIN_INDEX,
  KONTOS_JSON_RPC,
  KONTOS_NATIVE_ADDRESS,
  TEMP_KEY,
} from "src/config";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CircleIconPair from "src/components/icons/CircleIconPair";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import { t } from "i18next";
import { KontosButton } from "src/components/button/KontosButton";
import NumericInputV2 from "src/components/input/NumericInputV2";
import toast from "src/components/toast/Toast";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { ethers } from "ethers";
import { KontosQueryCli } from "@zkkontos/kontos-sdk";
import ERC20 from "src/assets/abis/ERC20.json";
import { loadingStore } from "src/store/loadingStore";
import { getChainByChainIndex, isSameFtAsset } from "src/utils/zkkontosHelper";
import { Chain } from "@/type/zkkontos";
import { useStores } from "src/hooks/useStore";
import Header from "src/components/common/header";
import walletIco from "src/assets/icons/wallet.svg";
import triangleIco from "src/assets/icons/triangle0.svg";
import { SelectReceiveModal } from "src/pages/send-h5/SelectReceiveModal";
import successIco from "src/components/toast/icons/success.svg";
import errorIco from "src/assets/icons/error1.svg";
import { AddressCheckResult } from "src/components/input/AddressInputV2";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { findUserHolding, getChainByAsset } from "@/store/storeHelper";
import {
  NativeTransferProps,
  ERC20TransferProps,
  TxConfirmation,
  CrossChainTransferProps,
} from "@/pages/contract-interaction/TxConfirmation";
import { transferToSendFtAssetTypeToInteractionType } from "@/pages/contract-interaction/types";
import { prepareRequest, signTransaction } from "../kontosEthers";
import { PaymentInitData, PaymentProvider } from "@/store/trade/PaymentStore";
import { PaymentPlanFlow } from "@/flows/payment-plan/PaymentPlanFlow";
import { TradeBreakdownViewV2 } from "@/pages/trade/common/TradeBreakdownViewV2";
import { TradeBreakdownEditableItem } from "@/pages/trade/common/TradeBreakdownEditableItem";
import { AssetIconGroup } from "@/components/icons/AssetIconGroup";
import { observer } from "mobx-react";
import { fetchTransferPaymentTask } from "@/service/trade-service";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  margin: 0 16px 16px 16px;
  box-sizing: border-box;

  .border-blue {
    border: 1px solid var(--Kontos-Blue, #413dec);
  }

  .all-blue {
    border: 1px solid var(--Kontos-Blue, #413dec);
    background: var(--Kontos-Blue, #413dec);
  }
`;

const InputPlaceholder = styled.div`
  height: 56px;
  border-radius: 8px;
  background: var(--White, #fff);
  cursor: pointer;
  position: relative;
  font-family: "HarmonyOS Sans SC";
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  padding-left: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 14px;
  }
  &.success {
    border: 1px solid var(--Success, #10ce5c);
    padding-left: 10px;
  }
  &.fail {
    border: 1px solid var(--error-notice, #ff1e2b);
    padding-left: 10px;
  }
  &:hover {
    border: 1px solid var(--Kontos-Blue, #413dec);
  }
`;

const SendInput = styled.div`
  border: 1px solid var(--Deep-50, #edeef1);
  padding: 10px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  &:hover {
    border: 1px solid var(--Kontos-Blue, #413dec);
  }
  .sendAssets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l {
      overflow: hidden;
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
    }

    .r {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;

      .amount {
        color: var(--Deep-400, #80868f);
        margin: 0 4px;
      }

      .max {
        color: var(--Kontos-Blue, #413dec);
        cursor: pointer;
      }
    }
  }
  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .l {
      display: flex;
      align-items: center;
      padding: 10px 8px;
      border-radius: 8px;
      cursor: pointer;
      .triangle {
        margin-right: 8px;
        transform: rotate(180deg);
      }
      &:hover {
        background: var(--Deep-25, #f5f5f6);
      }
      .inputSymbol {
        margin-left: 14px;
        .symbol {
          color: var(--Deep-800, #1a2535);
          ${fontBold};
          font-size: 14px;
          margin-bottom: 2px;
        }

        .chain-symbol {
          color: var(--Deep-400, #80868f);
          font-family: "HarmonyOS Sans SC";
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .r {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .usd-price {
        margin-top: 6px;
        color: var(--Deep-400, #80868f);
        font-family: "HarmonyOS Sans SC";
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;

const AddressTitle = styled.div`
  color: var(--Deep-800, #1a2535);
  ${fontBold};
  font-size: 18px;

  margin: 0 0 8px 8px;
`;

const BlankFilledArea = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const StyledKontosButton = styled(KontosButton)`
  width: calc(100% - 40px);
  position: fixed;
  bottom: 16px;
`;

const BreakdownWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  padding: 0 8px;

  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);
`;

interface IProps {
  toSendFtAsset?: FtAsset;
  displayPrecision?: number;
  maxAvailable?: KontosNumber;
  onBack?: () => void;
  onSuccess?: () => void;
}

export enum ToSendFtAssetType {
  KontosNative,
  KontosOthers,
  Others,
}

const TransferInputStep: React.FC<IProps> = observer(
  ({ toSendFtAsset, displayPrecision, maxAvailable, onBack, onSuccess }) => {
    const { userStore, sheetStore, chainStore, tradeStore } = useStores();
    const maxValue: KontosNumber = maxAvailable
      ? maxAvailable
      : new KontosNumber(toSendFtAsset?.balance?.balance, DEFAULT_DECIMAL);
    const [toSendFtAssetAmount, setToSendFtAssetAmount] =
      useState<KontosNumber>();
    const [isAmountInputFocused, setIsAmountInputFocused] =
      useState<boolean>(false);
    const [isAddressInputFocused, setIsAddressInputFocused] =
      useState<boolean>(false);

    const [receiver, setReceiver] = useState<string>();

    const [showInteractionModal, setShowInteractionModal] = useState(false);
    const [showSelectReceiverModal, setShowSelectReceiverModal] =
      useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const domNode = wrapperRef.current as Element | undefined;
    const [innerModalOpen, setInnerModalOpen] = useState(false);
    const [isReceiveAddressValid, setIsReceiveAddressValid] =
      useState<boolean>(false);
    const [sendKontosNativeProps, setSendKontosNativeProps] =
      useState<NativeTransferProps>();
    const [sendKontosOthersProps, setSendKontosOthersProps] =
      useState<ERC20TransferProps>();
    const [sendOthersProps, setSendOthersProps] =
      useState<CrossChainTransferProps>();
    const title = useMemo(() => {
      if (isAddressInputFocused) {
        return t("To Address");
      }
      return t("Send");
    }, [isAddressInputFocused]);
    const [okText, setOkText] = useState<string[] | undefined>(undefined);
    const [errText, setErrText] = useState<string[] | undefined>(undefined);
    const [initPage, setInitPage] = useState<boolean>(true);
    const showValidationError = useMemo(() => {
      return (
        !initPage &&
        ((receiver && !isReceiveAddressValid) ||
          (errText && errText?.length > 0))
      );
    }, [receiver, isReceiveAddressValid, errText, initPage]);
    const amountInputRef = useRef<{ focus: () => void }>(null);
    const [paymentInitData] = useState<PaymentInitData | undefined>(undefined);
    const [showPaymentPlanSheet, setShowPaymentPlanSheet] =
      useState<boolean>(false);
    const [enableCustomPlan, setEnableCustomPlan] = useState<boolean>(false);
    const [customPlan, setCustomPlan] = useState<FtAsset[]>([]);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(true);

    const selectedBalances: string[] = useMemo(() => {
      return enableCustomPlan
        ? customPlan.map((item) => item.balance!.id)
        : userStore.accountBalances
            .filter((item) => isSameFtAsset(item, toSendFtAsset))
            .map((item) => item.balance!.id);
    }, [
      customPlan,
      enableCustomPlan,
      toSendFtAsset,
      userStore.accountBalances,
    ]);

    const selectedBalancesForApiCall: string[] = useMemo(() => {
      return enableCustomPlan ? customPlan.map((item) => item.balance!.id) : [];
    }, [customPlan, enableCustomPlan]);

    const fixedBalanceIds = useMemo(() => {
      const ids = [];
      if (toSendFtAsset !== undefined)
        ids.push(findUserHolding(toSendFtAsset)?.balance?.id);
      return ids.filter((item) => item !== undefined) as string[];
    }, [toSendFtAsset]);

    const onInnerModalChange = useCallback((isOpen: boolean) => {
      setInnerModalOpen(isOpen);
    }, []);

    const setCustomPlanByIds = useCallback(
      (balanceIds: string[]) => {
        const plan = userStore.accountBalances?.filter(
          (item) => item.balance && balanceIds.includes(item.balance.id)
        );
        setCustomPlan(plan);
      },
      [userStore.accountBalances]
    );

    useMouseDownOutside({
      ref: wrapperRef,
      callback: () => {
        setShowInteractionModal(false);
        setShowSelectReceiverModal(false);
      },
      shouldClose: !innerModalOpen,
    });

    // Auto focus input when rendered
    useEffect(() => {
      amountInputRef.current?.focus();
    }, []);

    // Check AI params
    useEffect(() => {
      if (tradeStore.fromAi && receiver === "") {
        if (
          !toSendFtAsset ||
          !userStore.userHoldings.some((item) =>
            isSameFtAsset(item, toSendFtAsset)
          )
        ) {
          toast({
            text: t("It seems that you do not hold the asset"),
            type: "warning",
          });
          return;
        }
        amountInputRef.current?.focus();
      }

      return () => {
        tradeStore.resetAiFields();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Mark sheet status in sheetStore
    useEffect(() => {
      if (showInteractionModal) {
        sheetStore.openOuterSend();
      } else {
        sheetStore.closeOuterSend();
      }
    }, [sheetStore, showInteractionModal]);

    const toSendFtAssetType = useMemo((): ToSendFtAssetType | undefined => {
      if (toSendFtAsset?.chainIndex === KONTOS_CHAIN_INDEX) {
        if (toSendFtAsset?.address?.toLowerCase() === KONTOS_NATIVE_ADDRESS) {
          return ToSendFtAssetType.KontosNative;
        } else if (toSendFtAsset?.address !== undefined) {
          return ToSendFtAssetType.KontosOthers;
        }
      } else if (toSendFtAsset?.chainIndex !== undefined) {
        return ToSendFtAssetType.Others;
      } else {
        return undefined;
      }
    }, [toSendFtAsset]);

    const handleKontosNativeSend = async () => {
      setSendKontosNativeProps({
        toSendFtAsset: toSendFtAsset!,
        amount: toSendFtAssetAmount!,
      });

      setShowInteractionModal(true);
    };

    const handleKontosOthersSend = async () => {
      loadingStore.showLoading();
      try {
        const provider = new ethers.providers.JsonRpcProvider(KONTOS_JSON_RPC);
        const tempPrivateKey = TEMP_KEY;
        const wallet = new ethers.Wallet(tempPrivateKey, provider);
        const evm_address = KontosQueryCli.nameAddress(
          userStore.accountInfo!.name
        );

        const token = new ethers.Contract(
          toSendFtAsset!.address,
          ERC20,
          wallet
        );

        token.signer.signTransaction = signTransaction;
        const oldPrepareRequest = provider.prepareRequest;
        provider.prepareRequest = prepareRequest(
          oldPrepareRequest,
          evm_address
        );

        const amount = toSendFtAssetAmount!;

        const gasPrice = await provider.getGasPrice();
        const nonce = await provider.getTransactionCount(evm_address);

        setSendKontosOthersProps({
          toSendFtAsset: toSendFtAsset!,
          amount: amount,
          gasPrice: gasPrice,
          gasLimit: DEFAULT_GAS_LIMIT_FOR_KONTOS_OTHERS_TRANSFER,
          nonce: nonce,
          contract: token,
        });

        setShowInteractionModal(true);
      } catch (e) {
        console.log(e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : "An unknown error occurred when preparing your transfer";
        toast({ type: "error", text: errorMessage });
      } finally {
        loadingStore.hideLoading();
      }
    };

    const handleOthersSend = useCallback(async () => {
      loadingStore.showLoading();
      try {
        const transferTaskDataResult = await fetchTransferPaymentTask(
          userStore.accountName!,
          receiver?.replace(".os", "")!,
          toSendFtAsset?.chainIndex!,
          toSendFtAsset?.address!,
          toSendFtAssetAmount!,
          selectedBalancesForApiCall
        );

        if (
          !transferTaskDataResult?.msgs ||
          transferTaskDataResult.msgs.length === 0
        ) {
          throw new Error("Insufficient Balance");
        }

        setSendOthersProps({
          toSendFtAsset: toSendFtAsset!,
          amount: toSendFtAssetAmount!,
          taskData: transferTaskDataResult,
          chain: getChainByChainIndex(
            chainStore.chains,
            toSendFtAsset?.chainIndex!
          ) as Chain,
          isCustom: enableCustomPlan,
        });

        setShowInteractionModal(true);
      } catch (e) {
        console.log(e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : "An unknown error occurred when preparing your transfer";
        toast({ type: "error", text: errorMessage });
      } finally {
        loadingStore.hideLoading();
      }
    }, [
      chainStore.chains,
      enableCustomPlan,
      receiver,
      selectedBalancesForApiCall,
      toSendFtAsset,
      toSendFtAssetAmount,
      userStore.accountName,
    ]);

    const handleOnClickNext = () => {
      if (!toSendFtAssetAmount?.gt(0)) {
        amountInputRef.current?.focus();
        toast({ type: "info", text: t("Please input amount") });
        return;
      }

      if (
        !receiver ||
        !isReceiveAddressValid ||
        (errText && errText?.length > 0)
      ) {
        setShowSelectReceiverModal(true);
        return;
      }

      if (!toSendFtAssetAmount || !toSendFtAssetAmount.gt(0)) {
        toast({
          text: t("Please input amount"),
          type: "info",
        });
        return;
      }
      if (receiver === undefined) {
        toast({
          text: t("Please input a valid address"),
          type: "info",
        });
        return;
      }
      if (!userStore.accountInfo?.name) {
        toast({
          text: "Account info missing",
          type: "info",
        });
        return;
      }
      switch (toSendFtAssetType) {
        case ToSendFtAssetType.KontosNative:
          setShowInteractionModal(true);
          handleKontosNativeSend();
          break;
        case ToSendFtAssetType.KontosOthers:
          handleKontosOthersSend();
          break;
        case ToSendFtAssetType.Others:
          handleOthersSend();
          break;
        default:
          toast({
            text: "Invalid Asset",
            type: "warning",
          });
          break;
      }
    };

    const handleUserInputFtAssetValueChange = useCallback(
      (value: KontosNumber, forceToSet: boolean = false) => {
        if (value.eq(0) && forceToSet === false) {
          setToSendFtAssetAmount(undefined);
        } else {
          setToSendFtAssetAmount(value);
        }
      },
      []
    );

    const allowEOA = useMemo(() => {
      switch (toSendFtAssetType) {
        case ToSendFtAssetType.KontosNative:
          return false;
        case ToSendFtAssetType.KontosOthers:
          return false;
        case ToSendFtAssetType.Others:
          return true;
        default:
          return true;
      }
    }, [toSendFtAssetType]);

    const requireAA = useMemo(() => {
      switch (toSendFtAssetType) {
        case ToSendFtAssetType.KontosNative:
        case ToSendFtAssetType.KontosOthers:
        case ToSendFtAssetType.Others:
        default:
          return false;
      }
    }, [toSendFtAssetType]);

    const breakdownList: {
      label: string;
      value: ReactNode;
    }[] = useMemo(() => {
      const paymentPlanItem = (
        <TradeBreakdownEditableItem
          prefixItem={
            <AssetIconGroup
              style={{ marginRight: "6px" }}
              iconList={
                enableCustomPlan
                  ? customPlan.map((item) => item.imageUrl)
                  : [defaultTokenIcon]
              }
              size={16}
              overlapWidthPx={"6px"}
            />
          }
          text={enableCustomPlan ? t("Custom Plan") : t("Recommend Plan")}
          onClick={() => setShowPaymentPlanSheet(true)}
        />
      );
      return [
        {
          label: t("Payment Plan:"),
          value: paymentPlanItem,
        },
      ];
    }, [customPlan, enableCustomPlan]);

    return (
      <>
        <Header
          title={title}
          padding="0 20px 20px 20px"
          enableBack
          callBack={() => {
            if (isAddressInputFocused) {
              setIsAddressInputFocused(false);
            } else {
              onBack?.();
            }
          }}
          rightBtnText={isAddressInputFocused ? "Done" : ""}
          rightBtnCallBack={() => {}}
        />
        <Container ref={wrapperRef}>
          {/* input box */}
          {!isAddressInputFocused && (
            <SendInput
              className={` ${isAmountInputFocused ? "border-blue" : ""}`}
            >
              <div className={"sendAssets"}>
                <div className={"l"}>{t("Send Assets")}:</div>
                <div className={"r"}>
                  <img src={walletIco} alt="" />
                  <span className={"amount"}>
                    {maxValue.toFormat(displayPrecision)}
                  </span>
                  <span
                    className={"max"}
                    onClick={() => setToSendFtAssetAmount(maxValue)}
                  >
                    {t("Max")}
                  </span>
                </div>
              </div>
              <div className={"inputWrap"}>
                <div
                  className={"l"}
                  onClick={() => {
                    if (onBack) {
                      onBack();
                      setToSendFtAssetAmount(undefined);
                    }
                  }}
                >
                  <img className={"triangle"} src={triangleIco} alt="" />
                  <CircleIconPair
                    mainIcon={toSendFtAsset?.imageUrl}
                    mainIconFallbackSrc={DefaultTokenSvg}
                    subIcon={getChainByAsset(toSendFtAsset)?.greyImageURL}
                    subIconFallbackSrc={DefaultChainSvg}
                    mainWidth={32}
                    mainHeight={32}
                    subWidth={16}
                    subHeight={16}
                    totalWidth={40}
                    totalHeight={32}
                  />
                  <div className={"inputSymbol"}>
                    <div className={"symbol"}>{toSendFtAsset?.symbol}</div>
                    <div className={"chain-symbol"}>
                      {getChainByAsset(toSendFtAsset)?.chainSymbol}
                    </div>
                  </div>
                </div>
                <div className={"r"}>
                  <NumericInputV2
                    ref={amountInputRef}
                    alignRight={true}
                    unit={""}
                    precision={7}
                    maxValue={maxValue.toNumber()}
                    placeholder={`0.00`}
                    onChange={handleUserInputFtAssetValueChange}
                    value={toSendFtAssetAmount?.round(DEFAULT_PRECISION)}
                    onBlur={() => {
                      setIsAmountInputFocused(false);
                      handleUserInputFtAssetValueChange(
                        toSendFtAssetAmount || new KontosNumber(0),
                        true
                      );
                    }}
                    onFocus={() => {
                      setIsAmountInputFocused(true);
                    }}
                    fontSize="18px"
                  />
                  <div className="usd-price">
                    {toSendFtAsset?.usdPrice &&
                    toSendFtAssetAmount &&
                    toSendFtAssetAmount.gt(0)
                      ? "≈ " +
                        toSendFtAssetAmount
                          ?.multiply(
                            toSendFtAsset?.usdPrice || 0,
                            DEFAULT_DECIMAL
                          )
                          .toFixed(displayPrecision, true) +
                        " USD"
                      : "0.00"}
                  </div>
                </div>
              </div>
            </SendInput>
          )}

          {/* Address */}
          {!isAddressInputFocused && (
            <AddressTitle className="to-address">
              {t("To Address")}
            </AddressTitle>
          )}
          <InputPlaceholder
            className={`${receiver && isReceiveAddressValid ? "success" : ""} ${
              showValidationError ? "fail" : ""
            }`}
            onClick={() => {
              setShowSelectReceiverModal(true);
              setTimeout(() => {
                setInitPage(false);
              }, 500);
              // sheetStore.openSheet(SheetView.QrReader);
            }}
            style={{
              color: receiver
                ? "var(--Deep-800, #1A2535)"
                : "var(--Deep-400, #80868F)",
            }}
          >
            {receiver && isReceiveAddressValid && (
              <img src={successIco} alt="" />
            )}
            {showValidationError && <img src={errorIco} alt="" />}
            {receiver ? receiver : t("Enter recipient's address")}
          </InputPlaceholder>
          {!initPage && (
            <AddressCheckResult
              errText={errText}
              okText={okText}
              isFocus={false}
            />
          )}

          {/* Breakdown */}
          {toSendFtAssetType === ToSendFtAssetType.Others &&
            breakdownList.length > 0 && (
              <BreakdownWrapper>
                <TradeBreakdownViewV2 list={breakdownList} />
              </BreakdownWrapper>
            )}

          {/* Blank filled area */}
          <BlankFilledArea />

          {/* Next */}
          {!isAddressInputFocused && (
            <StyledKontosButton onClick={handleOnClickNext}>
              {t("Next")}
            </StyledKontosButton>
          )}

          {/* select receiver */}
          <BottomSheet
            isOpen={showSelectReceiverModal}
            onClose={() => setShowSelectReceiverModal(false)}
            mountPoint={domNode}
          >
            <SelectReceiveModal
              setShowSelectTokenModal={setShowSelectReceiverModal}
              setReceiver={setReceiver}
              allowEOA={allowEOA}
              requireAA={requireAA}
              toSendFtAsset={toSendFtAsset}
              receiver={receiver}
              setIsReceiveAddressValid={setIsReceiveAddressValid}
              onOkTextChange={setOkText}
              onErrTextChange={setErrText}
              showWarningModal={showWarningModal}
              setShowWarningModal={setShowWarningModal}
            />
          </BottomSheet>

          {/* Payment Plan Sheet */}
          {toSendFtAssetType === ToSendFtAssetType.Others && (
            <BottomSheet
              isOpen={showPaymentPlanSheet}
              onClose={() => setShowPaymentPlanSheet(false)}
              mountPoint={domNode}
              disableScrollLocking
            >
              <PaymentPlanFlow
                mode={"editable"}
                selectedPlan={enableCustomPlan ? "custom" : "recommend"}
                recommendAssets={undefined}
                customAssets={customPlan}
                onBack={() => setShowPaymentPlanSheet(false)}
                setCustomPlan={setCustomPlanByIds}
                selectedBalanceIds={selectedBalances}
                fixedBalanceIds={fixedBalanceIds}
                setSelectedPlan={(plan) => {
                  if (plan === "custom") {
                    setEnableCustomPlan(true);
                  } else {
                    setEnableCustomPlan(false);
                  }
                }}
              />
            </BottomSheet>
          )}

          {/* Three send methods */}
          <BottomSheet
            isOpen={showInteractionModal}
            onClose={() => setShowInteractionModal(false)}
            mountPoint={domNode}
          >
            <PaymentProvider initData={paymentInitData}>
              <TxConfirmation
                interactionType={transferToSendFtAssetTypeToInteractionType(
                  toSendFtAssetType!
                )}
                target={
                  (ethers.utils.isAddress(receiver || "")
                    ? receiver
                    : receiver?.replaceAll(".os", "") + ".os") || ""
                }
                onCancel={() => setShowInteractionModal(false)}
                onSuccess={() => {
                  setShowInteractionModal(false);
                  onSuccess?.();
                }}
                wallet={userStore.accountNameWithOs!}
                onInnerModalChange={onInnerModalChange}
                nativeTransferProps={sendKontosNativeProps}
                erc20TransferProps={sendKontosOthersProps}
                crossChainTransferProps={sendOthersProps}
                displayPrecision={displayPrecision}
              />
            </PaymentProvider>
          </BottomSheet>
        </Container>
      </>
    );
  }
);

export default TransferInputStep;
