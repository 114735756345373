import { observer } from "mobx-react";
import styled, { css } from "styled-components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import waitingRecoverEmailIco from "src/assets/images/waiting-recover-emai.svg";
import triangleIco from "src/assets/icons/triangle.svg";
import triangleActiveIco from "src/assets/icons/triangle-active.svg";
import pendingIcon from "src/assets/icons/info1.svg";
import successIcon from "src/assets/icons/recover-success.svg";
import HorizontalLine from "src/components/line/HorizontalLine";
import { RecoverStatusIcon } from "src/components/start/RecoverStatusIcon";
import {
  convertToLowercaseIfGmail,
  isMobileDevice,
  isValidGoogleEmail,
  openUrl,
} from "src/utils/helper";
import { KONTOS_SUPPORT_EMAIL } from "src/config";
import { KontosKey } from "@zkkontos/kontos-sdk/src/index";
import { kecca256String } from "src/pages/newAuth/CreateAccount";
import RecoverInputStatusText from "src/components/start/RecoverInputStatusText";
import CopyRecoveryEmailInfo from "src/components/start/CopyRecoveryEmailInfo";
import {
  callAccountNewPubKeyActionProcessWithAbort,
  RespAccountNewPubKeyActionProcess,
} from "@/apis/aa-apis";
import { fontBold, fontDescription, fontMainText } from "@/style/style.global";
import { BottomSheet } from "../bottom-sheet/BottomSheet";
import { useTranslation } from "react-i18next";
import { ApiEmailRecoveryStatus } from "@/apis/types";
import { loadingStore } from "@/store/loadingStore";
import { WaitingEmailRightPart } from "./components/WaitingEmailRightPart";
import { TipsArea, TipsAreaStatus } from "../tips-area/TipsArea";
import { InputStatusV3, KontosInputV3 } from "../input/KontosInputV3";
import { RecoveryByEmailHintPopup } from "./popups/RecoveryByEmailHintPopup";

const ACTION_PROCESS_REFRESH_INTERVAL = 5000;

const MoreOptionsText = styled.span`
  color: var(--Deep-400, #80868f);
  ${fontMainText}
`;

const MoreOptionsIconWrapper = styled.div`
  margin-top: 4px;
  width: 12px;
  height: 12.716px;
  background-image: url(${triangleIco});
`;

const MoreOptionsWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  padding: 8px 27px;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      ${MoreOptionsText} {
        color: var(--Kontos-Blue, #413dec);
      }
      ${MoreOptionsIconWrapper} {
        background-image: url(${triangleActiveIco});
      }
      border-color: var(--Kontos-Blue, #413dec);
    }
  }
  &:active {
    ${MoreOptionsText} {
      color: var(--Kontos-Blue, #413dec);
    }
    ${MoreOptionsIconWrapper} {
      background-image: url(${triangleActiveIco});
    }
    border-color: var(--Kontos-Blue, #413dec);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

const WaitingRecoverEmailIco = styled.img`
  width: 200px;
  height: 86px;
  margin-bottom: 24px;
`;

const InfoIcon = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 8px;
`;

const NormalDesc = styled.div`
  padding: 0 12px 6px 12px;
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontDescription}
`;

const Description = styled.div<{ $status: "handled" | "pending" }>`
  width: 100%;
  padding: 23px 16px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  ${fontDescription}
  ${(props) =>
    props.$status === "handled" &&
    css`
      border: 1px solid var(--Success, #10ce5c);
      background: rgba(16, 206, 92, 0.1);
      color: var(--Success, #10ce5c);
    `}
    ${(props) =>
    props.$status === "pending" &&
    css`
      border: 1px solid var(--Warning, #faad14);
      background: rgba(250, 173, 20, 0.1);
      color: var(--Warning, #faad14);
    `}
`;

const DescBold = styled.span`
  ${fontBold}
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
`;

const StyledHorizontalLine = styled(HorizontalLine)`
  margin: 16px 21px;
  width: calc(100% - 42px);
`;

const EmailItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const EmailItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const EmailInput = styled(KontosInputV3)`
  background-color: var(--White, #fff);

  input {
    background-color: var(--White, #fff);
  }
`;

const EmailInputRightStatusText = styled(RecoverInputStatusText)`
  margin-right: 20px;
`;

const isActionApproved = (
  emailHash?: string,
  actionProcess?: RespAccountNewPubKeyActionProcess
): boolean => {
  if (!emailHash || !actionProcess || !actionProcess.emailRecoveries)
    return false;
  return (
    actionProcess.emailRecoveries?.[emailHash]?.statusCode ===
    ApiEmailRecoveryStatus.HANDLED
  );
};

const isActionPending = (
  emailHash?: string,
  actionProcess?: RespAccountNewPubKeyActionProcess
): boolean => {
  if (!emailHash || !actionProcess || !actionProcess.emailRecoveries)
    return false;
  return (
    actionProcess.emailRecoveries?.[emailHash]?.statusCode ===
    ApiEmailRecoveryStatus.PENDING
  );
};

const isEnoughEmailsHandled = (
  actionThreshold: number,
  actionProcess: RespAccountNewPubKeyActionProcess
): boolean => {
  return (
    Object.values(actionProcess.emailRecoveries).filter(
      (item) => item.statusCode === ApiEmailRecoveryStatus.HANDLED
    ).length >= actionThreshold
  );
};

const getEmailInputStatus = (
  email: string,
  emailGuardianHashes: string[],
  actionProcess?: RespAccountNewPubKeyActionProcess
): InputStatusV3 => {
  if (email === "") {
    return "default";
  }
  if (!isValidGoogleEmail(email)) {
    return "error";
  }
  if (!emailGuardianHashes.includes(kecca256String(email))) {
    return "error";
  }
  if (actionProcess === undefined) {
    return "default";
  }
  const statusCode =
    actionProcess.emailRecoveries?.[kecca256String(email)]?.statusCode;
  switch (statusCode) {
    case ApiEmailRecoveryStatus.FAILED:
      return "error";
    case ApiEmailRecoveryStatus.PENDING:
      return "warning";
    case ApiEmailRecoveryStatus.HANDLED:
      return "success";
    default:
      return "default";
  }
};

const getEmailStatus = (
  email: string,
  actionProcess?: RespAccountNewPubKeyActionProcess
): TipsAreaStatus | undefined => {
  if (actionProcess === undefined) {
    return;
  }
  const statusCode =
    actionProcess.emailRecoveries?.[kecca256String(email)]?.statusCode;
  switch (statusCode) {
    case ApiEmailRecoveryStatus.FAILED:
      return "error";
    case ApiEmailRecoveryStatus.PENDING:
      return "warning";
    default:
      return;
  }
};

type IProps = {
  userEmailArr: string[];
  kontosName: string;
  nonce: number;
  kontosKey: KontosKey | undefined;
  actionThreshold: number;
  emailGuardianHashes: string[];
  setRefreshInterval: (ms: number) => void;
};

const WaitingAuthorizeMail: React.FC<IProps> = ({
  userEmailArr,
  kontosName,
  nonce,
  kontosKey,
  actionThreshold,
  emailGuardianHashes,
  setRefreshInterval,
}) => {
  const { t } = useTranslation();
  const [emailArr, setEmailArr] = useState<string[]>(
    Array(userEmailArr.length).fill("")
  );
  const [newPubKeyActionProcess, setNewPubKeyActionProcess] = useState<
    RespAccountNewPubKeyActionProcess | undefined
  >(undefined);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const intervalRef = useRef<number | null>(null);
  const [focusedInputIndex, setFocusedInputIndex] = useState<
    number | undefined
  >();
  const [showHintModal, setShowHintModal] = useState<boolean>(true);

  const status = useMemo((): "normal" | "pending" | "handled" => {
    if (newPubKeyActionProcess !== undefined) {
      const handledCount = Object.values(
        newPubKeyActionProcess.emailRecoveries
      ).filter(
        (item) => item.statusCode === ApiEmailRecoveryStatus.HANDLED
      ).length;
      if (handledCount >= actionThreshold) return "handled";
    }

    for (let email of emailArr) {
      if (isValidGoogleEmail(email)) {
        return "pending";
      }
    }

    return "normal";
  }, [actionThreshold, emailArr, newPubKeyActionProcess]);

  const successEmailLength = useMemo(() => {
    return userEmailArr.filter((item) => {
      return isActionApproved(item, newPubKeyActionProcess);
    }).length;
  }, [newPubKeyActionProcess, userEmailArr]);

  // Regularly refresh new pub key action process
  useEffect(() => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current);
    }

    const fetchAndSetActionProcess = async () => {
      if (!kontosKey) return;
      try {
        const resp = await callAccountNewPubKeyActionProcessWithAbort({
          account: kontosName,
          pubKey: kontosKey.pubKeyData.compressedPubKey,
        });
        setNewPubKeyActionProcess(resp);
        if (isEnoughEmailsHandled(actionThreshold, resp)) {
          intervalRef.current !== null &&
            window.clearInterval(intervalRef.current);
          setRefreshInterval(1000);
          loadingStore.showLoading();
          return;
        }
      } catch (e) {
        console.warn("Failed to fetch action process.", e);
      }
    };

    intervalRef.current = window.setInterval(() => {
      fetchAndSetActionProcess();
    }, ACTION_PROCESS_REFRESH_INTERVAL);

    return () => {
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, [actionThreshold, kontosKey, kontosName, setRefreshInterval]);

  useEffect(() => {
    return () => {
      loadingStore.forcedHideLoading();
    };
  }, []);

  const mailObj = useMemo(() => {
    return {
      account: `${kontosName.replaceAll(".os", "")}.os`,
      pkStr: kontosKey?.pubKeyData.compressedPubKey,
      nonce,
    };
  }, [kontosKey?.pubKeyData.compressedPubKey, kontosName, nonce]);

  const handleSendEmail = useCallback(() => {
    if (showHintModal) return;
    if (userEmailArr.length <= 0) return;
    openUrl(
      isMobileDevice()
        ? `mailto:${KONTOS_SUPPORT_EMAIL}?subject=Kontos Account Recovery&body=${JSON.stringify(
            mailObj
          )}`
        : `https://mail.google.com/mail/?view=cm&fs=1&to=${KONTOS_SUPPORT_EMAIL}&su=Kontos Account Recovery&body=${encodeURIComponent(
            JSON.stringify(mailObj)
          )}`
    );
  }, [mailObj, showHintModal, userEmailArr.length]);

  return (
    <Wrapper ref={wrapperRef}>
      <Main>
        <WaitingRecoverEmailIco
          src={waitingRecoverEmailIco}
          alt="waiting email confirm"
        />

        {status === "normal" && (
          <NormalDesc>
            {t(
              'Please enter the recovery Gmail address you set up and click "Send Email" to initiate the recovery process.'
            )}
          </NormalDesc>
        )}
        {status === "pending" && (
          <Description $status={status}>
            <InfoIcon src={pendingIcon} alt="info" />
            {t("Waiting for email confirmed")}&nbsp;
            <DescBold>
              {successEmailLength}/{actionThreshold}
            </DescBold>
            ...
          </Description>
        )}
        {status === "handled" && (
          <Description $status={status}>
            <InfoIcon src={successIcon} alt="info" />
            {t("Security email confirmed")}&nbsp;
            <DescBold>
              {successEmailLength}/{actionThreshold}
            </DescBold>
            ...
          </Description>
        )}

        <StyledHorizontalLine />

        <EmailItemWrapper>
          {emailArr?.map((item, index) => (
            <EmailItem key={index}>
              <EmailInput
                autoFocus={index === 0}
                inputStatus={getEmailInputStatus(
                  item,
                  emailGuardianHashes,
                  newPubKeyActionProcess
                )}
                leftItem={
                  <RecoverStatusIcon
                    status={
                      isActionApproved(
                        kecca256String(item),
                        newPubKeyActionProcess
                      )
                        ? "success"
                        : isActionPending(
                              kecca256String(item),
                              newPubKeyActionProcess
                            )
                          ? "loading"
                          : !item ||
                              (isValidGoogleEmail(item) &&
                                emailGuardianHashes?.includes(
                                  kecca256String(item)
                                )) ||
                              focusedInputIndex === index
                            ? "active"
                            : "error"
                    }
                  />
                }
                rightItem={
                  isActionApproved(
                    kecca256String(item),
                    newPubKeyActionProcess
                  ) ? (
                    <EmailInputRightStatusText status={"success"} />
                  ) : isActionPending(
                      kecca256String(item),
                      newPubKeyActionProcess
                    ) ? (
                    <EmailInputRightStatusText
                      status={"pending"}
                      text={t("Processing...")}
                    />
                  ) : !item ||
                    (isValidGoogleEmail(item) &&
                      emailGuardianHashes?.includes(kecca256String(item))) ? (
                    <WaitingEmailRightPart
                      index={index}
                      mailObj={mailObj}
                      emailArr={emailArr}
                      disabled={userEmailArr.length <= 0}
                    />
                  ) : null
                }
                onChange={(e) => {
                  const str = convertToLowercaseIfGmail(e.target.value);
                  setEmailArr(
                    emailArr.map((item, i) => {
                      if (i === index) {
                        return str;
                      } else {
                        return item;
                      }
                    })
                  );
                }}
                onSubmit={handleSendEmail}
                onFocus={() => {
                  setFocusedInputIndex(index);
                }}
                onBlur={() => {
                  setFocusedInputIndex(undefined);
                }}
                placeholder={t("Enter your security email")}
                value={item}
              />
              {focusedInputIndex !== index &&
                item !== "" &&
                (!isValidGoogleEmail(item) ? (
                  <TipsArea status={"error"}>
                    {t(
                      "Please enter a valid Gmail address (e.g., yourname@gmail.com)."
                    )}
                  </TipsArea>
                ) : !emailGuardianHashes?.includes(kecca256String(item)) ? (
                  <TipsArea status={"error"}>
                    {t(
                      "The hash of this email doesn't match the on-chain record. Please verify."
                    )}
                  </TipsArea>
                ) : null)}

              {newPubKeyActionProcess !== undefined &&
                typeof newPubKeyActionProcess.emailRecoveries?.[
                  kecca256String(item)
                ]?.message === "string" &&
                newPubKeyActionProcess.emailRecoveries[kecca256String(item)]
                  .message !== "" && (
                  <TipsArea
                    status={getEmailStatus(item, newPubKeyActionProcess)}
                  >
                    {
                      newPubKeyActionProcess.emailRecoveries[
                        kecca256String(item)
                      ].message
                    }
                  </TipsArea>
                )}
            </EmailItem>
          ))}
        </EmailItemWrapper>
      </Main>

      <MoreOptionsWrapper
        onClick={() => {
          setShowCopyModal(true);
        }}
      >
        <MoreOptionsText>{t("More Options")}</MoreOptionsText>
        <MoreOptionsIconWrapper />
      </MoreOptionsWrapper>

      <BottomSheet
        isOpen={showCopyModal}
        onClose={() => setShowCopyModal(false)}
        mountPoint={domNode}
        snapPoints={[350]}
      >
        <CopyRecoveryEmailInfo
          mailMap={{
            mailto: KONTOS_SUPPORT_EMAIL,
            subject: "Kontos Account Recovery",
            body: JSON.stringify({
              account: `${kontosName.replaceAll(".os", "")}.os`,
              pkStr: kontosKey?.pubKeyData.compressedPubKey,
              nonce,
            }),
          }}
        />
      </BottomSheet>

      {showHintModal && (
        <RecoveryByEmailHintPopup onClose={() => setShowHintModal(false)} />
      )}
    </Wrapper>
  );
};

export default observer(WaitingAuthorizeMail);
