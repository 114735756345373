import { DEFAULT_ASSET_ICON_OMIT } from "src/config";
import KontosNumber from "src/utils/KontosNumber";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import {
  CostDetail,
  Gas,
  Payment,
  TaskDetail,
} from "@zkkontos/kontos-sdk/src/codec/kontos/cc/v1/cc";
import { UniqueAssetDataWithCost } from "@zkkontos/kontos-sdk/src/codec/kontos/evmHelper/v1/evmHelper";
import AssetOmitSvg from "src/assets/icons/asset-omit.svg";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import Long from "long";
import { Chain, FtAsset } from "src/type/zkkontos";
import { KontosKey, getDefaultClientPolymorphism } from "@zkkontos/kontos-sdk";
import { base64ToUint8Array, uint8ArrayToBase64 } from "./helper";
import { keccak256 } from "@ethersproject/keccak256";
import { enhancedFromHex } from "@zkkontos/kontos-sdk/src/core/utils";
import { BeSignData } from "src/type/common";
import { serialize, UnsignedTransaction } from "@ethersproject/transactions";
import { arrayify } from "ethers/lib/utils";
import { getSequence } from "@/service/account-service";
import { callSearchAccountNames } from "@/apis/aa-apis";

export const getGreyChainImgUrl = (
  chainIndex: string | undefined,
  chains: Chain[]
): string | undefined => {
  if (chainIndex === undefined) {
    return undefined;
  }
  const chain = chains.find(
    (chain) =>
      chain.chainIndex.toLocaleLowerCase() === chainIndex.toLocaleLowerCase()
  );
  return chain?.greyImageURL;
};

export const calcFtAssetBalanceInUSD = (
  price: KontosNumber,
  amount: KontosNumber
) => {
  return price.multiply(amount);
};

const transferGas = (apiG: any): Gas | undefined => {
  if (!apiG) {
    return undefined;
  }
  return {
    gasCost: apiG.gas_cost,
    gasPrice: apiG.gas_price,
    chainIndex: apiG.chain_index,
  };
};

const tranferCostDetail = (apiCD: any): CostDetail => {
  return {
    costType: apiCD.cost_type,
    desc: apiCD.desc,
    asset: apiCD.asset,
    gas: transferGas(apiCD.gas),
    required_USDAmount: apiCD.required_USD_amount,
  };
};

export const transferTaskDetail = (apiTD: any): TaskDetail => {
  return {
    costDetails: apiTD.cost_details.map(tranferCostDetail),
    totalRequiredUSDAmount: apiTD.total_required_uSD_amount,
    totalCallGasCost: apiTD.total_call_gas_cost,
    totalCallGasCostRequired_USDAmount:
      apiTD.total_call_gas_cost_required_USD_amount,
  };
};

const transferUniqueAssetDataWithCost = (
  apiU: any
): UniqueAssetDataWithCost => {
  return {
    chainIndex: apiU.chain_index,
    assetAddress: apiU.asset_address,
    assetAmount: apiU.asset_amount,
    usdAmount: apiU.usd_amount,
  };
};

export const transferPayment = (apiP: any): Payment => {
  return {
    paymentAsset: transferUniqueAssetDataWithCost(apiP.payment_asset),
    payer: apiP.payer,
    status: apiP.status,
  };
};

export const getIcon = (
  ftAssets: FtAsset[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | undefined => {
  if (!chainIndex || !assetAddress) {
    return undefined;
  }

  const matchingAsset = ftAssets.find(
    (ftAsset) =>
      ftAsset.chainIndex === chainIndex &&
      ftAsset.address.toLowerCase() === assetAddress.toLowerCase()
  );

  return matchingAsset ? matchingAsset.imageUrl : DefaultTokenSvg;
};

export const getIconListWithOmit = (
  payments: UniformedPayment[],
  omitLmit: number
) => {
  const iconList = payments.map((payment) => {
    return payment.assetImageUrl;
  });
  const res = iconList.slice(0, omitLmit);
  if (iconList.length > DEFAULT_ASSET_ICON_OMIT) {
    res.push(AssetOmitSvg);
  }
  return res;
};

export const getChainIcon = (
  chains: Chain[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | any => {
  if (!chainIndex || !assetAddress) {
    return DefaultChainSvg;
  }
  const greyChainImg = getGreyChainImgUrl(chainIndex, chains);
  return greyChainImg ? greyChainImg : DefaultChainSvg;
};

export const getTokenName = (
  ftAssets: FtAsset[],
  chainIndex: string | undefined,
  assetAddress: string | undefined
): string | undefined => {
  if (!chainIndex || !assetAddress) {
    return undefined;
  }

  const matchingAsset = ftAssets.find(
    (ftAsset) =>
      ftAsset.chainIndex === chainIndex &&
      ftAsset.address.toLowerCase() === assetAddress.toLowerCase()
  );

  return matchingAsset ? matchingAsset.symbol : undefined;
};

export const getChainName = (
  chains: Chain[],
  chainIndex: string | undefined
): string | undefined => {
  if (!chainIndex) {
    return undefined;
  }
  const matchingChain = chains.find((chain) => chain.chainIndex === chainIndex);

  return matchingChain ? matchingChain.chainSymbol : undefined;
};

export const getAllIndex = (arr: any[]): number[] => {
  const lengthArray = [];
  for (let i = 0; i < arr.length; i++) {
    lengthArray.push(i);
  }
  return lengthArray;
};

export const getChainByChainIndex = (
  chains: Chain[],
  chainIndex: string
): Chain | undefined => {
  const res = chains.find((chain) => chain.chainIndex === chainIndex);
  return res;
};

export const filterNoKontosFtAssets = (ftAssets: FtAsset[]) => {
  return ftAssets?.filter((ftAsset) => ftAsset?.chainIndex !== "0");
};

export const filterSupportedChainsFtAssets = (
  ftAssets: FtAsset[],
  supportedChains: Chain[]
) => {
  return ftAssets?.filter(
    (ftAsset) =>
      supportedChains?.find(
        (chain) =>
          chain.chainIndex.toLocaleLowerCase() ===
            ftAsset?.chainIndex.toLocaleLowerCase() && chain.status === 1
      )
  );
};

export const getAccountsNameRegex = (character: string) => {
  return callSearchAccountNames({ regex: character });
};

export const getAccountsNameRegexAsNameArray = async (character: string) => {
  const accountsNameRegex = await getAccountsNameRegex(character);
  return accountsNameRegex.accounts.map((account) => account);
};

export const convertNanosecondsToMilliseconds = (nanoseconds: Long): Long => {
  const temp = new KontosNumber(nanoseconds.toString());
  const res = temp.divide(new KontosNumber(1000000)).integerValue().toString();
  return Long.fromString(res);
};

export const filterEventNewTaskOpHash = (resp: any): string => {
  let opHash = "";
  resp?.events?.forEach((event: any) => {
    if (event.type === "kontos.cc.v1.EventNewTask") {
      const taskRegistered = event.attributes.find(
        (attr: any) => attr.key === "op_hash"
      );
      if (taskRegistered) {
        opHash = taskRegistered?.value?.replaceAll('"', "");
        return;
      }
      return;
    }
  });
  return opHash;
};

export const parseApiErrorMessage = (
  error: Error
): { message: string; errCode: number } => {
  const errorMessageRegex = /API error: (.+) \(code (\d+)\)/;
  const match = errorMessageRegex.exec(error.message);

  if (match && match.length === 3) {
    const message = match[1];
    const errCode = parseInt(match[2], 10);
    return { message, errCode };
  }

  return {
    message: error.message,
    errCode: -1,
  };
};

export const classifyTaskStatus = (
  status: number
): "success" | "warning" | "error" => {
  switch (status) {
    case 0:
      return "warning";
    case 1:
      return "success";
    case 2:
      return "error";
    default:
      return "error";
  }
};

export const filterUniqueFtAssets = (
  existingFtAssets: FtAsset[],
  newFtAssets: FtAsset[]
): FtAsset[] => {
  if (newFtAssets?.length === 0) {
    return [];
  }
  const uniqueFtAssets = new Map();
  existingFtAssets?.forEach((ftAsset) => {
    const key = `${ftAsset.address}-${ftAsset.chainIndex}`;
    uniqueFtAssets.set(key, ftAsset);
  });

  newFtAssets?.forEach((ftAsset) => {
    const key = `${ftAsset.address}-${ftAsset.chainIndex}`;
    if (!uniqueFtAssets.has(key)) {
      uniqueFtAssets.set(key, ftAsset);
    }
  });

  return Array.from(uniqueFtAssets.values());
};

export const isSameKontosName = (nameA: string, nameB: any) => {
  if (!nameB || nameB === "") return false;
  return (
    nameA.toLowerCase().replaceAll(".os", "").trim() ===
    nameB.toLowerCase().replaceAll(".os", "").trim()
  );
};

export const isSameAddress = (addressA: any, addressB: any) => {
  return addressA?.toLowerCase() === addressB?.toLowerCase();
};

export const isSameFtAsset = (assetA: any, assetB: any) => {
  const getChainIndex = (asset: any): string | null => {
    return typeof asset?.chainIndex === "string" ? asset.chainIndex : null;
  };

  const getAddress = (asset: any): string | null => {
    return typeof asset?.address === "string"
      ? asset.address
      : typeof asset?.assetAddress === "string"
        ? asset.assetAddress
        : null;
  };

  const chainIndexA = getChainIndex(assetA);
  const chainIndexB = getChainIndex(assetB);
  const addressA = getAddress(assetA);
  const addressB = getAddress(assetB);

  return (
    chainIndexA !== null &&
    chainIndexB !== null &&
    addressA !== null &&
    addressB !== null &&
    chainIndexA === chainIndexB &&
    addressA === addressB
  );
};

export const isSameName = (nameA: string, nameB: string) => {
  return (
    nameA.toLowerCase().replaceAll(".os", "") ===
    nameB.toLowerCase().replaceAll(".os", "")
  );
};

export const stringArrayEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

export const isTimestampInPast = (targetTimestamp: number): boolean => {
  const currentTimestamp = Date.now();

  if (targetTimestamp.toString().length === 10) {
    targetTimestamp *= 1000;
  }

  return targetTimestamp < currentTimestamp;
};

export const getTimeDifference = (
  targetTimestamp: number,
  translateFn: Function
): string => {
  const currentTimestamp = Date.now();

  if (targetTimestamp.toString().length === 10) {
    targetTimestamp *= 1000;
  }

  if (targetTimestamp <= currentTimestamp) {
    return "";
  }

  const differenceInMilliseconds = targetTimestamp - currentTimestamp;

  const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );

  if (hours > 0) {
    if (hours > 1) {
      return `${hours}${translateFn(" hours", { count: hours })}`;
    }
    return `${hours}${translateFn(" hour", { count: hours })}`;
  } else if (minutes > 0) {
    if (minutes > 1) {
      return `${minutes}${translateFn(" minutes", { count: minutes })}`;
    }
    return `${minutes}${translateFn(" minute", { count: minutes })}`;
  } else {
    return "";
  }
};

export const deduplicateArrayByKey = <
  T extends Record<K, any>,
  K extends keyof T,
>(
  array: T[],
  key: K
): T[] => {
  const uniqueItems = new Map<T[K], T>();
  array.forEach((item) => {
    if (!uniqueItems.has(item[key])) {
      uniqueItems.set(item[key], item);
    }
  });
  return Array.from(uniqueItems.values());
};

export const prioritizeItems = <T extends { name: string }>(
  items: T[],
  nameToPrioritize: string
): T[] => {
  const priorityItems = items.filter((item) => item.name === nameToPrioritize);
  const otherItems = items.filter((item) => item.name !== nameToPrioritize);
  return [...priorityItems, ...otherItems];
};

export const getNonce = async (acountName: string) => {
  const nonce = await getSequence(acountName);
  return nonce;
};

export const signMessage = async (
  accountName: string,
  message: string,
  key: KontosKey
) => {
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(message)
  );
  return sig;
};

export const signTypedData = async (
  accountName: string,
  typedData: Object,
  key: KontosKey
) => {
  const signDataStr = btoa(JSON.stringify(typedData));
  const signDataBytes = base64ToUint8Array(signDataStr);
  const signDataHash = keccak256(signDataBytes);
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(signDataHash)
  );
  return uint8ArrayToBase64(sig);
};

export const signTx = async (accountName: string, tx: any, key: KontosKey) => {
  const txHash = keccak256(serialize(tx as UnsignedTransaction));
  const txBytes = arrayify(txHash);
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = key.privateKey as CryptoKey;
  const signedTx = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    txBytes
  );
  const u = new Uint8Array(65);
  u.set(new Uint8Array(signedTx));
  u.set(new Uint8Array([1]), 64);
  return serialize(tx as UnsignedTransaction, u);
};

export const getSignature = async (
  accountName: string,
  signData: Object,
  key: KontosKey
) => {
  const signDataStr = btoa(JSON.stringify(signData));
  const signDataBytes = base64ToUint8Array(signDataStr);
  const signDataHash = keccak256(signDataBytes);
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = key.privateKey as CryptoKey;
  console.log("signDataHash", signDataHash);
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(signDataHash)
  );
  console.log("sig", uint8ArrayToBase64(sig));
  return uint8ArrayToBase64(sig);
};

export const signForBe = async (
  accountName: string,
  key: KontosKey,
  data: string,
  expiration: number
) => {
  const nonce = await getNonce(accountName);
  const expiredAt = Date.now() + expiration;
  const signData: BeSignData = {
    data: data,
    expiredAt: expiredAt,
    nonce: nonce,
  };
  console.log("signData", signData);
  const signature = await getSignature(accountName, signData, key);
  return { signature, expiredAt };
};
