import styled from "styled-components";
import React from "react";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  color: var(--Warning, #faad14);
  border-radius: 8px;
  border: 1px solid var(--Warning, #faad14);
  background: rgba(250, 173, 20, 0.1);
  /* Description */
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding: 23px 16px;
  word-break: break-all;

  .info {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  span {
    color: var(--Warning, #faad14);
    ${fontBold};
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }
`;

type IProps = {
  icon: string;
  text: string;
};

const Description: React.FC<IProps> = ({ icon, text }) => {
  return (
    <Wrapper>
      <img className={"info"} src={icon} alt="info" />
      {text}
    </Wrapper>
  );
};

export default Description;
