import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import { TEMP_EXPLORER_URL } from "src/config";
import { API } from "./core/API";
import { handleApiError } from "src/utils/helper";

export interface ReqEbAccount {
  account: string;
}

export interface RespEbAccount {
  credit: number;
  creditLevel: number;
  creditLevelDesc: string;
  maxCreditValue: number;
  maxStageCreditValue: number;
  joinedDiscord: number;
  followedTwitter: number;
  boostCount: number;
  boostedCount: number;
  boostTimesLeft: number;
  energyValue: number;
  usedEnergyOrbsCount: number;
  unusedEnergyOrbsCount: number;
  energyBar: ebTypes.EnergyBar;
  accountActions: ebTypes.AccountActions;
}

export const callEbAccount = async (
  data: ReqEbAccount,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespEbAccount> => {
  return request<ReqEbAccount, RespEbAccount>(
    API.mwallet.account,
    data,
    endpoint
  );
};

export interface ReqEbNotifications {
  account?: string;
  limit: number;
}

export interface RespEbNotifications {
  notifications: ebTypes.Notification[];
}

export const callEbNotification = async (
  data: ReqEbNotifications,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespEbNotifications> => {
  return request<ReqEbNotifications, RespEbNotifications>(
    API.mwallet.notifications,
    data,
    endpoint
  );
};

export interface ReqBoost {
  booster: string;
  boostee: string;
  expiredAt: number;
  signature: string;
}

export interface RespBoost {
  boostValue: number;
}

export const callBoost = async (
  data: ReqBoost,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespBoost> => {
  return request<ReqBoost, RespBoost>(API.mwallet.boost, data, endpoint);
};

export interface ReqClaim {
  account: string;
  threshold: number;
  expiredAt: number;
  signature: string;
}

export interface RespClaim {
  energyOrbIds: number[];
}

export const callClaim = async (
  data: ReqClaim,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespClaim> => {
  return request<ReqClaim, RespClaim>(API.mwallet.claim, data, endpoint);
};

export interface ReqBoostHistory {
  account: string;
  isBoostee: number;
}

export interface RespBoostHistory {
  boostRecords: ebTypes.BoostRecord[];
}

export const callBoostHistory = async (
  data: ReqBoostHistory,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespBoostHistory> => {
  return request<ReqBoostHistory, RespBoostHistory>(
    API.mwallet.boostHistory,
    data,
    endpoint
  );
};

export interface ReqBackpack {
  account: string;
}

export interface RespBackpack {
  energyOrbs: ebTypes.EnergyOrb[];
  rewards: ebTypes.Reward[];
}

export const callBackpack = async (
  data: ReqBackpack,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespBackpack> => {
  return request<ReqBackpack, RespBackpack>(
    API.mwallet.backpack,
    data,
    endpoint
  );
};

export interface ReqOpenEnergyOrb {
  account: string;
  energyOrbType: number;
  expiredAt: number;
  signature: string;
}

export interface RespOpenEnergyOrb {
  reward: ebTypes.Reward;
}

export const callOpenEnergyOrb = async (
  data: ReqOpenEnergyOrb,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespOpenEnergyOrb> => {
  return request<ReqOpenEnergyOrb, RespOpenEnergyOrb>(
    API.mwallet.openEnergyOrb,
    data,
    endpoint
  );
};

export interface ReqTwitterAuth {
  account: string;
  expiredAt: number;
  signature: string;
}

export interface RespTwitterAuth {
  authUrl: string;
}

export const callTwitterAuth = async (
  data: ReqTwitterAuth,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespTwitterAuth> => {
  try {
    return request<ReqTwitterAuth, RespTwitterAuth>(
      API.mwallet.twitterAuth,
      data,
      endpoint
    );
  } catch (e) {
    handleApiError(API.mwallet.discordAuth, data, e);
    throw e;
  }
};

export interface ReqDiscordAuth {
  account: string;
  expiredAt: number;
  signature: string;
}

export interface RespDiscordAuth {
  authUrl: string;
}

export const callDsicordAuth = async (
  data: ReqDiscordAuth,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespDiscordAuth> => {
  try {
    return request<ReqDiscordAuth, RespDiscordAuth>(
      API.mwallet.discordAuth,
      data,
      endpoint
    );
  } catch (e) {
    handleApiError(API.mwallet.discordAuth, data, e);
    throw e;
  }
};

export interface ReqLeaderBoard {
  accountName: string;
  offset: number;
  limit: number;
}

export enum EbAccountStatus {
  Normal = 1,
  Unbound = 2,
}

export interface RespLeaderBoard {
  account: ebTypes.Leaderboard;
  entries: ebTypes.Leaderboard[];
  total: number;
  totalParticipants: number;
  accountStatus: EbAccountStatus;
}

export const callLeaderBoard = async (
  data: ReqLeaderBoard,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespLeaderBoard> => {
  return request<ReqLeaderBoard, RespLeaderBoard>(
    API.mwallet.leaderboard,
    data,
    endpoint
  );
};
