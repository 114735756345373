import { makeAutoObservable, reaction, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { AssetLabel } from "src/apis/markets-apis";
import * as MarketService from "src/service/market-service";
import { sleep } from "src/utils/helper";
import { UserStore } from "src/store/UserStore";
import { IVote } from "src/apis/markets-apis";
import { FtAsset } from "@/type/zkkontos";

interface IUpdateVotes {
  bullishVotes: number;
  bearishVotes: number;
  action: number;
}

export class MarketStore {
  rootStore: RootStore;
  userStore: UserStore;
  categoryArr: AssetLabel[] = [];
  chainArr: AssetLabel[] = [];
  selectedCategory: number = 2;
  selectedChain: string = "";
  currentAssetQuote: FtAsset | null = null;
  bullishVotes: number = 0;
  bearishVotes: number = 0;
  // -1 no vote, 0 bullish, 1 bearish
  votedStatus: number = -1;
  selectedSendAsset: FtAsset | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.userStore = rootStore.userStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setCategoryArr = (categoryArr: AssetLabel[]) => {
    runInAction(() => {
      this.categoryArr = categoryArr;
    });
  };

  setChainArr = (chainArr: AssetLabel[]) => {
    runInAction(() => {
      this.chainArr = chainArr;
    });
  };

  setCurrentAssetQuote = (currentAssetQuote: FtAsset | null) => {
    runInAction(() => {
      this.currentAssetQuote = currentAssetQuote;
    });
  };

  vote = async ({ account, cli, action }: IVote) => {
    // if (!this.userStore.accountName) {
    //   return;
    // }
    // const client = cli || this.userStore.kontosCli;
    // if (!client) {
    //   this.userStore.unlock(() =>
    //     this.vote({
    //       account,
    //       cli,
    //     })
    //   );
    //   return;
    // }
    // const { marketPredict } = await MarketService.vote({
    //   account: this.userStore.accountName,
    //   cli: client,
    //   action,
    // });
    // const { bullish, bearish, action: actionRs } = marketPredict;
    // this.updateVotes({
    //   bullishVotes: bullish,
    //   bearishVotes: bearish,
    //   action: actionRs,
    // });
  };

  updateVotes = ({ bullishVotes, bearishVotes, action }: IUpdateVotes) => {
    runInAction(() => {
      this.bullishVotes = bullishVotes;
      this.bearishVotes = bearishVotes;
      this.votedStatus = action;
    });
  };

  setSelectedSendAsset = (asset: FtAsset | null) => {
    runInAction(() => {
      this.selectedSendAsset = asset;
    });
  };

  setSelectedCategory = (category: number) => {
    this.selectedCategory = category;
  };

  setSelectedChain = (chain: string) => {
    this.selectedChain = chain;
  };
}
