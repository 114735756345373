import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { observer } from "mobx-react";
import Header from "src/components/common/header";
import TabEntity from "src/components/tab/tabEntity";
import FilterSection from "src/components/filter/FilterSection";
import ApproveItem from "src/components/guardian/ApproveItem";
import GuardianItem from "src/components/guardian/GuardianItem";
import { useState } from "react";
import taskStatusErrorIco from "src/assets/icons/taskStatusError.svg";
import taskStatusSuccessIco from "src/assets/icons/taskStatusSuccess.svg";
import taskStatusWarnIco from "src/assets/icons/taskStatusWarn.svg";
import filterIco from "src/assets/icons/filter.svg";
import guardianInfoIco from "src/assets/icons/guardianInfo.svg";
import myGuardianIco from "src/assets/icons/myGuardian.svg";
import guardianForIco from "src/assets/icons/guardianFor.svg";
import styled from "styled-components";
import { Sheet } from "react-modal-sheet";
import { Unlock } from "src/pages/sign-up/unlock/Unlock";
import { NoScrollerBarSheet } from "src/components/wrapper/ReactiveWrapper";
import { loadingStore } from "src/store/loadingStore";
import { t } from "i18next";
import guardianAvatarIco from "src/assets/images/guardian-avatar.svg";
import kontosAvatarIco from "src/assets/images/kontos-avatar-2.svg";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import EditGuardians from "./guardian-edit/EditGuardians";
import toast from "src/components/toast/Toast";
import OneBtnPanel, {
  OneBtnPanelProps,
} from "src/components/panel/OneBtnPanel";
import { KontosKey, KontosQueryCli } from "@zkkontos/kontos-sdk";
import NewGuardiansModal from "./modals/NewGuardiansModal";
import { PopupModal } from "src/components/popups/PopupModal";
import GuardianForModal from "./modals/GuardianForModal";
import { pollAccount } from "src/service/guardian-service";
import { Trans, useTranslation } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import Skeleton from "react-loading-skeleton";
import NoDataV2 from "src/components/no-data/NoDataV2";
import { fontBold } from "@/style/style.global";
import {
  callAccountWards,
  callRecoverAccountByGuardian,
  callRevertUpdateAccountGuardian,
  callWardsThresholdActions,
  RespWardsThresholdActions,
} from "@/apis/aa-apis";
import { ApiThresholdAction } from "@/apis/types";
import { GuardianActionCode } from "@zkkontos/kontos-sdk/src/codec/kontos/aa/v1/event";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "@/router/router-config";
import { signForBe } from "@/utils/zkkontosHelper";
import { COMMON_SIGN_EXPIREDAT } from "@/config";

const WrapperFlexStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  > .header {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;

const ApproveFilterStyle = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 50%;
  }
`;

const GuardianFilterStyle = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const ApproveListStyle = styled.div`
  padding: 0 16px;
  flex: 1;
  overflow: scroll;
`;
const GuardianListStyle = styled.div`
  padding: 0 16px;
  flex: 1;
  overflow: scroll;
`;

const YourRecordsStyle = styled.div`
  color: var(--Deep-400, #80868f);

  /* Description */
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;

  span {
    color: var(--Kontos-Blue, #413dec);

    /* Description */
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const GuardianDescription = styled.div`
  color: var(--Deep-800, #1a2535);
  text-align: center;

  /* Main text */
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 25px;

  > img {
    margin-bottom: 7px;
    height: 17px;
  }

  span {
    color: var(--Kontos-Blue, #413dec);
    ${fontBold};
    font-size: 16px;
    font-style: normal;
    line-height: 130%;
  }
`;

export const trans_hours = t(" hours");
export const trans_hour = t(" hour");
export const trans_minutes = t(" minutes");
export const trans_minute = t(" minute");
const showSkeletonCount = 2;

const filterArrGuardian = [
  {
    ico: myGuardianIco,
    text: t("My Guardians"),
    color: "var(--Deep-800, #1A2535)",
  },
  {
    ico: guardianForIco,
    text: t("Guard for..."),
    color: "var(--Deep-800, #1A2535)",
  },
];

const filterArrApprove = [
  {
    ico: filterIco,
    text: t("All Requests"),
    color: "var(--Deep-800, #1A2535)",
    status: -1,
  },
  {
    ico: taskStatusWarnIco,
    text: t("Pending..."),
    color: "var(--Warning, #FAAD14)",
    status: 0,
  },
  {
    ico: taskStatusErrorIco,
    text: t("Rejected"),
    color: "var(--error-notice, #FF1E2B)",
    status: 100,
  },
  {
    ico: taskStatusSuccessIco,
    text: t("Completed"),
    color: "var(--Deep-400, #80868F)",
    status: 1,
  },
];

export const enum NavValue {
  Requests = "requests",
  Guardians = "guardians",
}

const itemArr: { value: NavValue; text: string }[] = [
  { value: NavValue.Requests, text: t("Requests") },
  { value: NavValue.Guardians, text: t("Guardians") },
];

interface Props {
  init: "approve" | "editGuardian";
}

export const Guardian: React.FC<Props> = observer(({ init }) => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const [selectedVal, setSelectedVal] = useState(
    init === "approve" ? itemArr[0] : itemArr[1]
  );
  const [wardsThresholdActions, setWardsThresholdActions] = useState<
    RespWardsThresholdActions | undefined
  >(undefined);
  const [accountGuardians, setAccountGuardians] = useState<string[]>([]);
  const [fetchingGuardians, setFetchingGuardians] = useState<boolean>(false);
  const [accountWards, setAccountWards] = useState<string[]>([]);
  const [fetchingWards, setFetchingWards] = useState<boolean>(false);
  const [deleteParam, setDeleteParam] = useState<
    ApiThresholdAction | undefined
  >(undefined);
  const [approveParam, setApproveParam] = useState<
    ApiThresholdAction | undefined
  >(undefined);
  const [filterVal, setFilterVal] = useState(filterArrApprove[1].text);
  const [passwordModalShow, setPasswordModalShow] = useState<boolean>(false);
  const [filterValGuardian, setFilterValGuardian] = useState<string>(
    filterArrGuardian[0].text
  );
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [editGuardianModalShow, setEditGuardianModalShow] =
    useState<boolean>(false);
  const [guardianForModalShow, setGuardianForModalShow] =
    useState<boolean>(false);
  const [hideEdit, setHideEdit] = useState<boolean>(false);
  const [showPinModal, setShowPinModal] = useState<boolean>(false);
  const [showNewGuardianModal, setShowNewGuardianModal] =
    useState<boolean>(false);
  const [showEmailWarnModal, setShowEmailWarnModal] = useState<boolean>(false);
  const [currentGuardForName, setCurrentGuardForName] = useState<string>("");
  const [currentGuardForAddress, setCurrentGuardForAddress] =
    useState<string>("");
  const timeAgo = userStore.updateGuardianDeadlineTimeAgo;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!userStore?.accountInfo?.name) return;
      try {
        setWardsThresholdActions(
          await callWardsThresholdActions({
            account: userStore.accountInfo.name,
          })
        );
      } catch (e) {}
    })();
  }, [userStore.accountInfo]);

  useEffect(() => {
    const fetchAndSetGuardiansAndWards = async () => {
      if (!userStore.accountName) return;
      try {
        setFetchingWards(true);
        setFetchingGuardians(true);
        const { wards } = await callAccountWards({
          account: userStore.accountName,
        });
        const guardians = userStore.accountInfo?.guardians;
        setAccountGuardians(Array.isArray(guardians) ? guardians : []);
        setAccountWards(Array.isArray(wards) ? wards : []);
      } catch (e) {
        console.warn("Failed to fetch and set wards/guardians", e);
      } finally {
        setFetchingWards(false);
        setFetchingGuardians(false);
      }
    };

    if (userStore.accountName) {
      fetchAndSetGuardiansAndWards();
    }
  }, [userStore.accountInfo?.guardians, userStore.accountName]);

  const thresholdActionsArr = useMemo(() => {
    if (!wardsThresholdActions?.actions) return [];
    let filterStatus = filterArrApprove.find((item) => item.text === filterVal)
      ?.status;
    if (!filterStatus) filterStatus = 0;
    return wardsThresholdActions?.actions?.filter((item) => {
      if (filterStatus === -1) {
        return (
          item.status !== 0 ||
          !Object.keys(item.approvals)
            .map((key) => key.toLowerCase())
            .includes(userStore?.accountInfo?.nameAddress?.toLowerCase() || "")
        );
      }
      if (filterStatus === 0) {
        return (
          item.status === filterStatus &&
          !Object.keys(item.approvals)
            .map((key) => key.toLowerCase())
            .includes(userStore?.accountInfo?.nameAddress?.toLowerCase() || "")
        );
      }
      return item.status === filterStatus;
    });
  }, [
    wardsThresholdActions?.actions,
    filterVal,
    userStore?.accountInfo?.nameAddress,
  ]);

  const waitingActionsLength = useMemo(() => {
    if (!wardsThresholdActions?.actions) return 0;
    return (
      wardsThresholdActions?.actions?.filter((item) => {
        return (
          item.status === 0 &&
          !Object.keys(item.approvals)
            .map((key) => key.toLowerCase())
            .includes(userStore?.accountInfo?.nameAddress?.toLowerCase() || "")
        );
      }).length || 0
    );
  }, [wardsThresholdActions?.actions, userStore?.accountInfo?.nameAddress]);

  const records = thresholdActionsArr?.length || 0;

  const guardianData = useMemo<string[] | undefined>(() => {
    if (filterArrGuardian[0].text === filterValGuardian)
      return accountGuardians;
    return accountWards;
  }, [filterValGuardian, accountGuardians, accountWards]);

  const guarduanText = useMemo<ReactNode>(() => {
    const actionThreshold = userStore?.accountInfo?.actionThreshold;
    const accountWardsGuardiansLength = accountWards?.length || 0;

    if (filterArrGuardian[0].text === filterValGuardian) {
      if (fetchingGuardians || !accountGuardians) {
        return (
          <p>
            <Skeleton count={1} style={{ width: "150px" }} />
          </p>
        );
      } else if (accountGuardians?.length === 0) {
        return <p>{t("You currently do not have a guardian")}</p>;
      } else {
        return (
          <Trans i18nKey="trans-guardian-my" count={actionThreshold}>
            <p>
              Recover your account requires approval from any&nbsp;
              <span>{{ actionThreshold } as any}</span>
              &nbsp;of the following Guardians.
            </p>
          </Trans>
        );
      }
    } else {
      if (fetchingWards || !accountWards) {
        return <Skeleton count={1} style={{ width: "150px" }} />;
      } else if (Array.isArray(accountWards) && accountWards.length > 1) {
        return (
          <Trans
            i18nKey="trans-guardian-for-multiple"
            count={accountWardsGuardiansLength}
          >
            <p>
              <span>{{ accountWardsGuardiansLength } as any}</span>
              users have made you the Guardian.
            </p>
          </Trans>
        );
      } else
        return (
          <Trans
            i18nKey="trans-guardian-for-single"
            count={accountWardsGuardiansLength}
          >
            <p>
              <span>{{ accountWardsGuardiansLength } as any}</span>
              user has made you his/her Guardian.
            </p>
          </Trans>
        );
    }
  }, [
    accountGuardians,
    accountWards,
    fetchingGuardians,
    fetchingWards,
    filterValGuardian,
    t,
    userStore?.accountInfo?.actionThreshold,
  ]);

  const updatingInfo: OneBtnPanelProps | undefined = useMemo(() => {
    if (userStore.guardianChanged) {
      return {
        type: "warning",
        title: t("Guardian list being updated...") as string,
        content: t("inter_the_update_will_take_effect_in", {
          time: userStore.updateGuardianDeadlineTimeAgo,
        }),
        btnText: t("Cancel") as string,
        onClick: () => {
          setShowPinModal(true);
        },
        onRightTopClick: () => {
          setShowNewGuardianModal(true);
        },
      };
    } else {
      return undefined;
    }
  }, [userStore.guardianChanged, userStore.updateGuardianDeadlineTimeAgo, t]);

  const rightBtnText: string | undefined = useMemo(() => {
    if (
      filterArrGuardian[0].text === filterValGuardian &&
      selectedVal.value === NavValue.Guardians &&
      accountGuardians !== undefined &&
      !hideEdit
    ) {
      return t("Edit") as string;
    } else {
      console.log("1", filterArrGuardian[0].text === filterValGuardian);
      console.log("2", NavValue.Guardians);
      console.log("3", accountGuardians !== undefined);
      console.log("4", !hideEdit);
      return undefined;
    }
  }, [filterValGuardian, selectedVal, t, accountGuardians, hideEdit]);

  const handleEditOnClick = useCallback(() => {
    if (userStore.guardianChanged) {
      toast({
        type: "warning",
        text: t("Please handle the current guardian change first"),
      });
      return;
    }
    if (userStore.emailGuardianChanged) {
      toast({
        type: "warning",
        text: (
          <Trans
            i18nKey={
              "trans-You have an security email change operation in progress."
            }
          >
            <span>
              You have an security email change operation in progress. Please
              wait for it to complete in {{ timeAgo } as any} or manually cancel
              it
            </span>
          </Trans>
        ),
        autoClose: 10000,
      });
      return;
    }
    if (
      userStore.accountInfo?.emailGuardians &&
      userStore.accountInfo?.emailGuardians?.length > 0
    ) {
      setShowEmailWarnModal(true);
      return;
    }
    setEditGuardianModalShow(true);
  }, [
    userStore.guardianChanged,
    userStore.emailGuardianChanged,
    userStore.accountInfo?.emailGuardians,
    t,
    timeAgo,
  ]);

  const [innerModalOpen, setInnerModalOpen] = useState(false);

  const closeModals = () => {
    setEditGuardianModalShow(false);
    setPasswordModalShow(false);
    setShowPinModal(false);
    setShowNewGuardianModal(false);
  };

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: !innerModalOpen,
  });

  const onInnerModalChange = useCallback((isOpen: boolean) => {
    setInnerModalOpen(isOpen);
  }, []);

  const handleEditSuccess = useCallback(
    async (isRevert = false) => {
      setEditGuardianModalShow(false);
      try {
        setHideEdit(true);
        const newAccount = await pollAccount(isRevert);
        if (newAccount) {
          userStore.setAccountInfo(newAccount);
          setHideEdit(false);
        } else {
          toast({
            type: "warning",
            text: t("Sync failed, please check later "),
          });
        }
      } catch (e) {
        toast({
          type: "error",
          text: t("Failed to fetch latest info"),
        });
      }
    },
    [t, userStore]
  );

  const handleCancelGuardianChanges = useCallback(
    async (key: KontosKey) => {
      try {
        loadingStore.showLoading();
        // await cli.revertUpdateGuardianInfo();
        const { signature, expiredAt } = await signForBe(
          userStore.accountName!,
          key,
          `RevertUpdateAccountGuardian::${KontosQueryCli.nameAddress(
            userStore.accountName!
          )}`,
          COMMON_SIGN_EXPIREDAT
        );
        await callRevertUpdateAccountGuardian({
          account: userStore.accountName!,
          expiredAt,
          signature,
        });
        toast({
          type: "success",
          text: t("Your guardian change is successfully reverted"),
        });
        await handleEditSuccess(true);
      } catch (e) {
        console.log(e);
        const errorMessage =
          e instanceof Error
            ? e.message
            : t("Failed to revert your guardian change");
        toast({
          type: "error",
          text: errorMessage,
        });
      } finally {
        loadingStore.hideLoading();
      }
    },
    [handleEditSuccess, t, userStore.accountName]
  );

  const handleGuardianChanges = useCallback(
    (key: KontosKey) => {
      setShowPinModal(false);
      if (updatingInfo?.type === "warning") {
        handleCancelGuardianChanges(key);
      }
    },
    [handleCancelGuardianChanges, updatingInfo?.type]
  );

  return (
    <WrapperFlexStyle ref={wrapperRef}>
      <div className="header">
        <Header
          title={t("Guardians")}
          padding="8px 24px"
          enableBack={true}
          callBack={() => {
            navigate(ROUTE_HOME);
          }}
          rightBtnText={rightBtnText}
          rightBtnCallBack={handleEditOnClick}
        />
      </div>
      <TabEntity
        items={itemArr}
        selectedVal={selectedVal}
        setSelectedVal={setSelectedVal}
        waitingActionsLengthArr={[waitingActionsLength, 0]}
      />
      {selectedVal === itemArr[0] && (
        <>
          <ApproveFilterStyle>
            <FilterSection
              items={filterArrApprove}
              selectedVal={filterVal}
              setSelectedVal={setFilterVal}
            />
            <YourRecordsStyle>
              <Trans i18nKey={"trans-You have 0 records"}>
                You have <span>{{ records } as any}</span> records.
              </Trans>
            </YourRecordsStyle>
          </ApproveFilterStyle>
          <ApproveListStyle>
            {thresholdActionsArr.map((item, index) => (
              <ApproveItem
                key={index.toString()}
                itemData={item}
                name={wardsThresholdActions?.actions?.[index]?.executor || ""}
                onDelete={(params0) => {
                  setPasswordModalShow(true);
                  setDeleteParam(params0);
                  setApproveParam(undefined);
                }}
                onApprove={(params0) => {
                  //old approve logic
                  // setPasswordModalShow(true);
                  // setApproveParam(params0);
                  // setDeleteParam(undefined);
                  //   new approve logic
                  setCurrentGuardForName(
                    wardsThresholdActions?.actions?.[index]?.executor || ""
                  );
                  setGuardianForModalShow(true);
                }}
              />
            ))}
          </ApproveListStyle>
        </>
      )}

      {selectedVal === itemArr[1] && (
        <>
          <GuardianDescription>
            <img src={guardianInfoIco} alt="" />
            {guarduanText}
          </GuardianDescription>
          <GuardianFilterStyle>
            <FilterSection
              items={filterArrGuardian}
              selectedVal={filterValGuardian}
              setSelectedVal={setFilterValGuardian}
            />
          </GuardianFilterStyle>
          <GuardianListStyle>
            {updatingInfo && (
              <>
                <OneBtnPanel
                  type={updatingInfo.type}
                  title={updatingInfo.title}
                  content={updatingInfo.content}
                  btnText={updatingInfo.btnText}
                  onClick={updatingInfo.onClick}
                  onRightTopClick={updatingInfo.onRightTopClick}
                />
                <div style={{ marginBottom: "16px" }} />
              </>
            )}

            {(filterArrGuardian[0].text === filterValGuardian &&
              (fetchingGuardians || !guardianData)) ||
            (filterArrGuardian[1].text === filterValGuardian &&
              (fetchingWards || !guardianData)) ? (
              <>
                {Array.from({ length: showSkeletonCount }).map((_, index) => (
                  <Skeleton
                    key={index}
                    style={{
                      width: "100%",
                      height: "56px",
                      marginBottom: "8px",
                      borderRadius: "99px",
                    }}
                  />
                ))}
              </>
            ) : guardianData?.length === 0 ? (
              updatingInfo ? (
                <></>
              ) : (
                <NoDataV2 text={t("No Data")} />
              )
            ) : (
              guardianData?.map((item, index) => (
                <GuardianItem
                  name={item}
                  avatar={
                    filterArrGuardian[0].text === filterValGuardian
                      ? guardianAvatarIco
                      : kontosAvatarIco
                  }
                  key={item}
                  onClick={
                    filterArrGuardian[0].text === filterValGuardian
                      ? undefined
                      : () => {
                          if (filterValGuardian === filterArrGuardian[1].text) {
                            setCurrentGuardForName(item);
                            setGuardianForModalShow(true);
                          }
                        }
                  }
                />
              ))
            )}
          </GuardianListStyle>
        </>
      )}

      {/* Edit Guardian Sheet */}
      <NoScrollerBarSheet
        isOpen={editGuardianModalShow}
        onClose={() => {
          setEditGuardianModalShow(false);
        }}
        mountPoint={domNode}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <EditGuardians
              guardianData={accountGuardians!}
              onClose={() => {
                setEditGuardianModalShow(false);
              }}
              onSuccess={handleEditSuccess}
              onInnerModalChange={onInnerModalChange}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => {
            setEditGuardianModalShow(false);
          }}
        />
      </NoScrollerBarSheet>
      {/*guardian for: send approve transaction */}
      <NoScrollerBarSheet
        isOpen={guardianForModalShow}
        onClose={() => {
          setGuardianForModalShow(false);
        }}
        mountPoint={domNode}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <GuardianForModal
              setCurrentGuardForAddress={setCurrentGuardForAddress}
              currentGuardForAddress={currentGuardForAddress}
              currentGuardForName={currentGuardForName}
              handleBack={() => {
                setGuardianForModalShow(false);
              }}
              onSuccess={() => {
                setPasswordModalShow(true);
              }}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => {
            setGuardianForModalShow(false);
          }}
        />
      </NoScrollerBarSheet>

      <NoScrollerBarSheet
        isOpen={showPinModal}
        onClose={() => setShowPinModal(false)}
        mountPoint={domNode}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Unlock onSuccess={handleGuardianChanges} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowPinModal(false)} />
      </NoScrollerBarSheet>

      <NoScrollerBarSheet
        isOpen={showNewGuardianModal}
        onClose={() => setShowNewGuardianModal(false)}
        mountPoint={domNode}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div
              style={{
                height: 482,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <NewGuardiansModal
                onDone={() => {
                  setShowNewGuardianModal(false);
                }}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setShowNewGuardianModal(false)} />
      </NoScrollerBarSheet>

      {showEmailWarnModal && (
        <PopupModal
          type="warning"
          onConfirm={() => {
            setShowEmailWarnModal(false);
            setEditGuardianModalShow(true);
          }}
          onCancel={() => {
            setShowEmailWarnModal(false);
          }}
          onOutClick={() => {
            setShowEmailWarnModal(false);
          }}
          title={t("You already have security email")}
          desc={t("Enabling the guardian will invalidate your security email")}
          btnTxt={t("Continue")}
        />
      )}
      <NoScrollerBarSheet
        isOpen={passwordModalShow}
        onClose={() => {
          setPasswordModalShow(false);
        }}
        mountPoint={domNode}
        disableScrollLocking={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Unlock
              onSuccess={async (cli) => {
                try {
                  loadingStore.showLoading();
                  // guardian approve
                  if (guardianForModalShow) {
                    const { signature, expiredAt } = await signForBe(
                      userStore.accountName!,
                      cli,
                      `${KontosQueryCli.nameAddress(
                        userStore.accountName!
                      )}::${KontosQueryCli.nameAddress(
                        currentGuardForName
                      )}::${currentGuardForAddress}::${true}`,
                      COMMON_SIGN_EXPIREDAT
                    );
                    await callRecoverAccountByGuardian({
                      sender: userStore.accountName!,
                      executor: currentGuardForName,
                      newPubKey: currentGuardForAddress,
                      isApprove: true,
                      expiredAt,
                      signature,
                    });
                    // await userStore?.kontosCli?.newPubKeyFromGuardian(
                    //   currentGuardForName,
                    //   currentGuardForAddress
                    // );
                    setGuardianForModalShow(false);
                    setPasswordModalShow(false);
                    loadingStore.hideLoading();
                    toast({
                      type: "success",
                      text: t(
                        `Successfully approve for ${currentGuardForName}.`
                      ),
                    });
                    setCurrentGuardForName("");
                    setCurrentGuardForAddress("");
                  } else {
                    // old guardian approve
                    const param = deleteParam || approveParam;
                    if (!param) return;
                    const code = approveParam
                      ? GuardianActionCode.APPROVE
                      : GuardianActionCode.REJECT;
                    const { signature, expiredAt } = await signForBe(
                      userStore.accountName!,
                      cli,
                      `${KontosQueryCli.nameAddress(
                        param.sender
                      )}::${KontosQueryCli.nameAddress(param.executor)}::${
                        param.newPubKey
                      }::${code === GuardianActionCode.APPROVE}`,
                      COMMON_SIGN_EXPIREDAT
                    );
                    await callRecoverAccountByGuardian({
                      sender: param.sender,
                      executor: param.executor,
                      newPubKey: param.newPubKey!,
                      isApprove: code === GuardianActionCode.APPROVE,
                      expiredAt,
                      signature,
                    });
                    // await cli.takeGuardianAction(
                    //   param.executor,
                    //   param.actionHash,
                    //   code
                    // );
                    setPasswordModalShow(false);
                    setTimeout(async () => {
                      console.log("get data");
                      loadingStore.hideLoading();
                      userStore?.accountInfo?.name &&
                        setWardsThresholdActions(
                          await callWardsThresholdActions({
                            account: userStore.accountInfo.name,
                          })
                        );
                    }, 5000);
                  }
                } catch (e) {
                  const errorMessage =
                    e instanceof Error ? e.message : t("System Error");
                  toast({
                    text: errorMessage,
                    type: "error",
                  });
                } finally {
                  loadingStore.hideLoading();
                }
              }}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onTap={() => {
            setPasswordModalShow(false);
          }}
        />
      </NoScrollerBarSheet>
    </WrapperFlexStyle>
  );
});
