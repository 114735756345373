import styled from "styled-components";
import connectedTipIcon from "src/assets/icons/receive/connected-tip.svg";
import { Trans, useTranslation } from "react-i18next";
import disconnectIcon from "src/assets/icons/receive/disconnect.svg";
import { useCallback, useState } from "react";
import loadingIcon from "src/components/button/loading-purple-icon.svg";
import { useWalletDisconnect } from "../hooks/useWalletDisconnect";
import toast from "src/components/toast/Toast";
import { useAccount } from "wagmi";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  padding: 8px 10px 8px 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: rgba(65, 61, 236, 0.05);
`;

const TipIcon = styled.img`
  width: 28px;
  height: 16px;
`;

const Text = styled.span`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 15.4px */
`;

const StrongText = styled.strong`
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--Kontos-Blue, #413dec);
  text-overflow: ellipsis;
  ${fontBold}
  font-size: 12px;
  font-style: normal;
  line-height: 140%;
`;

const DisconnectButton = styled.button<{ $loading?: boolean }>`
  border: none;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-image: ${(props) =>
    props.$loading ? `url(${loadingIcon})` : `url(${disconnectIcon})`};
  background-size: 24px;
  background-position: center;

  @keyframes rotateBackground {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${(props) =>
    props.$loading && `animation: rotateBackground 1s linear infinite;`}
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export const ConnectStatusBar: React.FC<IProps> = ({ className, style }) => {
  const { t } = useTranslation();
  const { disconnectAsync, toastDisconnectSuccess } = useWalletDisconnect();
  const [disconnecting, setDisconnecting] = useState<boolean>(false);
  const walletAccount = useAccount();

  const handleDisconnect = useCallback(async () => {
    if (walletAccount.isDisconnected) {
      return;
    }

    try {
      setDisconnecting(true);
      await disconnectAsync();
      toastDisconnectSuccess();
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : t("Disconnect failed");
      toast({
        text: errorMessage,
        type: "error",
      });
    } finally {
      setDisconnecting(false);
    }
  }, [
    disconnectAsync,
    t,
    toastDisconnectSuccess,
    walletAccount.isDisconnected,
  ]);

  return (
    <Container className={className} style={style}>
      <TipIcon src={connectedTipIcon} alt="" />
      <Text>
        <Trans i18nKey={"trans-connected-tip"}>
          You are connected to <StrongText>third party wallet.</StrongText>
        </Trans>
      </Text>
      <DisconnectButton
        disabled={disconnecting}
        $loading={disconnecting}
        onClick={handleDisconnect}
      />
    </Container>
  );
};
