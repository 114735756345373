import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "src/pages/trade/asset-select/ToBuyAssetSelector";
import { Chain, FtAsset } from "src/type/zkkontos";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Header from "src/components/common/header";
import { useTranslation } from "react-i18next";
import { ReceiveDepositMain } from "./ReceiveDepositMain";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { DepositConfirm } from "./DepositConfirm";
import { StatusModal } from "src/components/modal/StatusModal";
import { isNative, openUrl } from "src/utils/helper";
import { parseEther } from "viem";
import {
  useAccount,
  useSendTransaction,
  useSwitchChain,
  useWriteContract,
} from "wagmi";
import { KontosButton } from "src/components/button/KontosButton";
import ERC20ABI from "../../../assets/abis/ERC20.json";
import { useLocation, useNavigate } from "react-router-dom";
import { callDepositCallback } from "src/apis/event-bybit-api";
import { CONNECTOR_BYBIT } from "@/config";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const SelectAssetContainer = styled.div`
  width: 100%;
  height: 100%;
  background: var(--White, #fff);
  overflow-y: auto;
`;

const ViewButton = styled(KontosButton)`
  background: var(--Kontos-Blue, #413dec);
  color: var(--White, #fff);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

const GotItButton = styled(KontosButton)`
  background: rgba(65, 61, 236, 0.1);
  color: var(--Kontos-Blue, #413dec);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

const ErrorGotItButton = styled(KontosButton)`
  border-radius: 99px;
  border: 1px solid var(--error-notice, #ff1e2b);
  background: var(--White, #fff);
  color: var(--error-notice, #ff1e2b);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

const initializeNativeTransfer = (originalEthRequest: any) => {
  if (typeof originalEthRequest === "undefined") {
    return;
  }

  if (typeof window.bybitWallet !== "undefined") {
    window.ethereum.request = async (...args: any) => {
      if (args[0]?.method === "eth_sendTransaction" && args[0]?.params?.[0]) {
        args[0].params[0].data = "0x";
      }
      return originalEthRequest.apply(this, args);
    };
  }
};

const finalizeNativeTransfer = (originalEthRequest: any) => {
  if (typeof originalEthRequest === "undefined") {
    return;
  }

  if (typeof window.ethereum !== "undefined") {
    window.ethereum.request = originalEthRequest;
  }
};

interface IProps {
  eoaAddress: string;
  chain: Chain;
  onClose: () => void;
}

export const ReceiveDepositFlow: React.FC<IProps> = observer(
  ({ eoaAddress, chain, onClose }) => {
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const domNode = wrapperRef.current as Element | undefined;
    const { receiveStore, userStore } = useStores();
    const [step, setStep] = useState<"select" | "input">(() => {
      if (receiveStore.asset) {
        return "input";
      }
      return "select";
    });
    const [showConfirmSheet, setShowConfirmSheet] = useState<boolean>(false);
    const [showTransferStatusModal, setShowTransferStatusModal] =
      useState<boolean>(false);
    const { sendTransactionAsync, status: nativeStatus } = useSendTransaction();
    const { writeContractAsync, status: erc20Status } = useWriteContract();
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [txHash, setTxHash] = useState<string | undefined>(undefined);
    const [mode, setMode] = useState<"native" | "erc20" | undefined>(undefined);
    const status =
      mode === "native"
        ? nativeStatus
        : mode === "erc20"
          ? erc20Status
          : undefined;
    const navigate = useNavigate();
    const location = useLocation();
    const walletAccount = useAccount();
    const { switchChainAsync } = useSwitchChain();

    const pingBeAfterTransfer = useCallback(
      async (
        eoaAccount: string,
        account: string,
        aaAddress: string,
        txHash: string,
        retryCount: number = 2
      ) => {
        receiveStore.addBybitTask({ eoaAccount, account, aaAddress, txHash });
        if (retryCount <= 0) {
          return;
        }
        try {
          await callDepositCallback({ eoaAccount, account, aaAddress, txHash });
        } catch (e) {
          console.log(e);
          setTimeout(() => {
            pingBeAfterTransfer(
              eoaAccount,
              account,
              aaAddress,
              txHash,
              retryCount - 1
            );
          }, 5000);
        }
      },
      [receiveStore]
    );

    const handleChooseAsset = useCallback(
      (asset: FtAsset) => {
        receiveStore.setAsset(asset);
        setStep("input");
      },
      [receiveStore]
    );

    const handleSubmit = useCallback(async () => {
      if (
        !receiveStore.chain ||
        !receiveStore.asset ||
        !receiveStore.amount ||
        !receiveStore.toReceiveAaAddress
      ) {
        return;
      }

      setShowTransferStatusModal(true);
      const originalEthRequest = window?.bybitWallet?.request;

      if (isNative(receiveStore.asset)) {
        initializeNativeTransfer(originalEthRequest);
        try {
          setMode("native");
          // TODO: Bybit special handler
          if (walletAccount?.connector?.id === CONNECTOR_BYBIT) {
            await switchChainAsync({
              chainId: parseInt(chain.chainIndex),
            });
          }

          const respTxHash = await sendTransactionAsync({
            // TODO: Bybit special handler
            // chainId: parseInt(receiveStore.chain.chainIndex),
            to: receiveStore.toReceiveAaAddress as
              | `0x${string}`
              | null
              | undefined,
            value: parseEther(receiveStore.amount.toString()),
          });
          if (
            typeof window?.bybitWallet !== "undefined" &&
            receiveStore.chain.chainIndex === "5000"
          ) {
            pingBeAfterTransfer(
              eoaAddress,
              userStore.accountName!,
              receiveStore.toReceiveAaAddress,
              respTxHash
            );
          }
          setTxHash(respTxHash);
          receiveStore.addPendingTxHash({
            explorerUrl: `${receiveStore.chain.explorerLinkURL}tx/${respTxHash}`,
            hash: respTxHash,
            chainId: receiveStore.chain.chainIndex,
            address: receiveStore.asset.address,
          });
          setShowConfirmSheet(false);
          receiveStore.reset();
        } catch (e) {
          const errorMessage =
            e instanceof Error
              ? e.message
              : t("Transaction failed due to unknown error");
          setErrorMsg(errorMessage);
          setTxHash(undefined);
          navigate(location.pathname, { replace: true });
        } finally {
          finalizeNativeTransfer(originalEthRequest);
        }
      } else {
        try {
          setMode("erc20");
          const respTxHash = await writeContractAsync({
            abi: ERC20ABI,
            address: receiveStore.asset.address as `0x${string}`,
            functionName: "transfer",
            chainId: parseInt(receiveStore.chain.chainIndex),
            args: [
              receiveStore.toReceiveAaAddress,
              receiveStore.amount.toStringWithDecimal(
                receiveStore.asset.decimals
              ),
            ],
          });
          setTxHash(respTxHash);
          receiveStore.addPendingTxHash({
            explorerUrl: `${receiveStore.chain.explorerLinkURL}tx/${respTxHash}`,
            hash: respTxHash,
            chainId: receiveStore.chain.chainIndex,
            address: receiveStore.asset.address,
          });
          setShowConfirmSheet(false);
          receiveStore.reset();
        } catch (e) {
          const errorMessage =
            e instanceof Error
              ? e.message
              : t("Transaction failed due to unknown error");
          setErrorMsg(errorMessage);
          setTxHash(undefined);
          navigate(location.pathname, { replace: true });
        }
      }
    }, [
      chain.chainIndex,
      eoaAddress,
      location.pathname,
      navigate,
      pingBeAfterTransfer,
      receiveStore,
      sendTransactionAsync,
      switchChainAsync,
      t,
      userStore.accountName,
      walletAccount?.connector?.id,
      writeContractAsync,
    ]);

    const statusModalDesc = useMemo(() => {
      switch (status) {
        case "error":
          return errorMsg;
        case "pending":
          return t("Please confirm in wallet");
        case "success":
          return t("The transaction has been submitted");
      }
    }, [errorMsg, status, t]);

    const buttons = useMemo((): ReactNode[] | undefined => {
      switch (status) {
        case "error":
          return [
            <ErrorGotItButton onClick={() => setShowTransferStatusModal(false)}>
              {t("Got it")}
            </ErrorGotItButton>,
          ];
        case "success":
          return [
            <ViewButton
              onClick={() => {
                openUrl(`${receiveStore.chain?.explorerLinkURL}tx/${txHash}`);
              }}
            >
              {t("View on Explorer")}
            </ViewButton>,
            <GotItButton onClick={onClose}>{t("Got it")}</GotItButton>,
          ];
      }
    }, [status, t, onClose, receiveStore.chain?.explorerLinkURL, txHash]);

    return (
      <Container ref={wrapperRef}>
        {step === "select" && (
          <SelectAssetContainer>
            <Header
              padding="8px 24px"
              title={t("Select Assets")}
              rightBtnText={t("Cancel")}
              rightBtnCallBack={onClose}
            />
            <ToBuyAssetSelector
              chains={[chain]}
              showAssetType={ShowAssetType.ExternalBalance}
              initAssetType={chain.chainIndex}
              onChoose={handleChooseAsset}
              limit={20}
            />
          </SelectAssetContainer>
        )}

        {step === "input" && (
          <ReceiveDepositMain
            goToSelectAsset={() => setStep("select")}
            onClose={onClose}
            onBack={() => setStep("select")}
            onNext={() => setShowConfirmSheet(true)}
          />
        )}

        <BottomSheet
          isOpen={showConfirmSheet}
          mountPoint={domNode}
          customHeight={330}
          onClose={() => setShowConfirmSheet(false)}
        >
          {receiveStore.asset && receiveStore.amount ? (
            <DepositConfirm
              asset={receiveStore.asset}
              amount={receiveStore.amount}
              onClose={() => setShowConfirmSheet(false)}
              onSubmit={handleSubmit}
            />
          ) : (
            <></>
          )}
        </BottomSheet>

        {/* Transfer Status Modal */}
        {showTransferStatusModal && (
          <StatusModal
            needBackDrop
            onClose={() => setShowTransferStatusModal(false)}
            status={status}
            title={t("Submit Transaction")}
            desc={statusModalDesc}
            buttons={buttons}
          />
        )}
      </Container>
    );
  }
);
