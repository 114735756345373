import styled from "styled-components";
import PaymentChargeItem from "../payment-items/PaymentChargeItem";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isSameFtAsset } from "src/utils/zkkontosHelper";
import {
  DEFAULT_DECIMAL,
  DEFAULT_DISPLAY_PRECESION,
  TRADE_DEFAULT_RECEIVE_FTASSET,
  TRADE_DEFAULT_RECEIVE_FTASSET_2,
} from "src/config";
import debounce from "lodash/debounce";
import { fetchSpecificFtAsset } from "src/service/trade-service";
import KontosNumber from "src/utils/KontosNumber";
import Divider from "src/components/divider/Divider";
import AvatarSvg from "src/assets/icons/trade/trade-avatar.svg";
import PaymentKeyValueList, {
  AvatarIcon,
  CommonText,
  SuccessText,
  WarningText,
} from "../payment-items/PaymentKeyValueList";
import PendingSvg from "src/assets/images/pending.svg";
import SuccessSvg from "src/assets/images/success.svg";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import QRCode from "qrcode.react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CopyableAddress from "src/components/copyable-address/CopyableAddress";
import toast from "src/components/toast/Toast";
import { Trans } from "react-i18next";
import warningIco from "src/assets/icons/receive/warning.svg";
import { isKontosChain } from "src/utils/helper";
import { useStores } from "src/hooks/useStore";
import { usePaymentStore } from "src/store/trade/PaymentStore";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import {
  ShowAssetType,
  ToBuyAssetSelector,
} from "src/pages/trade/asset-select/ToBuyAssetSelector";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { getChainByAsset } from "@/store/storeHelper";
import { fetchAaAccounts } from "@/service/account-service";

const REFRESH_INTERVAL = 20000;

const Container = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }

  .warning {
    margin-bottom: 16px;

    width: 100%;
    padding: 8px 20px;

    border-radius: 8px;
    background: rgba(250, 173, 20, 0.05);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .warning-icon {
      width: 16px;
      height: 16px;
    }

    .warning-text {
      margin-left: 16px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;

      color: var(--Warning, #faad14);
      font-family: "HarmonyOS Sans SC";
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }
  }
`;

const MainContainer = styled.div`
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  flex: 1;
  overflow-y: auto;
`;

const KeyValueListContainer = styled.div``;

const QRCodeContainer = styled.div`
  flex: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface Props {
  taskData: RespTaskPayment;
  choosing: boolean;
  onChoosing: (isOpen: boolean) => void;
  onSuccess: () => void;
}

const PaymentReceive: React.FC<Props> = ({
  taskData,
  choosing,
  onChoosing,
  onSuccess,
}) => {
  const { userStore, chainStore } = useStores();
  const {
    toReceiveFtAsset,
    targetFtAsset,
    maxAvailableUsd,
    setToReceiveFtAsset,
    wrappedFetchAndSetTaskData,
    switchToPlanMethod,
  } = usePaymentStore();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const toReceiveAmount: KontosNumber = new KontosNumber(
    taskData?.respQuotePayment?.assetAmount,
    DEFAULT_DECIMAL
  );
  const toReceiveAmountText: string | undefined = toReceiveAmount.gt(0)
    ? toReceiveAmount.toFormat() + " " + toReceiveFtAsset?.symbol
    : undefined;
  const [taskDataLoading, setTaskDataLoading] = useState<boolean>(true);
  const [assetLoading, setAssetLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<"success" | "pending">("pending");
  const [allowChangeReceive, setAllowChangeReceive] = useState<boolean>(true);
  const [addressList, setAddressList] = useState<{ [key: string]: string }>({});
  const toReceiveChainIndex = toReceiveFtAsset?.chainIndex;
  const selectedChainSymbol: string =
    getChainByAsset(toReceiveFtAsset)?.chainSymbol || "-";
  const accountName = userStore.accountName
    ? userStore.accountName?.replaceAll(".os", "") + ".os"
    : "N/A";

  const toReceiveAccountAddress: string | undefined = useMemo(() => {
    if (toReceiveChainIndex) {
      return addressList[toReceiveChainIndex];
    } else {
      return undefined;
    }
  }, [addressList, toReceiveChainIndex]);

  const executeTaskData = useCallback(async () => {
    const debouncedFunction = debounce(async () => {
      try {
        setTaskDataLoading(true);
        await wrappedFetchAndSetTaskData?.({
          chainIndex: toReceiveFtAsset?.chainIndex!,
          address: toReceiveFtAsset?.address!,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        setTaskDataLoading(false);
      }
    }, 0);
    debouncedFunction();
  }, [
    toReceiveFtAsset?.address,
    toReceiveFtAsset?.chainIndex,
    wrappedFetchAndSetTaskData,
  ]);

  const paymentKeyValueListCtx = useMemo(() => {
    if (taskDataLoading) {
      return {
        receiver: (
          <>
            <AvatarIcon src={AvatarSvg} />
            <CommonText>{accountName}</CommonText>
          </>
        ),
        totalPayment: <EllipsisPlaceholder />,
        myAvailable: <EllipsisPlaceholder />,
        status: (
          <>
            <AvatarIcon src={PendingSvg} />
            <WarningText>{t("Waiting for receive...")}</WarningText>
          </>
        ),
      };
    }
    return {
      receiver: (
        <>
          <AvatarIcon src={AvatarSvg} />
          <CommonText>{accountName}</CommonText>
        </>
      ),
      totalPayment: (
        <CommonText>
          {new KontosNumber(
            taskData.totalPaymentsInUsd,
            DEFAULT_DECIMAL
          ).toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
        </CommonText>
      ),
      myAvailable: (
        <CommonText>
          {maxAvailableUsd.toFixed(DEFAULT_DISPLAY_PRECESION) + " USD"}
        </CommonText>
      ),
      status: (
        <>
          <AvatarIcon src={status === "pending" ? PendingSvg : SuccessSvg} />
          {status === "pending" ? (
            <WarningText>{t("Waiting for receive...")}</WarningText>
          ) : (
            <SuccessText>{t("Received successfully")}</SuccessText>
          )}
        </>
      ),
    };
  }, [
    taskDataLoading,
    accountName,
    taskData.totalPaymentsInUsd,
    maxAvailableUsd,
    status,
  ]);

  useEffect(() => {
    if (taskData?.msgs && taskData?.msgs?.length > 0) {
      setStatus("success");
      setAllowChangeReceive(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setTimeout(() => {
        switchToPlanMethod();
        onSuccess();
      }, 2000);
    } else {
      setStatus("pending");
    }
  }, [onSuccess, switchToPlanMethod, taskData?.msgs, taskData?.msgs?.length]);

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        setAssetLoading(true);
        let toSearchToken = TRADE_DEFAULT_RECEIVE_FTASSET;
        if (isSameFtAsset(targetFtAsset, TRADE_DEFAULT_RECEIVE_FTASSET)) {
          toSearchToken = TRADE_DEFAULT_RECEIVE_FTASSET_2;
        }
        const respToken = await fetchSpecificFtAsset(
          toSearchToken.chainIndex,
          toSearchToken.address
        );
        setToReceiveFtAsset(respToken);
      } catch (e) {
        console.warn(e);
      } finally {
        setAssetLoading(false);
      }
    };

    const fetchAndSetAddress = async () => {
      try {
        const resp = await fetchAaAccounts(userStore.accountName!);
        let aaAccounts = resp;
        if (userStore.accountInfo?.nameAddress) {
          aaAccounts[0] = userStore.accountInfo?.nameAddress;
        }
        setAddressList(aaAccounts);
      } catch (e) {
        console.warn(e);
      }
    };

    if (!toReceiveFtAsset || isSameFtAsset(toReceiveFtAsset, targetFtAsset)) {
      fetchAndSetData();
    } else {
      setAssetLoading(false);
    }
    fetchAndSetAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toReceiveFtAsset) {
      timerRef.current = setInterval(() => {
        executeTaskData();
      }, REFRESH_INTERVAL);
      executeTaskData();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toReceiveFtAsset]);

  useEffect(() => {
    if (toReceiveChainIndex) {
    }
  }, [toReceiveChainIndex]);

  return (
    <Container>
      {choosing === false && (
        <>
          {/* Warning */}
          <div className="warning">
            <img className="warning-icon" src={warningIco} alt="" />
            <div className="warning-text">
              <Trans
                i18nKey={"trans-only-support-specific-assets-to-this-address"}
              >
                Only supports sending {{ selectedChainSymbol }} assets to this
                address. Other assets will be lost forever!
              </Trans>
            </div>
          </div>

          <MainContainer>
            <PaymentChargeItem
              currentFtAsset={toReceiveFtAsset}
              text={t("You need to receive") as string}
              amountText={toReceiveAmountText}
              isFixed={!allowChangeReceive}
              assetLoading={assetLoading}
              taskDataLoading={taskDataLoading}
              onClick={() => {
                onChoosing(true);
              }}
            />

            <Divider isDashed={true} top={16} bottom={16} />

            <KeyValueListContainer>
              <PaymentKeyValueList
                receiver={paymentKeyValueListCtx?.receiver}
                totalPayment={paymentKeyValueListCtx?.totalPayment}
                myAvailable={paymentKeyValueListCtx?.myAvailable}
                status={paymentKeyValueListCtx?.status}
              />
            </KeyValueListContainer>

            <QRCodeContainer>
              {toReceiveAccountAddress ? (
                <>
                  <QRCode
                    value={toReceiveAccountAddress}
                    className="transfer-receive__qr-code"
                    size={170}
                    renderAs="canvas"
                    fgColor="#2B2B2B"
                  />
                  <div style={{ marginTop: "16px" }} />
                  {isKontosChain(toReceiveChainIndex || "-1") ? (
                    <CopyableAddress
                      address={userStore.accountInfo?.name + ".os"}
                      needShort={false}
                      addressClickAble={false}
                    />
                  ) : (
                    <CopyableAddress
                      address={toReceiveAccountAddress}
                      addressFrontLength={6}
                      addressBehindLength={6}
                      addressClickAble={false}
                    />
                  )}
                </>
              ) : (
                <>
                  <Skeleton style={{ width: "170px", height: "170px" }} />
                  <Skeleton
                    count={1}
                    style={{ width: "120px", marginTop: "16px" }}
                  />
                </>
              )}
            </QRCodeContainer>
          </MainContainer>
        </>
      )}

      {choosing === true && (
        <ToBuyAssetSelector
          onChoose={(item) => {
            if (isSameFtAsset(item, targetFtAsset!)) {
              toast({
                text: t(
                  "Please do not receive transfers of the same type as the asset you intend to purchase."
                ),
                type: "warning",
              });
              return;
            }
            onChoosing(false);
            setToReceiveFtAsset(item);
          }}
          chains={chainStore.chains}
          hasAll
          showAssetType={ShowAssetType.Detail}
          initAssetType={NonChainIndex.All}
          isWhitelist
        />
      )}
    </Container>
  );
};

export default PaymentReceive;
