import React from "react";
import styled from "styled-components";
import { FtAsset } from "@/type/zkkontos";
import { t } from "i18next";
import { DEFAULT_DISPLAY_PRECESION } from "src/config";
import Header from "src/components/common/header";
import { useNavigate } from "react-router-dom";
import { ROUTE_HOME } from "src/router/router-config";
import { useStores } from "src/hooks/useStore";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import SelectAssetsModal from "@/pages/trade/SelectAssetsModal";

const Container = styled.div`
  height: calc(100% - 60px);
`;

const displayPrecision = DEFAULT_DISPLAY_PRECESION;

interface IProps {
  onChooseAsset: (asset: FtAsset) => void;
}

const TransferAssetStep: React.FC<IProps> = ({ onChooseAsset }) => {
  const { chainStore, userStore } = useStores();
  const navigate = useNavigate();

  return (
    <Container>
      <Header
        title={t("Choose Assets to Send")}
        padding="0 20px 20px 20px"
        enableBack
        callBack={() => navigate(ROUTE_HOME)}
      />
      <SelectAssetsModal
        allFtAssets={userStore.userHoldingsSortedByBalances}
        chains={chainStore.userHoldingsChains}
        showBalance
        displayPrecision={displayPrecision}
        onChoose={onChooseAsset}
        initChainIndex={
          chainStore.defaultOptForSend !== NonChainIndex.All
            ? userStore.userHoldings.some(
                (item) => item.chainIndex === chainStore.defaultOptForSend
              )
              ? chainStore.defaultOptForSend
              : NonChainIndex.All
            : NonChainIndex.All
        }
        onChainChange={chainStore.setDefaultOptForSend}
      />
    </Container>
  );
};

export default TransferAssetStep;
