import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { EXPLORER_KONTOS_URL, TEMP_EXPLORER_URL } from "src/config";
import { API } from "./core/API";

export interface ReqErrorCatch {
  account: string;
  apiUrl: string;
  reqParams: string;
  errMessage: string;
}

export interface RespErrorCatch {}

export const callErrorCatch = async (
  data: ReqErrorCatch,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespErrorCatch> => {
  return request<ReqErrorCatch, RespErrorCatch>(
    API.mwallet.errorCatch,
    data,
    endpoint
  );
};
