import {
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_ACCOUNT_REFERRAL,
  ROUTE_SETTINGS_ACCOUNT_SWITCH,
} from "src/router/router-config";
import { Route, Routes } from "react-router-dom";
import AccountSettings from "./account/AccountSettings";
import SwitchAccount from "./account/switch-account/SwitchAccount";
import { useEffect } from "react";
import { useStores } from "src/hooks/useStore";
import AccountReferral from "src/pages/settings/account/AccountReferral";

const SettingsRouter: React.FC = () => {
  const { uiSettingStore, userStore } = useStores();

  useEffect(() => {
    uiSettingStore.reset();
    return () => {
      uiSettingStore.reset();
    };
  }, [uiSettingStore]);

  useEffect(() => {
    userStore.clearEmailRecoveringAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {/* account */}
      <Route path={ROUTE_SETTINGS_ACCOUNT} element={<AccountSettings />} />
      <Route
        path={ROUTE_SETTINGS_ACCOUNT + ROUTE_SETTINGS_ACCOUNT_SWITCH}
        element={
          <SwitchAccount
            onDone={() => uiSettingStore.closeAccountSettingSheet()}
          />
        }
      />
      <Route
        path={ROUTE_SETTINGS_ACCOUNT + ROUTE_SETTINGS_ACCOUNT_REFERRAL}
        element={<AccountReferral />}
      />
    </Routes>
  );
};

export default SettingsRouter;
