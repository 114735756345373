import React, {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import { inputStatusMap } from "src/components/start/RegisterStatusIcon";
import { debounce } from "lodash";
import { useStores } from "src/hooks/useStore";
import { isGoogleEmail } from "../button/GoogleLoginBtn";
import { useTranslation } from "react-i18next";
import {
  isAccountLowercaseAlphanumeric,
  isAccountValidLength,
} from "src/utils/helper";
import { fetchAccount } from "@/service/account-service";

// Styled container that holds the input and the icons
const InputContainer = styled.div<{
  disabled?: boolean;
}>`
  height: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 8px;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    flex-grow: 1;
    outline: none;
    overflow: hidden;
    color: ${({ disabled }) =>
      disabled
        ? "var(--Deep-200, #B2B6BC)"
        : "color: var(--Deep-800, #1A2535)"};
    text-overflow: ellipsis;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    background: transparent;
    box-sizing: border-box;
    font-family: HarmonyOS Sans;
    border: 1px solid var(--Deep-50, #edeef1);
    border-radius: 99px;
    padding: 0 57px;
    line-height: 56px;
    background: var(--Deep-25, #f5f5f6);
    z-index: 1;
  }

  &.error {
    input {
      border: 1px solid var(--Kontos-Red, #ff4d4f);
      color: var(--Kontos-Red, #ff4d4f);
    }
  }

  input::placeholder {
    color: var(--Deep-200, #b2b6bc);
  }

  input:focus {
    border: 1px solid var(--Kontos-Blue, #413dec);
    color: var(--Kontos-Blue, #413dec);
  }

  input:focus + div {
    span {
      color: var(--Kontos-Blue, #413dec);
    }
  }
`;

// Icon containers to ensure consistent styling
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 42px;
  //height: 42px;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
`;

function isKontosValidString(str: string): boolean {
  const regex: RegExp = /^[a-z0-9]{4,64}$/;
  return regex.test(str);
}

// Define the props for the input component
interface InputWithIconsProps {
  leftIcon: JSX.Element;
  rightIcon?: React.ReactNode; // ReactNode can include text, JSX, or other elements
  placeholder?: string | null;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isDelete?: boolean;
  setInputStatus?: (status: keyof typeof inputStatusMap) => void;
  setInputErrorDesc?: (desc: string) => void;
  onFocused?: () => void;
  onBlurred?: () => void;
  // true: account exit: set input status true, account not exit: set input status false
  isCheckExist?: boolean;
  checkType?: "account" | "email";
  inputStatus?: keyof typeof inputStatusMap;
  inputStyle?: React.CSSProperties;
  rightDistance?: number;
  wrapperStyle?: React.CSSProperties;
  ref?: Ref<{ focus: () => void }>;
  onSubmit?: () => void;
  readonly?: boolean;
  autoFocus?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const InputWithIcons: React.FC<InputWithIconsProps> = forwardRef(
  (
    {
      leftIcon,
      rightIcon,
      placeholder,
      value,
      onChange,
      disabled,
      isDelete,
      setInputStatus,
      setInputErrorDesc,
      onFocused,
      onBlurred,
      isCheckExist = false,
      checkType = "account",
      inputStatus,
      inputStyle,
      rightDistance = 15,
      wrapperStyle,
      onSubmit,
      readonly = false,
      autoFocus,
      className,
      style,
    }: InputWithIconsProps,
    ref
  ) => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckName = useCallback(
      async (name: string) => {
        if (!name) {
          setInputStatus && setInputStatus("active");
          return;
        }

        if (!isCheckExist && !isKontosValidString(name)) {
          setInputStatus && setInputStatus("error");
          if (!!setInputErrorDesc) {
            if (!isAccountLowercaseAlphanumeric(name)) {
              setInputErrorDesc(
                t("Can only contain lowercase letters and numbers")
              );
              return;
            }
            if (!isAccountValidLength(name)) {
              setInputErrorDesc(
                t("The length needs to be between 4-64 digits")
              );
              return;
            }
          }
          return;
        }
        try {
          setInputStatus && setInputStatus("loading");
          const account = await fetchAccount(name);
          setInputStatus && setInputStatus(isCheckExist ? "success" : "error");
          !isCheckExist && setInputErrorDesc?.(t("Account already exists"));
          return account;
        } catch (e) {
          console.warn(e);
          setInputStatus && setInputStatus(isCheckExist ? "error" : "success");
          isCheckExist && setInputErrorDesc?.(t("Account already exists"));
        }
      },
      [isCheckExist, setInputErrorDesc, setInputStatus, t]
    );

    const handleCheckEmail = useCallback(
      (email: string) => {
        if (!email) {
          setInputStatus && setInputStatus("active");
          return;
        }
        if (!isGoogleEmail(email)) {
          setInputStatus && setInputStatus("error");
          setInputErrorDesc?.(
            t("Please enter a valid Gmail address (e.g., yourname@gmail.com).")
          );
          return;
        }
        setInputStatus && setInputStatus("success");
        return;
      },
      [setInputErrorDesc, setInputStatus, t]
    );

    const handleCheck = (str: string) => {
      switch (checkType) {
        case "account":
          handleCheckName(str);
          break;
        case "email":
          handleCheckEmail(str);
          break;
        case undefined:
          break;
      }
    };

    useEffect(() => {
      // if value is empty, return
      if (!value) return;
      handleCheck(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      setInputStatus && setInputStatus("loading");
      handleChangeDebunce(event);
    };

    // Debounce the onChange handler
    const handleChangeDebunce = useCallback(
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("request", event.target.value);
        handleCheck(event.target.value);
      }, 1000),
      []
    );

    return (
      <InputContainer
        className={`${inputStatus} ${className}`}
        disabled={disabled}
        style={wrapperStyle || style}
      >
        <IconContainer>{leftIcon}</IconContainer>
        <input
          readOnly={readonly}
          ref={inputRef}
          style={inputStyle}
          onFocus={() => {
            if (inputStatus === "blur") {
              setInputStatus && setInputStatus("active");
              onFocused && onFocused();
            }
          }}
          onBlur={() => {
            if (!value) {
              setInputStatus && setInputStatus("blur");
              onBlurred && onBlurred();
              return;
            }
          }}
          disabled={isDelete || disabled}
          placeholder={placeholder || ""}
          value={value}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !!onSubmit) {
              inputRef.current?.blur();
              onSubmit();
            }
          }}
        />
        <IconContainer style={{ paddingRight: `${rightDistance}px` }}>
          {rightIcon}
        </IconContainer>
      </InputContainer>
    );
  }
);

export default InputWithIcons;
