import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "src/hooks/useStore";
import ReceiveNetwork from "./ReceiveNetwork";
import ReceiveMain from "./ReceiveMain";
import { watchChainId } from "@wagmi/core";
import { wagmiConfig } from "src/configs/wagmi";
import toast from "src/components/toast/Toast";
import { Trans, useTranslation } from "react-i18next";
import { useWatchDefaultPendingTx } from "./hooks/useWatchDefaultPendingTx";
import { openUrl } from "src/utils/helper";
import { ROUTE_HOME } from "@/router/router-config";

const Receive: React.FC = observer(() => {
  const { t } = useTranslation();
  const { receiveStore } = useStores();
  const [step, setStep] = useState<"network" | "main">(() => {
    if (receiveStore.chain) {
      return "main";
    }
    return "network";
  });
  const [fromReceiveMain, setFromReceiveMain] = useState<boolean>(false);
  const navigate = useNavigate();

  const toastForTxResult = useCallback(
    (status: "success" | "reverted", explorerUrl: string) => {
      if (status === "success") {
        toast({
          text: (
            <div
              onClick={() => {
                openUrl(explorerUrl);
              }}
            >
              <Trans i18nKey={"trans-receive-tx-success"}>
                Transaction successful, click
                <span
                  style={{
                    color: "var(--Kontos-Blue, #413dec)",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  here{" "}
                </span>
                to view details
              </Trans>
            </div>
          ),
          type: "success",
        });
        return;
      }

      if (status === "reverted") {
        toast({
          text: (
            <Trans i18nKey={"trans-receive-tx-fail"}>
              Transaction failed, click
              <span
                style={{
                  color: "var(--Kontos-Blue, #413dec)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openUrl(explorerUrl);
                }}
              >
                {" "}
                here{" "}
              </span>
              to view details
            </Trans>
          ),
          type: "error",
        });
        return;
      }
    },
    []
  );

  useWatchDefaultPendingTx(toastForTxResult);
  useEffect(() => {
    const unwatch = watchChainId(wagmiConfig, {
      async onChange(chainId) {
        if (receiveStore.freezeWatchingChainId) {
          return;
        }

        if (step === "network") {
          return;
        }

        if (
          receiveStore.chain &&
          chainId === parseInt(receiveStore.chain?.chainIndex)
        ) {
          return;
        }

        try {
          receiveStore.setChain(undefined);
          const matched = await receiveStore.setChainByChainIndex(
            chainId.toString()
          );
          if (!matched) {
            throw new Error("Fail");
          }
        } catch (e) {
          console.warn(e);
          toast({
            text: t("Chain has been reset in connected wallet"),
          });
          setStep("network");
        }
      },
    });

    return () => {
      unwatch();
    };
  }, [receiveStore, step, t]);

  return (
    <>
      {step === "network" && (
        <ReceiveNetwork
          fromReceiveMain={fromReceiveMain}
          onNext={() => setStep("main")}
        />
      )}

      {step === "main" && (
        <ReceiveMain
          onGoNetworkSelect={() => {
            setFromReceiveMain(true);
            setStep("network");
          }}
          onBack={() => {
            setFromReceiveMain(false);
            setStep("network");
          }}
          onBackHome={() => {
            navigate(ROUTE_HOME);
          }}
        />
      )}
    </>
  );
});

export default Receive;
