import { useTranslation } from "react-i18next";
import styled from "styled-components";
import successIcon from "src/assets/icons/events/eb/copy-success.svg";
import { fontBold } from "@/style/style.global";

const Conatiner = styled.div<{ $showSuccess?: boolean }>`
  padding: 16px;

  border-radius: 99px;
  background: ${(props) =>
    props.$showSuccess
      ? "linear-gradient(88deg, rgba(65, 61, 236, 0.5), rgba(123, 231, 255, 0.5))"
      : "linear-gradient(88deg, rgba(65, 61, 236, 1), rgba(123, 231, 255, 1))"};

  color: var(--White, #fff);
  text-align: center;
  ${fontBold};
  font-size: 18px;
  line-height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${(props) => (props.$showSuccess ? "pointer" : "pointer")};

  &:active {
    opacity: ${(props) => (props.$showSuccess ? "1" : "0.9")};
  }

  &:hover {
    opacity: ${(props) => (props.$showSuccess ? "1" : "0.9")};
  }
`;

const SuccessIcon = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
`;

interface Props {
  onClick: () => void;
  className?: string;
  showSuccess: boolean;
}

const EbShareButton: React.FC<Props> = ({
  className,
  onClick,
  showSuccess,
}) => {
  const { t } = useTranslation();

  return (
    <Conatiner
      className={className}
      $showSuccess={showSuccess}
      onClick={onClick}
    >
      {showSuccess ? (
        <>
          <SuccessIcon src={successIcon} />
          {t("Link has been copied!")}
        </>
      ) : (
        t("Get Boosted!")
      )}
    </Conatiner>
  );
};

export default EbShareButton;
