import styled from "styled-components";
import { TokenOrChainIcon } from "./TokenOrChainIcon";
import { DEFAULT_ASSET_ICON_OMIT } from "@/config";
import assetOmitIcon from "src/assets/icons/asset-omit.svg";
import { useMemo } from "react";

const Container = styled.div<{ $height: number }>`
  height: ${(props) => props.$height}px;
`;

const StyledTokenOrChainIcon = styled(TokenOrChainIcon)<{
  $index: number;
  $overlapWidthPx: string;
}>`
  z-index: ${(props) => props.$index};
  &:not(:first-child) {
    margin-left: -${(props) => props.$overlapWidthPx};
  }
`;

interface IProps {
  iconList: string[];
  size: number;
  overlapWidthPx: string;
  className?: string;
  style?: React.CSSProperties;
}

export const AssetIconGroup: React.FC<IProps> = ({
  iconList,
  size,
  overlapWidthPx,
  className,
  style,
}) => {
  const realIconList = useMemo(() => {
    const list = iconList.slice(0, DEFAULT_ASSET_ICON_OMIT);
    if (iconList.length > DEFAULT_ASSET_ICON_OMIT) {
      list.push(assetOmitIcon);
    }
    return list;
  }, [iconList]);

  return (
    <Container className={className} style={style} $height={size}>
      {realIconList.map((icon, index) => (
        <StyledTokenOrChainIcon
          key={index}
          $index={index}
          $overlapWidthPx={overlapWidthPx}
          size={size}
          type="token"
          src={icon}
        />
      ))}
    </Container>
  );
};
