import { callFtAsset, RespFtAsset } from "@/apis/asset-apis";
import { FtAsset } from "@/apis/types";

/**
 *
 * @param account Kontos account name, etc 'alex' | 'alex.os'
 * @returns user's balances
 */
export const fetchAccountBalances = async (
  account: string
): Promise<FtAsset[]> => {
  const { ftAssets } = await callFtAsset({
    chainIndex: "",
    regex: "",
    isRecommended: false,
    account,
    isWhitelist: false,
    displayOnlyOwnedItems: true,
    offset: 0,
    limit: 0,
  });
  return Array.isArray(ftAssets) ? ftAssets : [];
};

export const fetchRecommendedFtAssets = async (
  offset: number,
  limit: number
): Promise<RespFtAsset> => {
  return await callFtAsset({
    isRecommended: true,
    offset,
    limit,
  });
};

export const fetchFtAssetsByChain = async (
  chainIndex: string,
  offset: number,
  limit: number
): Promise<RespFtAsset> => {
  return await callFtAsset({
    chainIndex,
    offset,
    limit,
  });
};
