import Header from "src/components/common/header";
import styled from "styled-components";
import SettingsItem from "src/components/settings/SettingsItem";
// import paymentIcon from "src/assets/icons/settings/payment.svg";
// import helperIcon from "src/assets/icons/settings/trade-helper.svg";
import { useTranslation } from "react-i18next";
// import { openUrl } from "src/utils/helper";
import aiScoreIcon from "src/assets/icons/settings/ai-score.svg";
import { useStores } from "src/hooks/useStore";
import { observer } from "mobx-react";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;

  padding: 0 16px 76px 16px;
`;

const Scrollable = styled.div`
  margin-top: 8px;

  flex: 1;
  overflow: auto;
`;

interface IProps {
  onBack: () => void;
}

export const TradeSetting: React.FC<IProps> = observer(({ onBack }) => {
  const { t } = useTranslation();
  const { tradeStore } = useStores();
  const itemList = [
    // {
    //   title: t("Manage Payment") as string,
    //   icon: paymentIcon,
    //   onClick: () => {
    //     navigate(ROUTE_SETTINGS_PAYMENT);
    //   },
    // },
    {
      title: t("AI Score") as string,
      icon: aiScoreIcon,
      isOn: !tradeStore.disableAiScore,
      onToggle: () => {
        tradeStore.toggleDisableAiScore();
      },
    },
    // {
    //   title: t("Help center") as string,
    //   icon: helperIcon,
    //   onClick: () => {
    //     openUrl(
    //       "https://docs.kontos.io/product-handbook/introducing-the-kontos-web-app"
    //     );
    //   },
    // },
  ];

  return (
    <Container>
      <Header
        callBack={onBack}
        enableBack={true}
        title={t("Trade Setting")}
        padding="8px 4px"
        rightBtnText={t("Done")}
        rightBtnCallBack={onBack}
      />
      <Scrollable>
        {itemList.map((item) => (
          <SettingsItem
            key={item.title}
            mainIcon={item.icon}
            title={item.title}
            isOn={item.isOn}
            // onClick={item.onClick}
            onToggle={item.onToggle}
          />
        ))}
      </Scrollable>
    </Container>
  );
});
