import { makeAutoObservable } from "mobx";
import { UserStore } from "./UserStore";
import { UiStore } from "./UiStore";
import { ReceiveStore } from "./ReceiveStore";
import { EbStore } from "./events/EbStore";
import { DappConnectStore } from "./DappConnectStore";
import { SheetStore } from "./SheetStore";
import { DiscoverStore } from "./DiscoverStore";
import { MarketStore } from "./MarketStore";
import { FavStore } from "./FavStore";
import { ChainStore } from "./data/ChainStore";
import { TradeStore } from "./trade/TradeStore";
import { TipStore } from "./TipStore";
import { GlobalStore } from "./GlobalStore";
import { BuyStore } from "./trade/BuyStore";
import { SellStore } from "./trade/SellStore";

export class RootStore {
  chainStore: ChainStore;
  userStore: UserStore;
  receiveStore: ReceiveStore;
  uiStore: UiStore;
  ebStore: EbStore;
  discoverStore: DiscoverStore;
  dappConnectStore: DappConnectStore;
  sheetStore: SheetStore;
  marketStore: MarketStore;
  favStore: FavStore;
  tradeStore: TradeStore;
  tipStore: TipStore;
  globalStore: GlobalStore;
  buyStore: BuyStore;
  sellStore: SellStore;

  constructor() {
    this.chainStore = new ChainStore(this);
    this.sheetStore = new SheetStore(this);
    this.userStore = new UserStore(this);
    this.receiveStore = new ReceiveStore(this);
    this.uiStore = new UiStore(this);
    this.ebStore = new EbStore(this);
    this.discoverStore = new DiscoverStore(this);
    this.dappConnectStore = new DappConnectStore(this);
    this.marketStore = new MarketStore(this);
    this.favStore = new FavStore(this);
    this.tradeStore = new TradeStore(this);
    this.tipStore = new TipStore(this);
    this.globalStore = new GlobalStore(this);
    this.buyStore = new BuyStore(this);
    this.sellStore = new SellStore(this);
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
