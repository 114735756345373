import styled from "styled-components";
import CSS from "csstype";

const Line = styled.div<{ $height: string }>`
  width: 100%;
  height: ${(props) =>
    props.style?.height ? props.style.height : props.$height};
  background-color: #edeef1;
`;

interface LineProps {
  height?: string;
  style?: CSS.Properties;
  className?: string;
}

const HorizontalLine = ({ height = "1px", style, className }: LineProps) => {
  return <Line className={className} style={style} $height={height} />;
};

export default HorizontalLine;
