import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { DeepButton } from "src/components/button/DeepButton";
import lightningIco from "src/assets/icons/events/eb/blue-lightning.svg";
import fireworksBgImg from "src/assets/images/event/eb/fireworks-bg.png";
import { BASIC_SHEET_INDEX } from "src/config";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  background-image: url(${fireworksBgImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 1100%;

  background-color: var(--White, #fff);
  max-width: 343px;
  border-radius: 16px;
  margin: 16px;
  padding: 24px 32px 20px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 18px;
    line-height: 22px;
  }

  > .value {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    color: var(--Kontos-Blue, #413dec);
    ${fontBold};
    font-size: 32px;

    > img {
      margin-left: 4px;
      width: 24px;
      height: 42px;
    }
  }

  > .content {
    margin-top: 7px;
    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 14px;
    font-weight: 400;
  }

  > .ok-btn {
    margin-top: 16px;
  }
`;

interface Props {
  type: "self" | "inviter";
  energyValue?: string;
  onClose: (open: boolean) => void;
}

export const EbHelpDonePopup: React.FC<Props> = ({
  type,
  energyValue = "?",
  onClose,
}) => {
  const { t } = useTranslation();
  const title = type === "self" ? t("Congratulations!") : t("Great job!");
  const content =
    type === "self" ? (
      <Trans i18nKey={"trans-eb-boost-inviter"}>
        You have gained {{ energyValue }} energy points by helping other users
        recharge.
      </Trans>
    ) : (
      <Trans i18nKey={"trans-eb-boost-self"}>
        You've been recharged with {{ energyValue }} energy points for the user.
      </Trans>
    );

  return (
    <Overlay zIndex={BASIC_SHEET_INDEX} outerOpacity={0.5}>
      <Container>
        <div className="title">{title}</div>
        <div className="value">
          +{energyValue}
          <img src={lightningIco} alt="" />
        </div>
        <div className="content">{content}</div>
        <DeepButton className="ok-btn" onClick={() => onClose(false)}>
          {t("OK")}
        </DeepButton>
      </Container>
    </Overlay>
  );
};
