import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useMemo, useRef, useState } from "react";
import React from "react";
import { KontosButton } from "src/components/button/KontosButton";
import Modal from "src/components/modal/Modal";
import { useConnect } from "wagmi";
import { injected } from "wagmi/connectors";
import { wagmiConfig, walletConnectConnector } from "src/configs/wagmi";
import walletConnectIcon from "src/assets/images/wallet/wallet-connect.png";
import injectedIcon from "src/assets/images/wallet/injected.svg";
import { StatusModal } from "src/components/modal/StatusModal";
import bybitIcon from "src/assets/images/wallet/bybit-wallet.svg";
import recommendIcon from "src/assets/icons/receive/recommend.svg";
import arrowIcon from "src/assets/icons/receive/arrow.svg";
import toast from "src/components/toast/Toast";
import { CONNECTOR_BYBIT } from "src/config";
import { fontBold, fontH8 } from "@/style/style.global";

const Container = styled.div`
  width: 343px;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.span`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontBold};
  font-size: 18px;
  line-height: 22px;

  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0;

  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

const ConnectButton = styled.button`
  position: relative;
  user-select: none;
  padding: 0 16px;
  height: 56px;
  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  color: var(--Deep-800, #1a2535);

  display: flex;
  align-items: center;
  gap: 8px;

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      ${ArrowIcon} {
        opacity: 1;
      }
    }
  }
  &:active {
    border-color: var(--Kontos-Blue, #413dec);
    ${ArrowIcon} {
      opacity: 1;
    }
  }
`;

const ConnectIcon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 8px;
`;

const ConnectText = styled.span`
  color: var(--Deep-800, #1a2535);
  /* H6 */
  font-family: "HarmonyOS Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

const RecommendMark = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;

  border-radius: 0px 8px;
  background: linear-gradient(94deg, #ff1e2b 1.91%, #ffa979 101.43%);

  display: flex;
  padding: 2px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const RecommendIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const RecommendText = styled.span`
  color: #fff;
  text-align: center;
  /* H8 */
  ${fontH8}
`;

const GotItButton = styled(KontosButton)`
  background: rgba(65, 61, 236, 0.1);
  color: var(--Kontos-Blue, #413dec);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
`;

interface IProps {
  chainId?: string;
  onSuccess: () => void;
  onClose: () => void;
}

const ConnectModal: React.FC<IProps> = ({ chainId, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [pendingText, setPendingText] = useState<string | undefined>(undefined);
  const { connectAsync, isPending } = useConnect({
    config: {
      ...wagmiConfig,
      state: {
        ...wagmiConfig.state,
        chainId: chainId ? parseInt(chainId) : wagmiConfig.state.chainId,
      },
    },
  });

  const connectInjectedWallet = useCallback(async () => {
    if (typeof window.ethereum === "undefined") {
      toast({
        text: t("No injected wallet detected"),
        type: "warning",
      });
      return;
    }

    try {
      setPendingText(t("Please confirm in wallet"));
      await connectAsync({ connector: injected() });
      onSuccess();
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : t("Failed to connect");
      console.warn(errorMessage);
    }
  }, [connectAsync, onSuccess, t]);

  const connectToBybitWallet = useCallback(async () => {
    if (typeof window.bybitWallet === "undefined") {
      toast({
        text: (
          <Trans i18nKey={"trans-bybit-connect-hint"}>
            Bybit wallet not detected. Please allow it to access this website,
            or visit{" "}
            <a
              href="https://chrome.google.com/webstore/category/extensions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome Web Store
            </a>{" "}
            and search for Bybit Wallet to install.
          </Trans>
        ),
        type: "info",
      });
      return;
    }

    try {
      setPendingText(t("Please confirm in wallet"));
      await connectAsync({
        connector: injected({
          target: {
            id: CONNECTOR_BYBIT,
            name: CONNECTOR_BYBIT,
            provider(window) {
              // @ts-ignore
              return window?.bybitWallet;
            },
          },
        }),
      });
      onSuccess();
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : t("Failed to connect");
      console.warn(errorMessage);
    }
  }, [connectAsync, onSuccess, t]);

  const connectViaWalletConnect = useCallback(async () => {
    try {
      setPendingText(t("Please confirm in WalletConnect"));
      await connectAsync({ connector: walletConnectConnector });
      onSuccess();
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : t("Failed to connect");
      console.warn(errorMessage);
      // toast({
      //   text: errorMessage,
      //   type: "error",
      // });
    }
  }, [connectAsync, onSuccess, t]);

  const connectItems = useMemo(() => {
    return [
      {
        id: "injected",
        icon: injectedIcon,
        label: "Injected",
        callback: connectInjectedWallet,
        isRecommend: false,
      },
      {
        id: CONNECTOR_BYBIT,
        icon: bybitIcon,
        label: "Bybit Wallet",
        callback: connectToBybitWallet,
        isRecommend: true,
      },
      {
        id: "wallet_connect",
        icon: walletConnectIcon,
        label: "Wallet Connect",
        callback: connectViaWalletConnect,
        isRecommend: false,
      },
    ];
  }, [connectInjectedWallet, connectToBybitWallet, connectViaWalletConnect]);

  return (
    <Modal onClose={onClose}>
      <Container ref={wrapperRef}>
        <Title>{t("Connect Your Wallet")}</Title>

        {isPending ? (
          <StatusModal
            status="pending"
            desc={pendingText}
            buttons={[
              <GotItButton onClick={onClose}>{t("Got it")}</GotItButton>,
            ]}
          />
        ) : (
          <ButtonWrapper>
            {connectItems.map((item) => (
              <ConnectButton key={item.id} onClick={item.callback}>
                <ConnectIcon src={item.icon} width={28} height={28} />
                <ConnectText>{item.label}</ConnectText>
                <ArrowIcon src={arrowIcon} alt="" />
                {item.isRecommend && (
                  <RecommendMark>
                    <RecommendIcon src={recommendIcon} alt="" />
                    <RecommendText>{t("Recommend")}</RecommendText>
                  </RecommendMark>
                )}
              </ConnectButton>
            ))}
          </ButtonWrapper>
        )}
      </Container>
    </Modal>
  );
};

export default ConnectModal;
