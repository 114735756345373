import { observer } from "mobx-react";
import styled from "styled-components";
import React, { useEffect } from "react";
import recover from "src/assets/images/recover.svg";
import arrowWhite from "src/assets/images/arrow-white.svg";
import arrowGrey from "src/assets/images/arrow-grey.svg";
import HorizontalLine from "src/components/line/HorizontalLine";
import { Button } from "src/components/button/Button";
import { t } from "i18next";
import { ButtonCancel } from "src/components/button/ButtonCancel";
import { statusRouterMap } from "src/components/start/routerMap";
import { useNavigate } from "react-router-dom";
import { ROUTE_RECOVER_ACCOUNT_OLD } from "src/router/router-config";
import { isSafari } from "src/utils/helper";
import { fontBold, fontH5, MainTextText } from "@/style/style.global";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${isSafari() ? "75px" : 0};

  .main {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > div {
      width: 100%;
    }

    .description {
      color: var(--Deep-800, #1a2535);
      text-align: center;

      /* Main text */
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */

      span {
        color: var(--Kontos-Blue, #413dec);
        ${fontBold};
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
      }
    }

    .kontosAvatarGoogle,
    .recover {
      width: 200px;
      height: 86px;
      flex-shrink: 0;
      margin-bottom: 30px;
    }

    .lineWrap {
      padding: 16px 21px;
      width: 100%;
      box-sizing: border-box;
    }

    .inputWrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: flex-start;
      align-items: center;

      .securityEmail,
      .setUpGuardian {
        box-sizing: border-box;
        display: inline-flex;
        height: 56px;
        padding: 17px 20px 18px 30px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 99px;
        border: 1px solid var(--Deep-50, #edeef1);
        background: var(--Deep-25, #f5f5f6);
        margin-bottom: 12px;
        width: 100%;
        color: var(--Deep-800, #1a2535);

        /* H5 */
        ${fontH5}

        .title {
          color: var(--Deep-800, #1a2535);

          /* H5 */
          ${fontH5}
          margin-right: 8px;
        }

        .text {
          color: var(--Deep-400, #80868f);

          /* Smaller */
          font-family: HarmonyOS Sans SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 25px;
        }

        img {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }

      .securityEmailActive,
      .setUpGuardianActive {
        width: 100%;
        position: relative;

        .arrow {
          width: 14px;
          height: auto;
          right: 30px;
          top: 20px;
          position: absolute;
        }
      }

      .securityEmailActive {
        margin-bottom: 12px;
      }
    }
  }
  .buttonWrap {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      cursor: pointer;
      color: var(--Kontos-Blue, #413dec);
      text-align: center;
      ${MainTextText}
    }
  }
`;

type IProps = {
  userEmailArr: string[];
  guardianArr: string[];
  checkKeyAndGoToRecoverPage: (route: string) => void;
};

const SecuritySettingsRecover: React.FC<IProps> = ({
  userEmailArr,
  guardianArr,
  checkKeyAndGoToRecoverPage,
}) => {
  const navigate = useNavigate();

  // If there's only one option for user to select
  // Execute it straightly
  useEffect(() => {
    if (userEmailArr.length <= 0 && guardianArr.length > 0) {
      checkKeyAndGoToRecoverPage(statusRouterMap.waitingAuthorizeKontos);
      return;
    }

    if (userEmailArr.length > 0 && guardianArr.length <= 0) {
      checkKeyAndGoToRecoverPage(statusRouterMap.waitingAuthorizeMail);
      return;
    }

    if (userEmailArr.length <= 0 && guardianArr.length <= 0) {
      navigate(ROUTE_RECOVER_ACCOUNT_OLD);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <div className={"main"}>
        <img className={"recover"} src={recover} alt="recover" />
        <div className={"inputWrap"}>
          <div className={"securityEmailActive"}>
            <Button
              onClick={() => {
                checkKeyAndGoToRecoverPage(
                  statusRouterMap.waitingAuthorizeMail
                );
              }}
              text={t("Security Email Recovery")}
              height={"56px"}
              disabled={userEmailArr.length <= 0}
            />
            <img
              className={"arrow"}
              src={userEmailArr.length > 0 ? arrowWhite : arrowGrey}
              alt=""
            />
          </div>

          <div className={"lineWrap"}>
            <HorizontalLine />
          </div>

          <div className={"setUpGuardianActive"}>
            <ButtonCancel
              onClick={() => {
                checkKeyAndGoToRecoverPage(
                  statusRouterMap.waitingAuthorizeKontos
                );
              }}
              text={t("Guardians Recovery")}
              height={"56px"}
              disabled={guardianArr.length <= 0}
            />
            <img
              className={"arrow"}
              src={guardianArr.length > 0 ? arrowWhite : arrowGrey}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className={"buttonWrap"}>
        {userEmailArr.length <= 0 && guardianArr.length <= 0 && (
          <span
            onClick={() => {
              navigate(ROUTE_RECOVER_ACCOUNT_OLD);
            }}
          >
            {t("Can't recover the account?")}
          </span>
        )}
      </div>
    </Wrapper>
  );
};

export default observer(SecuritySettingsRecover);
