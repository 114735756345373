import styled from "styled-components";
import triangleTips from "src/assets/icons/triangle-tips.svg";
import tipsIcon from "src/assets/icons/tips.svg";
import { t } from "i18next";
import { Trans } from "react-i18next";
import React, { useEffect, useRef } from "react";
import { fontBold } from "@/style/style.global";

type TipsProps = {
  className?: string;
  triangleLeft?: string;
  triangleRight?: string;
  triangleTop?: string;
  triangleBottom?: string;
  contentStyle?: React.CSSProperties;
  triggerElement: React.ReactNode;
  wrapperStyle?: React.CSSProperties;
  showTips?: boolean;
  showTitle?: boolean;
  setShowTips?: (showTips: boolean) => void;
  tipsContent?: React.ReactNode;
  rotate?: boolean;
};

type DynamicComponentProps = {
  wrapperStyle: React.CSSProperties;
};

const TipsWrapper = styled.div<DynamicComponentProps>`
  ${(props) => props.wrapperStyle && { ...props.wrapperStyle }};
  position: relative;

  .tips {
    position: absolute;
    z-index: 99999;

    .main {
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: 1px solid var(--Deep-50, #edeef1);
      background: var(--White, #fff);
      padding: 14px 10px 14px 56px;
      position: relative;
      display: block;

      .triangleIco {
        width: 24px;
        height: 12px;
        position: absolute;
      }

      .rotate {
        transform: rotate(180deg);
      }

      .tipsIco {
        width: 28px;
        height: 16px;
        position: absolute;
        left: 16px;
        top: 14px;
      }

      .title {
        color: var(--Deep-800, #1a2535);
        ${fontBold};
        font-size: 14px;
        font-style: normal;
        line-height: 130%;
        text-align: left;
      }
    }
  }
`;

const Tips = ({
  className,
  triggerElement,
  wrapperStyle = {},
  contentStyle = {
    left: "0px",
    top: "0px",
  },
  showTips = true,
  showTitle = true,
  triangleLeft = "30px",
  triangleRight = "30px",
  triangleTop = "-12px",
  triangleBottom = "-12px",
  tipsContent,
  rotate = false,
  setShowTips,
}: TipsProps) => {
  useEffect(() => {
    const pageClickEvent = (e: MouseEvent) => {
      setShowTips && setShowTips(false);
    };

    window.addEventListener("click", pageClickEvent);

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showTips]);

  return (
    <TipsWrapper wrapperStyle={wrapperStyle} className={className}>
      {triggerElement}
      {showTips && (
        <div className={"tips"} style={contentStyle}>
          <div className={"main"}>
            {!rotate && (
              <img
                style={{ left: triangleLeft, top: triangleTop }}
                className="triangleIco"
                src={triangleTips}
                alt=""
              />
            )}

            <img className="tipsIco" src={tipsIcon} alt="" />
            {showTitle && <div className="title">{t("Tips")} </div>}
            {tipsContent}
            {rotate && (
              <img
                style={{ right: triangleRight, bottom: triangleBottom }}
                className="triangleIco rotate"
                src={triangleTips}
                alt=""
              />
            )}
          </div>
        </div>
      )}
    </TipsWrapper>
  );
};

export default Tips;
