import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import Tips from "src/components/tips/Tips";
import {
  TipsReferral,
  TipsTextCreate,
  TipsTextRecover,
} from "src/components/tips/TipsText";
import { useSearchParams } from "react-router-dom";
import { statusRouterMap } from "src/components/start/routerMap";
import { fontBold, fontH6 } from "@/style/style.global";

const Wrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: var(--Kontos-Blue, #413dec);
  text-align: right;
  z-index: 9999;

  /* H6 */
  ${fontH6}
  cursor: pointer;
`;

type IProps = {
  type: string;
  kontosName: string;
};

const defaultDisplayTipsPageArr = [
  statusRouterMap.securitySettingsRecover,
  statusRouterMap.waitingAuthorizeKontos,
  statusRouterMap.waitingAuthorizeMail,
];

const HeaderRightComponent: React.FC<IProps> = ({ type, kontosName }) => {
  const [searchParams] = useSearchParams();
  const [showTips, setShowTips] = useState(false);
  useEffect(() => {
    const routeStatus = searchParams.get("type");
    setShowTips(defaultDisplayTipsPageArr.includes(routeStatus || ""));
  }, [searchParams]);

  const tipsContent = useMemo(() => {
    if (type === "create") {
      return <TipsTextCreate />;
    } else if (type === "referral") {
      return <TipsReferral />;
    } else {
      return <TipsTextRecover kontosName={kontosName} />;
    }
  }, [type]);

  return (
    <Wrapper>
      <Tips
        wrapperStyle={{ width: "320px" }}
        contentStyle={{
          right: "-28px",
          top: "48px",
          padding: "0px 8px",
          width: "100%",
        }}
        triangleLeft={"245px"}
        showTips={showTips}
        setShowTips={setShowTips}
        tipsContent={tipsContent}
        triggerElement={
          <span
            onMouseOver={() => {
              setShowTips(true);
            }}
            onMouseOut={() => {
              setShowTips(false);
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowTips(!showTips);
            }}
          >
            {t("Help?")}
          </span>
        }
      />
    </Wrapper>
  );
};

export default HeaderRightComponent;
