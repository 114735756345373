import { fontH7, fontSmaller } from "@/style/style.global";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import addIcon from "src/assets/icons/add4.svg";
import addActiveIcon from "src/assets/icons/add4-active.svg";

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px dashed var(--Deep-100, #cccfd2);
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
    }
  }
  &:active {
    border-color: var(--Kontos-Blue, #413dec);
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

const Title = styled.span`
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  ${fontH7}
`;

const Desc = styled.span`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-overflow: ellipsis;
  ${fontSmaller}
`;

const RightIcon = styled.img`
  width: 24px;
  height: 24px;
`;

interface IProps {
  onClick: () => void;
}

export const CustomizeButton: React.FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState<boolean>(false);
  const icon = {
    normal: addIcon,
    active: addActiveIcon,
  };

  return (
    <Button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Left>
        <Title>{t("Customize Payment")}</Title>
        <Desc>{t("Click here to add your payment plan.")}</Desc>
      </Left>
      <RightIcon src={hover ? icon.active : icon.normal} alt="" />
    </Button>
  );
};
