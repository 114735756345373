import { NavValue } from "@/pages/guardian/Guardian";
import { fontBold } from "@/style/style.global";
import React from "react";
import styled from "styled-components";

const TabEntityStyle = styled.div`
  margin: 0 16px;
  box-sizing: border-box;
  height: 41px;
  flex-shrink: 0;
  border-radius: 99px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--Deep-25, #f5f5f6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    color: var(--Deep-800, #1a2535);
    text-align: center;
    font-family: HarmonyOS Sans SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  .active {
    border-radius: 99px;
    border: 1px solid var(--Deep-50, #edeef1);
    background: var(--Deep-800, #1a2535);
    color: var(--White, #fff);
    text-align: center;
    ${fontBold};
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    min-width: 176px;
  }
  span {
    width: 39px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 99px;
    background: var(--error-notice, #ff1e2b);
    display: inline-block;
    //position: absolute;
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface KontosSearchProps {
  items: { value: NavValue; text: string }[];
  selectedVal: { value: NavValue; text: string };
  waitingActionsLengthArr: number[];
  setSelectedVal: (pram: { value: NavValue; text: string }) => void;
}

const TabEntity: React.FC<KontosSearchProps> = ({
  items,
  selectedVal,
  waitingActionsLengthArr,
  setSelectedVal,
}) => {
  return (
    <TabEntityStyle>
      {items.map((item: { value: NavValue; text: string }, index) => (
        <div
          key={index.toString()}
          className={`item ${selectedVal.value === item.value && "active"}`}
          onClick={() => {
            setSelectedVal(item);
          }}
        >
          {item.text}
          {selectedVal === item && waitingActionsLengthArr[index] > 0 && (
            <span>{waitingActionsLengthArr[index]}</span>
          )}
        </div>
      ))}
    </TabEntityStyle>
  );
};

export default TabEntity;
