import { ButtonHTMLAttributes, ReactNode } from "react";
import BaseButton from "./BaseButton";
import styled from "styled-components";
import { fontH7 } from "@/style/style.global";
import loadingGif from "@/assets/images/button/loading.gif";

const StyledBaseButton = styled(BaseButton)`
  position: relative;
  color: var(--Deep-400, #80868f);
  background: var(--Deep-50, #edeef1);
  ${fontH7}
`;

interface IProps<T> extends ButtonHTMLAttributes<HTMLButtonElement> {
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  width?: string | number;
  height?: string | number;
  radius?: string | number;
  extraProps?: T;
}

const SecondaryButton = <T,>({
  style,
  className,
  loading,
  disabled,
  children,
  width,
  height,
  radius,
  extraProps,
  ...props
}: IProps<T>) => {
  return (
    <StyledBaseButton
      style={style}
      className={className}
      $loading={loading}
      disabled={disabled || loading}
      $width={width}
      $height={height}
      $radius={radius}
      $hoverColor="var(--Black, #000)"
      $hoverBgColor="var(--Black, #000)"
      $hoverOpacity={0.02}
      $activeColor="var(--Black, #000)"
      $activeBgColor="var(--Black, #000)"
      $activeOpacity={0.05}
      $disabledColor="var(--Deep-100, #CCCFD2)"
      $disabledBgColor="var(--Deep-25, #F5F5F6)"
      $loadingColor="var(--Deep-100, #CCCFD2)"
      $loadingBgColor="var(--Deep-25, #F5F5F6)"
      {...(extraProps as T)}
      {...props}
    >
      {loading ? (
        <img src={loadingGif} width={50} height={18} alt="loading" />
      ) : (
        children
      )}
    </StyledBaseButton>
  );
};

export default SecondaryButton;
