import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { observer } from "mobx-react";
import tableHeaderLeftBgImg from "src/assets/images/event/eb/leaderboard-table-header-left.png";
import tableHeaderFlexBgImg from "src/assets/images/event/eb/leaderboard-table-header-flex.png";
import tableHeaderRightBgImg from "src/assets/images/event/eb/leaderboard-table-header-right.png";
import tableFooterBgLeftImg from "src/assets/images/event/eb/leaderboard-table-footer-left.png";
import tableFooterBgFlexImg from "src/assets/images/event/eb/leaderboard-table-footer-flex.png";
import tableFooterBgRightImg from "src/assets/images/event/eb/leaderboard-table-footer-right.png";
import {
  EB_HEADER_HEIGHT,
  EB_HEADER_MARGIN,
} from "src/components/common/header/EventHeader";
import EbLeaderBoardTable from "./EbLeaderBoardTable";
import { useStores } from "src/hooks/useStore";
import { useCallback, useEffect, useState } from "react";
import maskImg from "src/assets/images/event/eb/leaderboard-mask.png";
import EnergyBoostingTicker from "../EnergyBoostingTicker";
import { EbLeaderBoardCount } from "./EbLeaderBoardCount";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ $isOverWidth?: boolean; $innerHeight: number }>`
  width: 100%;
  height: ${(props) =>
    `calc(${
      props.$isOverWidth ? "100%" : `${props.$innerHeight}px`
    } - ${EB_HEADER_HEIGHT} - ${EB_HEADER_MARGIN})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`;

const Title = styled.div`
  height: 28px;
  line-height: 28px;
  ${fontBold};
  font-size: 24px;
  background: linear-gradient(
    93deg,
    #b4ffbb 0.53%,
    #31e4e2 26.44%,
    #a088ff 74.61%,
    #e28fff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 2;
`;

const TableContainer = styled.div`
  position: relative;
  flex: 1;
  z-index: 2;
  width: calc(100% - 32px);
  margin: 16px 16px 22px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  &:after {
    pointer-events: none;
    content: "";
    position: absolute;
    bottom: 16px;
    left: 7px;
    width: calc(100% - 14px);
    height: 180px;
    background-image: url(${maskImg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
`;

const TableHeaderLeft = styled.div`
  width: 207px;
  height: 49px;
  background-image: url(${tableHeaderLeftBgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const TableHeaderFlex = styled.div`
  flex: 1;
  height: 49px;
  background-image: url(${tableHeaderFlexBgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const TableHeaderRight = styled.div`
  width: 58px;
  height: 49px;
  background-image: url(${tableHeaderRightBgImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const TableBodyContainer = styled.div`
  width: 100%;
  flex: 1;
  border-right: 1px solid var(--Deep-800, #1a2535);
  border-left: 1px solid var(--Deep-800, #1a2535);
  padding: 0 7px;
  overflow: hidden;
`;

const TableBody = styled.div`
  height: 100%;
  width: 100%;
  border-right: 1px solid var(--Deep-700, #333d4c);
  border-left: 1px solid var(--Deep-700, #333d4c);
  background: var(--Deep-800, #1a2535);
  overflow: hidden;
`;

const TableFooter = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  /* padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom); */
`;

const TableFooterLeft = styled.div`
  width: 45px;
  height: 50px;
  background-image: url(${tableFooterBgLeftImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const TableFooterFlex = styled.div`
  flex: 1;
  height: 50px;
  background-image: url(${tableFooterBgFlexImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const TableFooterRight = styled.div`
  width: 135px;
  height: 50px;
  background-image: url(${tableFooterBgRightImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

export const EbLeaderBoard: React.FC = observer(() => {
  const { t } = useTranslation();
  const { ebStore, uiStore } = useStores();
  const [count, setCount] = useState<number | undefined>();
  const [innerHeight, setInnerHeight] = useState(window.innerWidth);

  const setCountByCondition = useCallback(
    (value?: number) => {
      if (!count) setCount(value);
    },
    [count]
  );

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container $isOverWidth={uiStore.isOverWidth} $innerHeight={innerHeight}>
      <Title>{t("LEADERBOARD")}</Title>

      <EbLeaderBoardCount count={count} />

      <EnergyBoostingTicker
        style={{ marginTop: "16px", marginBottom: "0" }}
        notifications={ebStore.ebNotifications}
        darkMode
      />

      <TableContainer>
        {/* Header */}
        <TableHeader>
          <TableHeaderLeft />
          <TableHeaderFlex />
          <TableHeaderRight />
        </TableHeader>

        {/* Body */}
        <TableBodyContainer>
          <TableBody>
            <EbLeaderBoardTable setCount={setCountByCondition} />
          </TableBody>
        </TableBodyContainer>

        {/* Footer */}
        <TableFooter>
          <TableFooterLeft />
          <TableFooterFlex />
          <TableFooterRight />
        </TableFooter>
      </TableContainer>
    </Container>
  );
});
