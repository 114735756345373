import styled from "styled-components";
import TipsSvg from "src/assets/icons/trade/tip.svg";
import { t } from "i18next";
import { KontosButton } from "src/components/button/KontosButton";
import AddressInput from "src/components/input/AddressInput";
import { useState } from "react";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { ethers } from "ethers";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import AddressInputSearch from "src/components/input-search/AddressInputSearch";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .top-12 {
    margin-top: 12px;
  }
`;

const Title = styled.div`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontBold};
  font-size: 24px;
`;

const AddressInputContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 13px;
`;

interface ReceiveAddressModalProps {
  rawName: string;
  rawAddress: string;
  onConfirm: (address: string) => void;
  onCancel: () => void;
}

const SearchAccountModal: React.FC<ReceiveAddressModalProps> = ({
  rawName,
  rawAddress,
  onConfirm,
  onCancel,
}) => {
  const [isAddressInputFocused, setIsAddressInputFocused] =
    useState<boolean>(false);
  const [receiver, setReceiver] = useState<string>();
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [address, setAddress] = useState(rawAddress);

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <SheetUpHeader
          headStyle={{ justifyContent: "center", width: "100%" }}
          title={t("Search Account")}
          handleBack={() => {
            onCancel();
          }}
          padding="8px"
          rightText={t("Done")}
          rightClickFunc={() => {
            onCancel();
          }}
        />
      </div>
      <AddressInputContainer>
        <AddressInputSearch
          onFocus={() => {
            setIsAddressInputFocused(true);
          }}
          onBlur={() => setIsAddressInputFocused(false)}
          onFinish={(isSuccess, value) => {
            isSuccess && onConfirm(value);
          }}
          allowEOA={false}
          userWallet={rawName}
          requireAA={false}
          placeholder={t("Search kontos account")}
        />
      </AddressInputContainer>
    </Container>
  );
};

export default SearchAccountModal;
