import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import ArrowSvg from "src/assets/icons/trade//trade-black-right-slim-arrow.svg";
import SearchSvg from "src/assets/icons/trade/trade-search.svg";
import HorizontalScrollableElements, {
  NonChainIndex,
  ChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import CircleIconPair from "src/components/icons/CircleIconPair";
import DefaultTokenSvg from "src/assets/icons/trade/default-token.svg";
import DefaultChainSvg from "src/assets/icons/trade/default-chain.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import { getChainIcon, filterUniqueFtAssets } from "src/utils/zkkontosHelper";
import { Chain, FtAsset } from "@/type/zkkontos";
import { debounce } from "lodash";
import { useStores } from "src/hooks/useStore";
import { NoDataAndAdd } from "src/components/no-data/NoDataAndAdd";
import { useNavigate } from "react-router-dom";
import { ROUTE_MARKETS } from "src/router/router-config";
import { useTranslation } from "react-i18next";
import SyncIcon from "src/components/sync/SyncIcon";
import { ExternalBalanceItem } from "./ExternalBalanceItem";
import { callFtAsset, ReqFtAsset } from "@/apis/asset-apis";
import { getChain } from "@/store/storeHelper";
import { AssetWhiteListIcon } from "./AssetWhiteListIcon";
import { AssetEndMessage } from "@/components/no-data/AssetEndMessage";
import { CrossChainAssetSelectTip } from "./CrossChainAssetSelectTip";
import { fontBold } from "@/style/style.global";
import { shortAddress } from "@/utils/helper";

const SearchContainer = styled.div`
  margin: 0 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const Title = styled.div`
  padding: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fontBold};
  font-size: 20px;
  line-height: 24px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  background-image: url(${SearchSvg});
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 11px 10px 10px 46px;

  border-radius: 20px;
  border: 1px solid var(--Deep-50, #edeef1);
  background-color: var(--Deep-25, #f5f5f6);

  margin-bottom: 8px;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: var(--Deep-400, #80868f);
  }

  &:focus {
    border-color: var(--Kontos-Blue, #413dec);
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(--Kontos-Blue, #413dec);
      outline: none;
      background-color: ${(props) => props.theme.colors.__white};
    }
  }
`;

const Scrollable = styled.div<{ $disabled?: boolean }>`
  flex: 1;
  overflow: auto;

  .normal-item {
    border: 1px solid var(--Deep-50, #edeef1);

    @media (hover: hover) {
      &:hover {
        border: 1px solid var(--Kontos-Blue, #413dec);
        background-color: ${(props) => props.theme.colors.__white};
      }
    }
  }

  .no-border-item {
    border: none;
    background: ${(props) => props.theme.colors.__white};

    ${(props) =>
      !props.$disabled &&
      css`
        &:active {
          background-color: ${(props) => props.theme.colors.__deep_50};
        }

        @media (hover: hover) {
          &:hover {
            background-color: ${(props) => props.theme.colors.__deep_25};
          }
        }
      `}
  }
`;

const AssetSymbolText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Deep-800, #1a2535);
  ${fontBold};
  font-size: 14px;

  .icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }
`;

const ChainSymbolText = styled.div`
  color: var(--Deep-400, #80868f);
  font-family: HarmonyOS Sans;
  font-size: 12px;
`;

const ItemContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;

  border-radius: 8px;
  background: var(--Deep-25, #f5f5f6);

  &:not(:first-child) {
    margin-top: 16px;
  }

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  .sync {
    display: block;
  }
  .syncHover {
    display: none;
  }
  &:hover {
    .sync {
      display: none;
    }
    .syncHover {
      display: block;
    }
    span {
      color: var(--Kontos-Blue, #413dec);
    }
  }

  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  ${(props) =>
    props.$disabled &&
    `${AssetSymbolText}, ${ChainSymbolText} {
      color: ${props.theme.colors.__deep_200};
  }`}
`;

const LeftContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

const ItemTextContainer2 = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: center;
  align-items: flex-end;

  .number-box-line-1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .positive {
      color: ${(props) => props.theme.colors.__success};
    }

    .negative {
      color: ${(props) => props.theme.colors.__error};
    }

    .number-box-line-1-left {
      font-family: HarmonyOS Sans SC;
      font-size: 12px;
      font-weight: 400;
    }

    .number-box-line-1-right {
      margin-left: 6px;

      color: ${(props) => props.theme.colors.__deep_800};
      ${fontBold};
      font-size: 14px;

      .number-box-line-1-right-symbol {
        color: ${(props) => props.theme.colors.__deep_400};
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .number-box-line-2 {
    margin-top: 7px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    color: ${(props) => props.theme.colors.__deep_400};
    font-family: HarmonyOS Sans SC;
    font-size: 14px;
    font-weight: 400;
  }
`;

const RightImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const InfiniteLoading = styled.img`
  width: 80px;
  text-align: center;
`;

const StyledNoDataAndAdd = styled(NoDataAndAdd)`
  margin-top: 60px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export enum ShowAssetType {
  Arrow,
  Balance,
  Detail,
  Sync,
  ExternalBalance,
}
interface SelectAssetsModalProps {
  onChainChange?: (chainIndex: ChainIndex) => void;
  onChoose?: (asset: FtAsset) => void;
  initData?: FtAsset[];
  initHasMore?: boolean;
  chains: Chain[];
  showButNotAvailableChains?: Chain[];
  showAssetType: ShowAssetType;
  initAssetType: ChainIndex;
  hasRecommend?: boolean;
  hasAll?: boolean;
  hasFavorites?: boolean;
  displayPrecision?: number;
  sortByBalance?: boolean;
  title?: string;
  hasMyAssets?: boolean;
  isSync?: boolean;
  isWhitelist?: boolean;
  limit?: number;
  middleComponent?: ReactNode;
  canBeClicked?: (asset: FtAsset) => void | string;
  enableTradeStore?: boolean;
}

const LIMIT: number = 30;
const WAIT: number = 300;

export const ToBuyAssetSelector: React.FC<SelectAssetsModalProps> = ({
  onChainChange,
  onChoose,
  initData,
  initHasMore = false,
  chains,
  showButNotAvailableChains = [],
  showAssetType,
  initAssetType,
  hasRecommend = false,
  hasAll = false,
  hasFavorites = false,
  displayPrecision,
  sortByBalance,
  title,
  hasMyAssets,
  isSync = false,
  isWhitelist,
  limit = LIMIT,
  middleComponent,
  enableTradeStore = false,
}) => {
  const { t } = useTranslation();
  const { chainStore, userStore, tradeStore } = useStores();
  const requestingTimestamp = useRef(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isRecommended, setIsRecommended] = useState<boolean>(
    initAssetType === NonChainIndex.Recommend
  );
  const [isMyAssets, setIsMyAssets] = useState<boolean>(
    initAssetType === NonChainIndex.MyAssets
  );
  const [isFav, setIsFav] = useState<boolean>(
    initAssetType === NonChainIndex.Favorites
  );
  const [chainIndex, setChainIndex] = useState<ChainIndex>(initAssetType);
  const [ftAssets, setFtAssets] = useState<FtAsset[]>(initData || []);
  const [hasMore, setHasMore] = useState<boolean>(initHasMore);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const realChainIndex =
    chainIndex === NonChainIndex.All ||
    chainIndex === NonChainIndex.Recommend ||
    chainIndex === NonChainIndex.MyAssets ||
    chainIndex === NonChainIndex.Favorites
      ? ""
      : chainIndex;
  const isChainAssetDisabled = useMemo((): boolean => {
    return showButNotAvailableChains.some(
      (item) => item.chainIndex === chainIndex
    );
  }, [chainIndex, showButNotAvailableChains]);

  const handleAddFav = useCallback(() => {
    navigate(ROUTE_MARKETS);
  }, [navigate]);

  const handleChooseAsset = useCallback(
    (asset: FtAsset) => {
      if (isChainAssetDisabled) {
        return;
      }
      onChainChange?.(chainIndex);
      onChoose?.(asset);
    },
    [chainIndex, isChainAssetDisabled, onChainChange, onChoose]
  );

  const callFetchFtAssets = useCallback(
    async (param: ReqFtAsset, currentFtAssets: FtAsset[]) => {
      const timestamp = Date.now();
      requestingTimestamp.current = timestamp;
      try {
        const respFtAssets = await callFtAsset(param);
        if (requestingTimestamp.current === timestamp) {
          const filteredFtAssets = filterUniqueFtAssets(
            currentFtAssets,
            respFtAssets?.ftAssets || []
          );
          setFtAssets(filteredFtAssets);
          setHasMore(respFtAssets.hasMore);
        } else {
          console.log("ignore outdated response");
        }
      } catch (e) {
        console.warn("Fetch ftassets failed", e);
      }
    },
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchFtAssets = useCallback(
    debounce((param: ReqFtAsset, currentFtAssets: FtAsset[]) => {
      callFetchFtAssets(param, currentFtAssets);
    }, WAIT),
    []
  );

  const fetchMoreData = () => {
    const requestParam: ReqFtAsset = {
      regex: searchTerm,
      chainIndex: realChainIndex,
      isRecommended: isRecommended,
      account:
        isMyAssets || sortByBalance
          ? enableTradeStore
            ? tradeStore.accountName
            : userStore.accountName
          : undefined,
      offset: ftAssets.length,
      limit: limit,
      displayOnlyOwnedItems: isMyAssets,
      isWhitelist: isWhitelist,
    };
    debouncedFetchFtAssets(requestParam, ftAssets);
  };

  const resetInfiniteScrollParam = () => {
    setFtAssets([]);
    setHasMore(true);
  };

  const handleChainSelect = (_chainIndex: string) => {
    resetInfiniteScrollParam();
    setChainIndex(_chainIndex);
    let toSetIsRecommended = false;
    let toSetInWhoseWatchlist = false;
    let toSetMyAssets = false;
    switch (_chainIndex) {
      case NonChainIndex.All:
        break;
      case NonChainIndex.Recommend:
        toSetIsRecommended = true;
        break;
      case NonChainIndex.Favorites:
        toSetInWhoseWatchlist = true;
        break;
      case NonChainIndex.MyAssets:
        toSetMyAssets = true;
        break;
      default:
        break;
    }
    setIsRecommended(toSetIsRecommended);
    setIsFav(toSetInWhoseWatchlist);
    setIsMyAssets(toSetMyAssets);
    const requestParam: ReqFtAsset = {
      regex: searchTerm,
      chainIndex:
        _chainIndex === NonChainIndex.All ||
        _chainIndex === NonChainIndex.Recommend ||
        _chainIndex === NonChainIndex.MyAssets ||
        _chainIndex === NonChainIndex.Favorites
          ? ""
          : _chainIndex,
      isRecommended: toSetIsRecommended,
      account:
        toSetMyAssets || sortByBalance
          ? enableTradeStore
            ? tradeStore.accountName
            : userStore.accountName
          : undefined,
      offset: 0,
      limit: limit,
      displayOnlyOwnedItems: toSetMyAssets,
      isWhitelist: isWhitelist,
    };
    debouncedFetchFtAssets(requestParam, []);
  };

  const handleSearchTermChanged = (_searchTerm: string) => {
    resetInfiniteScrollParam();
    setSearchTerm(_searchTerm);
    const requestParam: ReqFtAsset = {
      regex: _searchTerm,
      chainIndex: realChainIndex,
      isRecommended: isRecommended,
      account:
        isMyAssets || sortByBalance
          ? enableTradeStore
            ? tradeStore.accountName
            : userStore.accountName
          : undefined,
      offset: 0,
      limit: limit,
      displayOnlyOwnedItems: isMyAssets,
      isWhitelist: isWhitelist,
    };
    debouncedFetchFtAssets(requestParam, []);
  };

  useEffect(() => {
    if (!initData) {
      if (initAssetType) {
        handleChainSelect(initAssetType);
      } else if (hasRecommend) {
        handleChainSelect(NonChainIndex.Recommend);
      } else if (hasAll) {
        handleChainSelect(NonChainIndex.All);
      } else {
        chains?.[0].chainIndex && handleChainSelect(chains[0].chainIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchContainer>
      {title && <Title>{title}</Title>}
      <SearchInput
        ref={inputRef}
        placeholder={t("Search assets name / contract")}
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleSearchTermChanged(e.target.value)
        }
      />
      <HorizontalScrollableElements
        chains={chains}
        chainIndex={chainIndex}
        onSelect={handleChainSelect}
        hasRecommend={hasRecommend}
        hasAll={hasAll}
        hasFavorites={hasFavorites}
        hasMyAssets={hasMyAssets}
      />

      {middleComponent}

      {isChainAssetDisabled && (
        <CrossChainAssetSelectTip style={{ marginBottom: "16px" }} />
      )}

      <Scrollable id="scrollableDiv" $disabled={isChainAssetDisabled}>
        <InfiniteScroll
          dataLength={ftAssets.length} // The current length of displayed data
          next={fetchMoreData} // The function to trigger to load more
          hasMore={hasMore} // Whether there are more items to load
          loader={
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <InfiniteLoading src="/static/loading.gif" alt="Loading..." />
            </div>
          }
          endMessage={
            isFav && ftAssets.length === 0 ? (
              <StyledNoDataAndAdd
                noDataText={t("You have not added any favorite assets yet!")}
                btnText={"Add Favorites"}
                addCallback={handleAddFav}
              />
            ) : (
              <AssetEndMessage
                onGoToSearch={() => {
                  if (inputRef.current) {
                    inputRef.current.focus();
                  }
                }}
              />
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {ftAssets.map((item, index) => (
            <ItemContainer
              key={index}
              className={"no-border-item"}
              $disabled={isChainAssetDisabled}
              onClick={() => handleChooseAsset(item)}
            >
              <CircleIconPair
                mainIcon={item.imageUrl}
                mainIconFallbackSrc={DefaultTokenSvg}
                subIcon={getChainIcon(
                  chainStore.chains,
                  item.chainIndex,
                  item.address
                )}
                subIconFallbackSrc={DefaultChainSvg}
                mainWidth={32}
                mainHeight={32}
                subWidth={16}
                subHeight={16}
                totalWidth={40}
                totalHeight={32}
              />

              {/* Left */}
              <LeftContainer>
                <AssetSymbolText>
                  {item.symbol}
                  <AssetWhiteListIcon
                    isWhiteList={item.isWhitelist}
                    disableHoverToClick
                  />
                </AssetSymbolText>
                <ChainSymbolText>
                  {/* {getChain(item.chainIndex)?.chainSymbol} */}
                  {shortAddress(item.address)}
                </ChainSymbolText>
              </LeftContainer>

              {/* Right */}
              {showAssetType === ShowAssetType.Arrow && (
                <RightImage src={ArrowSvg} alt="Select" />
              )}
              {showAssetType === ShowAssetType.Balance && (
                <ItemTextContainer2>
                  <AssetSymbolText>
                    {new KontosNumber(
                      item.balance?.balance,
                      DEFAULT_DECIMAL
                    ).toFormat(displayPrecision)}
                  </AssetSymbolText>
                  <ChainSymbolText>
                    {"$" +
                      new KontosNumber(
                        item.balance?.balanceUsdAmount,
                        DEFAULT_DECIMAL
                      ).toFormat(displayPrecision)}
                  </ChainSymbolText>
                </ItemTextContainer2>
              )}
              {showAssetType === ShowAssetType.ExternalBalance && (
                <ExternalBalanceItem
                  asset={item}
                  displayPrecision={displayPrecision}
                />
              )}
              {showAssetType === ShowAssetType.Detail && (
                <ItemTextContainer2>
                  <div className="number-box-line-1">
                    <span className="number-box-line-1-right">
                      <span className="number-box-line-1-right-symbol">
                        {"$"}
                      </span>
                      {!new KontosNumber(item?.usdPrice, DEFAULT_DECIMAL).eq(0)
                        ? new KontosNumber(
                            item?.usdPrice,
                            DEFAULT_DECIMAL
                          ).toFormat()
                        : "-"}
                    </span>
                  </div>
                  <div className="number-box-line-2"></div>
                  {/* <div className="number-box-line-1">
                    <span
                      className={`number-box-line-1-left ${
                        new KontosNumber(
                          item?.h24PriceChangePercentage,
                          DEFAULT_DECIMAL
                        ).gt(0)
                          ? "positive"
                          : "negative"
                      }`}
                    >
                      {!new KontosNumber(
                        item?.h24PriceChangePercentage,
                        DEFAULT_DECIMAL
                      ).eq(0)
                        ? (new KontosNumber(
                            item?.h24PriceChangePercentage,
                            DEFAULT_DECIMAL
                          ).gt(0)
                            ? "↑"
                            : "↓") +
                          new KontosNumber(
                            item?.h24PriceChangePercentage,
                            DEFAULT_DECIMAL
                          )
                            .multiply(100)
                            .toFixed(2) +
                          "%"
                        : ""}
                    </span>
                    <span className="number-box-line-1-right">
                      <span className="number-box-line-1-right-symbol">
                        {"$"}
                      </span>
                      {!new KontosNumber(item?.usdPrice, DEFAULT_DECIMAL).eq(0)
                        ? new KontosNumber(
                            item?.usdPrice,
                            DEFAULT_DECIMAL
                          ).toFormat()
                        : "-"}
                    </span>
                  </div>
                  <div className="number-box-line-2">
                    {t("Vol $")}
                    {!new KontosNumber(item?.h24VolumeUsd, DEFAULT_DECIMAL).eq(
                      0
                    )
                      ? new KontosNumber(
                          item?.h24VolumeUsd,
                          DEFAULT_DECIMAL
                        ).toFormatWithSymbol(2)
                      : "-"}
                  </div> */}
                </ItemTextContainer2>
              )}
              {showAssetType === ShowAssetType.Sync && <SyncIcon />}
            </ItemContainer>
          ))}
        </InfiniteScroll>
      </Scrollable>
    </SearchContainer>
  );
};
