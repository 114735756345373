import styled, { css } from "styled-components";
import Header from "src/components/common/header";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { SelectAssetButton } from "src/components/asset-item/SelectAssetButton";
import balanceIcon from "src/assets/icons/balance.svg";
import { useAccount, useBalance } from "wagmi";
import Skeleton from "react-loading-skeleton";
import KontosNumber from "src/utils/KontosNumber";
import statusIcon from "src/assets/icons/receive/status.svg";
import lineIcon from "src/assets/icons/receive/line.svg";
import { formatAddress, isNative } from "src/utils/helper";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";
import NumericInputV2 from "src/components/input/NumericInputV2";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { buttonClick, buttonHover } from "src/components/global-styles";
import { KontosButton } from "src/components/button/KontosButton";
import { BlankFilledArea } from "src/components/blank-area/BlankFilledArea";
import toast from "src/components/toast/Toast";
import { DEFAULT_DECIMAL } from "src/config";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import { fontH6, fontH8 } from "@/style/style.global";
import { WarningArea } from "@/components/warnings/WarningArea";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledWarningArea = styled(WarningArea)`
  width: calc(100% - 32px);
  margin: 0 16px;
`;

const ChooseAssetTitle = styled.div`
  margin-top: 10px;
  margin-left: 24px;
  color: var(--Deep-800, #1a2535);
  /* H6 */
  ${fontH6}
`;

const AssetPanel = styled.div`
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-top: 8px;
  padding: 4px 16px 4px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid var(--Deep-50, #edeef1);
  background: var(--White, #fff);
`;

const BalanceGroup = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  justify-content: center;

  gap: 3px;
`;

const AccountLine = styled.div`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-align: right;
  text-overflow: ellipsis;
  /* Smaller */
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BalanceLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BalanceIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const BalanceText = styled.span`
  overflow: hidden;
  color: var(--Deep-400, #80868f);
  text-align: right;
  text-overflow: ellipsis;
  /* Description */
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InputContainer = styled.div<{ $focus?: boolean; $warning?: boolean }>`
  width: calc(100% - 32px);
  margin: 0 16px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.$focus
        ? "var(--Kontos-Blue, #413DEC)"
        : props.$warning
          ? "var(--Warning, #FAAD14)"
          : "var(--Deep-100, #cccfd2)"};
  background: var(--White, #fff);
`;

const InputHeaderBar = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--Deep-800, #1a2535);
  display: flex;
  width: 100%;
  height: 32px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`;

const StatusIcon = styled.img`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
`;

const AccountText = styled.span`
  overflow: hidden;
  color: var(--White, #fff);
  text-overflow: ellipsis;
  /* H8 */
  ${fontH8}
`;

const LineIcon = styled.img`
  width: 1px;
  height: 6px;
  flex-shrink: 0;
`;

const WalletBalanceGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const WalletBalanceText = styled.span`
  overflow: hidden;
  color: var(--White, #fff);
  text-overflow: ellipsis;
  /* Smaller */
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InputPanel = styled.div`
  padding: 18px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PriceEvaluator = styled.div<{ $warning?: boolean }>`
  margin-top: 6px;
  overflow: hidden;
  color: ${(props) =>
    props.$warning ? "var(--Warning, #FAAD14)" : "var(--Deep-400, #80868f)"};
  text-align: center;
  text-overflow: ellipsis;
  /* Smaller */
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const AmountSelectArea = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const AmountSelectButton = styled.button`
  border: none;
  outline: none;
  border-radius: 4px;
  background: rgba(65, 61, 236, 0.05);
  padding: 4px 16px;

  color: var(--Kontos-Blue, #413dec);
  /* Smaller */
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${buttonClick}
  ${buttonHover}
`;

const Buttons = styled.div`
  width: calc(100% - 40px);
  margin: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const disableStyle = css`
  background: var(--Deep-25, #f5f5f6);
  color: var(--Deep-100, #cccfd2);
  cursor: not-allowed;
`;

const DepositButton = styled(KontosButton)`
  ${(props) => props.disabled && disableStyle}
`;

const ratioButtons = [
  {
    text: "25%",
    value: 0.25,
  },
  {
    text: "50%",
    value: 0.5,
  },
  {
    text: "75%",
    value: 0.75,
  },
  {
    text: "MAX",
    value: 1,
  },
];

const ratioButtonsForNative = [
  {
    text: "25%",
    value: 0.25,
  },
  {
    text: "50%",
    value: 0.5,
  },
  {
    text: "75%",
    value: 0.75,
  },
  {
    text: "95%",
    value: 0.95,
  },
];

interface IProps {
  goToSelectAsset: () => void;
  onClose: () => void;
  onBack: () => void;
  onNext: () => void;
}

export const ReceiveDepositMain: React.FC<IProps> = observer(
  ({ goToSelectAsset, onClose, onBack, onNext }) => {
    const { t } = useTranslation();
    const amountInputRef = useRef<{ focus: () => void }>(null);
    const { receiveStore, userStore } = useStores();
    const [isBlur, setIsBlur] = useState<boolean>(true);
    const walletAccount = useAccount();
    const walletBalance = useBalance({
      address: walletAccount.address,
      chainId: parseInt(receiveStore.chain?.chainIndex || ""),
      token: isNative(receiveStore.asset)
        ? undefined
        : (receiveStore.asset?.address as `0x${string}` | undefined),
    });

    const walletBalanceNumber = useMemo(() => {
      const balance = new KontosNumber(
        walletBalance.data?.value?.toString(),
        walletBalance.data?.decimals
      );
      return balance;
    }, [walletBalance.data?.decimals, walletBalance.data?.value]);

    const insufficient = useMemo(() => {
      if (
        receiveStore.amount &&
        receiveStore.amount.gt(0) &&
        walletBalance.data &&
        walletBalanceNumber.lt(receiveStore.amount)
      ) {
        return true;
      }
      return false;
    }, [receiveStore.amount, walletBalance.data, walletBalanceNumber]);

    const handleInputChange = useCallback(
      (amount: KontosNumber) => {
        const toSetValue = amount.eq(0) ? undefined : amount;
        receiveStore.setAmount(toSetValue);
      },
      [receiveStore]
    );

    const handleRatioChange = useCallback(
      (ratio: number) => {
        const toSetValue = walletBalanceNumber.multiply(ratio);
        receiveStore.setAmount(toSetValue.eq(0) ? undefined : toSetValue);
      },
      [receiveStore, walletBalanceNumber]
    );

    const handleClickDepositButton = useCallback(() => {
      if (!receiveStore.amount) {
        toast({
          text: t("Please input amount"),
          type: "info",
        });
        amountInputRef.current?.focus();
        return;
      }
      if (walletBalanceNumber.lte(0)) {
        if (walletBalance.isFetching) {
          toast({
            text: t("Checking Balance..."),
            type: "info",
          });
          return;
        }
        toast({
          text: t("Insufficient Balance"),
          type: "warning",
        });
        return;
      }
      onNext();
    }, [
      onNext,
      receiveStore.amount,
      t,
      walletBalance.isFetching,
      walletBalanceNumber,
    ]);

    useEffect(() => {
      if (!receiveStore.amount) {
        amountInputRef.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Container>
        <Header
          padding="8px 24px"
          title={t("Deposit")}
          rightBtnText={t("Cancel")}
          rightBtnCallBack={onClose}
        />

        <StyledWarningArea>
          {t(
            "After a successful deposit, the balance may take 3 to 5 minutes to update."
          )}
        </StyledWarningArea>

        <ChooseAssetTitle>{t("Choose Assets:")}</ChooseAssetTitle>

        <AssetPanel>
          <SelectAssetButton
            hideSecurityIcon
            arrowDirection="left"
            asset={receiveStore.asset}
            chain={receiveStore.chain}
            onClick={goToSelectAsset}
          />

          <BalanceGroup>
            <AccountLine>{userStore.accountNameWithOs}</AccountLine>

            <BalanceLine>
              <BalanceIcon src={balanceIcon} alt="balance" />
              <BalanceText>
                {receiveStore.assetBalance ? (
                  receiveStore.assetBalance.toFormat()
                ) : (
                  <EllipsisPlaceholder />
                )}
              </BalanceText>
            </BalanceLine>
          </BalanceGroup>
        </AssetPanel>

        <InputContainer $focus={!isBlur} $warning={isBlur && insufficient}>
          <InputHeaderBar>
            <StatusIcon src={statusIcon} />
            <AccountText>
              {walletAccount.isConnecting ? (
                <Skeleton count={1} style={{ width: "30px" }} />
              ) : (
                formatAddress(walletAccount.address || "N/A")
              )}
            </AccountText>
            <LineIcon src={lineIcon} />
            <WalletBalanceGroup>
              <TokenOrChainIcon
                src={receiveStore.asset?.imageUrl}
                type={"token"}
                size={14}
              />
              <WalletBalanceText>
                {walletBalance.isFetching ? (
                  <EllipsisPlaceholder />
                ) : (
                  walletBalanceNumber.toFormatV2()
                )}
              </WalletBalanceText>
            </WalletBalanceGroup>
          </InputHeaderBar>

          <InputPanel>
            <NumericInputV2
              ref={amountInputRef}
              style={{
                textAlign: "center",
              }}
              inputStyle={{
                color:
                  isBlur && insufficient
                    ? "var(--Warning, #FAAD14)"
                    : "var(--Deep-800, #1a2535)",
              }}
              placeholder={`0.00 ${receiveStore.asset?.symbol}`}
              precision={7}
              maxValue={99999999}
              minValue={0}
              onChange={handleInputChange}
              value={receiveStore.amount}
              alignRight
              fontSize="24px"
              height={28}
              onFocus={() => setIsBlur(false)}
              onBlur={() => setIsBlur(true)}
              unit={receiveStore.asset?.symbol}
              widthFitContent={true}
            />

            <PriceEvaluator $warning={isBlur && insufficient}>
              {isBlur && insufficient
                ? t("Insufficient balance")
                : receiveStore.amount
                  ? "≈ " +
                    receiveStore.amount
                      .multiply(
                        receiveStore.asset?.usdPrice || "0",
                        DEFAULT_DECIMAL
                      )
                      .toFormat() +
                    " USD"
                  : "≈ 0.00 USD"}
            </PriceEvaluator>

            <AmountSelectArea>
              {(isNative(receiveStore.asset)
                ? ratioButtonsForNative
                : ratioButtons
              ).map((item) => (
                <AmountSelectButton
                  key={item.value}
                  onClick={() => handleRatioChange(item.value)}
                >
                  {item.text}
                </AmountSelectButton>
              ))}
            </AmountSelectArea>
          </InputPanel>
        </InputContainer>

        <BlankFilledArea />

        <Buttons>
          <DepositButton
            onClick={handleClickDepositButton}
            disabled={insufficient || walletBalance.isFetching}
          >
            {insufficient
              ? t("Insufficient Balance")
              : walletBalance.isFetching
                ? t("Checking Balance...")
                : t("Deposit")}
          </DepositButton>
        </Buttons>
      </Container>
    );
  }
);
