import styled, { css } from "styled-components";
import CircleIconPair from "../icons/CircleIconPair";
import { Chain, FtAsset } from "@/type/zkkontos";
import blackUpArrowIcon from "src/assets/icons/trade/trade-black-up-arrow.svg";
import { useTranslation } from "react-i18next";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import { AssetWhiteListIcon } from "src/pages/trade/asset-select/AssetWhiteListIcon";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ $disabled?: boolean }>`
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  background: var(--White, #fff);
  user-select: none;
  cursor: ${(props) => (props.$disabled ? "wait" : "pointer")};
  display: flex;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      background: ${(props) => !props.$disabled && "var(--Deep-25, #f5f5f6)"};
    }
  }
  &:active {
    background: ${(props) => !props.$disabled && "var(--Deep-25, #f5f5f6)"};
  }
`;

const Arrow = styled.img<{ $direction?: "up" | "down" | "left" | "right" }>`
  margin-right: 8px;
  width: 12px;
  height: 12px;

  ${({ $direction = "up" }) => {
    switch ($direction) {
      case "down":
        return css`
          transform: rotate(180deg);
        `;
      case "left":
        return css`
          transform: rotate(-90deg);
        `;
      case "right":
        return css`
          transform: rotate(90deg);
        `;
      case "up":
      default:
        return css`
          transform: rotate(0deg);
        `;
    }
  }}
`;

const StyledCircleIconPair = styled(CircleIconPair)`
  margin-right: 6px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  white-space: nowrap;
`;

const AssetSymbolBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AssetSymbol = styled.span`
  overflow: hidden;
  color: var(--Deep-800, #1a2535);
  text-overflow: ellipsis;
  ${fontBold};
  font-size: 14px;
`;

const VerifyPlaceholder = styled.div`
  width: 12px;
  height: 12px;
`;

const ChainSymbol = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

interface IProps {
  className?: string;
  asset?: FtAsset;
  chain?: Chain;
  hideSecurityIcon?: boolean;
  arrowDirection?: "up" | "down" | "left" | "right";
  onClick: () => void;
}

export const SelectAssetButton: React.FC<IProps> = ({
  className,
  asset,
  chain,
  hideSecurityIcon = false,
  arrowDirection = "up",
  onClick,
}) => {
  const { t } = useTranslation();
  const assetSymbol = asset ? asset.symbol : t("Select Asset");

  return (
    <Container
      className={className}
      onClick={() => {
        onClick();
      }}
    >
      <Arrow src={blackUpArrowIcon} $direction={arrowDirection} />

      <StyledCircleIconPair
        mainIcon={asset?.imageUrl || defaultTokenIcon}
        mainIconFallbackSrc={defaultTokenIcon}
        subIcon={chain?.greyImageURL || defaultChainIcon}
        subIconFallbackSrc={defaultChainIcon}
        mainWidth={32}
        mainHeight={32}
        subWidth={16}
        subHeight={16}
        totalWidth={40}
        totalHeight={32}
      />

      <TextBox>
        {/* Line 1 */}
        <AssetSymbolBox>
          <AssetSymbol>{assetSymbol}</AssetSymbol>
          {asset && hideSecurityIcon === false ? (
            <AssetWhiteListIcon
              isWhiteList={asset.isWhitelist}
              disableHoverToClick
            />
          ) : (
            <VerifyPlaceholder />
          )}
        </AssetSymbolBox>

        {/* Line 2 */}
        <ChainSymbol>{chain ? chain.chainSymbol : "..."}</ChainSymbol>
      </TextBox>
    </Container>
  );
};
