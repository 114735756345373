import { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import WhiteLoadingSvg from "src/assets/icons/white-loading.svg";
import { fontBold } from "@/style/style.global";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 14px 0;
  border: none;
  border-radius: 99px;
  background: var(--Deep-800, #1a2535);
  transition: 0.2s;

  color: var(--White, #fff);
  font-size: 18px;
  ${fontBold}
  text-align: center;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: ${(props) => (props.disabled ? "1" : "0.9")};
  }
`;

const FixedIcon = styled.img`
  display: span;
  margin-left: 10px;
  margin-right: 10px;
  width: 13px;
  height: 13px;
`;

const LoadingIcon = styled.img<{ $loading?: boolean }>`
  display: ${(props) => (props.$loading ? "span" : "none")};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  margin-left: 10px;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
`;

interface Props {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  icon?: any;
  fixedIcon?: any;
  fixedIconRight?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FloatingButton: React.FC<Props> = ({
  className,
  children,
  disabled,
  icon,
  fixedIcon,
  fixedIconRight = true,
  loading,
  onClick,
}) => {
  return (
    <Button disabled={disabled} onClick={onClick} className={className}>
      {!fixedIconRight && fixedIcon && <FixedIcon src={fixedIcon} />}
      {children}
      {fixedIconRight && fixedIcon ? (
        <FixedIcon src={fixedIcon} />
      ) : (
        <LoadingIcon src={icon || WhiteLoadingSvg} $loading={loading} />
      )}
    </Button>
  );
};

export default FloatingButton;
