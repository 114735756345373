import styled from "styled-components";
import { buttonHover, buttonClick, buttonDisabled } from "../global-styles";
import { fontBold } from "@/style/style.global";

export const DeepButton = styled.button`
  border-radius: 54px;
  border: none;
  background: ${(props) => props.theme.colors.__deep_800};
  color: ${(props) => props.theme.colors.__white};
  text-align: center;
  ${fontBold}
  font-size: 16px;
  padding: 7px;
  width: 100%;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  ${buttonHover};
  ${buttonClick};
  ${buttonDisabled}
`;
