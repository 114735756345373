import goodIcon from "src/assets/icons/payment/good.svg";
import styled from "styled-components";
import Floater from "react-floater";
import { WhiteListTip } from "@/components/tips/WhiteListTip";

const Icon = styled.img<{ size: number }>`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  cursor: pointer;
`;

const getWhiteListIcon = (isWhitelist: boolean): string | null => {
  if (isWhitelist) {
    return goodIcon;
  }
  return null;
};

interface IProps {
  isWhiteList: boolean;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
  disableHoverToClick?: boolean;
}

export const AssetWhiteListIcon: React.FC<IProps> = ({
  isWhiteList,
  size = 12,
  className,
  style,
  disableHoverToClick = false,
}) => {
  const icon = getWhiteListIcon(isWhiteList);

  if (!icon) {
    return <></>;
  }

  return (
    <Floater
      event="hover"
      eventDelay={0}
      component={<WhiteListTip />}
      styles={{
        options: {
          zIndex: 20,
        },
        arrow: {
          color: "var(--Deep-800, #1a2535)",
          length: 5,
          spread: 11,
        },
      }}
      offset={3}
      placement={"top"}
      disableHoverToClick={disableHoverToClick}
    >
      <Icon
        className={className}
        style={style}
        src={icon}
        size={size}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    </Floater>
  );
};
