import styled, { css } from "styled-components";
import Header from "src/components/common/header";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { KontosButton } from "src/components/button/KontosButton";
import { BlankFilledArea } from "src/components/blank-area/BlankFilledArea";
import CircleIconPair from "src/components/icons/CircleIconPair";
import { FtAsset } from "src/type/zkkontos";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import KontosNumber from "src/utils/KontosNumber";
import { TokenOrChainIcon } from "src/components/icons/TokenOrChainIcon";
import { getChainByAsset } from "@/store/storeHelper";
import { fontH4 } from "@/style/style.global";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: var(--White, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DetailBox = styled.div`
  width: 100%;
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
`;

const DetailTextLineOne = styled.span`
  overflow: hidden;
  color: var(--Kontos-Blue, #413dec);
  text-overflow: ellipsis;
  /* H4 */
  ${fontH4}
`;

const DetailTextLineTwo = styled.span`
  color: var(--Deep-400, #80868f);

  /* Main text */
  font-family: "HarmonyOS Sans SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

const Desc = styled.div`
  height: 16px;
  margin-top: 16px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const DescNormalText = styled.span`
  color: var(--Deep-400, #80868f);
  /* Description */
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DescStrongText = styled.span`
  color: var(--Deep-400, #80868f);
  /* Description */
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Buttons = styled.div`
  width: calc(100% - 40px);
  margin: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const disableStyle = css`
  background: var(--Deep-25, #f5f5f6);
  color: var(--Deep-100, #cccfd2);
  cursor: not-allowed;
`;

const DepositButton = styled(KontosButton)`
  ${(props) => props.disabled && disableStyle}
`;

const CancelButton = styled(KontosButton)`
  background: var(--Deep-50, #edeef1);
  color: var(--Deep-400, #80868f);
`;

interface IProps {
  asset: FtAsset;
  amount: KontosNumber;
  onClose: () => void;
  onSubmit: () => void;
}

export const DepositConfirm: React.FC<IProps> = observer(
  ({ asset, amount, onClose, onSubmit }) => {
    const { t } = useTranslation();

    return (
      <Container>
        <Header padding="8px 24px" title={t("Confirm Deposit")} />

        <BlankFilledArea />

        <DetailBox>
          <CircleIconPair
            mainIcon={asset?.imageUrl || defaultTokenIcon}
            mainIconFallbackSrc={defaultTokenIcon}
            subIcon={getChainByAsset(asset)?.greyImageURL || defaultChainIcon}
            subIconFallbackSrc={defaultChainIcon}
            mainWidth={48}
            mainHeight={48}
            subWidth={24}
            subHeight={24}
            totalWidth={60}
            totalHeight={48}
          />

          <DetailText>
            <DetailTextLineOne>
              {`+${amount.toFormat()} ${asset.symbol}`}
            </DetailTextLineOne>

            <DetailTextLineTwo>
              {getChainByAsset(asset)?.chainSymbol}
            </DetailTextLineTwo>
          </DetailText>
        </DetailBox>

        <Desc>
          <DescNormalText>{t("to Kontos smart account:")}</DescNormalText>
          <TokenOrChainIcon src={asset.imageUrl} type="token" size={16} />
          <DescStrongText>{getChainByAsset(asset)?.chainSymbol}</DescStrongText>
        </Desc>

        <BlankFilledArea />

        <Buttons>
          <DepositButton onClick={onSubmit}>{t("Confirm")}</DepositButton>

          <CancelButton onClick={onClose}>{t("Cancel")}</CancelButton>
        </Buttons>
      </Container>
    );
  }
);
