import Header from "src/components/common/header";
import { t } from "i18next";
import styled from "styled-components";
import { observer } from "mobx-react";
import GeneralItem from "../GeneralItem";
import { useCallback, useState } from "react";
import { LANG } from "src/i18n/lang";
import { useStores } from "src/hooks/useStore";
import { useNavigate } from "react-router-dom";
import { ROUTE_SETTINGS_GENERAL } from "@/router/router-config";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  flex: 1;
`;

const StyledHeader = styled(Header)`
  padding: 24px 24px 8px 24px;
`;

const Scrollable = styled.div`
  margin: 14px 16px 16px 16px;

  flex: 1;
  overflow: auto;
`;

const LangSetting: React.FC = () => {
  const { userStore } = useStores();
  const navigate = useNavigate();
  const [currentLang, setCurrentLang] = useState(
    userStore.lang || LANG.en.name
  );
  const handleLangSwitch = useCallback(
    (lang: string) => {
      userStore.updateLang(lang);
      setCurrentLang(lang);
    },
    [userStore]
  );

  return (
    <Container>
      <StyledHeader
        title={t("Language")}
        enableBack={true}
        callBack={() => navigate(ROUTE_SETTINGS_GENERAL)}
      />
      <Scrollable>
        {Object.values(LANG).map((lang) => (
          <GeneralItem
            key={lang.name}
            selected={lang.name === currentLang}
            title={lang.displayName}
            onClick={() => handleLangSwitch(lang.name)}
          />
        ))}
      </Scrollable>
    </Container>
  );
};

export default observer(LangSetting);
