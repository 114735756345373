import { Account } from "@/type/zkkontos";
import { rootStore } from "..";
import { callAccount } from "@/apis/aa-apis";
import { fetchAccount } from "./account-service";

export const fetchCurrentAndFutureGuardianNames = async (
  _name: string
): Promise<{ currentNames: string[]; futureNames: string[] }> => {
  const { accountDetail } = await callAccount({
    account: _name,
    offset: 0,
    limit: 0,
  });
  return {
    currentNames: accountDetail.guardians || [],
    futureNames: accountDetail.futureGuardians || [],
  };
};

export const pollAccount = async (
  isRevert: boolean,
  maxAttempts: number = 10,
  interval: number = 1000
): Promise<Account | null> => {
  let attempts = 0;

  const fetchData = async (): Promise<Account | null> => {
    try {
      const resp = await fetchAccount(rootStore.userStore.accountInfo?.name!);
      return resp;
    } catch (error) {
      console.error("refresh smart account failed:", error);
      return null;
    }
  };

  const poll = async (
    resolve: (arg0: Account | null) => void,
    reject: any
  ): Promise<void> => {
    attempts++;
    const result = await fetchData();

    if (
      (!isRevert && result && result.updateGuardiansDeadline > 0) ||
      (isRevert && result && result.updateGuardiansDeadline === 0)
    ) {
      resolve(result);
    } else if (attempts >= maxAttempts) {
      resolve(result);
    } else {
      setTimeout(() => poll(resolve, reject), interval);
    }
  };

  return new Promise(poll);
};
