import styled, { css } from "styled-components";
import infoIcon from "src/assets/icons/trade/info.svg";
import infoActiveIcon from "src/assets/icons/trade/info-active.svg";
import editIcon from "src/assets/icons/edit2.svg";
import editActiveIcon from "src/assets/icons/edit2-active.svg";
import deleteIcon from "src/assets/icons/delete2.svg";
import deleteActiveIcon from "src/assets/icons/delete2-active.svg";
import managePaymentIcon from "src/assets/icons/trade/payment-manage.svg";
import managePaymentActiveIcon from "src/assets/icons/trade/payment-manage-active.svg";
import { forwardRef } from "react";

const activeStyle = css<{
  $needBgEffect?: boolean;
  $activeIcon: string | null;
}>`
  ${(props) =>
    props.$activeIcon &&
    css`
      background-image: url(${props.$activeIcon});
    `}
  ${(props) =>
    props.$needBgEffect &&
    css`
      background-color: var(--Deep-50, #edeef1);
    `};
`;

const Button = styled.button<{
  $icon: string;
  $activeIcon: string | null;
  $wrapperSize?: number;
  $size?: number;
  $needBgEffect?: boolean;
  $forceActive?: boolean;
}>`
  /* Clear default style */
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  width: ${(props) => props.$wrapperSize}px;
  height: ${(props) => props.$wrapperSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) => `url(${props.$icon})`};
  background-repeat: no-repeat;
  background-size: ${(props) => props.$size}px ${(props) => props.$size}px;
  background-position: center center;
  background-clip: content-box;
  border-radius: 8px;

  ${(props) => props.$forceActive && activeStyle}

  @media (hover: hover) {
    &:hover {
      ${activeStyle}
    }
  }
  &:active {
    ${activeStyle}
  }
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  wrapperSize?: number;
  size?: number;
  icon?: any;
  activeIcon?: any;
  type?: "info" | "edit" | "delete" | "paymentAssetsManage";
  _type?: "submit" | "reset" | "button" | undefined;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  needBgEffect?: boolean;
  forceActive?: boolean;
}

export const SingleIconButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      className,
      style,
      size = 16,
      wrapperSize,
      type,
      icon,
      activeIcon,
      _type,
      onClick,
      disabled = false,
      needBgEffect = false,
      forceActive = false,
    }: IProps,
    ref
  ) => {
    let realIcon = null;
    let realActiveIcon = null;

    if (icon && activeIcon) {
      realIcon = icon;
      realActiveIcon = activeIcon;
    } else if (type) {
      switch (type) {
        case "info":
          realIcon = infoIcon;
          realActiveIcon = infoActiveIcon;
          break;
        case "edit":
          realIcon = editIcon;
          realActiveIcon = editActiveIcon;
          break;
        case "delete":
          realIcon = deleteIcon;
          realActiveIcon = deleteActiveIcon;
          break;
        case "paymentAssetsManage":
          realIcon = managePaymentIcon;
          realActiveIcon = managePaymentActiveIcon;
          break;
        default:
          break;
      }
    }

    return (
      <Button
        className={className}
        style={style}
        $icon={realIcon}
        $activeIcon={realActiveIcon || null}
        $wrapperSize={wrapperSize ? wrapperSize : size}
        $size={size}
        $forceActive={forceActive}
        onClick={onClick}
        disabled={disabled}
        $needBgEffect={needBgEffect}
        type={_type}
      />
    );
  }
);
