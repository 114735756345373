import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import NumericInputV2 from "src/components/input/NumericInputV2";
import { t } from "i18next";
import KontosNumber from "src/utils/KontosNumber";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import EllipsisPlaceholder from "src/components/load-placeholder/EllipsisPlaceholder";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";

const Container = styled.div`
  display: flex;
  margin-top: 16px;
  height: 24px;
  display: flex;
  align-items: center;
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
`;

const ChainIcon = styled.img`
  border-radius: 99px;
  margin-left: 4px;
  margin-right: 4px;
  width: 16px;
  height: 16px;
`;

interface IProps {
  value?: KontosNumber;
  unit?: string;
  precision?: number;
  amountOnChange?: (price: KontosNumber) => void;
  maxValue?: number;
  minValue?: number;
  onFocused?: () => void;
  onBlured?: () => void;
  attachmentProps?: {
    chainIcon: any;
    quantity?: KontosNumber;
    symbol: string;
    requesting: boolean;
  };
}

export const TradeInput: React.FC<IProps> = ({
  value,
  unit,
  precision,
  amountOnChange,
  maxValue,
  minValue,
  onFocused,
  onBlured,
  attachmentProps,
}) => {
  const handleFocus = useCallback(() => {
    onFocused && onFocused();
  }, [onFocused]);

  const handleBlur = useCallback(() => {
    onBlured && onBlured();
  }, [onBlured]);

  const attachment = useMemo(() => {
    return attachmentProps ? (
      <Attachment>
        ≈
        <ImageWithFallback
          src={attachmentProps.chainIcon || defaultTokenIcon}
          fallbackSrc={defaultTokenIcon}
          StyledImg={ChainIcon}
        />
        {attachmentProps.requesting ? (
          <>
            <EllipsisPlaceholder />
            &nbsp;
          </>
        ) : (
          attachmentProps.quantity?.toFormat() || "0.00"
        )}
        {" " + attachmentProps.symbol}
      </Attachment>
    ) : undefined;
  }, [attachmentProps]);

  return (
    <Container>
      <NumericInputV2
        precision={precision}
        unit={unit}
        placeholder={unit ? `${t("Amount")} (${unit})` : t("Amount")}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={amountOnChange}
        maxValue={maxValue}
        minValue={minValue}
        value={value}
        attachment={attachment}
      />
    </Container>
  );
};
