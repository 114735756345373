import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import { RootStore } from "./RootStore";
import {
  DappHistoryItem,
  DappHistoryItemMetaData,
  DappHistoryItemVerifyContext,
  Dapp,
} from "src/type/dapp";
import localKeeper from "./localKeeper";
import { UserStore } from "./UserStore";
import * as DappService from "src/service/dapp-service";
import { DappPlaceholder } from "src/pages/dapp/explore/DiscoverExploreTrending";

const FETCH_TREDING_LIMIT = 15;

export class DiscoverStore {
  rootStore: RootStore;
  userStore: UserStore;
  dappHistory: DappHistoryItem[] = [];
  dappTrendings: Dapp[] = [];
  hasMoreDappTrendings: boolean = true;
  innerWidthReactionDisposer?: IReactionDisposer;
  innerHeightReactionDisposer?: IReactionDisposer;
  exploreTrendingDisplayItems: DappPlaceholder[] = [];
  dappDesktopBoxesPerRow?: number;
  dappDesktopInnerHeight?: number;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.userStore = rootStore.userStore;
    // @240904 store not-used
    // this.fetchAndSetDappTrendings();
    // makeAutoObservable(this, undefined, { autoBind: true });
  }

  startTrackingInnerWidth = () => {
    this.innerWidthReactionDisposer = reaction(
      () => this.rootStore.uiStore.innerWidth,
      () => {
        this.exploreTrendingDisplayItems = [];
        this.dappDesktopBoxesPerRow = undefined;
      }
    );
  };

  stopTrackingInnerWidth = () => {
    this.innerWidthReactionDisposer?.();
  };

  startTrackingInnerHeight = () => {
    this.innerHeightReactionDisposer = reaction(
      () => this.rootStore.uiStore.innerHeight,
      () => {
        this.dappDesktopInnerHeight = undefined;
      }
    );
  };

  stopTrackingInnerHeight = () => {
    this.innerHeightReactionDisposer?.();
  };

  getAndSetDappHistory = (): DappHistoryItem[] => {
    if (this.userStore.accountName) {
      this.dappHistory = localKeeper.getDappHistory(this.userStore.accountName);
      return this.dappHistory;
    }
    return [];
  };

  addDappHistory = (newDappHistoryItem: {
    metadata: DappHistoryItemMetaData;
    verifyContext: DappHistoryItemVerifyContext;
  }): DappHistoryItem[] => {
    if (this.userStore.accountName) {
      localKeeper.addDappHistory(
        this.userStore.accountName,
        newDappHistoryItem.metadata,
        newDappHistoryItem.verifyContext
      );
    }
    return this.getAndSetDappHistory();
  };

  removeDappHistory = (timestamp: number) => {
    if (this.userStore.accountName) {
      localKeeper.removeDappHistory(this.userStore.accountName, timestamp);
      this.getAndSetDappHistory();
    }
  };

  clearDappHistory = () => {
    if (this.userStore.accountName) {
      localKeeper.clearDappHistory(this.userStore.accountName);
      this.getAndSetDappHistory();
    }
  };

  fetchAndSetDappTrendings = async () => {
    if (this.hasMoreDappTrendings) {
      try {
        const { dapps, total } = await DappService.fetchTrendings(
          this.dappTrendings.length,
          FETCH_TREDING_LIMIT
        );
        runInAction(() => {
          this.hasMoreDappTrendings =
            this.dappTrendings.length + dapps?.length < total;
          this.dappTrendings = this.dappTrendings.concat(dapps);
        });
      } catch (e) {
        console.log("Failed to fetch and set dapp trendings", e);
      }
    }
  };

  setExploreTrendingDisplayItems = (items: DappPlaceholder[]) => {
    this.exploreTrendingDisplayItems = items;
  };

  setDappDesktopBoxesPerRow = (count?: number) => {
    this.dappDesktopBoxesPerRow = count;
  };

  setDappDesktopInnerHeight = (height?: number) => {
    this.dappDesktopInnerHeight = height;
  };
}
