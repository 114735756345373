import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { PaymentPlanFlowGeneral } from "./PaymentPlanFlowGeneral";
import { PaymentPlanFlowCustom } from "./PaymentPlanFlowCustom";
import { FtAsset } from "@/type/zkkontos";

const Container = styled.div`
  margin-top: -4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export type PaymentPlanType = "recommend" | "custom";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  mode: "editable" | "view";
  selectedPlan: PaymentPlanType;
  recommendPlan?: UniformedPayment[];
  recommendAssets?: FtAsset[];
  customPlan?: UniformedPayment[];
  customAssets?: FtAsset[];
  selectedBalanceIds?: string[];
  fixedBalanceIds?: string[];
  onBack: () => void;
  setCustomPlan?: (balanceIds: string[]) => void;
  setSelectedPlan?: (planType: PaymentPlanType) => void;
}

export const PaymentPlanFlow: React.FC<IProps> = ({
  className,
  style,
  mode,
  selectedPlan,
  recommendPlan,
  recommendAssets,
  customPlan,
  customAssets,
  selectedBalanceIds = [],
  fixedBalanceIds = [],
  onBack,
  setCustomPlan,
  setSelectedPlan,
}) => {
  const [step, setStep] = useState<"general" | "custom">("general");

  const handleClearCustomPlan = useCallback(() => {
    setCustomPlan?.([]);
    setSelectedPlan?.("recommend");
  }, [setCustomPlan, setSelectedPlan]);

  return (
    <Container className={className} style={style}>
      {step === "general" && (
        <PaymentPlanFlowGeneral
          mode={mode}
          step={step}
          selectedPlan={selectedPlan}
          recommendPlan={recommendPlan}
          recommendAssets={recommendAssets}
          customPlan={customPlan}
          customAssets={customAssets}
          onBack={onBack}
          onEdit={() => setStep("custom")}
          setSelectedPlan={setSelectedPlan}
          setStep={setStep}
          onClearCustom={handleClearCustomPlan}
        />
      )}

      {mode === "editable" && step === "custom" && (
        <PaymentPlanFlowCustom
          selectedBalanceIds={selectedBalanceIds}
          fixedBalanceIds={fixedBalanceIds}
          onDone={(ids: string[]) => {
            setCustomPlan?.(ids);
            setSelectedPlan?.("custom");
            setStep("general");
          }}
          onBack={() => {
            setStep("general");
          }}
        />
      )}
    </Container>
  );
};
