import { FtAsset, FtAssetBase } from "src/type/zkkontos";
import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import KontosNumber from "src/utils/KontosNumber";
import {
  DEFAULT_DECIMAL,
  DEFAULT_QUOTE_FTASSET_1,
  DEFAULT_QUOTE_FTASSET_2,
  DEFAULT_SLIPPAGE,
} from "src/config";
import { RootStore } from "../RootStore";
import * as TradeService from "src/service/trade-service";
import {
  ChainIndex,
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import {
  RespQuotePayment,
  RespTaskPayment,
} from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { RespQuote } from "@zkkontos/kontos-sdk/src/api/types";
import { isSameFtAsset } from "@/utils/zkkontosHelper";
import { convertFtAssetBaseToUniqueAsset } from "@/utils/helper";
import { findUserHolding } from "../storeHelper";
const MAX_BUY_VALUE = 10000000;
const MIN_BUY_VALUE = 0.01;

export class BuyStore {
  rootStore: RootStore;
  taskData?: RespTaskPayment;
  fetchingTaskData: boolean = false;
  receiver?: string;
  slippage: KontosNumber = DEFAULT_SLIPPAGE;

  toBuyFtAsset?: FtAsset;
  toBuyFtAssetBaseFromQuery?: { assetBase: FtAssetBase; updatedAt: number };
  toBuyFtAssetFromApp?: { asset: FtAsset; updatedAt: number };

  toBuyFtAssetValue?: KontosNumber;
  toChargeFtAsset: FtAssetBase = DEFAULT_QUOTE_FTASSET_1; // otc, receive ...
  quoteData?: RespQuotePayment;
  fetchingQuoteData: boolean = false;
  accountBalancesTracker?: IReactionDisposer;
  customPlan: FtAsset[] = [];
  enableCustomPlan: boolean = false;

  get insufficient(): boolean {
    return this.taskData
      ? this.taskData.msgs === null || this.taskData.msgs.length === 0
      : false;
  }

  // Only when custom plan is enabled
  get selectedBalances(): string[] {
    return this.enableCustomPlan
      ? this.customPlan.map((item) => item.balance!.id)
      : this.rootStore.tradeStore.userHoldings
          .filter((item) => !isSameFtAsset(item, this.toBuyFtAsset))
          .map((item) => item.balance!.id);
  }

  get selectedBalancesForApiCall(): string[] {
    return this.enableCustomPlan
      ? this.customPlan.map((item) => item.balance!.id)
      : [];
  }

  get fixedBalanceIds(): string[] {
    const ids = [];
    if (this.toBuyFtAsset !== undefined)
      ids.push(findUserHolding(this.toBuyFtAsset, true)?.balance?.id);
    return ids.filter((item) => item !== undefined) as string[];
  }

  setCustomPlan = (balanceIds: string[]) => {
    this.customPlan = this.rootStore.tradeStore.userHoldings?.filter(
      (item) => item.balance && balanceIds.includes(item.balance.id)
    );
  };

  setEnableCustomPlan = (enableCustomPlan: boolean) => {
    this.enableCustomPlan = enableCustomPlan;
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.init();
    this.startTrackingAccountName();
    this.startTrackingToBuyFtAsset();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  resetSlippage = () => {
    this.slippage = DEFAULT_SLIPPAGE;
  };

  resetTaskData = () => {
    this.taskData = undefined;
  };

  resetInput = () => {
    this.toBuyFtAssetValue = undefined;
    this.taskData = undefined;
  };

  resetPaymentPlan = () => {
    this.customPlan = [];
    this.enableCustomPlan = false;
  };

  reset = () => {
    this.resetTaskData();
    this.resetSlippage();
    this.resetInput();
    this.resetPaymentPlan();
    this.receiver = this.rootStore.tradeStore.accountName;
  };

  init = () => {
    this.reset();
  };

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.tradeStore.accountName,
      () => {
        this.init();
      }
    );
  };

  startTrackingToBuyFtAsset = () => {
    reaction(
      () => this.toBuyFtAsset,
      () => {
        if (isSameFtAsset(this.toBuyFtAsset, this.toChargeFtAsset)) {
          isSameFtAsset(this.toChargeFtAsset, DEFAULT_QUOTE_FTASSET_1)
            ? this.setToChargeFtAsset(DEFAULT_QUOTE_FTASSET_2)
            : this.setToChargeFtAsset(DEFAULT_QUOTE_FTASSET_1);
        }
      }
    );
  };

  startTrackingAccountBalances = () => {
    this.accountBalancesTracker = reaction(
      () => this.rootStore.tradeStore.accountBalances,
      async () => {
        try {
          await this.fetchAndSetTaskData();
        } catch (e) {
          console.warn(e);
        } finally {
          this.stopTrackingAccountBalances();
        }
      }
    );
  };

  stopTrackingAccountBalances = () => {
    this.accountBalancesTracker?.();
  };

  setInitFtAssets = (ftAssets: FtAsset[]) => {
    this.toBuyFtAsset = ftAssets?.[0];
  };

  fetchAndSetTaskData = async (
    toChargeFtAsset?: FtAssetBase
  ): Promise<RespTaskPayment | undefined> => {
    if (!this.rootStore.tradeStore.accountName || !this.receiver)
      return undefined;

    if (!this.toBuyFtAsset) return undefined;
    const taskData = await TradeService.fetchBuyPaymentTask(
      this.rootStore.tradeStore.accountName!,
      this.receiver,
      this.toBuyFtAsset?.chainIndex,
      this.toBuyFtAsset?.address,
      this.toBuyFtAssetValue || new KontosNumber(0),
      this.slippage,
      convertFtAssetBaseToUniqueAsset(toChargeFtAsset || this.toChargeFtAsset),
      this.selectedBalancesForApiCall
    );
    if (this.toBuyFtAsset) {
      runInAction(() => {
        this.taskData = taskData;
      });
    } else {
      runInAction(() => {
        this.resetInput();
      });
    }
    return taskData;
  };

  get hasValidResultTaskData(): boolean {
    return !!this.quote;
  }

  get inputValue(): KontosNumber | undefined {
    return this.toBuyFtAssetValue;
  }

  get inputMaxValue(): number {
    return MAX_BUY_VALUE;
  }

  get inputMinValue(): number {
    return MIN_BUY_VALUE;
  }

  get quote(): RespQuote | undefined {
    return this.taskData?.respSimulateBuy?.respQuote;
  }

  get fee(): KontosNumber | undefined {
    return this.taskData &&
      this.taskData.paymentPlan !== null &&
      this.taskData.paymentPlan.length > 0
      ? new KontosNumber(this.taskData?.totalFeeInUsd, DEFAULT_DECIMAL)
      : undefined;
  }

  get orderPrice(): KontosNumber | undefined {
    return this.taskData
      ? new KontosNumber(this.taskData?.totalPaymentsInUsd, DEFAULT_DECIMAL)
      : undefined;
  }

  get toBuyFtAssetQuantity(): KontosNumber | undefined {
    return this.quote
      ? new KontosNumber(this.quote?.assetAmount, DEFAULT_DECIMAL)
      : undefined;
  }

  get inputSymbol(): string {
    return "USD";
  }

  get maxAvailableUsd(): KontosNumber | null {
    return this.taskData
      ? new KontosNumber(
          this.taskData.respSimulateBuy?.maxAvailableUsd,
          DEFAULT_DECIMAL
        )
      : null;
  }

  get totalUsd(): KontosNumber | null {
    return this.rootStore.tradeStore.balanceInUSD
      ? this.rootStore.tradeStore.balanceInUSD
      : null;
  }

  get hasUserInput(): boolean {
    return !!this.toBuyFtAssetValue;
  }

  get toBuyFtAssetValueEstimated(): KontosNumber | undefined {
    return this.toBuyFtAsset && this.toBuyFtAssetQuantity
      ? this.toBuyFtAssetQuantity.multiply(
          this.toBuyFtAsset.usdPrice,
          DEFAULT_DECIMAL
        )
      : undefined;
  }

  get defaultReceiveFtAssetType(): ChainIndex {
    return NonChainIndex.All;
  }

  get onReceiveChainChange(): (index: ChainIndex) => void {
    return () => {};
  }

  get onChainChange(): (index: ChainIndex) => void {
    return this.rootStore.chainStore.setDefaultOptForBuy;
  }

  // This value decides the chainOpt selected first
  // when user click the to buy asset panel
  // When the cache value is fav, we need to check user's hasFavFtAssets field
  get defaultToBuyFtAssetType(): ChainIndex {
    // TODO: Fav asset is currenttly disabled
    // return this.rootStore.chainStore.defaultOptForBuy ===
    //   NonChainIndex.Favorites
    //   ? this.rootStore.favStore.hasFavFtAssets
    //     ? NonChainIndex.Favorites
    //     : NonChainIndex.Recommend
    //   : this.rootStore.chainStore.defaultOptForBuy;

    return this.rootStore.chainStore.defaultOptForBuy ===
      NonChainIndex.Favorites
      ? NonChainIndex.Recommend
      : this.rootStore.chainStore.defaultOptForBuy;
  }

  setToBuyFtAsset = (asset?: FtAsset) => {
    this.toBuyFtAsset = asset;
    if (this.customPlan.some((item) => isSameFtAsset(item, asset))) {
      this.resetPaymentPlan();
    }
  };

  setToBuyFtAssetValue = (value?: KontosNumber) => {
    this.toBuyFtAssetValue = value;
  };

  setTaskData = (taskData?: RespTaskPayment) => {
    this.taskData = taskData;
  };

  setReceiver = (_receiver: string) => {
    this.receiver = _receiver;
  };

  setSlippage = (slippage: KontosNumber) => {
    this.slippage = slippage;
  };

  setToChargeFtAsset = (toChargeFtAsset: FtAssetBase) => {
    this.toChargeFtAsset = toChargeFtAsset;
  };

  setQuoteData = (quoteData: RespQuotePayment) => {
    this.quoteData = quoteData;
  };
}
