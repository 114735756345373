import { Trans, useTranslation } from "react-i18next";
import { UniversalModal } from "./UniversalModal";
import { observer } from "mobx-react";
import { useStores } from "@/hooks/useStore";
import { useNavigate } from "react-router-dom";
import { ROUTE_AUTH } from "@/router/router-config";
import tipsIcon from "src/assets/icons/tips.svg";
import styled from "styled-components";
import { fontBold, fontMainText } from "@/style/style.global";

const Text = styled.span`
  ${fontMainText}
`;

const Strong = styled.span`
  ${fontBold}
`;

export const AuthModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const { globalStore } = useStores();
  const navigate = useNavigate();

  return globalStore.showAuthModal ? (
    <UniversalModal
      icon={tipsIcon}
      title={"Sign in to Continue"}
      content={
        <Trans i18nKey={"trans-auth-content"}>
          <Text>
            To experience Kontos V2, click <Strong>"Confirm"</Strong> to create
            a new account or recover an existing one.
          </Text>
        </Trans>
      }
      leftBtnProps={{
        text: t("Later"),
        onClick: () => {
          globalStore.closeAuthModal();
        },
      }}
      rightBtnProps={{
        text: t("Confirm"),
        onClick: () => {
          globalStore.closeAuthModal();
          navigate(ROUTE_AUTH);
        },
      }}
    />
  ) : (
    <></>
  );
});
