import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { Chain, FtAsset } from "src/type/zkkontos";
import KontosNumber from "src/utils/KontosNumber";
import { isSameFtAsset } from "src/utils/zkkontosHelper";
import { DEFAULT_DECIMAL } from "src/config";
import { fetchSpecificFtAsset } from "src/service/trade-service";
import {
  callDepositCallback,
  ReqDepositCallback,
} from "@/apis/event-bybit-api";
import localKeeper from "./localKeeper";

export type TxHash = {
  explorerUrl: string;
  chainId: string;
  address: string;
  hash: string;
};

export type DepositOrder = {
  chainId: string;
  address: string;
  amount: string;
  decimals: string;
};

export class ReceiveStore {
  rootStore: RootStore;
  chain?: Chain;
  asset?: FtAsset;
  amount?: KontosNumber;
  pendingTxHashes: TxHash[] = [];
  byDepositOrder: boolean = false;
  freezeWatchingChainId: boolean = false;
  restoring: boolean = true;
  bybitTaskDepositCallbackParams: ReqDepositCallback[] =
    localKeeper.getBybitTaskDepositCallbackParams() || [];

  get toReceiveAaAddress(): string | undefined {
    if (this.chain?.chainIndex && this.rootStore.userStore.aaAccounts) {
      return (
        this.rootStore.userStore.aaAccounts?.[this.chain.chainIndex] ||
        undefined
      );
    }
    return undefined;
  }

  get assetBalance(): KontosNumber | undefined {
    if (this.asset) {
      const matched = this.rootStore.userStore.userHoldings.find((item) =>
        isSameFtAsset(item, this.asset)
      );
      if (matched) {
        return new KontosNumber(matched.balance?.balance, DEFAULT_DECIMAL);
      }
      return new KontosNumber(0);
    }
    return undefined;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.bybitTaskDepositCallbackParams =
      localKeeper.getBybitTaskDepositCallbackParams() || [];
    this.startReportingBybitTask();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset = async () => {
    this.amount = undefined;
    this.asset = undefined;
    this.byDepositOrder = false;
  };

  setChainByChainIndex = async (chainIndex: string) => {
    const matched =
      await this.rootStore.chainStore.fetchSpecificChain(chainIndex);
    this.setChain(matched);
    return matched;
  };

  setAssetByAssetAddress = async (address: string) => {
    if (!this.chain) return undefined;
    const matched = await fetchSpecificFtAsset(this.chain.chainIndex, address);
    this.setAsset(matched);
    return matched;
  };

  setChain = (chain?: Chain) => {
    this.byDepositOrder = false;
    this.amount = undefined;
    this.asset = undefined;
    this.chain = chain;
  };

  setAsset = (asset?: FtAsset) => {
    if (!this.chain) {
      this.amount = undefined;
      this.asset = undefined;
      return;
    }
    if (
      asset &&
      this.chain &&
      asset.chainIndex.toLowerCase() !== this.chain.chainIndex.toLowerCase()
    ) {
      return;
    }
    this.byDepositOrder = false;
    this.amount = undefined;
    this.asset = asset;
  };

  setAmount = (amount?: KontosNumber) => {
    if (!this.chain || !this.asset) {
      this.amount = undefined;
      return;
    }
    this.byDepositOrder = false;
    this.amount = amount;
  };

  addPendingTxHash = (hash: TxHash) => {
    this.pendingTxHashes = [...this.pendingTxHashes, hash];
  };

  removePendingTxHash = (hash: string) => {
    this.pendingTxHashes = this.pendingTxHashes.filter(
      (item) => item.hash !== hash
    );
  };

  setByDepositOrder = (byDepositOrder: boolean) => {
    this.byDepositOrder = byDepositOrder;
  };

  setFreezeWatchingChainId = (freezeWatchingChainId: boolean) => {
    this.freezeWatchingChainId = freezeWatchingChainId;
  };

  addBybitTask = (req: ReqDepositCallback) => {
    localKeeper.addBybitTaskDepositCallbackParams(req);
    this.bybitTaskDepositCallbackParams =
      localKeeper.getBybitTaskDepositCallbackParams();
  };

  removeBybitTask = (txHash: string) => {
    localKeeper.removeBybitTaskDepositCallbackParams(txHash);
    this.bybitTaskDepositCallbackParams =
      localKeeper.getBybitTaskDepositCallbackParams();
  };

  startReportingBybitTask = async () => {
    this.bybitTaskDepositCallbackParams.forEach(async (param) => {
      try {
        const { isExist } = await callDepositCallback(param);
        if (isExist) {
          this.removeBybitTask(param.txHash);
        }
      } catch (e) {
        console.warn(e);
      }
    });
    setTimeout(() => {
      this.startReportingBybitTask();
    }, 30000);
  };
}
