import { EXPLORER_KONTOS_URL, TEMP_EXPLORER_URL } from "src/config";
import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { API } from "src/apis/core/API";

export interface ReqReferalInfo {
  name: string;
}

export interface IInvite {
  invitee_name: string;
  asset_usd_amount: string;
  indect_asset_usd_amount: string;
}

export interface RespReferralInfo {
  earning_amount: string;
  invitee: IInvite[];
}

export const getReferralInfo = async (
  data: ReqReferalInfo,
  endpoint: string = EXPLORER_KONTOS_URL
): Promise<RespReferralInfo> => {
  return request<ReqReferalInfo, RespReferralInfo>(
    API.account.referralInfo,
    data,
    endpoint
  );
};

export interface ReqSyncAccount {
  account: string;
  chainIndex: string;
  assetAddress: string;
}

export interface RespSyncAccount {}

export const callSyncSingleBalance = async (
  data: ReqSyncAccount,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespSyncAccount> => {
  return request<ReqSyncAccount, RespSyncAccount>(
    API.masset.syncSingleBalance,
    data,
    endpoint
  );
};
