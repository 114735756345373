import React, {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  height: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const Input = styled.input<{
  $status?: InputStatusV3;
  $leftDistance: number;
  $rightDistance: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  flex-grow: 1;
  outline: none;
  overflow: hidden;
  color: ${({ disabled }) =>
    disabled ? "var(--Deep-200, #B2B6BC)" : "var(--Deep-800, #1A2535)"};
  text-overflow: ellipsis;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  background: transparent;
  box-sizing: border-box;
  font-family: HarmonyOS Sans;
  border: 1px solid var(--Deep-50, #edeef1);
  border-radius: 99px;
  padding: ${(props) =>
    `0 ${props.$rightDistance}px 0 ${props.$leftDistance}px`};
  line-height: 56px;
  background: var(--Deep-25, #f5f5f6);
  z-index: 1;

  &::placeholder {
    color: var(--Deep-200, #b2b6bc);
  }

  ${(props) =>
    props.$status === "warning" &&
    css`
      border-color: var(--Warning, #faad14);
      color: var(--Warning, #faad14);
    `};

  ${(props) =>
    props.$status === "error" &&
    css`
      border-color: var(--error-notice, #ff1e2b);
      color: var(--error-notice, #ff1e2b);
    `};

  ${(props) =>
    props.$status === "success" &&
    css`
      border-color: var(--Success, #52c41a);
      color: var(--Success, #52c41a);
    `};

  &:focus {
    border-color: var(--Kontos-Blue, #413dec) !important;
    color: var(--Kontos-Blue, #413dec) !important;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
`;

export type InputStatusV3 = "default" | "warning" | "success" | "error";

// Define the props for the input component
interface InputWithIconsProps {
  ref?: Ref<{ focus: () => void }>;
  inputStatus?: InputStatusV3;
  leftItem?: React.ReactNode;
  leftWrapperStyle?: React.CSSProperties;
  leftWrapperClassName?: string;
  rightItem?: React.ReactNode;
  rightWrapperStyle?: React.CSSProperties;
  rightWrapperClassName?: string;
  className?: string;
  style?: React.CSSProperties;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
}

export const KontosInputV3: React.FC<InputWithIconsProps> = forwardRef(
  (
    {
      inputStatus,
      leftItem,
      leftWrapperStyle,
      leftWrapperClassName = "",
      rightItem,
      rightWrapperStyle,
      rightWrapperClassName = "",
      className = "",
      style,
      inputClassName = "",
      inputStyle,
      placeholder = "",
      value,
      disabled = false,
      readonly = false,
      autoFocus = false,
      onChange,
      onKeyDown,
      onFocus,
      onBlur,
      onSubmit,
    }: InputWithIconsProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const leftItemRef = useRef<HTMLDivElement>(null);
    const rightItemRef = useRef<HTMLDivElement>(null);
    const [leftWidth, setLeftWidth] = useState<number>(0);
    const [rightWidth, setRightWidth] = useState<number>(0);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const handleResize = () => {
        if (leftItemRef.current) {
          setLeftWidth(leftItemRef.current.offsetWidth);
        }
        if (rightItemRef.current) {
          setRightWidth(rightItemRef.current.offsetWidth);
        }
      };

      const resizeObserver = new ResizeObserver(handleResize);
      const currentLeftItem = leftItemRef.current;
      const currentRightItem = rightItemRef.current;

      if (leftItemRef.current) {
        resizeObserver.observe(leftItemRef.current);
        setLeftWidth(leftItemRef.current.offsetWidth);
      }
      if (rightItemRef.current) {
        resizeObserver.observe(rightItemRef.current);
        setRightWidth(rightItemRef.current.offsetWidth);
      }

      return () => {
        if (currentLeftItem) {
          resizeObserver.unobserve(currentLeftItem);
        }
        if (currentRightItem) {
          resizeObserver.unobserve(currentRightItem);
        }
        resizeObserver.disconnect();
      };
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };

    return (
      <Container className={`${inputStatus} ${className}`} style={style}>
        <ItemWrapper
          className={leftWrapperClassName}
          style={leftWrapperStyle}
          ref={leftItemRef}
        >
          {leftItem}
        </ItemWrapper>
        <Input
          $status={inputStatus}
          $leftDistance={leftWidth + 8}
          $rightDistance={rightWidth + 8}
          className={inputClassName}
          readOnly={readonly}
          ref={inputRef}
          style={inputStyle}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder || ""}
          value={value}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !!onSubmit) {
              inputRef.current?.blur();
              onSubmit();
            } else {
              onKeyDown?.(event);
            }
          }}
        />
        <ItemWrapper
          className={rightWrapperClassName}
          style={rightWrapperStyle}
          ref={rightItemRef}
        >
          {rightItem}
        </ItemWrapper>
      </Container>
    );
  }
);
