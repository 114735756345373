import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { Dapp as KontosDapp } from "src/apis/types/DappTypes";
import Long from "long";
import {
  ApiChain,
  AssetChange as KontosAssetChange,
  SingerSendOrReceive,
  FtAsset as ApiFtAsset,
  ApiAccountDetail,
  ApiTaskDetail,
  ApiTask,
  FtAssetDetail as ApiFtAssetDetail,
} from "src/apis/types";

export const toKontosAccount = (
  account: ApiAccountDetail | string
): Account => {
  if (typeof account === "string") {
    return {
      createdAt: -1,
      name: account,
      pubKey: "",
      futurePubKey: "",
      recoveryPubKey: "",
      blockChainAccounts: {},
      guardians: [],
      futureGuardians: [],
      actionThreshold: 0,
      futureThreshold: 0,
      updateGuardiansDeadline: Long.fromValue(0).toNumber(),
      accountStatus: 0,
      inviter: "",
      nameAddress: "",
      kontosAddress: "",
      emailGuardians: [],
      futureEmailGuardians: [],
    };
  } else {
    return account as Account;
  }
};

export const toFtAsset = (data: UniformedPayment): FtAsset => {
  return {
    chainIndex: data.chainIndex,
    address: data.assetAddress,
    name: data.assetName,
    symbol: data.assetSymbol,
    decimals: data.assetDecimals,
    imageUrl: data.assetImageUrl,
    usdPrice: data.assetUsdPrice,
    isWhitelist: true,
    balance: {
      id: "",
      balance: "",
      balanceUsdAmount: "",
    },
  };
};

export interface Account extends ApiAccountDetail {}

export interface FtAsset extends ApiFtAsset {}

export interface FtAssetDetail extends ApiFtAssetDetail {}

export interface FtAssetBase {
  chainIndex: string;
  address: string;
}

export interface Chain extends ApiChain {}

export interface Task extends ApiTask {}

export interface TaskDetail extends ApiTaskDetail {}

export enum ActivityType {
  Send = "Send",
  Receive = "Receive",
}

export const toActivityType = (
  singerSendOrReceive: SingerSendOrReceive
): ActivityType => {
  if (singerSendOrReceive === SingerSendOrReceive.Send)
    return ActivityType.Send;
  return ActivityType.Receive;
};

export interface Dapp extends KontosDapp {}

export interface AssetChange extends KontosAssetChange {}
