import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import { MAX_WIDTH } from "src/config";
import { UiSettingStore } from "./ui/UiSettingStore";
import {
  ROUTE_TRADE,
  ROUTE_TRADE_BUY,
  ROUTE_TRADE_SELL,
} from "src/router/router-config";

export class UiStore {
  rootStore: RootStore;
  uiSettingStore: UiSettingStore = new UiSettingStore(this);
  isOverWidth: boolean = window.innerWidth > MAX_WIDTH;
  innerWidth?: number;
  innerHeight?: number;
  tradeRoute: string = ROUTE_TRADE;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    window.addEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.isOverWidth = window.innerWidth > MAX_WIDTH;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  setTradeRouteBuy = () => {
    this.tradeRoute = `${ROUTE_TRADE}${ROUTE_TRADE_BUY}`;
  };

  setTradeRouteSell = () => {
    this.tradeRoute = `${ROUTE_TRADE}${ROUTE_TRADE_SELL}`;
  };
}
