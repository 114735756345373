import styled from "styled-components";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { observer } from "mobx-react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { FtAsset } from "src/type/zkkontos";
import { useStores } from "src/hooks/useStore";
import { QuoteWrapper } from "src/components/assets-detail/QuoteWrapper";
import { TradeNavigator } from "./TradeNavigator";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { ROUTE_TRADE_BUY, ROUTE_TRADE_SELL } from "src/router/router-config";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import { TradeBuy } from "./buy/TradeBuy";
import { TradeSell } from "./sell/TradeSell";
import { TradeModeToggle } from "./TradeModeToggle";
import { TradeMode } from "@/store/trade/TradeStore";
import { TradeSetting } from "../settings/trade/TradeSetting";

const Container = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #f1f5ff 0%, #fff 100%);
  box-shadow: 6px 6px 10px 0px rgba(0, 13, 31, 0.03);
`;

const ContentContainer = styled(Scrollable)`
  position: relative;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }
`;

export const Trade: React.FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { marketStore, tradeStore } = useStores();
  const showQuote = searchParams.get("showQuote");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const domNode = wrapperRef.current as Element | undefined;
  const [showTradeSettingSheet, setShowTradeSettingSheet] =
    useState<boolean>(false);

  const viewAssetInfo = useCallback(
    (asset: FtAsset) => {
      marketStore.setCurrentAssetQuote(asset);
      setSearchParams({
        ...searchParams,
        chainIndex: asset.chainIndex,
        ftAssetAddress: asset.address,
        showQuote: "1",
        hideNavigationBar: "1",
      });
    },
    [marketStore, searchParams, setSearchParams]
  );

  const handleNavBtnClick = useCallback(() => {
    setShowTradeSettingSheet(true);
  }, []);

  const closeModals = useCallback(() => {
    setShowTradeSettingSheet(false);
  }, []);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: true,
  });

  useEffect(() => {
    if (
      pathname.toLowerCase().endsWith(ROUTE_TRADE_BUY) &&
      tradeStore.mode !== TradeMode.Buy
    ) {
      tradeStore.switchMode(TradeMode.Buy);
      return;
    }

    if (
      pathname.toLowerCase().endsWith(ROUTE_TRADE_SELL) &&
      tradeStore.mode !== TradeMode.Sell
    ) {
      tradeStore.switchMode(TradeMode.Sell);
      return;
    }
  }, [pathname, tradeStore]);

  useEffect(() => {
    return () => {
      tradeStore.resetAiFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={wrapperRef}>
      {/* When the quote view is displayed, the header is hidden to allow for a full-page view */}
      <TradeNavigator onClick={handleNavBtnClick} />

      <ContentContainer>
        <TradeModeToggle />

        {tradeStore.mode === TradeMode.Buy && (
          <TradeBuy viewAssetInfo={viewAssetInfo} />
        )}

        {tradeStore.mode === TradeMode.Sell && (
          <TradeSell viewAssetInfo={viewAssetInfo} />
        )}
      </ContentContainer>

      {/* Quote Modal */}
      {showQuote && (
        <QuoteWrapper>
          <div />
        </QuoteWrapper>
      )}

      {/* Trade Setting Sheet */}
      <BottomSheet
        isOpen={showTradeSettingSheet}
        onClose={() => setShowTradeSettingSheet(false)}
        mountPoint={domNode}
      >
        <TradeSetting onBack={() => setShowTradeSettingSheet(false)} />
      </BottomSheet>
    </Container>
  );
});
