import React, { useState, useEffect } from "react";
import styled from "styled-components";
import homeBg from "src/assets/images/home-bg.jpg";

interface BackgroundImageProps {
  imageUrl?: string;
}

const StyledBackgroundImage = styled.div<
  BackgroundImageProps & { loaded: boolean }
>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  background-image: url(${(props) => props.imageUrl});
  background-size: 126% auto;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const HomeBg: React.FC<BackgroundImageProps> = ({ imageUrl = homeBg }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl || homeBg;

    img.onload = () => {
      setLoaded(true);
    };
  }, [imageUrl]);

  return <StyledBackgroundImage imageUrl={imageUrl} loaded={loaded} />;
};

export default HomeBg;
