import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TradeBlackUpArrowSvg from "src/assets/icons/trade/trade-black-up-arrow.svg";
import { t } from "i18next";
import toast, { NewToastProps } from "src/components/toast/Toast";
import { PaymentMethod } from "@/store/trade/PaymentStore";
import { fontBold } from "@/style/style.global";

const DropdownWrapper = styled.div`
  position: relative;
`;

const ArrowIcon = styled.img<{ $isOpen: boolean; $selectable: boolean }>`
  display: ${(props) => (props.$selectable ? "inline" : "none")};

  margin-left: 8px;
  transition: transform 0s ease-in-out;

  transform: rotate(${(props) => (props.$isOpen ? "0" : "180deg")});
`;

const SelectedItem = styled.div<{ $isOpen: boolean; $selectable: boolean }>`
  box-sizing: border-box;
  width: 142px;
  height: 28px;
  // padding: 6px 10px 6px 20px;
  background-color: ${(props) => props.theme.colors.__white};

  ${(props) =>
    props.$selectable
      ? "cursor: pointer; -webkit-tap-highlight-color: transparent;"
      : ""}

  border-radius: ${(props) => (props.$isOpen ? "16px 16px 0 0" : "99px")};
  display: ${(props) => (props.$isOpen ? "flex" : "inline-flex")};
  align-items: center;
  justify-content: center;

  transition: border-radius 0s ease-in-out;

  color: ${(props) => props.theme.colors.__deep_800};
  ${fontBold};
  font-size: 14px;
`;

const DropdownMenu = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 142px;
  border-left: ${(props) =>
    `${props.$isOpen && `1px solid ${props.theme.colors.__deep_50}`} `};
  border-right: ${(props) =>
    `${props.$isOpen && `1px solid ${props.theme.colors.__deep_50}`} `};
  border-bottom: ${(props) =>
    `${props.$isOpen && `1px solid ${props.theme.colors.__deep_50}`} `};
  background-color: ${(props) => props.theme.colors.__white};
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  z-index: 10;

  max-height: ${(props) => (props.$isOpen ? "220px" : "0")};
  transition: max-height 0s ease-in-out;

  text-align: center;
  color: ${(props) => props.theme.colors.__deep_800};
  ${fontBold};
  font-size: 14px;

  padding: ${(props) => (props.$isOpen ? "8px 0" : "0")};
`;

const DropdownItem = styled.div`
  padding: 9px 11px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    margin: 0 4px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.__deep_50};
    color: ${(props) => props.theme.colors.__kontos_blue};
  }
`;

export interface PaymentOptionType {
  label: string;
  value: PaymentMethod;
  toastObj: NewToastProps;
  isAllowed: boolean;
}

const findSelected = (
  options: PaymentOptionType[],
  selected: PaymentMethod
) => {
  return options.find((item) => item.value === selected);
};

interface Props {
  selected: PaymentMethod;
  options: PaymentOptionType[];
  onChange: (selected: PaymentOptionType) => void;
}

const PaymentSelect: React.FC<Props> = ({ selected, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const selectable = options.length > 1;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: PaymentOptionType) => {
    if (!option.isAllowed) {
      toast(option.toastObj);
      return;
    }
    onChange(option);
    setIsOpen(false);
  };

  return (
    <DropdownWrapper ref={wrapperRef}>
      {/* Selected One */}
      <SelectedItem
        $selectable={selectable}
        $isOpen={isOpen}
        onClick={() => {
          selectable && toggleDropdown();
        }}
      >
        {t(findSelected(options, selected)?.label || "")}
        <ArrowIcon
          $selectable={selectable}
          $isOpen={isOpen}
          src={TradeBlackUpArrowSvg}
        />
      </SelectedItem>
      {/* Options */}
      <DropdownMenu $isOpen={isOpen}>
        {options
          .filter((option) => option.value !== selected && option.isAllowed)
          .map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default PaymentSelect;
