import styled from "styled-components";
import { ReactNode } from "react";
import pendingIco from "src/assets/icons/events/eb/action-pending.svg";
import successIco from "src/assets/icons/events/eb/action-success.svg";
import { KontosButton } from "src/components/button/KontosButton";
import arrowIco from "src/assets/icons/events/eb/black-up-arrow.svg";
import {
  EB_ACTION_STATUS_PENDING,
  EB_ACTION_STATUS_SUCCESS,
} from "src/apis/types/energyBoostingTypes";
import starIco from "src/assets/icons/events/eb/star.svg";
import loadingIcon from "src/assets/icons/white-linear-loading.svg";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ clickable?: boolean }>`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .action-title {
    color: var(--Deep-800, #1a2535);
    ${fontBold};
    font-size: 14px;
  }

  .action-title-energy {
    color: var(--Deep-400, #80868f);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }

  .action-pending {
    color: var(--Deep-800, #1a2535);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
  }

  .action-success {
    color: var(--Deep-200, #b2b6bc);
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;
    // text-decoration: line-through;
  }

  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > .icon {
    margin-right: 9.6px;
    width: 12.8px;
    height: 12.8px;
  }

  > .star-icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  > .desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Right = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  white-space: nowrap;
`;

const FoldingIco = styled.img<{ $isFold: boolean }>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  transform: ${(props) => (props.$isFold ? "rotate(180deg)" : "")};
`;

const StyledKontosButton = styled(KontosButton)<{ bgColor?: string }>`
  width: 72px;
  height: 24px;
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
  font-weight: 400;
  padding: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.bgColor &&
    `
    background-color: ${props.bgColor} !important
  `}
`;

const LoadingIcon = styled.img`
  display: span;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
`;

interface Props {
  isTitle: boolean;
  status: number;
  desc: ReactNode;
  className?: string;
  btnText?: string;
  btnDisabled?: boolean;
  btnPending?: boolean;
  isFold?: boolean;
  needStar?: boolean;
  rightText?: ReactNode;
  onClick?: () => void;
  onGlobalClick?: () => void;
}

export const EbActionItem: React.FC<Props> = ({
  isTitle,
  status,
  desc,
  className,
  btnText,
  btnDisabled,
  btnPending,
  isFold = false,
  needStar = false,
  rightText,
  onClick,
  onGlobalClick,
}) => {
  let icon: any = pendingIco;
  let fontClass: string = "action-pending";
  switch (status) {
    case EB_ACTION_STATUS_PENDING:
      icon = pendingIco;
      fontClass = "action-pending";
      break;
    case EB_ACTION_STATUS_SUCCESS:
      icon = successIco;
      fontClass = "action-success";
      break;
    default:
      icon = pendingIco;
      fontClass = "action-pending";
      break;
  }

  return (
    <Container
      className={className}
      clickable={isTitle}
      onClick={onGlobalClick}
    >
      <Left>
        <img className="icon" src={icon} alt="" />
        {needStar && <img className="star-icon" src={starIco} alt="" />}
        {isTitle ? (
          <span className="desc action-title">{desc}</span>
        ) : (
          <span className={`desc ${fontClass}`}>{desc}</span>
        )}
      </Left>
      <Right>
        {isTitle ? (
          <FoldingIco $isFold={isFold} src={arrowIco} alt="" />
        ) : btnText ? (
          <StyledKontosButton
            $isOutlined={btnPending ? false : true}
            bgColor={btnPending ? "var(--Kontos-Blue, #413DEC)" : undefined}
            disabled={btnDisabled || btnPending}
            onClick={onClick}
          >
            {btnPending ? (
              <LoadingIcon src={loadingIcon} alt="Pending" />
            ) : (
              <span>{btnText}</span>
            )}
          </StyledKontosButton>
        ) : rightText ? (
          <span>{rightText}</span>
        ) : (
          <></>
        )}
      </Right>
    </Container>
  );
};
