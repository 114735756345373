import styled from "styled-components";
import guardianInfoIco from "src/assets/icons/guardianInfo.svg";
import GuardianItem from "src/components/guardian/GuardianItem";
import guardianAvatarIco from "src/assets/images/guardian-avatar.svg";
import { useCallback, useEffect, useState } from "react";
import { isSameName } from "src/utils/zkkontosHelper";
import { KontosButton } from "src/components/button/KontosButton";
import { t } from "i18next";
import { fetchCurrentAndFutureGuardianNames } from "src/service/guardian-service";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { shortAddress } from "src/utils/helper";
import { Trans } from "react-i18next";
import { useStores } from "src/hooks/useStore";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  flex: 1;
  height: 100%;
  position: relative;
  .header {
  }
`;

const GuardianDescription = styled.div`
  color: ${(props) => props.theme.colors.__deep_800};
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  text-align: center;

  padding: 20px 30px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > .icon {
    margin-bottom: 7px;
    width: 29px;
    height: 17px;
  }

  > div .keyword {
    color: var(--Kontos-Blue, #413dec);
    ${fontBold}
    font-size: 16px;
    line-height: 130%;
  }
`;

const Scrollable = styled.div`
  overflow: auto;
  margin: 16px 0;
  padding: 0 20px;
`;

const StyledKontosButton = styled(KontosButton)`
  position: absolute;
  bottom: 16px;
  left: 20px;

  z-index: 1;
  width: calc(100% - 40px);
`;

type DivsComponentProps = {
  count: number;
};

const SkeletonArrComponent: React.FC<DivsComponentProps> = ({ count }) => {
  const divs = Array.from({ length: count }).map((_, index) => (
    <Skeleton
      key={index}
      style={{ height: "56px", borderRadius: "99px", marginBottom: "5px" }}
    />
  ));

  return <div>{divs}</div>;
};

interface Props {
  onDone: () => void;
}

const NewGuardiansModal: React.FC<Props> = ({ onDone }) => {
  const { userStore } = useStores();
  const futureThresholds: number = userStore?.accountInfo?.futureThreshold || 0;
  const futureGuardianList: string[] =
    userStore?.accountInfo?.futureGuardians || [];
  const rawGuardianList: string[] = userStore?.accountInfo?.guardians || [];
  const [requesting, setRequesting] = useState<boolean>(false);
  const [currentNames, setCurrentNames] = useState<string[]>([]);
  const [futureNames, setFutureNames] = useState<string[]>([]);

  const handleDoneClick = useCallback(() => {
    onDone();
  }, [onDone]);

  useEffect(() => {
    const fetchData = async () => {
      if (userStore?.accountInfo?.name) {
        setRequesting(true);
        try {
          const resp = await fetchCurrentAndFutureGuardianNames(
            userStore?.accountInfo?.name
          );
          setCurrentNames(resp.currentNames);
          setFutureNames(resp.futureNames);
        } catch (e) {
          console.log(e);
        } finally {
          setRequesting(false);
        }
      }
    };

    fetchData();
  }, [userStore?.accountInfo?.name]);

  return (
    <Container>
      <GuardianDescription>
        <img className="icon" src={guardianInfoIco} alt="" />
        <Trans i18nKey={"trans-futureThresholds"}>
          <div>
            Recover your account requires approval from any{" "}
            <span className="keyword">{{ futureThresholds } as any}</span> of
            the following Guardians.
          </div>
        </Trans>
      </GuardianDescription>
      <Scrollable>
        {requesting ? (
          <SkeletonArrComponent count={futureGuardianList.length} />
        ) : futureNames.length > 0 ? (
          futureNames.map((guardianName) => (
            <GuardianItem
              key={guardianName}
              name={guardianName}
              avatar={guardianAvatarIco}
              isNew={
                !currentNames.find((rawName) =>
                  isSameName(rawName, guardianName)
                )
              }
            />
          ))
        ) : (
          futureGuardianList.map((guardianAddr) => (
            <GuardianItem
              key={guardianAddr}
              name={shortAddress(guardianAddr)}
              avatar={guardianAvatarIco}
              isNew={
                !rawGuardianList.find((rawName) =>
                  isSameName(rawName, guardianAddr)
                )
              }
              hasSuffix={false}
            />
          ))
        )}
      </Scrollable>
      <StyledKontosButton onClick={handleDoneClick}>
        {t("Done")}
      </StyledKontosButton>
    </Container>
  );
};

export default NewGuardiansModal;
