import { DEFAULT_DECIMAL } from "@/config";
import KontosNumber from "@/utils/KontosNumber";
import { UniformedPayment } from "@zkkontos/kontos-sdk/src/api/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CustomizeButton } from "./CustomizeButton";
import { FtAsset } from "@/type/zkkontos";
import {
  bulkConvertFtAssetToUniformedPayment,
  bulkConvertUniformedPaymentToFtAsset,
} from "@/utils/helper";
import { PaymentPlanType } from "./PaymentPlanFlow";
import { PaymentButton } from "./PaymentButton";
import { useCallback, useState } from "react";
import Header from "@/components/common/header";
import { SingleIconButton } from "@/components/button/SingleIconButton";
import { Scrollable } from "@/components/scrollable/Scrollable";
import { KontosButton } from "@/components/button/KontosButton";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const MainContainer = styled.div`
  padding: 10px 16px 16px 16px;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
`;

const EditableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`;

interface IProps {
  mode: "editable" | "view";
  step: "general" | "custom";
  selectedPlan: PaymentPlanType;
  recommendPlan?: UniformedPayment[];
  recommendAssets?: FtAsset[];
  customPlan?: UniformedPayment[];
  customAssets?: FtAsset[];
  onBack: () => void;
  onEdit: () => void;
  onClearCustom: () => void;
  setSelectedPlan?: (planType: PaymentPlanType) => void;
  setStep: (step: "general" | "custom") => void;
}

const calcUsdAmountFromUniformedPaymentList = (
  plan: UniformedPayment[]
): KontosNumber => {
  if (plan.length === 0) return new KontosNumber(0);
  return plan
    .map((item) => new KontosNumber(item.usdAmount, DEFAULT_DECIMAL))
    .reduce((itemA, itemB) => itemA.add(itemB));
};

export const PaymentPlanFlowGeneral: React.FC<IProps> = ({
  mode,
  step,
  selectedPlan,
  recommendPlan,
  recommendAssets,
  customPlan,
  customAssets,
  onBack,
  onEdit,
  onClearCustom,
  setSelectedPlan,
  setStep,
}) => {
  const { t } = useTranslation();
  const [expandedItem, setExpandedItem] = useState<
    "recommend" | "custom" | undefined
  >(
    customPlan !== undefined && customPlan.length > 0
      ? "custom"
      : recommendPlan !== undefined && recommendPlan.length > 0
        ? "recommend"
        : undefined
  );
  const [showEditBtns, setShowEditBtns] = useState<boolean>(false);

  const handleHeaderCallback = useCallback(() => {
    if (step === "general") {
      onBack();
      return;
    }

    if (step === "custom") {
      setStep("general");
      return;
    }
  }, [onBack, setStep, step]);

  const handleHeaderRightCallback = useCallback(() => {
    if (expandedItem === "custom") setExpandedItem(undefined);
    setShowEditBtns((prev) => !prev);
  }, [expandedItem]);

  return (
    <Container>
      {mode === "editable" && (
        <Header
          title={t("Payment Plan")}
          padding="8px 24px"
          enableBack
          callBack={handleHeaderCallback}
          rightBtnText={
            customAssets && customAssets.length > 0
              ? showEditBtns
                ? t("Cancel")
                : t("Manage")
              : ""
          }
          rightBtnCallBack={handleHeaderRightCallback}
        />
      )}

      <MainContainer>
        <Scrollable>
          {/* View Mode */}
          {mode === "view" && (
            <>
              {recommendPlan && recommendPlan.length > 0 && (
                <PaymentButton
                  title={t("Recommend Plan")}
                  subTitle={
                    `${recommendPlan.length} ` +
                    (recommendPlan.length <= 1 ? t("Asset") : t("Assets")) +
                    " ≈ " +
                    calcUsdAmountFromUniformedPaymentList(
                      recommendPlan
                    ).toFormat() +
                    " USD"
                  }
                  assets={bulkConvertUniformedPaymentToFtAsset(recommendPlan)}
                  isExpanded={expandedItem === "recommend"}
                  isSelected={true}
                  onExpand={() => {
                    expandedItem === "recommend"
                      ? setExpandedItem(undefined)
                      : setExpandedItem("recommend");
                  }}
                  onSelect={onBack}
                />
              )}

              {customPlan && customPlan.length > 0 && (
                <PaymentButton
                  title={t("Recommend Plan")}
                  subTitle={
                    `${customPlan.length} ` +
                    (customPlan.length <= 1 ? t("Asset") : t("Assets")) +
                    " ≈ " +
                    calcUsdAmountFromUniformedPaymentList(
                      customPlan
                    ).toFormat() +
                    " USD"
                  }
                  assets={bulkConvertUniformedPaymentToFtAsset(customPlan)}
                  isExpanded={expandedItem === "custom"}
                  isSelected={true}
                  onExpand={() => {
                    expandedItem === "custom"
                      ? setExpandedItem(undefined)
                      : setExpandedItem("custom");
                  }}
                  onSelect={onBack}
                />
              )}
            </>
          )}

          {/* Editable Mode */}
          {mode === "editable" && (
            <EditableContainer>
              <PaymentButton
                title={t("Recommend Plan")}
                subTitle={
                  recommendAssets !== undefined
                    ? `${recommendAssets.length} ` +
                      (recommendAssets.length <= 1 ? t("Asset") : t("Assets")) +
                      " ≈ " +
                      calcUsdAmountFromUniformedPaymentList(
                        bulkConvertFtAssetToUniformedPayment(recommendAssets)
                      ).toFormat() +
                      " USD"
                    : "..."
                }
                assets={recommendAssets}
                isExpanded={expandedItem === "recommend"}
                isSelected={selectedPlan === "recommend"}
                onExpand={() => {
                  expandedItem === "recommend"
                    ? setExpandedItem(undefined)
                    : setExpandedItem("recommend");
                }}
                onSelect={() => {
                  if (selectedPlan === "recommend") {
                    onBack();
                  } else {
                    setSelectedPlan?.("recommend");
                  }
                }}
              />

              {customAssets && customAssets.length > 0 ? (
                <ButtonContainer>
                  <PaymentButton
                    title={t("Custom Plan")}
                    assets={customAssets}
                    subTitle={
                      `${customAssets.length} ` +
                      (customAssets.length === 1 ? t("Asset") : t("Assets")) +
                      " ≈ " +
                      calcUsdAmountFromUniformedPaymentList(
                        bulkConvertFtAssetToUniformedPayment(customAssets)
                      ).toFormat() +
                      " USD"
                    }
                    isExpanded={expandedItem === "custom"}
                    isSelected={selectedPlan === "custom"}
                    onExpand={() => {
                      setShowEditBtns(false);
                      expandedItem === "custom"
                        ? setExpandedItem(undefined)
                        : setExpandedItem("custom");
                    }}
                    onSelect={() => {
                      if (selectedPlan === "custom") {
                        onBack();
                      } else {
                        setSelectedPlan?.("custom");
                      }
                    }}
                  />
                  {showEditBtns && (
                    <>
                      <SingleIconButton
                        type="edit"
                        size={20}
                        onClick={onEdit}
                      />
                      <SingleIconButton
                        type="delete"
                        size={20}
                        onClick={onClearCustom}
                      />
                    </>
                  )}
                </ButtonContainer>
              ) : (
                <CustomizeButton onClick={onEdit} />
              )}
            </EditableContainer>
          )}
        </Scrollable>

        <KontosButton onClick={onBack}>{t("Confirm")}</KontosButton>
      </MainContainer>
    </Container>
  );
};
