import styled from "styled-components";
import { buttonClick, buttonDisabled, buttonHover } from "../global-styles";
import { fontBold } from "@/style/style.global";

export const KontosButton = styled.button<{
  $isOutlined?: boolean;
  $isRed?: boolean;
  $loading?: boolean;
}>`
  border-radius: 54px;
  border: ${(props) =>
    props.disabled
      ? `1px solid ${props.theme.colors.__deep_50}`
      : props.$isOutlined
        ? `1px solid ${props.theme.colors.__kontos_blue}`
        : "none"};
  background: ${(props) =>
    props.disabled
      ? props.$isOutlined
        ? "transparent"
        : props.theme.colors.__deep_50
      : props.$isOutlined
        ? "transparent"
        : props.$isRed
          ? props.theme.colors.__error
          : props.theme.colors.__kontos_blue};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.__deep_100
      : props.$isOutlined
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__white};
  text-align: center;
  ${fontBold}
  font-size: 18px;
  padding: 17px 0;
  width: 100%;

  -webkit-tap-highlight-color: transparent;

  ${buttonHover};
  ${buttonClick};
  ${(props) => props.disabled && buttonDisabled}

  cursor: ${(props) =>
    props.disabled ? "not-allowed" : props.$loading ? "wait" : "pointer"};
`;
