import CircleIconPair from "src/components/icons/CircleIconPair";
import { FtAsset } from "@/type/zkkontos";
import styled from "styled-components";
import defaultTokenIcon from "src/assets/icons/trade/default-token.svg";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import KontosNumber from "src/utils/KontosNumber";
import arrowIcon from "src/assets/icons/down-arrow.svg";
import assetOmitIcon from "src/assets/icons/asset-omit.svg";
import ImageWithFallback from "src/components/images/ImageWithFallback";
import { DEFAULT_ASSET_ICON_OMIT } from "src/config";
import { useTranslation } from "react-i18next";
import { getChainByAsset } from "@/store/storeHelper";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ $crossChain: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--White, #fff);

  ${(props) =>
    props.$crossChain &&
    "border: 1px solid var(--Deep-50, #edeef1); padding: 32px 10px 14px 10px;"}
`;

const CrossChainTag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 22px;
  border-radius: 8px 0px;
  background: var(--Deep-50, #edeef1);

  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  line-height: 16px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

const AmountLine = styled.span<{ $isKontos?: boolean }>`
  color: ${(props) => (props.$isKontos ? "#413DEC" : "#1A2535")};
  ${fontBold};
  font-size: 20px;
  line-height: 24px;
`;

const FeeLine = styled.span`
  color: var(--Deep-400, #80868f);
  font-family: "HarmonyOS Sans";
  font-size: 12px;
`;

const Arrow = styled.img`
  margin-left: 13px;
  width: 7px;
`;

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img<{ index: number }>`
  width: 32px;
  height: 32px;
  z-index: ${(props) => props.index};

  border-radius: 50%;

  &:not(:first-child) {
    margin-left: -6px;
  }
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  isCrossChain?: boolean;
  fee?: KontosNumber;
  fromAssets: FtAsset[];
  fromValue: KontosNumber;
  fromSymbol: string;
  fromValueInUsd?: KontosNumber;
  toAssets: FtAsset[];
  toValue: KontosNumber;
  toSymbol: string;
  toValueInUsd?: KontosNumber;
}

export const FromToTitle: React.FC<IProps> = ({
  className,
  style,
  isCrossChain = false,
  fee,
  fromAssets,
  fromValue,
  fromValueInUsd,
  fromSymbol,
  toAssets,
  toValue,
  toSymbol,
  toValueInUsd,
}) => {
  const { t } = useTranslation();
  const fromIconList = fromAssets
    ?.slice(0, DEFAULT_ASSET_ICON_OMIT)
    .map((asset) => asset?.imageUrl);
  if (fromAssets.length > DEFAULT_ASSET_ICON_OMIT) {
    fromIconList[DEFAULT_ASSET_ICON_OMIT - 1] = assetOmitIcon;
  }

  const toIconList = toAssets
    ?.slice(0, DEFAULT_ASSET_ICON_OMIT)
    .map((asset) => asset.imageUrl);
  if (toAssets.length > DEFAULT_ASSET_ICON_OMIT) {
    toIconList[DEFAULT_ASSET_ICON_OMIT - 1] = assetOmitIcon;
  }

  return (
    <Container className={className} style={style} $crossChain={isCrossChain}>
      {isCrossChain && <CrossChainTag>{t("Cross-Chain")}</CrossChainTag>}

      <Item>
        {fromAssets.length > 1 ? (
          <HorizontalContainer>
            {fromIconList.map((item, index) => (
              <ImageWithFallback
                key={index}
                src={item}
                fallbackSrc={defaultTokenIcon}
                StyledImg={Icon}
              />
            ))}
          </HorizontalContainer>
        ) : (
          <CircleIconPair
            mainIcon={fromAssets?.[0]?.imageUrl || defaultTokenIcon}
            mainIconFallbackSrc={defaultTokenIcon}
            subIcon={
              getChainByAsset(fromAssets?.[0])?.greyImageURL || defaultChainIcon
            }
            subIconFallbackSrc={defaultChainIcon}
            mainWidth={32}
            mainHeight={32}
            subWidth={16}
            subHeight={16}
            totalWidth={40}
            totalHeight={32}
          />
        )}

        <AmountBox>
          <AmountLine>
            {"-" + fromValue.toFormat() + " " + fromSymbol}
          </AmountLine>
          {(fromValueInUsd || fee) && (
            <FeeLine>
              {fromValueInUsd && `≈${fromValueInUsd.toFormat()} USD `}
              {fee && `(${t("Fee")} ${fee.toFormat()} USD)`}
            </FeeLine>
          )}
        </AmountBox>
      </Item>

      <Arrow src={arrowIcon} />

      <Item>
        {toAssets.length > 1 ? (
          <HorizontalContainer>
            {toIconList.map((item, index) => (
              <ImageWithFallback
                key={index}
                src={item}
                fallbackSrc={defaultTokenIcon}
                StyledImg={Icon}
              />
            ))}
          </HorizontalContainer>
        ) : (
          <CircleIconPair
            mainIcon={toAssets?.[0].imageUrl || defaultTokenIcon}
            mainIconFallbackSrc={defaultTokenIcon}
            subIcon={
              getChainByAsset(toAssets?.[0])?.greyImageURL || defaultChainIcon
            }
            subIconFallbackSrc={defaultChainIcon}
            mainWidth={32}
            mainHeight={32}
            subWidth={16}
            subHeight={16}
            totalWidth={40}
            totalHeight={32}
          />
        )}

        <AmountBox>
          <AmountLine $isKontos>
            {"+" + toValue.toFormat() + " " + toSymbol}
          </AmountLine>
          {toValueInUsd && (
            <FeeLine>{`≈${toValueInUsd.toFormat()} USD`}</FeeLine>
          )}
        </AmountBox>
      </Item>
    </Container>
  );
};
