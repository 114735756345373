import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL, TEMP_EXPLORER_URL } from "src/config";
import * as PaymentApi from "@zkkontos/kontos-sdk/src/api/paymentApi";
import * as ReqTypes from "@zkkontos/kontos-sdk/src/api/reqTypes";
import { FtAsset, FtAssetDetail } from "src/type/zkkontos";
import { paramStore } from "src/store/independent/paramStore";
import { API as SdkApi } from "@zkkontos/kontos-sdk/src/api/core/API";
import { uploadErrorOnce } from "./wallet-service";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import { callFtAsset, callFtAssetWithAbort } from "@/apis/asset-apis";

export type MarkPaymentAssetsSignData = {
  ids: number[];
  nonce: number;
};

export const callTaskPaymentWithAbortAndMemory = async (
  params: PaymentApi.ReqTaskPayment,
  needSave: boolean = true
) => {
  try {
    needSave && paramStore.setTaskDataParams(params);
    return PaymentApi.taskPaymentWithAbort(params, TEMP_EXPLORER_URL);
  } catch (e) {
    uploadErrorOnce(params.account, SdkApi.payment.taskPayment, params, e);
    throw e;
  }
};

export const fetchSpecificFtAsset = async (
  chainIndex: string,
  assetAddress: string
): Promise<FtAsset | undefined> => {
  if (Object.values(NonChainIndex).some((item) => item === chainIndex)) {
    chainIndex = "";
  }
  const { ftAssets, ftAssetDetail } = await callFtAsset({
    regex: assetAddress,
    chainIndex,
    offset: 0,
    limit: 1,
  });
  return ftAssets?.[0] || ftAssetDetail || undefined;
};

export const fetchFtAssetDetail = async (
  chainIndex: string,
  assetAddress: string
): Promise<FtAssetDetail | null> => {
  if (Object.values(NonChainIndex).some((item) => item === chainIndex)) {
    chainIndex = "";
  }
  const { ftAssetDetail } = await callFtAssetWithAbort({
    regex: assetAddress,
    chainIndex,
    offset: 0,
    limit: 1,
  });
  return ftAssetDetail;
};

export const fetchInitRecommendedFtAsset = async (): Promise<
  FtAsset | undefined
> => {
  const { ftAssets } = await callFtAsset({
    isRecommended: true,
    offset: 0,
    limit: 1,
  });
  return ftAssets?.[0] || undefined;
};

// taskPayment Buy
export const fetchBuyPaymentTask = async (
  account: string,
  receiver: string,
  chainIndex: string,
  assetAddress: string,
  usdAmount: KontosNumber,
  slippage: KontosNumber,
  quoteAsset: ReqTypes.UniqueAsset, // it's necessary to ensure insufficient condition safe
  selectedBalances: string[] = [],
  gasPrice: string = "0"
): Promise<PaymentApi.RespTaskPayment> => {
  return await callTaskPaymentWithAbortAndMemory({
    account,
    payer: account,
    reqTaskBuy: {
      chainIndex,
      assetAddress,
      usdAmount: usdAmount.toStringWithDecimal(DEFAULT_DECIMAL),
      receiver,
      slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
      quoteAsset,
    },
    selectedBalances,
    gasPrice,
  });
};

export const fetchQuotePayment = async (
  account: string,
  receiver: string,
  chainIndex: string,
  assetAddress: string,
  usdAmount: KontosNumber,
  slippage: KontosNumber
): Promise<PaymentApi.RespQuotePayment> => {
  return await PaymentApi.quotePayment({
    account,
    reqTaskBuy: {
      chainIndex,
      assetAddress,
      usdAmount: usdAmount.toStringWithDecimal(DEFAULT_DECIMAL),
      receiver,
      slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
      quoteAsset: {
        chainIndex,
        assetAddress,
      },
    },
  });
};

// taskPayment Sell
export const fetchSellPaymentTask = async (
  account: string,
  receiver: string,
  sellChainIndex: string,
  sellAssetAddress: string,
  sellAssetAmount: KontosNumber,
  buyChainIndex: string,
  buyAssetAddress: string,
  slippage: KontosNumber,
  selectedBalances: string[] = [],
  gasPrice: string = "0"
): Promise<PaymentApi.RespTaskPayment> => {
  return await callTaskPaymentWithAbortAndMemory({
    account,
    payer: account,
    reqTaskSell: {
      sellChainIndex,
      sellAssetAddress,
      sellAssetAmount: sellAssetAmount.toStringWithDecimal(DEFAULT_DECIMAL),
      buyChainIndex,
      buyAssetAddress,
      receiver,
      slippage: slippage.toStringWithDecimal(DEFAULT_DECIMAL),
    },
    selectedBalances,
    gasPrice,
  });
};

// taskPayment Transfer
export const fetchTransferPaymentTask = async (
  account: string,
  receiver: string,
  chainIndex: string,
  assetAddress: string,
  assetAmount: KontosNumber,
  selectedBalances: string[] = [],
  gasPrice: string = "0"
): Promise<PaymentApi.RespTaskPayment> => {
  return await callTaskPaymentWithAbortAndMemory({
    account,
    payer: account,
    reqTaskTransfer: {
      chainIndex,
      assetAddress,
      assetAmount: assetAmount.toStringWithDecimal(DEFAULT_DECIMAL),
      receiver,
    },
    selectedBalances,
    gasPrice,
  });
};

// taskPayment User Op Call
export const fetchUserOpPaymentTask = async (
  account: string,
  payer: string,
  chainIndex: string,
  targetAddress: string,
  value: string,
  callData: string,
  selectedBalances: string[] = [],
  gasPrice: string = "0"
): Promise<PaymentApi.RespTaskPayment> => {
  return await callTaskPaymentWithAbortAndMemory({
    account: account,
    payer: account,
    reqTaskUserOp: {
      chainIndex,
      targetAddress,
      value,
      callData,
      requiredAssets: [],
    },
    selectedBalances,
    gasPrice,
  });
};
