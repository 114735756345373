import React, { useEffect, useRef, useMemo, useCallback } from "react";
import styled from "styled-components";
import headIco from "src/assets/icons/account/accountIco.svg";
import homeIco1 from "src/assets/icons/homeIco1.svg";
import headIco2 from "src/assets/icons/homeIco2.svg";
import headIco4 from "src/assets/icons/homeIco4.svg";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  ROUTE_HOME,
  ROUTE_QR_READER,
  ROUTE_RECOVER_ACCOUNT,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_RECEIVE,
  ROUTE_AUTH,
  ROUTE_TRANSFER,
} from "src/router/router-config";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useMouseDownOutside from "src/hooks/useMouseDownOutside";
import SettingsRouter from "../settings/SettingsRouter";
import errIcon from "src/assets/icons/error.svg";
import { PopupModal } from "src/components/popups/PopupModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { EbBanner } from "../events/energy-boosting/EbBanner";
import { useStores } from "src/hooks/useStore";
import LinkButton from "../main/LinkButton";
import { SheetView } from "src/store/SheetStore";
import { UnlockSheet } from "../sign-up/unlock/UnlockSheet";
import SignSheet from "src/sheets/dapps/SignSheet";
import { Flag } from "src/configs/featureFlags";
import HomeBg from "src/components/bg/HomeBg";
import { TabWrapper } from "src/components/tab";
import { OTCModal } from "src/components/otc/OTC";
import { FtAsset } from "@/type/zkkontos";
import { SWITCH_ACCOUNT_PATH } from "src/router/Router";
import SyncIcon from "src/components/sync/SyncIcon";
import {
  ToBuyAssetSelector as SyncAssetSelector,
  ShowAssetType,
} from "src/pages/trade/asset-select/ToBuyAssetSelector";
import { callSyncSingleBalance } from "src/apis/explorer-apis";
import toast from "src/components/toast/Toast";
import { NonChainIndex } from "src/components/selects/HorizontalScrollableElements";
import { NoNetworkPopup } from "src/components/popups/NoNetworkPopup";
import { useSetState } from "react-use";
import Joyride, { CallBackProps, Step, STATUS } from "react-joyride";
import {
  JoyRideToolTip,
  KontosText,
} from "src/components/onboarding/JoyRideTooltip";
import { OnboardingType } from "src/store/localKeeper";
import { Trans, useTranslation } from "react-i18next";
import { ChainAssetList } from "src/components/asset-list/ChainAssetList";
import { BottomSheet } from "src/components/bottom-sheet/BottomSheet";
import { TaskOrActivityList } from "src/components/task-activity/TaskOrActivityList";
import { openUrl } from "src/utils/helper";
import useAuthCheck from "@/hooks/useAuthCheck";
import PrimaryButton from "@/components/button/PrimaryButton";
import { fontBold } from "@/style/style.global";
import { t } from "i18next";

const Wrapper = styled.div<{ $isFixedTab: boolean }>`
  box-sizing: border-box;
  /* padding: 20px 0 0 0; */
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;

  .link-btn,
  .settings-btn {
    position: absolute;
    top: 24px;
    right: 16px;
    z-index: 10;
  }

  .settings-btn {
    cursor: pointer;
  }

  > .head {
    position: fixed;
    width: 100%;
    top: 0;
    padding: 22px 16px 24px 16px;
    display: flex;
    flex-direction: column;

    z-index: 2;

    .normal-line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .logo {
        margin-right: 6px;

        .logo-avatar {
          cursor: pointer;
          width: 32px;
          height: 32px;
        }
      }

      .name {
        overflow: hidden;
        color: var(--Deep-800, #1a2535);
        text-overflow: ellipsis;
        font-family: HarmonyOS Sans;
        font-size: 16px;
      }
    }
    .exception-line {
      margin: 0 -20px;
      background-color: rgba(255, 30, 43, 0.1);
      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      .exception-line-icon {
        width: 14px;
        height: 14px;
      }

      .exception-line-text {
        margin-left: 8px;

        color: var(--error-notice, #ff1e2b);
        font-family: "HarmonyOS Sans";
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .mainPageContent {
    // padding-top: 24px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0;
    }
    ::-ms-scrollbar {
      width: 0;
    }
    > div {
      width: 100%;
    }

    .event-banner {
      margin-bottom: 10px;
    }

    .listWrapperFixed {
      height: 100%;
      display: flex;
      padding-top: 50px;
      overflow-y: ${(props) => (props.$isFixedTab ? "auto" : "hidden")};
      background-color: #fff;
    }
  }

  .totalPrice {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    height: 38px;
    padding: 0 24px;
    .l {
      flex: 1;
      overflow: hidden;
      color: var(--Deep-800, #1a2535);
      //text-overflow: ellipsis;
      ${fontBold}
      font-size: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .symbol {
        overflow: hidden;
        color: var(--Deep-800, #1a2535);
        text-overflow: ellipsis;
        ${fontBold}
        font-size: 22px;
        font-style: normal;
        line-height: normal;
        margin-left: 8px;
        transform: translateY(7px);
      }
    }

    .r {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--Deep-400, #80868f);
      text-overflow: ellipsis;
      cursor: pointer;

      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
      font-weight: 400;
      padding-top: 13px;
      img {
        margin-left: 5px;
      }
    }
  }

  .nav {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div,
    > a {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 16px;
      text-decoration: none;
    }

    .ico {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      margin-bottom: 4px;
    }

    .text {
      margin: 0;
      padding: 0;
      color: var(--Deep-400, #80868f);
      text-align: center;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .bannerWrap {
    padding: 0 16px;
  }
`;

const TabContainer = styled.div<{ $isFixed?: boolean; topOffset?: number }>`
  padding: 10px 28px 0 28px;
  width: 100%;

  overflow-y: ${(props) => (props.$isFixed ? "auto" : "hidden")};
  position: ${(props) => (props.$isFixed ? "fixed" : "relative")};
  left: 0;
  top: ${(props) => (props.$isFixed ? `${props.topOffset}px` : "hidden")};
  // padding: 26px 28px 0;
  z-index: 2;
  background: ${(props) => (props.$isFixed ? "#fff" : "transparent")};
`;

const ListWrapper = styled.div<{ $whiteBg?: boolean; $needRightGap?: boolean }>`
  background-color: ${(props) => (props.$whiteBg ? "#fff" : "transparent")};
  padding: ${(props) =>
    props.$needRightGap ? "0 10px 60px 20px" : "0 20px 60px"};
  width: 100%;
  // height: 347px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;

  > div,
  > span {
    width: 100%;
  }
`;

const SyncBtnOnboardingHelper = styled.div`
  position: absolute;
  top: 0;
  left: -6px;
  width: calc(100% + 12px);
  height: 100%;
`;

interface AssetsOnboardingState {
  assetsRun: boolean;
  assetsSteps: Step[];
  assetsSpotLightRadius: string;
}

interface TabItem {
  name: string;
  label: string;
}

export const ASSETS: TabItem = { name: "asset", label: t("Assets") };
export const TASK: TabItem = { name: "task", label: t("Task") };
export const ACTIVITY: TabItem = { name: "activity", label: t("Activity") };
const TABS: TabItem[] = [ASSETS, TASK];
const NAV_ARR = [
  {
    ico: homeIco1,
    text: t("Send"),
    value: "send",
  },
  {
    ico: headIco2,
    text: t("Receive"),
    value: "receive",
  },
  // {
  //   ico: headIco3,
  //   text: rawT("Bridge"),
  //   value: "bridge",
  // },
  {
    ico: headIco4,
    text: t("OTC"),
    value: "otc",
  },
];

const HomePage: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    userStore,
    uiSettingStore,
    sheetStore,
    chainStore,
    dappConnectStore,
  } = useStores();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const domNode = wrapperRef.current as Element | undefined;
  // scroll container
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [distanceBetweenMainAndTab, setDistanceBetweenMainAndTab] =
    useState<number>(0);
  const [isFixedTab, setIsFixedTab] = useState(false);
  const [tabTop, setTabTop] = useState<number>(0);
  // asset detail
  const [otcShow, setOtcShow] = useState(false);
  // Pub key warn
  const [showPubKeyWarnPopup, setShowPubKeyWarnPopup] =
    useState<boolean>(false);
  // No network warn
  const [showNoNetworkWarnPopup, setShowNoNetworkPopup] =
    useState<boolean>(false);
  // Show Energy Booster Banner
  const [showEbBanner, setShowEbBanner] = useState<boolean>(Flag.eb);
  const ebBannerRef = useRef<HTMLDivElement | null>(null);
  const isQrReaderOpen = sheetStore.sheetVisibility.get(
    SheetView.QrReader
  ) as boolean;
  // quote modal
  const [searchParams] = useSearchParams();
  const tabBySearchParams = useMemo(() => {
    return searchParams.get("tab") || "";
  }, [searchParams]);
  const [tab, setTab] = useState<TabItem>(
    TABS.find((item) => item.name === tabBySearchParams) || ASSETS
  );
  const backToHome = useCallback(() => {
    navigate(ROUTE_HOME);
  }, [navigate]);
  const [showSyncAssetSheet, setShowSyncAssetSheet] = useState(false);
  const isHomePageFullyMatch = useMemo(() => {
    return location.pathname === ROUTE_HOME;
  }, [location]);
  const { authed, ensureAuthed } = useAuthCheck();

  const [
    { assetsRun, assetsSpotLightRadius, assetsSteps },
    setAssetsOnboardingState,
  ] = useSetState<AssetsOnboardingState>({
    assetsRun: false,
    assetsSpotLightRadius: "8px",
    assetsSteps: [
      {
        content: (
          <div>
            <Trans i18nKey={"trans-assets-onboarding-step-1"}>
              Now, you can proactively sync your balance by clicking on{" "}
              <KontosText>"Sync",</KontosText> then selecting the specific asset
              to proceed.
            </Trans>
          </div>
        ),
        placement: "top-end",
        target: ".sync-btn-helper",
        title: t("Initiate your asset synchronization!"),
        disableBeacon: true,
        spotlightPadding: 8,
        offset: 7,
      },
    ],
  });

  const handleAssetsJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      userStore.markOnboardingFinished(OnboardingType.Assets);
    }
  };

  useEffect(() => {
    setTab(TABS.find((item) => item.name === tabBySearchParams) || ASSETS);
  }, [tabBySearchParams]);

  useEffect(() => {
    if (
      !userStore.pubKeyException &&
      !userStore.noNetwork &&
      userStore.needOnboardingAssets
    ) {
      setAssetsOnboardingState({ assetsRun: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.needOnboardingAssets]);

  useEffect(() => {
    if (
      location.pathname === SWITCH_ACCOUNT_PATH &&
      userStore.pubKeyException
    ) {
      if (!uiSettingStore.showAccountSettingSheet) {
        setShowPubKeyWarnPopup(true);
        uiSettingStore.openAccountSettingSheet();
      }
    } else if (
      !uiSettingStore.showAccountSettingSheet &&
      !isQrReaderOpen &&
      !uiSettingStore.showTradeSettingSheet &&
      searchParams.size === 0
    ) {
      backToHome();
    }
  }, [
    backToHome,
    isQrReaderOpen,
    location.pathname,
    userStore.pubKeyException,
    uiSettingStore.showTradeSettingSheet,
    searchParams.size,
    uiSettingStore.showAccountSettingSheet,
    uiSettingStore,
  ]);

  useEffect(() => {
    // handleLoadMoreItems();
    if (isHomePageFullyMatch) {
      uiSettingStore.closeAccountSettingSheet();
      uiSettingStore.closeTradeSettingSheet();
      sheetStore.closeSheetByView(SheetView.QrReader);
    }
  }, [isHomePageFullyMatch, sheetStore, uiSettingStore]);

  const closeModals = useCallback(() => {
    uiSettingStore.closeAccountSettingSheet();
    uiSettingStore.closeTradeSettingSheet();
    setShowPubKeyWarnPopup(false);
    setOtcShow(false);
    setShowSyncAssetSheet(false);
  }, [uiSettingStore]);

  useMouseDownOutside({
    ref: wrapperRef,
    callback: closeModals,
    shouldClose: uiSettingStore.canClose,
  });

  useEffect(() => {
    const pair = async (wcLink: string, retryCount: number = 5) => {
      if (!dappConnectStore.client && retryCount > 0) {
        setTimeout(() => pair(wcLink, retryCount - 1), 500);
        return;
      }
      try {
        await dappConnectStore.onPair(wcLink);
      } catch (error) {
        console.error("Pairing error:", error);
      }
    };

    const wc = searchParams.get("wc");
    const expiryTimestamp = searchParams.get("expiryTimestamp");
    const relayProtocol = searchParams.get("relay-protocol");
    const symKey = searchParams.get("symKey");

    if (wc && expiryTimestamp && relayProtocol && symKey) {
      const link = `wc:${wc}?expiryTimestamp=${expiryTimestamp}&relay-protocol=${relayProtocol}&symKey=${symKey}`;
      pair(link);
    }
  }, [dappConnectStore, searchParams, sheetStore]);

  // For tab sticky
  /**
   * x <= a
   * x + y = b
   * -->
   * b - y <= a
   */
  useEffect(() => {
    const handleResize = () => {
      if (
        wrapperRef.current &&
        tabRef.current &&
        scrollContainerRef.current &&
        headerRef.current
      ) {
        scrollContainerRef.current.scrollTop = 0;
        const scrollContainerScrollTop = scrollContainerRef.current.scrollTop;
        const containerTop = wrapperRef.current.getBoundingClientRect().top;
        const tabTop = tabRef.current.getBoundingClientRect().top;

        setDistanceBetweenMainAndTab(
          scrollContainerScrollTop + tabTop - containerTop
        );
      }
    };

    const handleScroll = () => {
      if (distanceBetweenMainAndTab <= 0) {
        return;
      }

      if (wrapperRef.current && tabRef.current && scrollContainerRef.current) {
        const containerTop = wrapperRef.current.getBoundingClientRect().top;
        const scrollContainerTop =
          scrollContainerRef.current.getBoundingClientRect().top;
        const realDistance = scrollContainerTop - containerTop;

        const scrollContainerScrollTop = scrollContainerRef.current.scrollTop;

        setIsFixedTab(
          distanceBetweenMainAndTab - scrollContainerScrollTop <
            realDistance + 1
        );
        setTabTop(realDistance);
      }
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      if (distanceBetweenMainAndTab <= 0) {
        // After header's height is calculated into tabTop
        setTimeout(() => {
          handleResize();
        }, 0);
      }
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [distanceBetweenMainAndTab]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (userStore.noNetwork && !showNoNetworkWarnPopup) {
        setShowNoNetworkPopup(true);
        event.stopPropagation();
        return;
      }

      if (userStore.pubKeyException && !showPubKeyWarnPopup) {
        event.stopPropagation();
        navigate(SWITCH_ACCOUNT_PATH);
        return;
      }
    };

    const rootElement = wrapperRef.current;
    if (rootElement) {
      rootElement.addEventListener("click", handleClick);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener("click", handleClick);
      }
    };
  }, [
    navigate,
    showNoNetworkWarnPopup,
    showPubKeyWarnPopup,
    userStore.noNetwork,
    userStore.pubKeyException,
  ]);

  useEffect(() => {
    if (userStore.noNetwork && !showNoNetworkWarnPopup) {
      setShowNoNetworkPopup(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleTabChange = useCallback((current: TabItem) => {
    setTab(current);
  }, []);

  return (
    <Wrapper ref={wrapperRef} $isFixedTab={isFixedTab}>
      <HomeBg />
      <div className={"head"} ref={headerRef}>
        <div className="normal-line">
          {authed ? (
            <>
              <div className={"logo"}>
                <img
                  className="logo-avatar"
                  src={headIco}
                  onClick={(event) =>
                    ensureAuthed(() => {
                      if (userStore.pubKeyException) return;
                      event.stopPropagation();
                      uiSettingStore.openAccountSettingSheet();
                      navigate(ROUTE_SETTINGS_ACCOUNT);
                    })
                  }
                  alt=""
                />
              </div>
              <div className={"name"}>
                {userStore.accountInfo?.name || ""}.os
              </div>
            </>
          ) : (
            <PrimaryButton
              radius={99}
              style={{ padding: "8px 16px" }}
              onClick={() => {
                navigate(ROUTE_AUTH);
              }}
            >
              {t("Sign in")}
            </PrimaryButton>
          )}
        </div>

        {userStore.pubKeyException && (
          <div className="exception-line">
            <img className="exception-line-icon" src={errIcon} alt="" />
            <span className="exception-line-text">
              {t("You need to reset and recover account now")}
            </span>
          </div>
        )}
      </div>
      <div
        className={"mainPageContent"}
        ref={scrollContainerRef}
        style={{ marginTop: `${headerRef.current?.offsetHeight}px` }}
      >
        <div className={"totalPrice"}>
          <div className={"l"}>
            {!authed ? (
              "0.00"
            ) : userStore.balanceInUSDStr ? (
              userStore.balanceInUSDStr
            ) : (
              <Skeleton count={1} style={{ width: "120px" }} />
            )}{" "}
            <span className={"symbol"}>USD</span>
          </div>
        </div>
        <div className={"nav"}>
          {NAV_ARR.map((item, index) => (
            <div
              key={index.toString()}
              onClick={() => {
                if (userStore.pubKeyException) return;
                switch (item.value) {
                  case "send":
                    ensureAuthed(() => {
                      navigate(ROUTE_TRANSFER);
                    });
                    break;
                  case "receive":
                    ensureAuthed(() => {
                      navigate(ROUTE_RECEIVE);
                    });
                    break;
                  case "bridge":
                    ensureAuthed(() => {
                      openUrl(
                        `https://bridge.kontos.io?account=${userStore.accountName}`
                      );
                    });
                    break;
                  case "otc":
                    ensureAuthed(() => {
                      setOtcShow(true);
                    });
                    break;
                }
              }}
            >
              <img className={"ico"} src={item.ico} alt="" />
              <p className={"text"}>{t(item.text)}</p>
            </div>
          ))}
        </div>

        {showEbBanner && (
          <div
            ref={ebBannerRef}
            style={isFixedTab ? { visibility: "hidden" } : {}}
            className={"bannerWrap"}
          >
            <EbBanner className="event-banner" onClose={setShowEbBanner} />
          </div>
        )}

        <>
          <TabContainer ref={tabRef} $isFixed={isFixedTab} topOffset={tabTop}>
            <TabWrapper>
              {[ASSETS, TASK].map((item) => (
                <div
                  key={item.name}
                  className={tab === item ? "item current" : "item"}
                  onClick={() => handleTabChange(item)}
                >
                  <span>{t(item.label)}</span>
                </div>
              ))}
              <SyncIcon
                click={() => {
                  setShowSyncAssetSheet(true);
                }}
                styles={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                <SyncBtnOnboardingHelper className="sync-btn-helper" />
              </SyncIcon>
            </TabWrapper>
          </TabContainer>
          <div
            className={`listWrapper ${isFixedTab ? "listWrapperFixed" : ""}`}
          >
            {tab.name === ASSETS.name && (
              <ListWrapper $whiteBg $needRightGap>
                {/* {userStore.accountBalances?.ftAssetTypes?.map(
                    (item, index) => (
                      <AssetsList
                        item={item}
                        key={index.toString()}
                        handleClick={() => {
                          setAssetDetailShow(true);
                          setCurrentAssetDetail(item);
                        }}
                      />
                    )
                  )} */}
                <ChainAssetList
                  assets={userStore.userHoldings}
                  chains={chainStore.chainsSortedByUserHoldings}
                  wrapperStyle={{
                    marginRight: "10px",
                  }}
                />

                {/* Manage Payment Floating Button */}
                {/* <SingleIconWithTextButton
                  wrapperStyle={{
                    margin: "24px 0 16px 0",
                    width: "initial",
                  }}
                  content={t("Manage Payment")}
                  icon={managePaymentIcon}
                  activeIcon={managePaymentActiveIcon}
                  size={16}
                  onClick={(event) => {
                    if (userStore.pubKeyException) return;
                    event.stopPropagation();
                    uiSettingStore.openTradeSettingSheet();
                    navigate(ROUTE_SETTINGS_PAYMENT);
                  }}
                /> */}
              </ListWrapper>
            )}

            {tab.name === TASK.name && (
              <ListWrapper $whiteBg $needRightGap>
                <TaskOrActivityList
                  key={userStore.accountNameWithOs}
                  accountName={userStore.accountNameWithOs || ""}
                  type="task"
                  scrollStyle={{
                    marginRight: "10px",
                  }}
                />
              </ListWrapper>
            )}

            {/* {tab.name === ACTIVITY.name && (
              <ListWrapper $whiteBg $needRightGap>
                <TaskOrActivityList
                  key={userStore.accountNameWithOs}
                  accountName={userStore.accountNameWithOs || ""}
                  type="activity"
                  scrollStyle={{
                    marginRight: "10px",
                  }}
                />
              </ListWrapper>
            )} */}
          </div>
        </>
      </div>

      {/* LinkBtn */}
      <LinkButton
        className="link-btn"
        onPasteClick={() => {
          ensureAuthed(() => {
            if (userStore.pubKeyException) return;
            sheetStore.openSheet(SheetView.DappConnection);
          });
        }}
        onScanClick={() => {
          ensureAuthed(() => {
            if (userStore.pubKeyException) return;
            sheetStore.openSheet(SheetView.QrReader);
            navigate(ROUTE_QR_READER);
          });
        }}
      />

      {/* otc*/}
      <BottomSheet
        isOpen={otcShow}
        onClose={() => setOtcShow(false)}
        mountPoint={domNode}
      >
        <OTCModal handleBack={() => setOtcShow(false)} />
      </BottomSheet>

      {/* account settings */}
      <BottomSheet
        isOpen={
          uiSettingStore.showAccountSettingSheet ||
          uiSettingStore.showTradeSettingSheet
        }
        onClose={() => {
          uiSettingStore.closeAccountSettingSheet();
          uiSettingStore.closeTradeSettingSheet();
        }}
        mountPoint={domNode}
      >
        <SettingsRouter />
      </BottomSheet>

      <BottomSheet
        isOpen={showSyncAssetSheet}
        onClose={() => setShowSyncAssetSheet(false)}
        mountPoint={domNode}
      >
        <SyncAssetSelector
          onChoose={async (item: FtAsset) => {
            try {
              setShowSyncAssetSheet(false);
              await callSyncSingleBalance({
                account: userStore.accountInfo?.name || "",
                chainIndex: item.chainIndex,
                assetAddress: item.address,
              });
              userStore.fetchAndUpdateAccountBalances();
              toast({
                text: t("Sync Success"),
                type: "success",
                autoClose: 3000,
              });
            } catch (e) {
              toast({
                text: t("Sync fail" + e),
                type: "error",
                autoClose: 3000,
              });
            }
          }}
          chains={chainStore.chains}
          hasAll
          showAssetType={ShowAssetType.Sync}
          initAssetType={
            userStore.userHoldings.length > 0
              ? NonChainIndex.MyAssets
              : NonChainIndex.All
          }
          hasMyAssets
          isSync={true}
        />
      </BottomSheet>

      {/* pub key exception modal */}
      {showPubKeyWarnPopup && (
        <PopupModal
          type="error"
          onConfirm={() => {
            userStore.removeAccount(userStore.accountInfo?.name || "", true);
            navigate(
              ROUTE_RECOVER_ACCOUNT + "?name=" + userStore.accountInfo?.name
            );
            setShowPubKeyWarnPopup(false);
          }}
          onCancel={(event) => {
            setShowPubKeyWarnPopup(false);
          }}
          onOutClick={(event) => {
            setShowPubKeyWarnPopup(false);
          }}
          title={t("Account Warning !")}
          desc={t(
            "Some issues with your public key, the account can not make transactions any more."
          )}
          subDesc={"*Please reset and recover your account first."}
          btnTxt={t("Reset Now") as string}
          cancelBtnText={t("Later") as string}
        />
      )}

      {/* no network exception modal */}
      {showNoNetworkWarnPopup && (
        <NoNetworkPopup
          accountName={userStore.accountName}
          onRetry={userStore.retryConnectToNetwork}
          onClose={() => setShowNoNetworkPopup(false)}
        />
      )}

      {/* Unlock */}
      <UnlockSheet mountPoint={domNode} />

      {/* Sign */}
      <SignSheet mountPoint={domNode} />

      {/* Beginner guidance */}
      {userStore.needOnboardingAssets && (
        <Joyride
          callback={handleAssetsJoyrideCallback}
          disableCloseOnEsc
          disableOverlayClose
          disableScrolling
          continuous
          run={assetsRun}
          showProgress
          showSkipButton
          steps={assetsSteps}
          floaterProps={{
            styles: {
              arrow: {
                color: "#fff",
                spread: 20,
                length: 14,
              },
            },
          }}
          styles={{
            options: {
              zIndex: 2000000,
            },
            spotlight: {
              borderRadius: assetsSpotLightRadius,
            },
            overlay: {
              backgroundColor: "rgba(0, 13, 31, 0.30)",
            },
          }}
          tooltipComponent={JoyRideToolTip}
        />
      )}
    </Wrapper>
  );
});

export default HomePage;
