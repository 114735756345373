import { CloseButton } from "src/components/button/CloseButton";
import { Scrollable } from "src/components/scrollable/Scrollable";
import { useTranslation } from "react-i18next";
import { Overlay } from "src/components/popups/Overlay";
import styled from "styled-components";
import NoDataV2 from "src/components/no-data/NoDataV2";
import * as ebTypes from "src/apis/types/energyBoostingTypes";
import "react-loading-skeleton/dist/skeleton.css";
import { EbBackpackItem } from "./EbBackpackItem";
import { LocalLoading } from "src/components/loading/LocalLoading";
import { EbBackpackOrb } from "./EbBackpackOrb";
import { useMemo } from "react";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  min-height: min(80%, 471px);
  height: 70%;
  width: calc(100% - 40px);
  border-radius: 16px;
  background: var(--White, #fff);
  padding: 24px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 20px 16px;
  position: relative;
  overflow-y: hidden;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .eb-backpack-title {
    color: var(--Deep-800, #1a2535);
    text-align: center;
    ${fontBold};
    font-size: 18px;
    line-height: 22px;
  }

  .eb-backpack-orbs {
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    background: var(--Deep-25, #f5f5f6);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 18px 18px 18px;

    > img {
      width: 60px;
      height: 60px;
    }

    > span {
      margin-top: 4px;
      line-height: 14px;
      color: var(--Deep-800, #1a2535);
      ${fontBold};
      font-size: 12px;
    }
  }

  .open-btn {
    margin-top: 8px;
    border-radius: 8px !important;
    padding: 13px !important;
    font-size: 14px !important;
  }

  .eb-backpack-scrollable {
    width: calc(100% - 16px);
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
`;

interface Props {
  energyOrbs: ebTypes.EnergyOrb[];
  rewards: ebTypes.Reward[];
  loading: boolean;
  onClose: (open: boolean) => void;
  onOpenOrb: (energyOrbType: number) => void;
}

export const EbBackpackPopup: React.FC<Props> = ({
  energyOrbs,
  rewards,
  loading,
  onClose,
  onOpenOrb,
}) => {
  const { t } = useTranslation();
  const iDisplayOrbs: ebTypes.EnergyOrb[] = useMemo(() => {
    if (energyOrbs.length > 0) {
      return energyOrbs;
    } else {
      return [
        {
          orbType: 0,
          orbTypeDesc: "",
          amount: 0,
        },
      ];
    }
  }, [energyOrbs]);
  const orbTypeCount = new Set(iDisplayOrbs.map((orb) => orb.orbType)).size;

  return (
    <Overlay>
      <Container>
        <CloseButton className="close-btn" onClick={() => onClose(false)} />
        <div className="eb-backpack-title">{t("My Backpack")}</div>
        {iDisplayOrbs.map((orb) => (
          <EbBackpackOrb
            key={orb.orbType}
            orbType={orb.orbType}
            orbCount={orb.amount}
            orbTypeCount={orbTypeCount}
            loading={loading}
            onOpenOrb={() => onOpenOrb(orb.orbType)}
          />
        ))}
        <Scrollable className="eb-backpack-scrollable">
          {rewards.length > 0 ? (
            rewards.map((reward) => (
              <EbBackpackItem
                key={
                  reward.rewardItem.assetAddress + reward.rewardItem.chainIndex
                }
                img={reward.rewardItem.assetImageUrl}
                title={
                  new KontosNumber(
                    reward.rewardItem.assetAmount,
                    DEFAULT_DECIMAL
                  ).toFormat(2) +
                  " " +
                  reward.rewardItem.assetDesc
                }
                subTitle={reward.rewardItem.assetName}
              />
            ))
          ) : loading ? (
            <LocalLoading />
          ) : (
            <NoDataV2 text={t("There's nothing here")} />
          )}
        </Scrollable>
      </Container>
    </Overlay>
  );
};
