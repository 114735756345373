// TODO: TG Bot
import PoweredBy from "src/components/common/powerdBy";
import styled from "styled-components";
import logo from "src/assets/images/first-screen-logo.svg";
import { observer } from "mobx-react";
import { useStores } from "src/hooks/useStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sleep, withTimeout } from "src/utils/helper";
import { useCallback, useEffect, useState } from "react";
import { CONNECTOR_BYBIT } from "src/config";
import toast from "src/components/toast/Toast";
import { useTranslation } from "react-i18next";
// import tgManager from "src/store/managers/tgManager";
import KontosNumber from "src/utils/KontosNumber";
import { loadingStore } from "src/store/loadingStore";
import { fontBold } from "@/style/style.global";
import { useAccount, useConnect, useSwitchChain } from "wagmi";
import { useWalletDisconnect } from "../receive-v2/hooks/useWalletDisconnect";
import Receive from "../receive-v2/ReceiveV2";
import { ROUTE_HOME } from "@/router/router-config";
import { injected } from "wagmi/connectors";

const Container = styled.div<{ $isMobile: boolean }>`
  flex: 1;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(/static/first-screen-bg.jpg);
  background-size: ${(props) => (props.$isMobile ? "160% auto" : "120% auto")};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: ${(props) => (props.$isMobile ? "center" : "center")};
`;

const Body = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 96px;
  height: 96px;
`;

const Title = styled.span`
  margin-top: 16px;

  color: ${(props) => props.theme.colors.__kontos_blue};
  ${fontBold};
  font-size: 28px;
  margin-bottom: 16px;
`;

const PowerByWrapper = styled.div`
  position: absolute;
  bottom: 20px;
`;

const SCREEN_WATING_TIME = 11000;
const SYNC_WAITING_TIME = 5000;

export const ReceiveScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const { uiStore, receiveStore, userStore } = useStores();
  const [searchParams] = useSearchParams();
  const walletAccount = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { disconnectAsync } = useWalletDisconnect();
  const [chainIdParam] = useState(searchParams.get("chainId"));
  const [addressParam] = useState(
    chainIdParam === "5000"
      ? "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
      : searchParams.get("address")
  );
  const [amountParam] = useState(
    chainIdParam === "5000"
      ? "10000000000000000000"
      : searchParams.get("amount")
  );
  const [decimalsParam] = useState(
    chainIdParam === "5000" ? "18" : searchParams.get("decimals")
  );
  const [connectorParam] = useState(
    chainIdParam === "5000" ? CONNECTOR_BYBIT : searchParams.get("connector")
  );
  const [initialized, setInitialized] = useState<boolean>(
    !(
      typeof chainIdParam === "string" &&
      typeof addressParam === "string" &&
      typeof amountParam === "string" &&
      typeof decimalsParam === "string"
    )
  );
  const navigate = useNavigate();
  const { connectAsync } = useConnect();

  const connectToBybitWallet = useCallback(async () => {
    try {
      await connectAsync({
        connector: injected({
          target: {
            id: CONNECTOR_BYBIT,
            name: CONNECTOR_BYBIT,
            provider(window) {
              // @ts-ignore
              return window?.bybitWallet;
            },
          },
        }),
      });
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : t("Failed to connect");
      console.warn(errorMessage);
    }
  }, [connectAsync, t]);

  useEffect(() => {
    const checkAaAccounts = async () => {
      try {
        loadingStore.showLoading();
        if (Object.keys(userStore.aaAccounts)?.length === 0) {
          const aaAccounts = await userStore.fetchAndSetAaAccounts();
          if (
            aaAccounts !== undefined &&
            Object.keys(aaAccounts)?.length === 0
          ) {
            toast({
              text: t("Failed to fetch account info"),
              type: "error",
            });
            navigate(ROUTE_HOME);
          }
        }
      } catch (e) {
        console.warn(e);
      } finally {
        loadingStore.hideLoading();
      }
    };

    const checkLegalityOfSearchParams = async () => {
      try {
        if (
          typeof chainIdParam === "string" &&
          typeof addressParam === "string" &&
          typeof amountParam === "string" &&
          typeof decimalsParam === "string"
        ) {
          loadingStore.showLoading();
          // Check chain
          const chain = await receiveStore.setChainByChainIndex(chainIdParam);
          if (!chain) {
            throw new Error(
              t(
                "The specified chain does not exist or is currently not supported by Kontos Wallet. You can freely edit the asset that need to be deposited"
              )
            );
          }

          await sleep(300);

          // Check connector 1
          if (
            typeof connectorParam === "string" &&
            connectorParam === CONNECTOR_BYBIT &&
            walletAccount?.connector?.id === CONNECTOR_BYBIT
          ) {
            // Bybit Wallet
            await switchChainAsync({
              chainId: parseInt(chain.chainIndex),
            });
          } else {
            // Others
            try {
              await disconnectAsync();
            } catch (e) {
              console.warn(e);
            }
          }

          // Check asset
          const asset = await receiveStore.setAssetByAssetAddress(addressParam);
          if (!asset) {
            throw new Error(
              t(
                "The specified asset does not exist or is currently not supported by Kontos Wallet. You can freely edit the asset that need to be deposited"
              )
            );
          }

          // Amount
          receiveStore.setAmount(
            new KontosNumber(amountParam, Number(decimalsParam))
          );

          // Check connector 2
          // If window.bybitWallet exists and is not connected, connect to bybit
          if (
            walletAccount?.connector?.id !== CONNECTOR_BYBIT &&
            connectorParam === CONNECTOR_BYBIT &&
            typeof window.bybitWallet !== "undefined"
          ) {
            try {
              await withTimeout(connectToBybitWallet(), SCREEN_WATING_TIME);
              await withTimeout(
                switchChainAsync({
                  chainId: parseInt(chain.chainIndex),
                }),
                SYNC_WAITING_TIME
              );
            } catch (e) {
              try {
                await disconnectAsync();
              } catch (e) {
                console.warn(e);
              }
              console.warn(e);
            }
          }

          receiveStore.setByDepositOrder(true);
        }
      } catch (e) {
        const errorMessage =
          e instanceof Error
            ? e.message
            : t(
                "The specified parameters are incorrect. You can freely edit the asset that need to be deposited"
              );
        toast({
          text: errorMessage,
          type: "error",
        });
      } finally {
        loadingStore.hideLoading();
        setInitialized(true);
      }
    };
    checkAaAccounts();
    checkLegalityOfSearchParams();

    return () => {
      receiveStore.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialized ? (
    <Receive />
  ) : (
    <Container $isMobile={!uiStore.isOverWidth}>
      <Body>
        <Logo src={logo} />
        <Title>Kontos Wallet</Title>
        <PowerByWrapper>
          <PoweredBy />
        </PowerByWrapper>
      </Body>
    </Container>
  );
});
