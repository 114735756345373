import styled from "styled-components";

const Path = styled.path``;
const Ellipse = styled.ellipse``;

const Svg = styled.svg<{ $isAvtive: boolean; $disable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${Path} {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_100};
  }

  ${Ellipse} {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_200};
  }

  cursor: ${(props) => (!props.$disable ? "pointer" : "not-allowed")};

  @media (hover: hover) {
    &:hover {
      ${Path} {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }

      ${Ellipse} {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  isActive?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const SettingsSvg: React.FC<IProps> = ({
  isActive = false,
  disable = false,
  onClick,
}) => {
  return (
    <Svg
      $isAvtive={isActive}
      $disable={disable}
      onClick={onClick}
      width="33"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.681 26.8242C22.6589 26.8242 27.505 21.9781 27.505 16.0001C27.505 10.0222 22.6589 5.17609 16.681 5.17609C10.703 5.17609 5.85693 10.0222 5.85693 16.0001C5.85693 21.9781 10.703 26.8242 16.681 26.8242ZM16.6817 22.1423C20.074 22.1423 22.824 19.3923 22.824 16C22.824 12.6077 20.074 9.85777 16.6817 9.85777C13.2895 9.85777 10.5395 12.6077 10.5395 16C10.5395 19.3923 13.2895 22.1423 16.6817 22.1423Z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4477 15.4943C10.3722 15.8271 10.3722 16.1726 10.4477 16.5055L13.1047 28.2198C13.3409 29.2609 14.2663 29.9999 15.3338 29.9999H18.034C19.1016 29.9999 20.027 29.2609 20.2631 28.2198L22.9201 16.5055C22.9956 16.1726 22.9956 15.8271 22.9201 15.4943L20.2631 3.78C20.0269 2.73887 19.1016 1.99988 18.034 1.99988H15.3338C14.2662 1.99988 13.3409 2.73887 13.1047 3.78L10.4477 15.4943ZM16.6833 22.1426C20.0756 22.1426 22.8256 19.3926 22.8256 16.0003C22.8256 12.608 20.0756 9.85807 16.6833 9.85807C13.291 9.85807 10.5411 12.608 10.5411 16.0003C10.5411 19.3926 13.291 22.1426 16.6833 22.1426Z"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0024 10.3468C13.6764 10.4478 13.3772 10.6206 13.1267 10.8524L4.31029 19.0105C3.52672 19.7356 3.34943 20.9065 3.88321 21.831L5.2333 24.1695C5.76708 25.094 6.86977 25.5259 7.88948 25.2099L19.3628 21.6537C19.6888 21.5527 19.9881 21.3799 20.2386 21.1481L29.0549 12.99C29.8385 12.2649 30.0158 11.094 29.482 10.1695L28.1319 7.83103C27.5981 6.90649 26.4955 6.47458 25.4757 6.79064L14.0024 10.3468ZM16.6826 22.1427C20.0749 22.1427 22.8249 19.3927 22.8249 16.0004C22.8249 12.6082 20.0749 9.85819 16.6826 9.85819C13.2904 9.85819 10.5404 12.6082 10.5404 16.0004C10.5404 19.3927 13.2904 22.1427 16.6826 22.1427Z"
      />
      <Ellipse cx="16.6816" cy="16" rx="3.65862" ry="3.65862" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2371 10.8524C19.9866 10.6206 19.6874 10.4479 19.3614 10.3468L7.88801 6.7907C6.8683 6.47464 5.76562 6.90655 5.23183 7.83109L3.88174 10.1695C3.34796 11.0941 3.52526 12.265 4.30883 12.99L13.1252 21.1482C13.3757 21.38 13.6749 21.5527 14.0009 21.6538L25.4743 25.2099C26.494 25.526 27.5967 25.0941 28.1305 24.1695L29.4805 21.8311C30.0143 20.9065 29.837 19.7356 29.0535 19.0106L20.2371 10.8524ZM16.6818 22.1423C20.0741 22.1423 22.824 19.3923 22.824 16.0001C22.824 12.6078 20.0741 9.85783 16.6818 9.85783C13.2895 9.85783 10.5395 12.6078 10.5395 16.0001C10.5395 19.3923 13.2895 22.1423 16.6818 22.1423Z"
      />
    </Svg>
  );
};

export default SettingsSvg;
