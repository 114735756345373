import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import { RootStore } from "./RootStore";
import { Dapp, DappWatched } from "src/type/dapp";
import { UserStore } from "./UserStore";
import * as FavService from "src/service/fav-service";
import { KontosKey } from "@zkkontos/kontos-sdk";
import { sleep } from "src/utils/helper";
import { FtAssetWatched } from "src/type/ftAsset";
import { FtAsset } from "@/type/zkkontos";

const CRON_INTERVAL = 60000;
const SLEEP_TIME = 100;

type IAddOrRemoveFavorites = {
  dappId?: number;
  key?: KontosKey;
  ftAssetId?: number;
};

type IUpdateFavorites = {
  dappId?: number;
  isAdd: boolean;
  key?: KontosKey;
  ftAssetId?: number;
};

export class FavStore {
  rootStore: RootStore;
  userStore: UserStore;
  dappFavoritesWatched: DappWatched[] = [];
  ftAssetFavoritesWatched: FtAssetWatched[] = [];
  sortingFavorites: boolean = false;
  removingFavorites: boolean = false;
  fetchingFavorites: boolean = false;
  accountNameReactionDisposer?: IReactionDisposer;
  cronjobId: NodeJS.Timer | null = null;
  initing: boolean = true;
  hasFavFtAssets: boolean = false;

  get dappFavorites(): Dapp[] {
    return this.dappFavoritesWatched.map((dapp) => dapp.dapp) || [];
  }

  get dappFavoritesWatchIds(): number[] {
    return this.dappFavoritesWatched.map((dapp) => dapp.watchId) || [];
  }

  get ftAssetFavorites(): FtAsset[] {
    return this.ftAssetFavoritesWatched.map((asset) => asset.ftAsset) || [];
  }

  get initingDappFav(): boolean {
    return this.dappFavoritesWatched.length === 0 && this.fetchingFavorites;
  }

  get initingFtAssetFav(): boolean {
    return this.ftAssetFavoritesWatched.length === 0 && this.fetchingFavorites;
  }

  setHasFavFtAssets = (hasFavFtAssets: boolean) => {
    this.hasFavFtAssets = hasFavFtAssets;
  };

  init = async () => {
    this.initing = true;
    await Promise.all([this.startTrackingAccountName()]);
    runInAction(() => {
      this.initing = false;
    });
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.userStore = rootStore.userStore;
    // this.init();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  startCronjob = async () => {
    this.cronjobId = setInterval(() => {
      this.fetchAndSetFavorites();
    }, CRON_INTERVAL);
  };

  stopCronjob = () => {
    if (this.cronjobId !== null) {
      clearInterval(this.cronjobId);
      this.cronjobId = null;
    }
  };

  startTrackingAccountName = () => {
    this.accountNameReactionDisposer = reaction(
      () => this.userStore.accountName,
      () => {
        this.hasFavFtAssets = false;
        this.dappFavoritesWatched = [];
        this.ftAssetFavoritesWatched = [];
        this.fetchAndSetFavorites();
      }
    );
  };

  stopTrackingAccountName = () => {
    this.accountNameReactionDisposer?.();
  };

  fetchAndSetFavorites = async (): Promise<DappWatched[]> => {
    if (!this.userStore.accountName) {
      return [];
    }

    try {
      this.fetchingFavorites = true;
      const { dapps, ftAssets } = await FavService.getFavorites(
        this.userStore.accountName
      );
      runInAction(() => {
        this.dappFavoritesWatched = dapps;
        this.ftAssetFavoritesWatched = ftAssets;
        this.hasFavFtAssets = ftAssets.length > 0;
      });
      return this.dappFavoritesWatched;
    } catch (e) {
      console.log("Failed to fetch favorites", e);
    } finally {
      runInAction(() => {
        this.fetchingFavorites = false;
      });
    }
    return [];
  };

  updateFavorites = async ({
    dappId,
    isAdd,
    key,
    ftAssetId,
  }: IUpdateFavorites) => {
    if (!this.userStore.accountName) {
      return;
    }
    if (!key) {
      this.userStore.unlock(() =>
        this.updateFavorites({
          dappId,
          isAdd,
          ftAssetId,
        })
      );
      return;
    }
    if (isAdd)
      await FavService.addFavorites({
        account: this.userStore.accountName,
        key: key,
        dappId,
        ftAssetId,
      });
    else
      await FavService.removeFavorites({
        account: this.userStore.accountName,
        key: key,
        dappId,
        ftAssetId,
      });
    await sleep(SLEEP_TIME);
    return await this.fetchAndSetFavorites();
  };

  addFavorites = async ({ dappId, ftAssetId, key }: IAddOrRemoveFavorites) => {
    return await this.updateFavorites({
      dappId,
      isAdd: true,
      key,
      ftAssetId,
    });
  };

  removeFavorites = async ({
    dappId,
    ftAssetId,
    key,
  }: IAddOrRemoveFavorites) => {
    try {
      this.removingFavorites = true;
      return await this.updateFavorites({
        dappId,
        isAdd: false,
        key,
        ftAssetId,
      });
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.removingFavorites = false;
      });
    }
  };

  sortFavorites = async (ids: number[], key?: KontosKey) => {
    // if (!this.userStore.accountName) {
    //   return;
    // }
    // try {
    //   this.sortingFavorites = true;
    //   const client = cli || this.userStore.kontosCli;
    //   if (!client) {
    //     this.userStore.unlock(() => this.sortFavorites(ids));
    //     return;
    //   }
    //   await FavService.sortFavorites(this.userStore.accountName, ids, client);
    //   await sleep(SLEEP_TIME);
    //   await this.fetchAndSetFavorites();
    // } catch (e) {
    //   throw e;
    // } finally {
    //   runInAction(() => {
    //     this.sortingFavorites = false;
    //   });
    // }
  };
}
