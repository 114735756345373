import { EXPLORER_KONTOS_URL, TEMP_EXPLORER_URL } from "@/config";
import { API } from "./core/API";
import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import * as ApiTypes from "./types";

export interface ReqChain {
  chainIndex: string;
}

export interface RespChain {
  chains: ApiTypes.ApiChain[];
}

export const callChain = async (
  data: ReqChain,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespChain> => {
  return await request<ReqChain, RespChain>(API.mscraper.chain, data, endpoint);
};
