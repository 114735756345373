import styled from "styled-components";
import { buttonClick, buttonHover } from "../global-styles";
import LoadingIcon from "./loading-icon.svg";
import LoadingRedIcon from "./loading-red-icon.svg";
import React from "react";
import { fontBold } from "@/style/style.global";

const Wrapper = styled.div<{
  $disabled: boolean;
  variant: string;
  $fullWidth: boolean;
  size?: string;
  type: string;
  height?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 54px;
  background-color: #fff;
  height: 40px;
  ${fontBold};
  color: #413dec;
  height: ${(props) => props.height};
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  border: solid 1px #413dec;
  box-sizing: border-box;
  ${buttonHover};
  ${buttonClick};
  ${(props) =>
    props.$fullWidth &&
    `
    width: 100%;
  `}
  ${(props) =>
    props.variant === "outlined" &&
    `
    color: #413dec;
    background-color: #FFF;
    border: 1px solid #413dec;
  `};
  ${(props) =>
    props.type === "error" &&
    `
    color: #FFFFFF;
    background: #FF1E2B;
    ${
      props.variant === "outlined" &&
      `
    background: #FFFFFF;
    color:  #FF1E2B;
    border-color:  #FF1E2B;
    `
    }
  `};
  ${(props) =>
    props.$disabled &&
    `
    background: #F5F5F6;;
    cursor: not-allowed;
    border-color: #F7F7F8;
    color: #CCCFD2;
  `};
  ${(props) =>
    props.size === "large" &&
    `
    font-size: 18px;
    line-height: 48px;
    height: 48px;
  `};
  ${(props) =>
    props.size === "small" &&
    `
    width: 166px;
  `} @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  .loading {
    position: absolute;
    width: 20px;
    right: 15%;
    transform: rotate(45deg);
    animation: rotation 1s linear infinite;
  }
`;

type ButtonProp = {
  disabled?: boolean;
  variant?: "outlined" | "contained";
  text: string;
  height?: string;
  fullWidth?: boolean;
  size?: "normal" | "large" | "small";
  loading?: boolean;
  type?: "normal" | "error";
  onClick?: () => void;
  wrapperStyle?: React.CSSProperties;
};

export const ButtonCancel: React.FC<ButtonProp> = ({
  disabled = false,
  fullWidth = false,
  variant = "contained",
  text,
  size = "normal",
  loading,
  type = "normal",
  onClick,
  wrapperStyle = {},
  height = "40px",
}) => {
  const handleClick = (evt: any) => {
    if (onClick && !disabled && !loading) {
      onClick();
    }
  };

  return (
    <Wrapper
      $disabled={disabled}
      variant={variant}
      $fullWidth={fullWidth}
      size={size}
      type={type}
      onClick={handleClick}
      style={{ ...wrapperStyle }}
      height={height}
    >
      <span>{text}</span>
      {loading && type === "normal" && (
        <img src={LoadingIcon} className="loading" alt="" />
      )}
      {loading && type === "error" && (
        <img src={LoadingRedIcon} className="loading" alt="" />
      )}
    </Wrapper>
  );
};
