import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { t } from "i18next";
import CloseSvg from "src/components/input/close.svg";
import toast from "../toast/Toast";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  padding-bottom: 16px;
`;

const InputBox = styled.div<{ $IsError?: boolean; $IsOk?: boolean }>`
  flex-shrink: 0;
  box-sizing: border-box;

  border-radius: 8px;
  border: ${(props) =>
    props.$IsError
      ? "2px solid var(--error-notice,#ff1e2b)"
      : props.$IsOk
        ? "2px solid var(--Success, #10ce5c)"
        : "1px solid var(--Deep-50, #EDEEF1)"};
  background: var(--Deep-25, #f5f5f6);

  padding: ${(props) =>
    props.$IsError || props.$IsOk
      ? "13px 13px 12px 19px"
      : "14px 14px 13px 20px"};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: text;

  &:focus-within {
    padding: 13px 13px 12px 19px;
    border: 2px solid var(--Kontos-Blue, #413dec);
    background: var(--White, #fff);
  }

  &:hover {
    padding: 13px 13px 12px 19px;
    border: 2px solid var(--Kontos-Blue, #413dec);
  }
`;

const Input = styled.input`
  width: 100%;
  height: 29px;
  color: var(--Kontos-Blue, #413dec);
  border: none;
  outline: none;
  background-color: transparent;

  color: var(--Deep-800, #1a2535);
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    border: none;
  }

  &::placeholder {
    color: var(--Deep-400, #80868f);
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 400;
  }
`;

const RightItem = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const ClearIcon = styled.img`
  width: 17px;
  height: 17px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const PasteButton = styled.div`
  width: 75px;
  height: 29px;

  border-radius: 99px;
  background: var(--Deep-800, #1a2535);

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--White, #fff);
  text-align: center;
  ${fontBold};
  font-size: 14px;

  margin-left: 15px;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const ErrorTextBox = styled.div`
  margin-top: 5px;
  margin-left: 10px;

  color: var(--error-notice, #ff1e2b);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

export const OkTextBox = styled.div`
  margin-top: 5px;
  margin-left: 10px;

  color: var(--Success, #10ce5c);
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: 400;
`;

interface AddressInputProps {
  errText?: string[];
  okText?: string[];
  value?: string;
  placeholder: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (address: string) => void;
  inputStyle?: React.CSSProperties;
}

type IPropsAddressCheckResult = {
  errText: string[] | undefined;
  okText: string[] | undefined;
  isFocus?: boolean;
};

export const AddressCheckResult = ({
  errText,
  okText,
  isFocus = false,
}: IPropsAddressCheckResult) => {
  return (
    <>
      {errText && errText.length > 0 && !isFocus && (
        <ErrorTextBox>
          {errText.map((text, index) => (
            <div key={index}>{text}</div>
          ))}
        </ErrorTextBox>
      )}
      {!(errText && errText.length > 0) &&
        okText &&
        okText.length > 0 &&
        !isFocus && (
          <OkTextBox>
            {/* Hide ok text By Kontos Issues 9 */}
            {okText.map((text, index) => (
              <div key={index}>{text}</div>
            ))}
          </OkTextBox>
        )}
    </>
  );
};

const AddressInputV2: React.FC<AddressInputProps> = ({
  errText,
  okText,
  value,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  inputStyle,
}) => {
  const [inputValue, setInputValue] = useState<string>(value || "");
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLImageElement>(null);
  const divRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleInputBoxClick = (event: React.MouseEvent | React.TouchEvent) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    if (/^[a-zA-Z0-9.]*$/.test(str)) {
      setInputValue(str);
      onChange?.(str);
    }
  };

  const handleInputOnFocus = () => {
    onFocus?.();
    setIsFocus(true);
  };

  const handleInputOnBlur = () => {
    onBlur?.();
    setIsFocus(false);
  };

  const handleClearIconOnMouseDown = (
    event: React.MouseEvent | React.TouchEvent
  ) => {
    event.preventDefault();
    setInputValue("");
    onChange?.("");
    event.stopPropagation();
  };

  const handlePasteButtonOnMouseDown = async (
    event: React.MouseEvent | React.TouchEvent
  ) => {
    event.preventDefault();
    setInputValue("");
    try {
      let text = await navigator.clipboard.readText();
      if (text.endsWith(".os")) {
        text = text.slice(0, text.length - 3);
      }
      if (/^[a-zA-Z0-9.]*$/.test(text)) {
        setInputValue(text);
        onChange?.(text);
      } else {
        toast({ text: "Contains illegal characters", type: "warning" });
      }
    } catch (err) {
      console.error("Failed to read clipboard contents:", err);
      toast({ text: "Failed to read clipboard contents", type: "warning" });
    }
    event.stopPropagation();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current?.blur();
    }
  };

  return (
    <Container>
      <InputBox
        onClick={handleInputBoxClick}
        $IsError={errText && errText.length > 0}
        $IsOk={okText && okText.length > 0}
        ref={divRef}
        style={inputStyle}
      >
        <Input
          ref={inputRef}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChanged}
          onBlur={handleInputOnBlur}
          onFocus={handleInputOnFocus}
          onKeyDown={handleKeyDown}
          maxLength={64}
        />
        {isFocus && (
          <RightItem>
            <ClearIcon
              src={CloseSvg}
              ref={iconRef}
              onMouseDown={handleClearIconOnMouseDown}
            />
            <PasteButton
              ref={buttonRef}
              onMouseDown={handlePasteButtonOnMouseDown}
            >
              {t("Paste")}
            </PasteButton>
          </RightItem>
        )}
      </InputBox>
      <AddressCheckResult errText={errText} okText={okText} isFocus={isFocus} />
    </Container>
  );
};

export default AddressInputV2;
