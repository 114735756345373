import styled from "styled-components";
import goodIcon from "src/assets/icons/payment/good.svg";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  user-select: none;
  max-width: 320px;
  padding: 8px;
  border-radius: 8px;
  background: var(--Deep-800, #1a2535);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const TipsIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const LightText = styled.span`
  color: var(--White, #fff);
  font-family: "HarmonyOS Sans SC";
  font-size: 12px;
`;

export enum SecurityTipType {
  Good = "good",
  Secure = "secure",
  Alert = "alery",
  Risky = "risky",
  SecurePlus = "securePlus",
  AlertPlus = "alertPlus",
  RiskyPlus = "riskyPlus",
}

export const WhiteListTip: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TipsIcon src={goodIcon} />
      <Content>
        <LightText>{t("This is an asset available for Kontos.")}</LightText>
      </Content>
    </Container>
  );
};
