import { DEFAULT_DECIMAL } from "src/config";
import { loadingStore } from "src/store/loadingStore";
import {
  filterEventNewTaskOpHash,
  getChainByChainIndex,
  parseApiErrorMessage,
  transferPayment,
  transferTaskDetail,
} from "src/utils/zkkontosHelper";
import {
  KontosKey,
  KontosQueryCli,
  TypeURLMsgRegisterTask,
  getDefaultClientPolymorphism,
} from "@zkkontos/kontos-sdk";
import KontosNumber from "src/utils/KontosNumber";
import { ethers } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { enhancedFromHex } from "@zkkontos/kontos-sdk/src/core/utils";
import { RegisterSingleTaskData } from "@zkkontos/kontos-sdk/src/api/types";
import Long from "long";
import {
  base64ToUint8Array,
  checkCryptoSupport,
  getDeviceInfo,
  uint8ArrayToBase64,
} from "src/utils/helper";
import {
  Chain as ChainInfo,
  OpOrIntention,
  Payment,
  TaskDetail,
} from "@zkkontos/kontos-sdk/src/codec/kontos/cc/v1/cc";
import { Chain, FtAsset } from "src/type/zkkontos";
import toast from "src/components/toast/Toast";
import { uploadErrorOnce } from "src/service/wallet-service";
import { paramStore } from "src/store/independent/paramStore";
import { MsgRegisterTask } from "@zkkontos/kontos-sdk/src/codec/kontos/cc/v1/tx";
import { rootStore } from "src/index";
import { RecordOverviewDisplayProps } from "@/components/task-activity/TaskOrActivityOverviewItem";
import localKeeper from "src/store/localKeeper";
import { RespTaskPayment } from "@zkkontos/kontos-sdk/src/api/paymentApi";
import { ApiActionType, ApiPaymentConfirmation } from "@/apis/types";
import { getSequence } from "@/service/account-service";
import { callConfirmPayment } from "@/apis/payment-apis";
import { UserOperation } from "@zkkontos/kontos-sdk/src/codec/kontos/evmHelper/v1/evmHelper";

enum RegisterMethod {
  Buy = "buy",
  Sell = "sell",
  Send = "send",
  Swap = "swap",
  Dapp = "dapp",
  BatchSell = "batchsell",
}

const getUserOpHash = (userOp: UserOperation, chainInfo: ChainInfo): string => {
  // console.log('>>>getUserOpHash::userOp:', JSON.stringify(userOp))
  const abiCoder = ethers.utils.defaultAbiCoder;
  const validUntilBigNumber = ethers.BigNumber.from(
    userOp.validUntil.toString()
  );
  const rawUserOpHash = ethers.utils.keccak256(
    abiCoder.encode(
      [
        "address",
        "uint64",
        "bytes",
        "bytes",
        "uint256",
        "tuple(address address,uint256 amount)[]",
        "uint64",
      ],
      [
        userOp.sender,
        userOp.nonce.toNumber(),
        userOp.initCode,
        userOp.callData,
        userOp.callGasLimit.toString(10),
        userOp.requiredAssets,
        validUntilBigNumber,
      ]
    )
  );
  return ethers.utils.keccak256(
    abiCoder.encode(
      ["bytes32", "address", "uint256"],
      [rawUserOpHash, chainInfo.entryPointAddress, chainInfo.chainId]
    )
  );
};

const fetchUserOpFromSingleTaskData = async (
  accountName: string,
  key: KontosKey,
  syncAccountTask: RegisterSingleTaskData,
  chain: Chain
) => {
  let userOp = {
    sender: syncAccountTask.opOrIntention.user_op?.sender || "",
    nonce: syncAccountTask.opOrIntention.user_op?.nonce
      ? Long.fromValue(syncAccountTask.opOrIntention.user_op?.nonce)
      : new Long(0),
    initCode: syncAccountTask.opOrIntention.user_op?.init_code
      ? base64ToUint8Array(syncAccountTask.opOrIntention.user_op?.init_code)
      : new Uint8Array(),
    callData: syncAccountTask.opOrIntention.user_op?.call_data
      ? base64ToUint8Array(syncAccountTask.opOrIntention.user_op?.call_data)
      : new Uint8Array(),
    callGasLimit: syncAccountTask.opOrIntention.user_op?.call_gas_limit
      ? Long.fromValue(syncAccountTask.opOrIntention.user_op?.call_gas_limit)
      : new Long(0),
    requiredAssets:
      syncAccountTask.opOrIntention.user_op?.required_assets || [],
    validUntil: syncAccountTask.opOrIntention.user_op?.valid_until
      ? Long.fromValue(syncAccountTask.opOrIntention.user_op?.valid_until)
      : new Long(0),
    sigOrProof: new Uint8Array(),
  };
  const userOpHash = getUserOpHash(userOp, {
    chainDesc: "",
    chainSymbol: chain?.chainSymbol || "",
    entryPointAddress: chain.entryPointAddress,
    chainId: chain.chainIndex,
    lightClientVerifierAddress: "",
    minTxConfirmPeriod: new Long(0),
    minTrustedBlocks: new Long(0),
    minInterval: new Long(0),
    status: chain?.status || 0,
  });
  const kontosClientPolymorphism = getDefaultClientPolymorphism();
  const privateKey = key.privateKey as CryptoKey;
  const sig = await kontosClientPolymorphism.sign(
    accountName,
    privateKey,
    enhancedFromHex(userOpHash)
  );
  userOp.sigOrProof = sig;
  return { userOp: userOp, opHash: userOpHash, sig: uint8ArrayToBase64(sig) };
};

// Send Kontos Native
export const sendKontosNative = async (
  key: KontosKey,
  amount: KontosNumber,
  toAddress: string,
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  // const newOnSuccess = () => {
  //   handleSuccess("Transaction successful!");
  //   onSuccess?.();
  // };
  // const newOnFail = (e: any) => {
  //   handleError(e, "sending");
  //   onFail?.(e);
  // };
  // loadingStore.showLoading();
  // try {
  //   await key.sendNativeToken(
  //     toAddress.replaceAll(".os", ""),
  //     Decimal.fromUserInput(amount.toString(), DEFAULT_DECIMAL)
  //   );
  //   newOnSuccess?.();
  // } catch (e) {
  //   newOnFail?.(e);
  // } finally {
  //   loadingStore.hideLoading();
  // }
};

// Send Kontos Others
export const sendKontosOthers = async (
  token: ethers.Contract,
  gasPrice: ethers.BigNumber,
  nonce: number,
  gasLimit: number,
  amount: KontosNumber,
  toAddress: string,
  onSuccess?: () => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = () => {
    handleSuccess("Transaction successful!");
    onSuccess?.();
  };

  const newOnFail = (e: any) => {
    handleError(e, "sending");
    onFail?.(e);
  };

  loadingStore.showLoading();
  try {
    await token.transfer(
      KontosQueryCli.nameAddress(toAddress),
      parseEther(amount.toString()),
      {
        gasPrice: gasPrice,
        nonce: nonce,
        gasLimit: gasLimit,
      }
    );
    newOnSuccess?.();
  } catch (e) {
    newOnFail?.(e);
  } finally {
    loadingStore.hideLoading();
  }
};

export const handleSuccess = (text: string) => {
  toast({
    text: text,
    type: "success",
  });
};

export const handleError = (e: any, text?: string) => {
  const errorMessage =
    e instanceof Error
      ? parseApiErrorMessage(e).message
      : text
        ? "An unknown error occurred when " + text
        : "An unknown error occurred";
  if (errorMessage.toLocaleLowerCase() !== "canceled") {
    toast({ type: "error", text: errorMessage });
  }
};

export const executeTaskPayment = async (
  method: RegisterMethod,
  accountName: string,
  chains: Chain[],
  key: KontosKey,
  taskData: RespTaskPayment,
  onSuccess?: (resp?: any) => void,
  onFail?: (e: any) => void
) => {
  loadingStore.showLoading();

  const toRegisterTasks: {
    chainIndex: string;
    opOrIntention: OpOrIntention;
    payments: Payment[];
    taskDetail: TaskDetail;
  }[] = [];
  const userOpArr: ApiPaymentConfirmation[] = [];

  // error reporting: save chain data before tx
  let nonce = -1;
  let gas = -1;
  let msgs: {
    typeUrl: string;
    value: MsgRegisterTask;
  }[] = [];
  let txHex = "";
  let cryptoSupport = {};

  try {
    let resp = undefined;

    await Promise.all(
      taskData.msgs!.map(async (task) => {
        if (task.opOrIntention.intention) {
          const intention = task.opOrIntention.intention;
          const intentionTask = {
            chainIndex: task.chainIndex,
            opOrIntention: {
              intention: {
                receiver: intention.receiver,
                ftAssets: [
                  {
                    assetAddress: intention.ft_assets[0].asset_address,
                    assetAmount: intention.ft_assets[0].asset_amount,
                    targetPrice: intention.ft_assets[0].target_price,
                    slippage: intention.ft_assets[0].slippage,
                  },
                ],
                nftAssets: [],
                digitalAssets: [],
                data: intention.data,
                initCode: intention.init_code,
              },
            },
            payments: task.payments ? task.payments.map(transferPayment) : [],
            taskDetail: transferTaskDetail(task.taskDetail),
          };
          toRegisterTasks.push(intentionTask);
        } else if (task.opOrIntention.user_op) {
          const { userOp, opHash, sig } = await fetchUserOpFromSingleTaskData(
            accountName,
            key,
            task,
            getChainByChainIndex(chains, task.chainIndex)!
          );
          const opTask = {
            chainIndex: task.chainIndex,
            opOrIntention: {
              userOp: userOp,
            },
            payments: task.payments ? task.payments.map(transferPayment) : [],
            taskDetail: transferTaskDetail(task.taskDetail),
          };
          toRegisterTasks.push(opTask);
          userOpArr.push({
            opHash,
            sig,
          });
        }
      })
    );
    if (toRegisterTasks.length > 0) {
      // error reporting: save chain data before tx
      try {
        await Promise.all([
          (async () => {
            msgs = toRegisterTasks.map((task) => ({
              typeUrl: TypeURLMsgRegisterTask,
              value: MsgRegisterTask.fromPartial({
                chainIndex: task.chainIndex,
                from: KontosQueryCli.nameAddress(accountName),
                opOrIntention: task.opOrIntention,
                payments: task.payments,
                taskDetail: task.taskDetail,
              }),
            }));
          })(),
          (async () => {
            const sequence = await getSequence(accountName);
            nonce = sequence;
          })(),
          (async () => {
            cryptoSupport = await checkCryptoSupport();
          })(),
        ]);
      } catch (e) {
        console.log("tx debug warning");
      }

      // execute
      // resp = await cli.registerMultiTasks(toRegisterTasks);
      resp = await callConfirmPayment({
        account: accountName,
        confirmations: userOpArr,
      });
    }
    onSuccess?.(resp?.opHashes?.[0]);
    return filterEventNewTaskOpHash(resp);
  } catch (e) {
    const kontosAddressFromRpc = rootStore.userStore.accountInfo?.kontosAddress;
    let nameBytes;
    let addressBytes;
    let addressBytes_2;
    let calculatedKontosAddress;

    uploadErrorOnce(
      accountName,
      method,
      {
        toRegisterTasks,
        reqTaskData: paramStore.getTaskDataParams(),
        respTaskData: taskData,
        debugData: {
          sender: accountName,
          kontosAddress: KontosQueryCli.kontosAddress(accountName),
          kontosAddressFromRpc,
          kontosAddressCalcProcess: {
            nameBytes,
            addressBytes,
            addressBytes_2,
            calculatedKontosAddress,
          },
          nonce: nonce,
          gas: gas,
          pubKey: rootStore.userStore.accountInfo?.pubKey,
          msgs: msgs,
          txHex,
        },
        deviceInfo: getDeviceInfo(),
        cryptoSupport,
      },
      e
    );
    onFail?.(e);
  } finally {
    loadingStore.hideLoading();
  }
};

export const buyV2 = async (
  accountName: string,
  chains: Chain[],
  key: KontosKey,
  taskData: RespTaskPayment,
  onSuccess?: (opHash?: string) => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (opHash?: string) => {
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: ApiActionType.Buy,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: "-",
        chainSymbol: "-",
        intentionProps: {
          totalUsdCost: taskData.totalPaymentsInUsd,
          requiredAssets: [
            {
              amount:
                rootStore.buyStore.toBuyFtAssetQuantity?.toStringWithDecimal(
                  DEFAULT_DECIMAL
                ) || "-",
              imageUrl: rootStore.buyStore.toBuyFtAsset?.imageUrl,
            },
          ],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.(opHash);
  };

  const newOnFail = (e: any) => {
    handleError(e, "buying assets");
    onFail?.(e);
  };

  await executeTaskPayment(
    RegisterMethod.Buy,
    accountName,
    chains,
    key,
    taskData,
    newOnSuccess,
    newOnFail
  );
};

export const sellV2 = async (
  accountName: string,
  chains: Chain[],
  key: KontosKey,
  taskData: RespTaskPayment,
  onSuccess?: (opHash?: string) => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (opHash?: string) => {
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: ApiActionType.Sell,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: "-",
        chainSymbol: "-",
        intentionProps: {
          totalUsdCost: taskData.totalPaymentsInUsd,
          requiredAssets: [
            {
              amount:
                rootStore.sellStore.toReceiveFtAssetQuantity?.toStringWithDecimal(
                  DEFAULT_DECIMAL
                ) || "-",
              imageUrl: rootStore.sellStore.toReceiveFtAsset?.imageUrl,
            },
          ],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.(opHash);
  };

  const newOnFail = (e: any) => {
    handleError(e, "selling assets");
    onFail?.(e);
  };

  await executeTaskPayment(
    RegisterMethod.Sell,
    accountName,
    chains,
    key,
    taskData,
    newOnSuccess,
    newOnFail
  );
};

export const userOpCall = async (
  accountName: string,
  key: KontosKey,
  taskData: RespTaskPayment,
  chains: Chain[],
  onSuccess?: (opHash?: string) => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (opHash?: string) => {
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: ApiActionType.UserOp,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: undefined,
        chainSymbol: "-",
        opCallProps: {
          totalUsdCost: taskData.totalPaymentsInUsd,
          opCallDestAddrs: [],
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.(opHash);
  };

  const newOnFail = (e: any) => {
    handleError(e, "executing this interaction");
    onFail?.(e);
  };

  await executeTaskPayment(
    RegisterMethod.Dapp,
    accountName,
    chains,
    key,
    taskData,
    newOnSuccess,
    newOnFail
  );
};

export const crossChainTransfer = async (
  chain: Chain,
  amount: string,
  toSendFtAsset: FtAsset,
  accountName: string,
  key: KontosKey,
  taskData: RespTaskPayment,
  chains: Chain[],
  onSuccess?: (opHash?: string) => void,
  onFail?: (e: any) => void
) => {
  const newOnSuccess = (opHash?: string) => {
    if (opHash && opHash.startsWith("0x")) {
      const record: RecordOverviewDisplayProps = {
        id: opHash,
        tasktype: ApiActionType.Transfer,
        isFake: true,
        createdAt: Date.now(),
        chainImageUrl: chain?.imageURL,
        chainSymbol: chain?.chainSymbol || "-",
        transferProps: {
          amount: amount,
          symbol: toSendFtAsset.symbol,
          imageUrl: toSendFtAsset.imageUrl,
          totalUsdCost: taskData.totalPaymentsInUsd,
        },
      };
      localKeeper.saveRecord(accountName, record, "task");
    }
    onSuccess?.(opHash);
  };

  const newOnFail = (e: any) => {
    handleError(e, "sending");
    onFail?.(e);
  };

  await executeTaskPayment(
    RegisterMethod.Send,
    accountName,
    chains,
    key,
    taskData,
    newOnSuccess,
    newOnFail
  );
};
