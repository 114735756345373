import { fontSmaller } from "@/style/style.global";
import styled, { css } from "styled-components";
import errorIcon from "src/assets/icons/error1.svg";
import warningIcon from "src/assets/icons/warn1.svg";
import successIcon from "src/assets/icons/success1.svg";
import infoIcon from "src/assets/icons/trade/info.svg";

const Container = styled.div<{
  $status: "success" | "warning" | "error" | "info";
}>`
  width: 100%;
  padding: 8px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;

  ${(props) =>
    (props.$status === "success" || props.$status === "info") &&
    css`
      background: rgba(16, 206, 92, 0.1);
      color: var(--Success, #10ce5c);
    `}

  ${(props) =>
    props.$status === "warning" &&
    css`
      background: rgba(250, 173, 20, 0.1);
      color: var(--Warning, #faad14);
    `}

    ${(props) =>
    props.$status === "error" &&
    css`
      background: rgba(255, 30, 43, 0.05);
      color: var(--error-notice, #ff1e2b);
    `}
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const Content = styled.div`
  ${fontSmaller}
`;

interface IProps {
  status?: "success" | "warning" | "error" | "info";
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export type TipsAreaStatus = IProps["status"];

export const TipsArea: React.FC<IProps> = ({
  status = "info",
  className,
  style,
  children,
}) => {
  const icon =
    status === "error"
      ? errorIcon
      : status === "success"
        ? successIcon
        : status === "warning"
          ? warningIcon
          : infoIcon;
  const altText = `${status} icon`;

  return (
    <Container $status={status} className={className} style={style}>
      <Icon src={icon} alt={altText} />
      <Content>{children}</Content>
    </Container>
  );
};
