import styled from "styled-components";
import smallBallIcon from "src/assets/icons/events/eb/ball-small.svg";
import middleBallIcon from "src/assets/icons/events/eb/ball-middle.svg";
import bigBallIcon from "src/assets/icons/events/eb/ball-big.svg";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import CountUp from "react-countup";
import { fontBold } from "@/style/style.global";

const BALL_SIZE_SMALL = 2;
const BALL_SIZE_MIDDLE = 4;
const BALL_SIZE_BIG = 6;
const COUNT_UP_DURATION = 2;

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Ball = styled.img<{ $size: number }>`
  border-radius: 99px;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

const CountContainer = styled.div`
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid var(--Deep-800, #1a2535);

  background: linear-gradient(
    93deg,
    #b4ffbb 0.53%,
    #31e4e2 40.32%,
    #e7ffdf 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  line-height: 16px;

  z-index: 2;

  .count {
    ${fontBold};
    font-size: 14px;
  }
`;

interface IProps {
  count?: number;
}

export const EbLeaderBoardCount: React.FC<IProps> = ({ count }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Ball src={smallBallIcon} $size={BALL_SIZE_SMALL} />
      <Ball src={middleBallIcon} $size={BALL_SIZE_MIDDLE} />
      <Ball src={bigBallIcon} $size={BALL_SIZE_BIG} />
      <CountContainer>
        <span>{t("Total Participants:")}&nbsp;</span>
        {typeof count === "number" ? (
          <CountUp
            className="count"
            start={0}
            end={count}
            duration={COUNT_UP_DURATION}
          />
        ) : (
          <Skeleton count={1} style={{ width: "50px" }} />
        )}
      </CountContainer>
      <Ball src={bigBallIcon} $size={BALL_SIZE_BIG} />
      <Ball src={middleBallIcon} $size={BALL_SIZE_MIDDLE} />
      <Ball src={smallBallIcon} $size={BALL_SIZE_SMALL} />
    </Container>
  );
};
