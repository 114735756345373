import styled from "styled-components";

const PathOne = styled.path``;
const PathTwo = styled.path``;
const Ellipse = styled.ellipse``;

const Svg = styled.svg<{ $isAvtive: boolean; $disable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${PathOne} {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_100};
  }

  ${PathTwo} {
    stroke: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_200};
  }

  ${Ellipse} {
    fill: ${(props) =>
      props.$isAvtive
        ? props.theme.colors.__kontos_blue
        : props.theme.colors.__deep_200};
  }

  cursor: ${(props) => (!props.$disable ? "pointer" : "not-allowed")};

  @media (hover: hover) {
    &:hover {
      ${PathOne} {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }

      ${PathTwo} {
        stroke: ${(props) =>
          !props.$disable && props.theme.colors.__kontos_blue};
      }

      ${Ellipse} {
        fill: ${(props) => !props.$disable && props.theme.colors.__kontos_blue};
      }
    }
  }
`;

interface IProps {
  className?: string;
  isActive?: boolean;
  disable?: boolean;
  onClick?: () => void;
}

const TradeSvg: React.FC<IProps> = ({
  className,
  isActive = false,
  disable = false,
  onClick,
}) => {
  return (
    <Svg
      $isAvtive={isActive}
      $disable={disable}
      className={className}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <PathOne
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2832 2.07614C18.2831 4.05195 19.7653 5.66828 21.5091 6.59726C23.4078 7.60878 24.9636 9.17665 25.959 11.0822C26.8639 12.8143 28.4605 14.2855 30.4147 14.2856C30.4528 14.2856 30.4832 14.2525 30.4784 14.2147C29.676 7.88137 24.6607 2.85972 18.3254 2.03866C18.303 2.03576 18.2832 2.05353 18.2832 2.07614ZM14.8546 2.07611C14.8546 2.04387 14.8266 2.01821 14.7946 2.02205C8.44324 2.78392 3.39081 7.76351 2.52682 14.075C2.51125 14.1888 2.60891 14.2855 2.72369 14.2855C4.59054 14.2855 6.11797 12.8838 6.95862 11.217C7.97688 9.19792 9.61978 7.54608 11.6348 6.5153C13.3753 5.62494 14.8546 4.03112 14.8546 2.07611ZM14.7945 29.9777C14.8267 29.9815 14.8547 29.9558 14.8547 29.9234C14.8548 27.9686 13.3756 26.3749 11.6352 25.4847C9.62 24.4539 7.97692 22.8019 6.95859 20.7827C6.11793 19.1158 4.59052 17.7141 2.72362 17.714C2.60886 17.714 2.51122 17.8107 2.52678 17.9244C3.39066 24.2361 8.44311 29.2158 14.7945 29.9777ZM18.2833 29.9234C18.2833 29.9461 18.3032 29.9639 18.3257 29.961C24.6609 29.1399 29.676 24.1183 30.4784 17.7851C30.4832 17.7472 30.4528 17.7141 30.4147 17.7141C28.4605 17.7141 26.8639 19.1852 25.9592 20.9173C24.9638 22.8229 23.4079 24.3908 21.5092 25.4024C19.7654 26.3314 18.2833 27.9477 18.2833 29.9234Z"
      />
      <PathTwo
        d="M16.501 7.95502L16.501 9.9663"
        strokeWidth="2.28571"
        strokeLinecap="round"
      />
      <PathTwo
        d="M16.501 22.0338L16.501 24.045"
        strokeWidth="2.28571"
        strokeLinecap="round"
      />
      <PathTwo
        d="M8.50098 15.9999L10.501 15.9999"
        strokeWidth="2.28571"
        strokeLinecap="round"
      />
      <PathTwo
        d="M22.501 15.9999L24.501 15.9999"
        strokeWidth="2.28571"
        strokeLinecap="round"
      />
      <Ellipse cx="16.501" cy="15.9999" rx="3" ry="3.01693" />
    </Svg>
  );
};

export default TradeSvg;
