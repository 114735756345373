import PoweredBy from "src/components/common/powerdBy";
import styled from "styled-components";
import logo from "src/assets/images/first-screen-logo.svg";
import { UpgradingPopup } from "src/components/popups/UpgradingPopup";
import { fontBold } from "@/style/style.global";

const Container = styled.div<{ $isMobile: boolean }>`
  flex: 1;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(/static/first-screen-bg.jpg);
  background-size: ${(props) => (props.$isMobile ? "160% auto" : "120% auto")};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: ${(props) => (props.$isMobile ? "center" : "center")};
`;

const Body = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 96px;
  height: 96px;
`;

const Title = styled.span`
  margin-top: 16px;

  color: ${(props) => props.theme.colors.__kontos_blue};
  ${fontBold};
  font-size: 28px;
  margin-bottom: 16px;
`;

const PowerByWrapper = styled.div`
  position: absolute;
  bottom: 20px;
`;

interface Props {
  isMobile: boolean;
}

const FirstScreen: React.FC<Props> = ({ isMobile }) => {
  return (
    <Container $isMobile={isMobile}>
      <Body>
        <Logo src={logo} />
        <Title>Kontos Wallet</Title>
        <PowerByWrapper>
          <PoweredBy />
        </PowerByWrapper>
      </Body>

      {/* <UpgradingPopup /> */}
    </Container>
  );
};

export default FirstScreen;
