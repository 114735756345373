import styled from "styled-components";
import PaymentSelect, { PaymentOptionType } from "./PaymentSelect";
import { PaymentMethod } from "@/store/trade/PaymentStore";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  border-radius: 8px;
  background: var(--Deep-800, #1a2535);

  display: flex;
  padding: 14px 10px;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
  }
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
  flex-shrink: 0;
`;

const Text = styled.div`
  margin-left: 12px;

  color: var(--White, #fff);
  ${fontBold};
  font-size: 14px;
`;

export interface Props {
  icon: any;
  text: string;
  selected: PaymentMethod;
  options: PaymentOptionType[];
  onChange: (selected: PaymentOptionType) => void;
}

const PaymentSwitch: React.FC<Props> = ({
  icon,
  text,
  selected,
  options,
  onChange,
}) => {
  return (
    <Container>
      <div className="left">
        <Icon src={icon} />
        <Text>{text}</Text>
        <div className="spacer"></div>
      </div>
      <PaymentSelect
        options={options}
        selected={selected}
        onChange={onChange}
      />
    </Container>
  );
};

export default PaymentSwitch;
