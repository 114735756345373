import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { KontosButton } from "src/components/button/KontosButton";
import Modal from "src/components/modal/Modal";
import {
  fontDescription,
  fontH5,
  fontH7,
  fontMainText,
} from "@/style/style.global";
import warningIcon from "src/assets/icons/warn1.svg";

const Container = styled.div`
  width: 343px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const WarningIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Title = styled.span`
  color: var(--Warning, #faad14);
  text-align: center;
  ${fontH5};
`;

const Desc = styled.div`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontMainText};
`;

const DescStrong = styled.span`
  color: var(--Kontos-Blue, #413dec);
`;

const Tips = styled.div`
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontDescription};
`;

const CloseButton = styled(KontosButton)`
  width: calc(100% - 16px);
  height: 34px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--White, #fff);
  text-align: center;
  ${fontH7};
`;

interface IProps {
  onClose: () => void;
}

export const CEXTransferWarningModal: React.FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose}>
      <Container>
        <MainContainer>
          <WarningIcon src={warningIcon} alt={t("Warning")} />

          <Title>{t("Warning!")}</Title>

          <Desc>
            <Trans i18nKey={"trans-cex-transfer-warning"}>
              Please transfer to an <DescStrong>EOA address</DescStrong> or a{" "}
              <DescStrong>Kontos account</DescStrong>, and avoid sending funds
              directly to a CEX account as some exchanges do not support
              transfers from contract addresses.
            </Trans>
          </Desc>

          <Tips>
            {t(
              "*If the funds are sent to them, you may need to contact the CEX customer support to recover them."
            )}
          </Tips>
        </MainContainer>

        <CloseButton onClick={onClose}>{t("Got it")}</CloseButton>
      </Container>
    </Modal>
  );
};
