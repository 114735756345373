import { makeAutoObservable, reaction, runInAction } from "mobx";
import { RootStore } from "../RootStore";
import { Chain } from "src/type/zkkontos";
import {
  KONTOS_CHAIN_INDEX,
  XLAYER_CHAIN_INDEX,
  ZETA_CHAIN_INDEX,
} from "src/config";
import {
  ChainIndex,
  NonChainIndex,
} from "src/components/selects/HorizontalScrollableElements";
import { fetchAllChains } from "@/service/chain-service";

const RETRY_INTERVAL = 45000;

export class ChainStore {
  rootStore: RootStore;
  chains: Chain[] = [];
  defaultOptForSend: ChainIndex = NonChainIndex.All;
  defaultOptForBuy: ChainIndex = NonChainIndex.Favorites;
  defaultOptForSell: ChainIndex = NonChainIndex.MyAssets;
  defaultOptForSellReceive: ChainIndex = NonChainIndex.Recommend;
  initing: boolean = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, undefined, { autoBind: true });
    this.init();
  }

  startTrackingAccountName = () => {
    reaction(
      () => this.rootStore.userStore.accountName,
      () => {
        this.resetDefaultOpts();
      }
    );
  };

  resetDefaultOpts = () => {
    this.defaultOptForSend = NonChainIndex.All;
    this.defaultOptForBuy = NonChainIndex.Favorites;
    this.defaultOptForSell = NonChainIndex.All;
    this.defaultOptForSellReceive = NonChainIndex.Recommend;
  };
  setDefaultOptForSend = (index: ChainIndex) => {
    this.defaultOptForSend = index;
  };
  setDefaultOptForBuy = (index: ChainIndex) => {
    this.defaultOptForBuy = index;
  };
  setDefaultOptForSell = (index: ChainIndex) => {
    this.defaultOptForSell = index;
  };
  setDefaultOptForSellReceive = (index: ChainIndex) => {
    this.defaultOptForSellReceive = index;
  };

  fetchAndSetChains = async (
    retryCount: number = 2
  ): Promise<Chain[] | undefined> => {
    try {
      const chains = await fetchAllChains();
      if (chains.length === 0) {
        throw new Error("no chains");
      }
      runInAction(() => {
        this.chains = chains || [];
      });
      return chains;
    } catch (e) {
      console.log("Failed to fetch scraper chains", e);
      if (retryCount > 0) {
        return await this.fetchAndSetChains(retryCount - 1);
      } else {
        setTimeout(() => this.fetchAndSetChains(), RETRY_INTERVAL);
        return undefined;
      }
    }
  };

  init = async () => {
    await this.fetchAndSetChains();
    runInAction(() => {
      this.initing = false;
    });
  };

  fetchSpecificChain = async (index: string): Promise<Chain | undefined> => {
    const matchingOne = this.chains.find((item) => item.chainIndex === index);
    if (matchingOne) {
      return matchingOne;
    }

    const chains = await fetchAllChains();
    if (chains.length === 0) {
      throw new Error("no chains");
    }
    runInAction(() => {
      this.chains = chains || [];
    });
    return chains.find((item) => item.chainIndex === index);
  };

  get isInitialized(): boolean {
    return !this.initing && this.chains.length > 0;
  }

  get noKontosChains(): Chain[] {
    return this.chains.filter(
      (chain) => chain.chainIndex !== KONTOS_CHAIN_INDEX
    );
  }

  get allowSwapChains(): Chain[] {
    return this.chains.filter(
      (chain) =>
        chain.chainIndex !== KONTOS_CHAIN_INDEX &&
        chain.chainIndex !== ZETA_CHAIN_INDEX &&
        chain.chainIndex !== XLAYER_CHAIN_INDEX
    );
  }

  get allowBuyChains(): Chain[] {
    return this.noKontosChains;
  }

  get allowSellChains(): Chain[] {
    return this.noKontosChains;
  }

  get userHoldingsChains(): Chain[] {
    return this.chains.filter(
      (chain) =>
        this.rootStore.userStore.userHoldings?.some(
          (asset) => asset.chainIndex === chain.chainIndex
        )
    );
  }

  get userHoldingsAndAllowBuyChains(): Chain[] {
    return this.userHoldingsChains.filter((item1) =>
      this.allowBuyChains.some((item2) => item1.chainIndex === item2.chainIndex)
    );
  }

  get noUserHoldingsChains(): Chain[] {
    return this.chains.filter(
      (chain) =>
        !this.rootStore.userStore.userHoldings?.some(
          (asset) => asset.chainIndex === chain.chainIndex
        )
    );
  }

  get userHoldingsNoKontosChains(): Chain[] {
    return this.chains.filter(
      (chain) =>
        this.rootStore.userStore.userHoldings.some(
          (asset) => asset.chainIndex === chain.chainIndex
        ) && chain.chainIndex !== KONTOS_CHAIN_INDEX
    );
  }

  get chainsSortedByUserHoldings(): Chain[] {
    return this.userHoldingsChains.concat(this.noUserHoldingsChains);
  }
}
