import { callTask } from "@/apis/task-apis";
import { Task, TaskDetail } from "@/type/zkkontos";
import { t } from "i18next";

export const fetchAccountTasks = async (
  account: string,
  offset: number,
  limit: number
): Promise<{
  tasks: Task[];
  hasMore: boolean;
}> => {
  const { tasks, hasMore } = await callTask({
    opHash: "",
    chainIndex: "",
    account,
    offset,
    limit,
  });
  return { tasks: tasks || [], hasMore };
};

export const fetchTaskDetail = async (opHash: string): Promise<TaskDetail> => {
  const { taskDetail } = await callTask({
    opHash,
    chainIndex: "",
    account: "",
    offset: 0,
    limit: 0,
  });
  if (taskDetail === null) {
    throw new Error(t("Failed to query task details"));
  }
  return taskDetail;
};
