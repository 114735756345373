import { convertChainId } from "./helper";
import { rootStore } from "..";
import defaultChainIcon from "src/assets/icons/trade/default-chain.svg";
import { Chain } from "src/type/zkkontos";

export const getChain = (chainIndex: string): Chain | undefined => {
  const decimalChainId = convertChainId(chainIndex, "toDecimal");
  const chains = rootStore.chainStore.chains;
  const matchedChain = chains.find(
    (chain) => convertChainId(chain.chainIndex, "toDecimal") === decimalChainId
  );
  return matchedChain;
};

export const getChainIcon = (chainIndex: string): any => {
  const matchedChain = getChain(chainIndex);
  return matchedChain ? matchedChain.imageURL : defaultChainIcon;
};

export const getChainSymbol = (chainIndex: string): string | null => {
  const matchedChain = getChain(chainIndex);
  return matchedChain ? matchedChain.chainSymbol : null;
};

export const getAaAccount = (chainIndex: string) => {};
