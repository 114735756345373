import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import { useStores } from "src/hooks/useStore";
import Warn from "src/components/warn/Warn";
import AddressInputSearch from "src/components/input-search/AddressInputSearch";
import { FtAsset } from "src/type/zkkontos";
import { getChainByAsset } from "@/store/storeHelper";
import { useCallback } from "react";
import { CEXTransferWarningModal } from "../transfer/modals/CEXTransferWarningModal";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
`;

type IProps = {
  setShowSelectTokenModal: (show: boolean) => void;
  setReceiver: (value: string | undefined) => void;
  allowEOA: boolean;
  requireAA: boolean;
  toSendFtAsset?: FtAsset;
  receiver?: string;
  setIsReceiveAddressValid?: (valid: boolean) => void;
  onOkTextChange?: (okText: string[] | undefined) => void;
  onErrTextChange?: (errText: string[] | undefined) => void;
  showWarningModal: boolean;
  setShowWarningModal: (show: boolean) => void;
};

export const SelectReceiveModal: React.FC<IProps> = observer(
  ({
    setShowSelectTokenModal,
    setReceiver,
    allowEOA,
    requireAA,
    toSendFtAsset,
    receiver,
    setIsReceiveAddressValid,
    onOkTextChange,
    onErrTextChange,
    showWarningModal,
    setShowWarningModal,
  }) => {
    const { userStore } = useStores();
    const onFinish = useCallback(
      (isSuccess: boolean, value: string) => {
        setIsReceiveAddressValid && setIsReceiveAddressValid(isSuccess);
        setReceiver(value);
      },
      [setIsReceiveAddressValid, setReceiver]
    );

    return (
      <Wrapper>
        <SheetUpHeader
          handleBack={() => {
            setShowSelectTokenModal(false);
          }}
          headStyle={{ justifyContent: "center" }}
          title={t("To Address")}
          padding="8px"
          rightText={"Done"}
          rightClickFunc={() => {
            setShowSelectTokenModal(false);
          }}
        />
        <Warn
          styles={{
            width: "100%",
            margin: "10px 0",
          }}
          text={t(
            "Please avoid sending funds to your CEX addresses because some CEXs do not support such transfers."
          )}
        />
        <AddressInputSearch
          rawAddress={receiver}
          onFinish={onFinish}
          allowEOA={allowEOA}
          userWallet={userStore.accountInfo?.name}
          requireAA={requireAA}
          chainIndex={toSendFtAsset?.chainIndex}
          chainSymbol={getChainByAsset(toSendFtAsset)?.chainSymbol}
          inputStyle={{
            background: "transparent",
          }}
          onOkTextChange={onOkTextChange}
          onErrTextChange={onErrTextChange}
          onSuggestionsOnClick={(suggestion) => {
            setShowSelectTokenModal(false);
          }}
          needValidateAfterRender
          showLoading={false}
        />

        {showWarningModal && (
          <CEXTransferWarningModal onClose={() => setShowWarningModal(false)} />
        )}
      </Wrapper>
    );
  }
);
