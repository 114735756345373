import styled from "styled-components";
import React from "react";
import success from "src/assets/images/success.svg";
import pending from "src/assets/images/pending.svg";
import { t } from "i18next";
import { fontH7 } from "@/style/style.global";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    text-align: right;

    /* H7 */
    ${fontH7}
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  .normal {
    color: var(--Deep-100, #cccfd2);
  }

  .pending {
    color: var(--Warning, #faad14);
  }

  .success {
    color: var(--Success, #10ce5c);
  }

  img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 6px;
  }
`;

export const rocoverStatusMap = {
  normal: "normal",
  pending: "pending",
  success: "success",
};

type IProps = {
  status: keyof typeof rocoverStatusMap;
  className?: string;
  style?: React.CSSProperties;
  text?: string;
};

const RecoverInputStatusText: React.FC<IProps> = ({
  status,
  className,
  style,
  text,
}) => {
  return (
    <Wrapper className={className} style={style}>
      {status === "normal" && (
        <span className={"normal"}>{text || t("No request")}</span>
      )}
      {status === "pending" && (
        <>
          <span className={"pending"}>{text || t("Pending...")}</span>
          <img src={pending} alt="pending" />
        </>
      )}
      {status === "success" && (
        <>
          <span className={"success"}>{text || t("Confirmed")}</span>
          <img src={success} alt="success" />
        </>
      )}
    </Wrapper>
  );
};

export default RecoverInputStatusText;
