import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { KontosButton } from "src/components/button/KontosButton";
import {
  fontBold,
  fontDescription,
  fontH5,
  fontH6,
  fontMainText,
} from "@/style/style.global";
import Modal from "@/components/modal/Modal";
import tipsIcon from "src/assets/icons/trade/tip.svg";

const StyledModal = styled(Modal)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: var(--White, #fff);
  max-width: 375px;
  min-width: 330px;
  border-radius: 16px;
`;

const TipsIcon = styled.img`
  width: 35px;
  height: 20px;
`;

const Title = styled.div`
  color: var(--Kontos-Blue, #413dec);
  text-align: center;
  ${fontH5}
`;

const Desc = styled.div`
  color: var(--Deep-800, #1a2535);
  text-align: center;
  ${fontMainText}
`;

const Strong = styled.strong`
  color: var(--Kontos-Blue, #413dec);
  ${fontBold}
  font-size: 16px;
  line-height: 130%;
`;

const Tips = styled.div`
  color: var(--Deep-400, #80868f);
  text-align: center;
  ${fontDescription}
`;
const StyledKontosButton = styled(KontosButton)`
  width: 100%;
  height: 34px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--White, #fff);
  text-align: center;
  ${fontH6};
`;

interface Props {
  onClose: () => void;
}

export const RecoveryByEmailHintPopup: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <StyledModal onClose={onClose}>
      <TipsIcon src={tipsIcon} />

      <Title>{t("Only Gmail is accepted!")}</Title>

      <Desc>
        <Trans i18nKey={"trans-recover-by-email-hint-desc"}>
          Currently, we only accept recovery emails that are sent from{" "}
          <Strong>Gmail</Strong>, and the email address must match the one you
          provided during the account registration process.
        </Trans>
      </Desc>

      <Tips>
        {t(
          "*Please make sure that the email address you use to send the account recovery email is correct."
        )}
      </Tips>

      <StyledKontosButton onClick={onClose}>{t("Got it")}</StyledKontosButton>
    </StyledModal>
  );
};
