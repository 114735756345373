import { fontDescription } from "@/style/style.global";
import { ReactNode, useState } from "react";
import { SingleIconButton } from "src/components/button/SingleIconButton";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PrefixIcon = styled.img`
  margin-right: 6px;
  width: 16px;
  height: 16px;
`;

const Text = styled.span<{ $isHover: boolean }>`
  overflow: hidden;
  ${(props) =>
    props.$isHover
      ? css`
          color: var(--Kontos-Blue, #413dec);
        `
      : css`
          color: var(--Deep-800, #1a2535);
        `};
  text-overflow: ellipsis;
  font-family: "HarmonyOS Sans SC";
  font-size: 14px;
  font-weight: 400;
`;

const EditIcon = styled(SingleIconButton)`
  margin-left: 6px;
`;

interface IProps {
  prefixIcon?: string;
  prefixItem?: ReactNode;
  text: string;
  onClick: () => void;
}

export const TradeBreakdownEditableItem: React.FC<IProps> = ({
  prefixIcon,
  prefixItem,
  text,
  onClick,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {prefixIcon && <PrefixIcon src={prefixIcon} />}
      {prefixItem}
      <Text $isHover={isHover}>{text}</Text>
      <EditIcon type="edit" forceActive={isHover} />
    </Container>
  );
};

export const BreakDownLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DarkBreakDownText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Deep-800, #1a2535);
  ${fontDescription};
  line-height: 16px;
`;

export const LightBreakDownText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Deep-400, #80868f);
  ${fontDescription};
  line-height: 16px;
`;

export const BreakDownAssetIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
