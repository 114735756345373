export interface EnergyThreshold {
  value: number;
  energyOrbRewardType: number;
  energyOrbsRewardCount: number;
  claimStatus: number;
  updatedAt: number;
}

export interface EnergyBarStage {
  stage: number;
  stageDesc: string;
  start: number;
  end: number;
  thresholds: EnergyThreshold[];
}

export interface EnergyBar {
  stages: EnergyBarStage[];
}

export interface ActionItem {
  actionType: number;
  actionTypeDesc: string;
  actionStatus: number;
  outputX: string;
  outputY: string;
  updatedAt: number;
  requireConnection: number;
}

export const EB_KEY_ACTION_CATEGORY = 0;

export const EB_ACTION_STATUS_PENDING = 0;
export const EB_ACTION_STATUS_SUCCESS = 1;

export const EB_ACTIONS_ISFIXED_FIXED = 1;
export const EB_ACTIONS_ISFIXED_DYNAMIC = 0;

export const EB_ACTION_TYPE_TRADE = 0;
export const EB_SOCIAL_ACTION_TYPE_TWITTER = 600;
export const EB_SOCIAL_ACTION_TYPE_DISCORD = 601;
export const EB_SOCIAL_ACTION_TYPE_BYBIT = 6000;

export const EB_SOCIAL_ACTION_TYPES: number[] = [
  EB_SOCIAL_ACTION_TYPE_TWITTER,
  EB_SOCIAL_ACTION_TYPE_DISCORD,
];
export const EB_BOOST_NEED_ACTION_TYPES: number[] = [
  EB_SOCIAL_ACTION_TYPE_TWITTER,
  EB_SOCIAL_ACTION_TYPE_DISCORD,
  EB_ACTION_TYPE_TRADE,
];

export interface Actions {
  isFixed: number;
  category: number;
  categoryDesc: string;
  actionItems: ActionItem[];
}

export interface AccountActions {
  actions: Actions[];
}

export interface Notification {
  id: number;
  account: string;
  message: string;
  messageType: number;
  messageTypeDesc: string;
}

export interface BoostRecord {
  booster: string;
  boosterName: string;
  boostee: string;
  boosteeName: string;
  boostValue: number;
  boostedAt: number;
}

export enum OrbType {
  normal = 0,
  newcomer = 1,
}

export enum RewardRarity {
  normal = 0,
  rare = 1,
}

export interface EnergyOrb {
  orbType: number;
  orbTypeDesc: string;
  amount: number;
}

export interface RewardItem {
  chainIndex: string;
  chainSymbol: string;
  chainImageUrl: string;
  chainGreyImageUrl: string;
  assetName: string;
  assetSymbol: string;
  assetImageUrl: string;
  assetAddress: string;
  assetIndex: string;
  assetAmount: string;
  assetDesc: string;
  updatedAt: number;
}

export interface Reward {
  rewardType: number;
  rewardTypeDesc: string;
  rewardItem: RewardItem;
}

export interface Leaderboard {
  rank: number;
  energyValue: number;
  name: string;
  avatar: string;
}
