import PrimaryButton from "@/components/button/PrimaryButton";
import SecondaryButton from "@/components/button/SecondaryButton";
import Modal from "@/components/modal/Modal";
import { fontBold, fontH6 } from "@/style/style.global";
import { ReactNode } from "react";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  border-radius: 16px;
  background: var(--White, #fff);
  padding: 16px;
  width: 343px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.img`
  width: 42px;
  height: 24px;
`;

const Title = styled.span`
  color: var(--Kontos-Blue, #413dec);
  ${fontH6}
`;

const Content = styled.div`
  text-align: center;
`;

const BtnContainer = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  height: 34px;
  flex: 1;
  ${fontBold}
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  height: 34px;
  flex: 1;
  ${fontBold}
`;

interface BtnProps {
  text: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}

interface IProps {
  icon: string;
  title: string;
  content: ReactNode;
  leftBtnProps: BtnProps;
  rightBtnProps: BtnProps;
}

export const UniversalModal: React.FC<IProps> = ({
  icon,
  title,
  content,
  leftBtnProps,
  rightBtnProps,
}) => {
  return (
    <StyledModal>
      <MainContainer>
        <Icon src={icon} alt="icon" />
        <Title>{title}</Title>
        <Content>{content}</Content>
      </MainContainer>

      <BtnContainer>
        <StyledSecondaryButton
          onClick={leftBtnProps.onClick}
          loading={leftBtnProps.loading}
          disabled={leftBtnProps.disabled}
          radius={99}
        >
          {leftBtnProps.text}
        </StyledSecondaryButton>

        <StyledPrimaryButton
          onClick={rightBtnProps.onClick}
          loading={rightBtnProps.loading}
          disabled={rightBtnProps.disabled}
          radius={99}
        >
          {rightBtnProps.text}
        </StyledPrimaryButton>
      </BtnContainer>
    </StyledModal>
  );
};
