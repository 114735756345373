import { CONNECTOR_BYBIT } from "src/config";
import { useTranslation } from "react-i18next";
import toast from "src/components/toast/Toast";
import { useAccount, useDisconnect } from "wagmi";
import { type UseDisconnectParameters } from "wagmi";
import { useCallback } from "react";

export const useWalletDisconnect = (parameters?: UseDisconnectParameters) => {
  const { t } = useTranslation();
  const {
    disconnect: rawDisconnect,
    disconnectAsync: rawDisconnectAsync,
    ...rest
  } = useDisconnect(parameters);
  const walletAccount = useAccount();

  const disconnect = (...args: Parameters<typeof rawDisconnect>) => {
    if (walletAccount.connector?.id === CONNECTOR_BYBIT) {
      toast({
        text: t(
          "Please manually disconnect your account from the Bybit wallet."
        ),
        type: "warning",
      });
      return;
    }
    return rawDisconnect(...args);
  };

  const disconnectAsync = async (
    ...args: Parameters<typeof rawDisconnectAsync>
  ) => {
    if (walletAccount.connector?.id === CONNECTOR_BYBIT) {
      toast({
        text: t(
          "Please manually disconnect your account from the Bybit wallet."
        ),
        type: "warning",
      });
      return;
    }
    return await rawDisconnectAsync(...args);
  };

  const toastDisconnectSuccess = useCallback(() => {
    if (
      walletAccount.connector &&
      walletAccount.connector.id !== CONNECTOR_BYBIT
    ) {
      console.log("id", walletAccount.connector?.id);
      toast({
        text: t("Successfully disconnected"),
        type: "success",
      });
    }
  }, [t, walletAccount.connector]);

  return { toastDisconnectSuccess, disconnect, disconnectAsync, ...rest };
};
