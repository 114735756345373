import { useTranslation } from "react-i18next";
import styled from "styled-components";
import backIco from "src/assets/icons/events/eb/left-blue-arrow.svg";
import { useEffect } from "react";
import ProgressBar from "progressbar.js";
import CreditPath from "./CreditPath";
import questionIco from "src/assets/icons/events/eb/question.svg";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { fontBold } from "@/style/style.global";

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;

  padding: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackContainer = styled.div`
  position: absolute;
  height: 14px;
  left: 13.5px;
  top: 12px;

  cursor: pointer;

  display: flex;
  align-items: center;

  .back-icon {
    width: 6px;
    height: 10px;
  }

  .back-text {
    margin-left: 2px;

    color: var(--Kontos-Blue, #413dec);
    ${fontBold};
    font-size: 12px;
  }
`;

const ProgressContainer = styled.div`
  width: 168px;
  height: 168px;
  border-radius: 50%;
  border: 1px solid #edeef1;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .credit-bar {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 149.907px;
    height: 149.907px;
    flex-shrink: 0;
  }

  .credit-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .credit-text-upper {
      color: var(--Kontos-Blue, #413dec);
      text-align: center;
      ${fontBold};
      font-size: 24px;
    }

    .credit-text-lower {
      margin-top: -1px;

      color: var(--Kontos-Blue, #413dec);
      text-align: center;
      font-family: "HarmonyOS Sans SC";
      font-size: 12px;
      font-weight: 400;
    }
  }

  .credit-score {
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
    align-items: center;

    color: var(--Deep-400, #80868f);
    text-align: center;
    font-family: "HarmonyOS Sans SC";
    font-size: 12px;
    font-weight: 400;

    .credit-score-icon {
      margin-left: 4px;
      cursor: pointer;

      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }
  }
`;

interface Props {
  percentage: number | undefined;
  accountCredit: string | undefined;
  accountCreditLevel: string | undefined;
  onBack: () => void;
}

const EbAccountPanelCredit: React.FC<Props> = ({
  percentage,
  accountCredit,
  accountCreditLevel,
  onBack,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (percentage) {
      let bar = new ProgressBar.Path("#credit-path", {
        easing: "easeInOut",
        duration: 1400,
      });
      bar.animate(percentage);
    } else {
      let bar = new ProgressBar.Path("#credit-path", {
        easing: "easeInOut",
        duration: 1,
      });
      bar.animate(0);
    }
  }, [percentage]);

  return (
    <Container
    // TODO: eb anime
    // initial={{ x: -100, opacity: 0 }}
    // animate={{ x: 0, opacity: 1 }}
    // exit={{ x: 100, opacity: 0 }}
    // transition={{ ease: "easeInOut" }}
    >
      <BackContainer onClick={onBack}>
        <img className="back-icon" src={backIco} alt="" />
        <span className="back-text">{t("Back")}</span>
      </BackContainer>
      <ProgressContainer>
        <CreditPath className="credit-bar" />
        <div className="credit-text">
          <div className="credit-text-upper">
            {/* TODO: Credit level */}
            {/* {accountCreditLevel || (
              <Skeleton count={1} style={{ width: "30px" }} />
            )} */}
            {"?"}
          </div>
          <div className="credit-text-lower">{t("Account Credit")}</div>
        </div>
        <div className="credit-score">
          <span>
            {t("Score: ")}
            {accountCredit || <Skeleton count={1} style={{ width: "20px" }} />}
          </span>
          {/* <img className="credit-score-icon" src={questionIco} alt="" /> */}
        </div>
      </ProgressContainer>
    </Container>
  );
};

export default EbAccountPanelCredit;
