import { useCallback, useMemo } from "react";
import { useStores } from "./useStore";

const useAuthCheck = () => {
  const { globalStore, userStore } = useStores();

  const authed = useMemo(() => {
    return userStore.accountInfo !== undefined;
  }, [userStore.accountInfo]);

  const ensureAuthed = useCallback(
    (callback?: (...args: any[]) => void, ...args: any[]) => {
      if (!authed) {
        globalStore.openAuthModal();
        return;
      }
      callback?.(...args);
    },
    [authed, globalStore]
  );

  const ensureAuthedAsync = useCallback(
    async (
      callback?: (...args: any[]) => Promise<void> | void,
      ...args: any[]
    ) => {
      if (!authed) {
        globalStore.openAuthModal();
        return;
      }
      await callback?.(...args);
    },
    [authed, globalStore]
  );

  return { authed, ensureAuthed, ensureAuthedAsync };
};

export default useAuthCheck;
