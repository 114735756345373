import { Chain, FtAsset } from "@/type/zkkontos";
import { useCallback, useState } from "react";
import styled from "styled-components";
import expandDownIcon from "src/assets/icons/expand-down.svg";
import expandDownActiveIcon from "src/assets/icons/expand-down-active.svg";
import expandUpIcon from "src/assets/icons/expand-up.svg";
import expandUpActiveIcon from "src/assets/icons/expand-up-active.svg";
import { TokenOrChainIcon } from "../icons/TokenOrChainIcon";
import KontosNumber from "src/utils/KontosNumber";
import { DEFAULT_DECIMAL } from "src/config";
import { GeneralFtAssetItem } from "../asset-item/GeneralFtAssetItem";
import { useStores } from "src/hooks/useStore";
import { useSearchParams } from "react-router-dom";
import NoDataV2 from "../no-data/NoDataV2";
import { useTranslation } from "react-i18next";
import { fontBold } from "@/style/style.global";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  user-select: none;
`;

const ChainItemWrapper = styled.div``;

const ChainItem = styled.div<{ $expanded: boolean }>`
  border-radius: 8px;
  background: var(--White, #fff);
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background: var(--Deep-25, #f5f5f6);
      .chain-balance {
        color: var(--Kontos-Blue, #413dec) !important;
      }
      .expand-icon {
        background-image: ${(props) =>
          props.$expanded
            ? `url(${expandUpActiveIcon})`
            : `url(${expandDownActiveIcon})`} !important;
      }
    }
  }
  &:active {
    background: var(--Deep-25, #f5f5f6);
    .chain-balance {
      color: var(--Kontos-Blue, #413dec) !important;
    }
    .expand-icon {
      background-image: ${(props) =>
        props.$expanded
          ? `url(${expandUpActiveIcon})`
          : `url(${expandDownActiveIcon})`} !important;
    }
  }

  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .chain-item-left {
    display: flex;
    align-items: center;
    gap: 8px;

    > .chain-symbol {
      color: var(--Deep-800, #1a2535);
      ${fontBold};
      font-size: 14px;
    }
  }

  > .chain-item-right {
    display: flex;
    align-items: center;
    gap: 8px;

    > .chain-balance {
      color: var(--Deep-400, #80868f);
      font-family: "HarmonyOS Sans SC";
      font-size: 14px;
    }

    > .expand-icon {
      width: 20px;
      height: 20px;
      background-image: ${(props) =>
        props.$expanded ? `url(${expandUpIcon})` : `url(${expandDownIcon})`};
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center center;
    }
  }
`;

const AssetContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

interface IProps {
  assets: FtAsset[];
  chains: Chain[];
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  expandAll?: boolean;
}

export const ChainAssetList: React.FC<IProps> = ({
  assets,
  chains,
  wrapperClassName,
  wrapperStyle,
  expandAll = false,
}) => {
  const { t } = useTranslation();
  const { marketStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedChainIndexes, setExpandedChainIndexes] = useState<string[]>(
    expandAll ? chains.map((chain) => chain.chainIndex) : []
  );

  const viewAssetInfo = useCallback(
    (asset: FtAsset) => {
      marketStore.setCurrentAssetQuote(asset);
      setSearchParams({
        ...searchParams,
        chainIndex: asset.chainIndex,
        ftAssetAddress: asset.address,
        showQuote: "1",
        hideNavigationBar: "1",
      });
    },
    [marketStore, searchParams, setSearchParams]
  );

  return (
    <Container className={wrapperClassName} style={wrapperStyle}>
      {chains.map((chain) => (
        <ChainItemWrapper key={chain.chainIndex}>
          <ChainItem
            key={chain.chainIndex}
            $expanded={expandedChainIndexes.includes(chain.chainIndex)}
            onClick={() => {
              if (expandedChainIndexes.includes(chain.chainIndex))
                setExpandedChainIndexes(
                  expandedChainIndexes.filter(
                    (index) => index !== chain.chainIndex
                  )
                );
              else
                setExpandedChainIndexes(
                  expandedChainIndexes.concat(chain.chainIndex)
                );
            }}
          >
            {/* Chain Left */}
            <div className="chain-item-left">
              <TokenOrChainIcon type={"chain"} size={22} src={chain.imageURL} />
              <span className="chain-symbol">{chain.chainSymbol}</span>
            </div>

            {/* Chain Right */}
            <div className="chain-item-right">
              <span className="chain-balance">{`$${assets
                .filter((asset) => asset.chainIndex === chain.chainIndex)
                .reduce(
                  (acc: KontosNumber, cur: FtAsset) =>
                    acc.add(
                      cur.balance?.balanceUsdAmount || 0,
                      DEFAULT_DECIMAL
                    ),
                  new KontosNumber(0)
                )
                .toFormat()}`}</span>
              <div className="expand-icon" />
            </div>
          </ChainItem>

          {/* Assets */}
          {expandedChainIndexes.includes(chain.chainIndex) &&
            (assets.filter((asset) => asset.chainIndex === chain.chainIndex)
              .length > 0 ? (
              <AssetContainer>
                {assets
                  .filter((asset) => asset.chainIndex === chain.chainIndex)
                  .map((asset) => (
                    <GeneralFtAssetItem
                      // onClick={viewAssetInfo}
                      key={asset.address}
                      asset={asset}
                      showAssetAddress
                      canCopyAddress
                      showAvailableLabel
                      showSecurityIcon
                      showBalance
                      showUsd
                      disableHoverToClick
                    />
                  ))}
              </AssetContainer>
            ) : (
              <NoDataV2
                style={{ marginTop: "16px", height: "auto" }}
                text={t("You have no assets on this chain")}
              />
            ))}
        </ChainItemWrapper>
      ))}
    </Container>
  );
};
