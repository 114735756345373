import styled from "styled-components";
import GuardianItem from "src/components/guardian/GuardianItem";
import React from "react";
import { t } from "i18next";
import SheetUpHeader from "src/components/common/header/SheetUpHeader";
import kontosAvatarIco from "src/assets/images/kontos-avatar-2.svg";
import Description from "src/components/description/Description";
import warnIco from "src/assets/icons/warn.svg";
import { Button } from "src/components/button/Button";
import { Input } from "src/components/input/Input";
import { fontBold, fontH5 } from "@/style/style.global";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .toAddress {
      color: var(--Deep-800, #1a2535);
      width: 100%;

      /* H5 */
      ${fontH5}
      padding-top: 16px;
      padding-bottom: 8px;
      padding-left: 8px;
    }

    .toAddressInput {
      width: 100%;
      position: relative;
      margin-bottom: 16px;

      input {
        height: 56px;
        border-radius: 8px;
        border: 1px solid var(--Deep-50, #edeef1);
        background: var(--Deep-25, #f5f5f6);
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        color: var(--Deep-400, #80868f);
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input::placeholder {
        color: var(--Deep-400, #80868f);
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      span {
        width: 75px;
        height: 29px;
        flex-shrink: 0;
        border-radius: 99px;
        background: var(--Deep-800, #1a2535);
        display: block;
        position: absolute;
        right: 14px;
        top: 14px;
        cursor: pointer;
        color: var(--White, #fff);
        text-align: center;
        ${fontBold}
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        line-height: 29px;
      }
    }
  }

  .buttonWrap {
    padding: 0 20px 16px;
  }
`;

interface Props {
  handleBack: () => void;
  currentGuardForName: string;
  onSuccess: () => void;
  currentGuardForAddress: string;
  setCurrentGuardForAddress: (address: string) => void;
}

function isHexString(value: string): boolean {
  return value.length === 66;
}

const GuardianForModal: React.FC<Props> = ({
  handleBack,
  currentGuardForName,
  onSuccess,
  currentGuardForAddress,
  setCurrentGuardForAddress,
}) => {
  const canSubmit = React.useMemo(() => {
    return isHexString(currentGuardForAddress);
  }, [currentGuardForAddress]);

  return (
    <Wrapper>
      <SheetUpHeader
        handleBack={() => {
          handleBack();
        }}
        headStyle={{ justifyContent: "center" }}
        title={t("Guard for")}
        padding="0 24px"
      />
      <div className={"main"}>
        <GuardianItem avatar={kontosAvatarIco} name={currentGuardForName} />
        <div className={"toAddress"}>Public key</div>
        <div className={"toAddressInput"}>
          <Input
            onChange={(e) => {
              setCurrentGuardForAddress(e);
            }}
            value={currentGuardForAddress}
            placeHolder={t("Please enter public key")}
          />
          <span
            onClick={() => {
              navigator.clipboard.readText().then((clipText) => {
                setCurrentGuardForAddress(clipText);
              });
            }}
          >
            Paste
          </span>
        </div>
        <Description
          icon={warnIco}
          text={
            "you must enter the public key provided by the user and submit to initiate the account recovery request."
          }
        />
      </div>
      <div className={"buttonWrap"}>
        <Button
          onClick={() => {
            console.log("newPubKeyFromGuardian");
            onSuccess();
          }}
          text={t("Submit")}
          height={"56px"}
          disabled={!canSubmit}
        />
      </div>
    </Wrapper>
  );
};

export default GuardianForModal;
