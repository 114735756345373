import { request } from "@zkkontos/kontos-sdk/src/api/core/request";
import { TEMP_EXPLORER_URL } from "src/config";
import { API } from "./core/API";
import * as apiTypes from "./types";

export interface ReqConfirmPayment {
  account: string;
  confirmations: apiTypes.ApiPaymentConfirmation[];
}

export interface RespConfirmPayment {
  opHashes: string[];
}

export const callConfirmPayment = async (
  data: ReqConfirmPayment,
  endpoint: string = TEMP_EXPLORER_URL
): Promise<RespConfirmPayment> => {
  return await request<ReqConfirmPayment, RespConfirmPayment>(
    API.payment.confirmPayment,
    data,
    endpoint
  );
};
