import { makeAutoObservable, reaction, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { Web3Wallet, Web3WalletTypes } from "@walletconnect/web3wallet";
import { buildApprovedNamespaces, getSdkError } from "@walletconnect/utils";
import { Core } from "@walletconnect/core";
import { Web3Wallet as WcClient } from "@walletconnect/web3wallet/dist/types/client";
import { SessionTypes } from "@walletconnect/types/dist/types/sign-client/session";
import { SignClientTypes } from "@walletconnect/types";
import { Verify } from "@walletconnect/types";
import { EIP155_SIGNING_METHODS } from "src/data/EIP155Data";
import toast from "src/components/toast/Toast";
import { t } from "i18next";
import {
  areAllStrings,
  convertChainId,
  getSignPramsMessageHex,
  getSignTypedDataParamsData,
  hexStringToUint8Array,
  isTimestampExpired,
  uint8ArrayToBase64,
} from "src/utils/helper";
import { KontosError, WcErrorType, wcError } from "src/type/error";
import {
  JsonRpcError,
  JsonRpcResult,
  formatJsonRpcError,
  formatJsonRpcResult,
} from "@walletconnect/jsonrpc-utils";
import { ethers } from "ethers";
import { SheetStore, SheetView } from "./SheetStore";
import { signMessage, signTx, signTypedData } from "src/utils/zkkontosHelper";
import { keccak256 } from "ethers/lib/utils";
import { loadingStore } from "./loadingStore";
import { KontosKey } from "@zkkontos/kontos-sdk";
import localKeeper from "./localKeeper";
import { fetchUserOpPaymentTask } from "src/service/trade-service";
import { WALLET_CONNECT_METADATA, WALLET_CONNECT_PROJECT_ID } from "src/config";

type RequestEventArgs = Omit<
  SignClientTypes.EventArguments["session_request"],
  "verifyContext"
>;

const RETRY_INTERVAL = 20000;

const SUPPORTED_EVENTS = [
  "chainChanged",
  "accountsChanged",
  "disconnect",
  "connect",
  "message",
];

const SUPPORTED_MWTHODS = Object.values(EIP155_SIGNING_METHODS);

type Validation = "VALID" | "INVALID" | "UNKNOWN";

export enum VerifyStatus {
  DomainMatch,
  Unverified,
  Mismatch,
  Threat,
}

export const verify = (
  isScam?: boolean,
  validation?: Validation
): VerifyStatus => {
  if (isScam) {
    return VerifyStatus.Threat;
  }
  switch (validation) {
    case "VALID":
      return VerifyStatus.DomainMatch;
    case "INVALID":
      return VerifyStatus.Mismatch;
    case "UNKNOWN":
      return VerifyStatus.Unverified;
    default:
      return VerifyStatus.Unverified;
  }
};

export class DappConnectStore {
  rootStore: RootStore;
  sheetStore: SheetStore;
  user: string;
  client?: WcClient;
  pairLoading: boolean = false;
  sessions: SessionTypes.Struct[] = [];
  aaAccounts: { [key: string]: string } = {};
  sessionProposal?: Web3WalletTypes.SessionProposal;
  sessionProposalWaiting: boolean = false;
  requestEvent?: SignClientTypes.EventArguments["session_request"];
  requestSession?: SessionTypes.Struct;
  expiredRequests: string[] = [];
  successfulRequests: number[] = [];
  rejectedRequests: number[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.sheetStore = rootStore.sheetStore;
    this.user = localKeeper.getWalletConnectUser();
    this.startTrackingAccountName();
    this.createClient();
  }

  get wcChains(): string[] {
    if (!this.rootStore.userStore.aaAccounts) return [];
    return Object.keys(this.rootStore.userStore.aaAccounts).map(
      (chainIndex) => "eip155:" + chainIndex
    );
  }

  get wcAccounts(): string[] {
    if (!this.rootStore.userStore.aaAccounts) return [];
    return Object.keys(this.rootStore.userStore.aaAccounts).map(
      (chainIndex) =>
        "eip155:" +
        chainIndex +
        ":" +
        this.rootStore.userStore.aaAccounts?.[chainIndex]
    );
  }

  get isActive(): boolean {
    return this.sessions.length > 0;
  }

  get proposalVerifyCtx(): Verify.Context | undefined {
    if (!this.sessionProposal) return undefined;
    return this.sessionProposal.verifyContext;
  }

  get proposalVerifyStatus(): VerifyStatus | undefined {
    if (!this.proposalVerifyCtx) return undefined;
    return verify(
      this.proposalVerifyCtx.verified.isScam,
      this.proposalVerifyCtx.verified.validation
    );
  }

  async createClient() {
    try {
      const core = new Core({
        projectId: WALLET_CONNECT_PROJECT_ID,
      });
      const web3wallet = await Web3Wallet.init({
        core,
        metadata: WALLET_CONNECT_METADATA,
      });
      const existedSessions = web3wallet.getActiveSessions();
      this.sessions = Object.values(existedSessions || []) || [];
      web3wallet.on("session_proposal", this.onSessionProposal);
      web3wallet.on("session_request", this.onSessionRequest);
      web3wallet.on("auth_request", (data) =>
        console.log("auth_request", data)
      );
      web3wallet.on("session_delete", (data) => {
        console.log("session_delete event received", data);
        this.refreshSessions();
      });
      web3wallet.on("session_request_expire", (data) => {
        console.log("session_request_expire event received", data);
      });

      web3wallet.engine.signClient.events.on("session_ping", (data) =>
        console.log("ping", data)
      );

      runInAction(() => {
        this.client = web3wallet;
      });
    } catch (e) {
      console.log("Failed to init wc client");
      setTimeout(() => {
        this.createClient();
      }, RETRY_INTERVAL);
    }
  }

  refreshSessions = () => {
    if (this.client) {
      const activeSessions = this.client.getActiveSessions();
      this.sessions = Object.values(activeSessions || []) || [];
    }
  };

  async onSessionProposal(sessionProposal: Web3WalletTypes.SessionProposal) {
    //TODO: log
    console.log("sessionProposal", sessionProposal);

    // If session loading or no pairing, return
    if (this.sessionProposalWaiting || !this.pairLoading) return;

    // If current sheet is not DappConnection, reset pairloading and return
    // if (
    //   this.sheetStore?.latestSheet?.view !== SheetView.DappConnection &&
    //   this.sheetStore?.latestSheet?.view !== SheetView.QrReader
    // ) {
    //   this.pairLoading = false;
    //   return;
    // }

    // Open session approve sheet and process
    this.sessionProposal = sessionProposal;
    this.sessionProposalWaiting = true;
    this.sheetStore.closeSheetByView(SheetView.QrReader);
    this.sheetStore.openSheet(SheetView.WcConnect);
    this.pairLoading = false;
  }

  async onSessionRequest(
    requestEvent: SignClientTypes.EventArguments["session_request"]
  ) {
    //TODO: log
    console.log("requestEvent", requestEvent);

    const { id, topic, params } = requestEvent;
    const { request } = params;
    const requestSession = this.client?.engine.signClient.session.get(topic);

    if (this.successfulRequests.includes(id)) {
      this.client?.respondSessionRequest({
        topic,
        response: formatJsonRpcError(id, getSdkError("USER_REJECTED").message),
      });
      return;
    }
    if (this.rejectedRequests.includes(id)) {
      this.client?.respondSessionRequest({
        topic,
        response: formatJsonRpcError(id, getSdkError("USER_REJECTED").message),
      });
      return;
    }

    switch (request.method) {
      case EIP155_SIGNING_METHODS.ETH_SIGN:
      case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
        return this.handleSign(requestEvent, requestSession);

      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
        return this.handleSignTypedData(requestEvent, requestSession);

      case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
        return this.handleSendTx(requestEvent, requestSession);

      case EIP155_SIGNING_METHODS.WALLET_ADD_ETHEREUM_CHAIN:
      case EIP155_SIGNING_METHODS.WALLET_SWITCH_ETHEREUM_CHAIN:
        return this.handleSwitchChain(requestEvent, requestSession);

      default:
        return this.handleNoMatchMethod();
    }
  }

  /**
   * Approves a WalletConnect session proposal.
   *
   * This method attempts to approve a session proposal with the current states.
   * If successful, it sets the session and returns the session details. However,
   * it may throw errors in several scenarios that the caller needs to handle:
   *
   * - Throws `wcNoSessionProposal` if no session proposal is currently available.
   * - Throws `wcSessionProposalExpired` if the session proposal has expired.
   * - Throws `KontosError` with `WcErrorType.ApproveSessionFail` if approving the session fails
   *   for any reason, including user rejection or network issues.
   *
   * The method also attempts to reject the session proposal in case of failure to approve,
   * logging any errors encountered during this rejection.
   *
   * Callers of this method should be prepared to catch and handle these exceptions,
   * potentially informing the user of the failure and its cause.
   *
   * @throws {KontosError} For general failures to approve the session proposal.
   * @returns {Promise<void>} A promise that resolves when the session proposal has been processed.
   */
  async approveSessionProposal(): Promise<void> {
    if (this.sessionProposal === undefined) {
      throw wcError.wcNoSessionProposal;
    }

    if (isTimestampExpired(this.sessionProposal.params.expiryTimestamp, true)) {
      throw wcError.wcSessionProposalExpired;
    }

    const allowOneChain =
      this.sessionProposal.params.proposer.metadata.name
        .toLowerCase()
        .includes("pancake") ||
      this.sessionProposal.params.proposer.metadata.name
        .toLowerCase()
        .includes("1inch") ||
      this.sessionProposal.params.proposer.metadata.name
        .toLowerCase()
        .includes("uniswap");

    try {
      if (this.wcAccounts.length === 0 || this.wcChains.length === 0) {
        await this.rootStore.userStore.executeWithAccountInfoRetry(
          this.rootStore.userStore.fetchAndSetAaAccounts
        );
      }
      // ------- namespaces builder util ------------ //
      const requiredChainIndex =
        this.sessionProposal.params?.requiredNamespaces?.eip155?.chains?.[0];
      console.log("requiredChainIndex", requiredChainIndex);
      const newChainIdFormatted = requiredChainIndex
        ? requiredChainIndex.replace("eip155:", "")
        : "1";
      console.log("newChainIdFormatted", newChainIdFormatted);
      const matchedChainIndex = Object.keys(this.rootStore.userStore.aaAccounts)
        .map((chainIndex) => chainIndex)
        .find((idx) => idx === newChainIdFormatted);
      const chainId = "eip155:" + matchedChainIndex;
      console.log("this.wcChains", this.wcChains);
      console.log("this.wcAccounts", this.wcAccounts);
      const approvedNamespaces = buildApprovedNamespaces({
        proposal: this.sessionProposal.params,
        supportedNamespaces: {
          eip155: {
            chains: allowOneChain ? [chainId] : this.wcChains,
            // chains: [chainId],
            accounts: this.wcAccounts,
            methods: SUPPORTED_MWTHODS,
            events: SUPPORTED_EVENTS,
          },
        },
      });
      // TODO: log
      console.log("approvedNamespaces", approvedNamespaces);
      // ------- end namespaces builder util ------------ //
      const session = await this.client?.approveSession({
        id: this.sessionProposal.id,
        namespaces: approvedNamespaces,
      });
      //TODO: log
      console.log("session", session);
      this.rootStore.discoverStore.addDappHistory({
        metadata: this.sessionProposal.params.proposer.metadata,
        verifyContext: this.sessionProposal.verifyContext,
      });
      runInAction(() => {
        session !== undefined && this.sessions.push(session);
      });
    } catch (e) {
      // use the error.message to show toast/info-box letting the user know that the connection attempt was unsuccessful
      console.log(`Failed to approve session`, e);
      try {
        await this.client?.rejectSession({
          id: this.sessionProposal.id,
          reason: getSdkError("USER_REJECTED"),
        });
      } catch (e2) {
        console.log("Failed to reject session", e2);
      }
      const errorMessage =
        e instanceof Error ? e.message : wcError.approveSessionFail.message;
      throw new KontosError(WcErrorType.ApproveSessionFail, errorMessage);
    }
  }

  handleSign = async (
    requestEvent: SignClientTypes.EventArguments["session_request"],
    requestSession: SessionTypes.Struct | undefined
  ) => {
    this.requestEvent = requestEvent;
    this.requestSession = requestSession;
    const sheet = this.sheetStore.openSheetWithCheck(SheetView.Sign, {
      requestEvent,
      requestSession,
      isMessgae: true,
    });
    if (sheet === null) {
      await this.rejectEIP155RequestAndRespond(requestEvent);
    }
  };

  handleSignTypedData = async (
    requestEvent: SignClientTypes.EventArguments["session_request"],
    requestSession: SessionTypes.Struct | undefined
  ) => {
    this.requestEvent = requestEvent;
    this.requestSession = requestSession;
    const sheet = this.sheetStore.openSheetWithCheck(SheetView.Sign, {
      requestEvent,
      requestSession,
      isMessgae: false,
    });
    if (sheet === null) {
      await this.rejectEIP155RequestAndRespond(requestEvent);
    }
  };

  handleSendTx = async (
    requestEvent: SignClientTypes.EventArguments["session_request"],
    requestSession: SessionTypes.Struct | undefined
  ) => {
    // console.log("handleSendTx requestEvent", requestEvent);
    // console.log("handleSendTx requestSession", requestSession);
    this.requestEvent = requestEvent;
    this.requestSession = requestSession;
    const { params } = requestEvent;

    const chainId = convertChainId(params?.chainId, "toDecimal");
    const targetAddress = params.request.params?.[0]?.to;
    const callDataHex = params.request.params?.[0]?.data as string;
    const valueHex = params.request.params?.[0]?.value as string;

    console.log("chainId", chainId);

    if (areAllStrings(chainId, targetAddress, callDataHex)) {
      const callDataBytes = hexStringToUint8Array(callDataHex);
      const callDataBase64 = uint8ArrayToBase64(callDataBytes);

      const data = {
        account: this.rootStore.userStore.accountName!,
        payer: this.rootStore.userStore.accountName!,
        chainIndex: chainId as string,
        targetAddress: targetAddress as string,
        value: valueHex ? parseInt(valueHex, 16).toString() : "0",
        callData: callDataBase64 as string,
      };

      try {
        loadingStore.showLoading();
        const taskData = await fetchUserOpPaymentTask(
          data.account,
          data.payer,
          data.chainIndex,
          data.targetAddress,
          data.value,
          data.callData
        );

        const sheet = this.sheetStore.openSheetWithCheck(
          SheetView.ContractInteraction,
          {
            requestEvent,
            requestSession,
            taskData,
          }
        );
        if (sheet === null) {
          await this.rejectEIP155RequestAndRespond(requestEvent);
        }
      } catch (e) {
        console.log(e);
        toast({
          text: t("Failed to get transaction data, please try again later"),
          type: "error",
        });
      } finally {
        loadingStore.hideLoading();
      }
    }
  };

  async handleSwitchChain(
    requestEvent: SignClientTypes.EventArguments["session_request"],
    requestSession?: SessionTypes.Struct
  ) {
    const { id, topic, params } = requestEvent;
    const newChainId = params.request.params?.[0]?.chainId;
    console.log("newChainId", newChainId);
    if (
      typeof newChainId === "string" &&
      newChainId &&
      requestEvent !== undefined
    ) {
      const newChainIdFormatted = ethers.BigNumber.from(newChainId).toString();
      const matchedChainIndex = Object.keys(this.rootStore.userStore.aaAccounts)
        .map((chainIndex) => chainIndex)
        .find((idx) => idx === newChainIdFormatted);
      console.log("matchedChainIndex", matchedChainIndex);
      if (matchedChainIndex !== undefined) {
        const address = this.rootStore.userStore.aaAccounts[matchedChainIndex];
        const chainId = "eip155:" + matchedChainIndex;

        const accountsChanged = {
          topic: requestSession!.topic,
          event: {
            name: "accountsChanged",
            data: [`${chainId}:${address}`],
          },
          chainId,
        };

        console.log("accountsChanged", accountsChanged);

        const isOneInch = requestSession?.peer.metadata.name
          .toLowerCase()
          .includes("1inch");

        try {
          const updateSessionParams = {
            topic,
            namespaces: {
              ...requestSession?.namespaces,
              eip155: {
                ...requestSession!.namespaces.eip155,
                chains: isOneInch ? [chainId, "eip155:1"] : [chainId],
                accounts: [
                  `${chainId}:${address}`,
                  `eip155:1:0x32ae88936f94f3c5c8e0ed152bd2b034b4ea0fbc`,
                ],
              },
            },
          };
          console.log("updateSessionParams", updateSessionParams);
          await this.client?.updateSession(updateSessionParams);
          await this.client?.respondSessionRequest({
            topic,
            response: formatJsonRpcResult(id, `${chainId}:${address}`),
          });

          await this.client?.emitSessionEvent(accountsChanged);
          setTimeout(() => {
            this.client?.emitSessionEvent(accountsChanged);
          }, 1500);
          console.log("acc");
        } catch (e) {
          console.log("Switch chain failed", e);
          return;
        }
      } else {
        try {
          await this.client?.respondSessionRequest({
            topic,
            response: formatJsonRpcError(
              id,
              "Currently Kontos does not support this chain"
            ),
          });
        } catch (e) {
          console.log("Notify chain failed", e);
          return;
        }
      }
    }
  }

  handleNoMatchMethod() {}

  async onPair(uri: string) {
    if (this.pairLoading) return;
    this.pairLoading = true;
    try {
      await this.client?.pair({ uri });
    } catch (e) {
      console.log(`Failed to pair with ${uri}`, e);
      const errorMessage = e instanceof Error ? e.message : t("Connect failed");
      toast({
        type: "error",
        text: errorMessage,
      });
      runInAction(() => {
        this.pairLoading = false;
      });
    }
  }

  async onDelete(topic: string, pairingTopic: string) {
    try {
      await this.client?.disconnectSession({
        topic,
        reason: getSdkError("USER_DISCONNECTED"),
      });
      await this.client?.core.pairing.disconnect({
        topic: pairingTopic,
      });
    } catch (e) {
      console.log("Failed to disconnect", e);
    } finally {
      runInAction(() => {
        this.sessions = this.sessions.filter(
          (session) => session.topic !== topic
        );
      });
    }
  }

  rejectEIP155RequestAndRespond = async (requestEvent: RequestEventArgs) => {
    try {
      loadingStore.showLoading();
      const { topic } = requestEvent;
      const response = this.rejectEIP155Request(requestEvent);
      await this.client?.respondSessionRequest({ topic, response });
    } catch (e: any) {
      return e;
    } finally {
      loadingStore.hideLoading();
    }
  };

  rejectEIP155Request = (requestEvent: RequestEventArgs) => {
    const { id } = requestEvent;

    return formatJsonRpcError(id, getSdkError("USER_REJECTED").message);
  };

  approveEIP155RequestAndRespond = async (
    requestEvent: RequestEventArgs,
    wallet: KontosKey,
    result?: {
      errorMessage?: string;
      txHash?: string;
    }
  ) => {
    const { topic } = requestEvent;
    loadingStore.showLoading();
    try {
      const response = await this.approveEIP155Request(
        requestEvent,
        wallet,
        result
      );
      try {
        await this.client?.respondSessionRequest({ topic, response });
      } catch (e) {
        console.log("Failed to respond", e);
      }
      return true;
    } catch (e) {
      if (e instanceof KontosError && e.name === wcError.noCli.name) {
        this.rootStore.userStore.unlock(() =>
          this.approveEIP155RequestAndRespond(requestEvent, wallet, result)
        );
        return;
      }
      if (e instanceof KontosError && e.name === wcError.invalidMethod.name) {
        toast({
          type: "error",
          text: e.message,
        });
      }
      const errorMessage =
        e instanceof Error
          ? e.message
          : t("Signature failed, please try again later.");
      toast({
        type: "error",
        text: errorMessage,
      });
    }
  };

  approveEIP155Request = async (
    requestEvent: RequestEventArgs,
    wallet: KontosKey,
    result?: {
      errorMessage?: string;
      txHash?: string;
    }
  ): Promise<JsonRpcError | JsonRpcResult> => {
    const { params, id } = requestEvent;
    const { chainId, request } = params;
    const decimalChainId = convertChainId(chainId, "toDecimal");

    console.log("Approving EIP155 Request...", requestEvent, decimalChainId);

    switch (request.method) {
      case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
      case EIP155_SIGNING_METHODS.ETH_SIGN:
        try {
          loadingStore.showLoading();
          const messageHex = getSignPramsMessageHex(request.params);
          const messageHash = keccak256(messageHex);
          const signedMessage = await signMessage(
            this.rootStore.userStore.accountName!,
            messageHash,
            wallet
          );
          return formatJsonRpcResult(id, signedMessage);
        } catch (e: any) {
          console.log(`Failed to approve ${request.method}`, e);
          const errorMessage =
            e instanceof Error
              ? e.message
              : t("Signature failed, please try again later.");
          toast({
            type: "error",
            text: errorMessage,
          });
          return formatJsonRpcError(id, errorMessage);
        } finally {
          loadingStore.hideLoading();
        }

      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
        try {
          loadingStore.showLoading();
          const {
            domain,
            types,
            message: data,
            primaryType,
          } = getSignTypedDataParamsData(request.params);

          console.log(
            "getSignTypedDataParamsData",
            domain,
            types,
            data,
            primaryType
          );

          // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
          delete types.EIP712Domain;

          const signedData = await signTypedData(
            this.rootStore.userStore.accountName!,
            {
              domain,
              types,
              primaryType,
              message: data,
            },
            wallet
          );

          console.log("signedData", signedData);

          return formatJsonRpcResult(id, signedData);
        } catch (e: any) {
          console.log(`Failed to approve ${request.method}`, e);
          const errorMessage =
            e instanceof Error
              ? e.message
              : t("Signature failed, please try again later.");
          toast({
            type: "error",
            text: errorMessage,
          });
          return formatJsonRpcError(id, errorMessage);
        } finally {
          loadingStore.hideLoading();
        }

      case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
        try {
          loadingStore.showLoading();
          if (result?.errorMessage) {
            toast({
              type: "error",
              text: result.errorMessage,
            });
            return formatJsonRpcError(id, result.errorMessage);
          } else {
            return formatJsonRpcResult(id, result?.txHash);
          }
        } catch (error: any) {
          console.error(error);
          return formatJsonRpcError(id, error.message);
        } finally {
          loadingStore.hideLoading();
        }

      case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
        try {
          loadingStore.showLoading();
          const signTransaction = request.params[0];
          const signature = await signTx(
            this.rootStore.userStore.accountName!,
            signTransaction,
            wallet
          );
          return formatJsonRpcResult(id, signature);
        } catch (e: any) {
          console.log(`Failed to approve ${request.method}`, e);
          const errorMessage =
            e instanceof Error
              ? e.message
              : t("Signature failed, please try again later.");
          toast({
            type: "error",
            text: errorMessage,
          });
          return formatJsonRpcError(id, errorMessage);
        } finally {
          loadingStore.hideLoading();
        }

      default:
        throw wcError.invalidMethod;
      //throw new Error(getSdkError("INVALID_METHOD").message);
    }
  };

  markUsedRequest = (id: number, isSuccess: boolean) => {
    if (isSuccess) {
      runInAction(() => {
        this.successfulRequests.push(id);
      });
    } else {
      runInAction(() => {
        this.rejectedRequests.push(id);
      });
    }
  };

  clearSessionProposal = () => {
    runInAction(() => {
      this.sessionProposal = undefined;
      this.sessionProposalWaiting = false;
    });
  };

  disConnectAll = async () => {
    if (this.sessions.length > 0) {
      await Promise.all(
        this.sessions.map((session) => {
          return this.onDelete(session.topic, session.pairingTopic);
        })
      );
    }
  };

  startTrackingAccountName = async () => {
    reaction(
      () => this.rootStore.userStore.accountName,
      async () => {
        if (
          !!this.rootStore.userStore.accountName &&
          this.user !== this.rootStore.userStore.accountName
        ) {
          await this.disConnectAll();
          this.user = this.rootStore.userStore.accountName;
          localKeeper.setWalletConnectUser(
            this.rootStore.userStore.accountName
          );
        }
      }
    );
  };

  // async fetchAndUpdateAaAccounts() {
  //   const resp = await callAaaccounts(this.rootStore.userStore.accountName);
  //   let aaAccounts = resp.aaAccounts;
  //   const nameAddress = KontosQueryCli.nameAddress(
  //     this.rootStore.userStore.accountName
  //   );
  //   aaAccounts[0] = nameAddress;
  //   runInAction(() => {
  //     this.aaAccounts = aaAccounts;
  //   });
  // }
}
